import {
    DEPLOYMENT_DATA,
} from "../actionTypes";


const initialState = {
    deploymentData:{
        currentState:"asd",
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case DEPLOYMENT_DATA: {

            return {
                ...state,
                deploymentData: {...state.deploymentData, ...action.payload.deploymentData},
            }
        }

        default:
            return state;
        }
}
