import React from 'react';
import VideoContent from './videoContent';

class BasicPortlet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
      }

    render() {
        return(
            <div className={`portlet ${this.props.className}`}>
                <div className="portlet-title">
                    <div className="caption">
                        {this.props.id === "discoverSearch" && this.props.searchText !== "" &&  this.props.searchText !== undefined ?
                        <><strong className="font-dark">{ '"' + this.props.searchText + '" '}</strong><span className="text-muted">Search results</span></>:
                        <span className="text-uppercase font-dark">{this.props.title}</span>
                        }
                    </div>
                    <div className='actions d-flex'>
                        {
                            this.state.showModal ?
                            <VideoContent
                                show={this.state.showModal}
                                onHide={this.hideModal}
                                video_url={this.props.video_url}
                                name={this.props.id === "discoverSearch" ? "Discover" : this.props.title}
                            /> : ""
                        }
                    </div>
                </div>
                <div className={this.props.bodyClassName !== undefined ? `portlet-body ${this.props.bodyClassName}`:"portlet-body"} id={this.props.id}>
                    {this.props.content}
                </div>
            </div>
        );
    }
}

export default BasicPortlet;