import {ui_service_ip, iam_ip} from "./ip_details";

import { getProtocol, removeLocalStorageKeys } from "./common_utils";


function call_update_api(ip, end_point,data, token="") {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            removeLocalStorageKeys();
            window.location = '/';
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    return fetch(protocol+'//'+ip+'/'+end_point, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':token,
            "userId": user_id, "teamId": team_id}
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.log(end_point, "- failed"));
} 

function call_post_api(ip, end_point,data, token="") {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            removeLocalStorageKeys();
            window.location = '/';
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    return fetch(protocol+'//'+ip+'/'+end_point, {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'authToken':token, "api-type": "qualdo_db_auth", 'userId':user_id, 'teamId':team_id }
        }).then((response) => response.json()).then((responseData) => {
              return responseData;
        }).catch( error => console.log(end_point, "- failed"));
}

function call_iam_get_api(ip, end_point,data, token="") {
        let session =  localStorage.getItem('sessionToken');
        let user_id = localStorage.getItem('user_id');
        let team_id = localStorage.getItem('team_id');
        if (session !== "" && session !== null) {
            const item = JSON.parse(session);
            const now = new Date();
            if (now.getTime() > item.expiry) {
                localStorage.removeItem("sessionToken");
                window.location = '/';
            }
            session = item.value;
        }
        let protocol = getProtocol(ip);
        return fetch(protocol+'//'+ip+'/'+end_point, {
                method: 'GET',
                headers:{ 'data':JSON.stringify(data), 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth', 'authToken':session, "userId": user_id, "teamId": team_id}
            }).then((response) => response.json()).then((responseData) => {
                return responseData;
            }).catch( error => console.log(end_point, "- failed"));
}

export function getApi(api_name) {
    let data = {tenant_id:localStorage.getItem('team_id')}
    switch(api_name) {
        case "edition_details":
            call_iam_get_api(ui_service_ip, "plan/edition",data).then( (response) =>{
                if(response.code === 200) {
                    if (Object.keys(response['edition_details']).length > 0)
                    {
                        this.setState({edition_details:response['edition_details'],
                                       next_billing_date:response["next_billing_date"]});
                    }
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        case "user_details":
           call_iam_get_api(iam_ip, "user",{"user_id":localStorage.getItem("user_id")}).then( (response) =>{
                if(response.code === 200) {
                    if ("user_details" in response && response['user_details'] !==null)
                    {
                        let user_details=response['user_details'];
                        this.setState({first_name: user_details["first_name"],
                        last_name:user_details["last_name"],
                        org_name: user_details["org_name"],
                        email: user_details["email"],
                        phoneNo: user_details["phone_number"],
                        user_profile_image: user_details["user_profile_image"]})
                    }
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({errorOccurred: true});
                }
            });
            break;
        default:
            break;
        }
}

export function handleUserSubmit(event){
    event.preventDefault();
    let session = localStorage.getItem("sessionToken");
    let user_id = localStorage.getItem("user_id");
    const { name } = event.target;
    switch(name) {
        case "changePassword":
              const passwordData = new FormData(event.target);
              const passwordDataValue = {"user_id":localStorage.getItem('user_id'),
                                 "current_password":passwordData.get("currentPassword"),
                                 "reset_password":passwordData.get("password")}
              call_post_api(iam_ip, "profile/reset-password", passwordDataValue, session).then(
                (response) => {
                    if(response.code === 200) {
                       this.showToast("alert-success", "password changed successfully", "");
                    }else{
                     this.showToast("alert-danger", "could not change password", "");
                    }
                }
            );
            break;
        case "update_user_details":
            this.setState({disableButton: true});
            const profile_data = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                org_name: this.state.org_name,
                user_profile_image: this.state.user_profile_image
            };
            const userDetailUpdateURL = "user";
            profile_data["team_id"] = localStorage.getItem("team_id");
            profile_data["org_id"] = localStorage.getItem("org_id");
            profile_data["user_id"] = user_id;
            profile_data["detail_type"] = "account";
            call_update_api(iam_ip, userDetailUpdateURL, profile_data, session).then((response) =>{
                if(response.code === 200) {
                    localStorage.setItem("user_name",response.data["username"]);
                    this.showToast("alert-success","User details updated successfully",'');
                } else if(response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                }
                else {
                    this.showToast("alert-danger","could not update user details",'');
                }
            });
            break;
        default:
            break;
            }
    }