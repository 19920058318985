import $ from "jquery";
import { browserHistory } from 'react-router';
import { iam_ip, ui_service_ip } from "./ip_details";
import {
    BRAIN_TREE_AUTH_TOKEN,
    SUBSCRIPTION_PLANS,
    VALID_QUALDO_PLANS,
    QualdoPlanVersions,
    PERMISION_DENIED,
    INSUFFICIENT_QUATO,
    ACCOUNT_MISMATCH,
    REQUIRED_QUOTAS,
    REQUIRED_PERMISSIONS, 
    DEPLOYMENT_DATA,
    DEPLOYMENT_FAILURE,
} from "./constant";
import {
    getCompleteEndpoint, getProtocol,
    isPlanChanged, removeLocalStorageKeys
} from "./common_utils";
import { postMixpanel } from '../mixpanel';


//eslint-disable-next-line
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}


export function call_post_api(ip, end_point, data, token = localStorage.getItem("sessionToken")) {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            removeLocalStorageKeys();
            window.location = '/';
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': token, "userId": user_id, "teamId": team_id }
    }).then((response) => response.json()).then((responseData) => {
        return responseData;
    }).catch(error => console.log(end_point, "- failed"));
}

export function call_update_api(ip, end_point, data, token = localStorage.getItem("sessionToken")) {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            removeLocalStorageKeys();
            window.location = '/';
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': token,
            "userId": user_id, "teamId": team_id
        }
    }).then((response) => response.json()).then((responseData) => {
        return responseData;
    }).catch(error => console.log(end_point, "- failed"));
}

function call_delete_api(ip, end_point, data = {}, token = localStorage.getItem("sessionToken")) {
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            removeLocalStorageKeys();
            window.location = '/';
        }
        token = item.value;
    }
    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);

    return fetch(reqEndPoint, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': token, "userId": user_id, "teamId": team_id }
    }).then((response) => response.json()).then((responseData) => {
        return responseData;
    }).catch(error => console.log(end_point, "- failed"));
}

export function call_get_api(ip, end_point, data, token = localStorage.getItem("sessionToken")) {
    let session = localStorage.getItem('sessionToken');
    let user_id = localStorage.getItem('user_id');
    let team_id = localStorage.getItem('team_id');
    let deployment_id = localStorage.getItem('deployment_id');
    if (session !== "" && session !== null && session !== undefined) {
        const item = JSON.parse(session);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem("sessionToken");
            window.location = '/';
        }
        session = item.value;
    }

    let protocol = getProtocol(ip);
    const reqEndPoint = getCompleteEndpoint(protocol, ip, end_point);
    return fetch(reqEndPoint, {
        method: 'GET',
        // 'user_plan': 'professional', 'plan_cycle': 'annually'
        headers: { "data": JSON.stringify(data), 'Content-Type': 'application/json', 'api-type': 'qualdo_db_auth', 'authToken': session, "userId": user_id, "teamId": team_id, 'deployment_id': deployment_id }
    }).then((response) => response.json()).then((responseData) => {
        return responseData;
    }).catch((error) => {
        console.log(reqEndPoint, JSON.stringify(error));
    });
}

export function postUIlogs(error, info) {
    let session = localStorage.getItem("sessionToken");
    let postError = { "ui_logs": { "message": error.toString(), "info": info } }
    call_post_api(ui_service_ip, "/ui_logs", postError, session).then((res) => {
        console.log(res)
    });

}

export const countErrors = (errors) => {
    let count = 0;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (count = count + 1)
    );
    return count;
}

export function getPaymentDetailsTableData(cardData) {
    let allPaymentDetails = [];
    if (cardData === undefined || cardData === null) {
        return allPaymentDetails;
    }

    for (let card of cardData) {
        let cardDetail = card.details;
        let requiredCardData = {
            "customerName": cardDetail.cardholder_name,
            "isPrimary": card.is_default === 1,
            "id": card.payment_detail_id,
            "brand": cardDetail.card_type,
            "expires": `${String(cardDetail.expiration_month)}/${cardDetail.expiration_year % 100}`,
            "lastFourDigits": cardDetail.last_4,
        };
        allPaymentDetails.push(requiredCardData);
    }

    return allPaymentDetails;
}

export function getBillingAddressDetailsTableData(addressDetails) {
    let reqAddressDetails = [];

    if (addressDetails === undefined || addressDetails === null) {
        return reqAddressDetails;
    }
    let prime = addressDetails.filter(stu => stu.details.isPrimary === true);

    if (prime.length === 0) {
        prime = addressDetails.filter(stu => stu.details.isPrimary !== true);
        prime[0]['details']['billing_address_id'] = prime[0]['billing_address_id']
        prime = prime[0]
    }
    else {
        prime[0]['details']['billing_address_id'] = prime[0]['billing_address_id']
        prime = prime[0]
    }

    return prime;
}

export function getBillingAddressDetails(addressDetails) {
    let reqAddressDetails = [];
    if (addressDetails === undefined || addressDetails === null) {
        return reqAddressDetails;
    }
    for (let data of addressDetails) {
        let billingAddress = data.details;
        reqAddressDetails[data.billing_address_id] = billingAddress;
    }

    return reqAddressDetails;
}

// 

export function getpendingPaymentTableData(billingHistory) {
    let headers = ["Order ID", "Deployment Name", "Edition",
        "Cloud", "Amount", "Validity Period", "Auto Renewal", "Actions"];
    let billingHistoryTableRows = [];
    let requiredData = []
    let billingTableData = {
        "headers": headers,
        "data": billingHistoryTableRows,
        "requiredData": requiredData,
    };

    if (billingHistory === undefined || billingHistory === null) {
        return billingTableData;
    }
    let index = 0;
    for (let deployment of billingHistory) {
        if (deployment.payment_status ===0) {
            let rowData = [];
            let reqData = {};
            let billingDetails = deployment.billing_history
            for (let billing of billingDetails) {


                rowData.push({ "value": billing.order_id, "type": "td" });
                rowData.push({ "value": deployment.deployment_name, "type": "td" });
                rowData.push({ "value": deployment.edition, "type": "td" });
                rowData.push({ "value": deployment.cloud, "type": "td" });
                rowData.push({ "value": billing.amount, "type": "td" });
                rowData.push({ "value": deployment.validity_date, "type": "td" });
                if (deployment.auto_debit_status === 0) {
                    rowData.push({ "value": 'No', "type": "td" });
                }
                else {
                    rowData.push({ "value": billing.details.billing_cycle, "type": "td" });
                }
            reqData['renew'] =  deployment.renew
            reqData['payment_status'] = deployment.payment_status
            reqData['deployment_id'] = deployment.deployment_id
            rowData.push({"value": reqData, "type": "td"});
            requiredData.push(reqData);
            }
            billingHistoryTableRows.push(rowData);
            index = index + 1;
        }

    }
    return billingTableData;
}

export function getupcomingPaymentTableData(billingHistory) {
    let headers = ["Order ID", "Deployment Name", "Edition",
        "Cloud", "Amount", "Validity Period", "Auto Renewal", "Actions"];
    let billingHistoryTableRows = [];
    let requiredData = []
    let billingTableData = {
        "headers": headers,
        "data": billingHistoryTableRows,
        "requiredData": requiredData,
    };

    if (billingHistory === undefined || billingHistory === null) {
        return billingTableData;
    }
    let index = 0;
    for (let deployment of billingHistory) {
        if (deployment.is_active === 1 && (deployment.status === 'deployed' || deployment.status === 'deletion initiated' || deployment.status === 'deleted' || deployment.status === 'active' || deployment.status === 'deletion failed' )) {
            let rowData = [];
            let reqData = {};
            let billingDetails = deployment.billing_history
            for (let billing of billingDetails) {
                rowData.push({ "value": billing.order_id, "type": "td" });
                rowData.push({ "value": deployment.deployment_name, "type": "td" });
                rowData.push({ "value": deployment.edition, "type": "td" });
                rowData.push({ "value": deployment.cloud, "type": "td" });
                rowData.push({ "value": billing.amount, "type": "td" });
                rowData.push({ "value": deployment.validity_date, "type": "td" });
                if (deployment.auto_debit_status === 0) {
                    rowData.push({ "value": 'No', "type": "td" });
                }
                else {
                    rowData.push({ "value": billing.details.billing_cycle, "type": "td" });
                }
            reqData['renew'] =  deployment.renew
            reqData['payment_status'] = deployment.payment_status
            reqData['deployment_id'] = deployment.deployment_id
            rowData.push({"value": reqData, "type": "td"});
            requiredData.push(reqData);
            }
            billingHistoryTableRows.push(rowData);
            index = index + 1;
        }

    }
    return billingTableData;
}

export function getBillingHistoryTableData(billingData) {
    let headers = ["Order ID", "Deployment Name", "Edition",
        "Cloud", "Billing Date", "Card Number","Coupon", "Amount", "Status", "Pdf"];
    let billingTableRows = [];

    let billingTableData = {
        "headers": headers,
        "data": billingTableRows
    };

    if (billingData === undefined || billingData === null) {
        return billingTableData;
    }
    let index = 0;
    for (let billing of billingData) {
        let rowData = [];
        let billingDetail = billing.billing_history

        /**
         * Replace the deleted deployment name
         * deleted deployement name is modified in backend
         */
        if(billing.is_active === 0){
            let name = billing.deployment_name
            const splitName = name.split("__1__");
            billing.deployment_name = splitName[0]
        }
        for (let details of billingDetail) {
            rowData.push({ "value": details.order_id, "type": "td" });
            rowData.push({ "value": billing.deployment_name, "type": "td" });
            rowData.push({ "value": billing.edition, "type": "td" });
            rowData.push({ "value": billing.cloud, "type": "td" });

            rowData.push({ "value": `${details.created_time}`, "type": "td" });
            rowData.push({ "value": `**** ${details.details.last_4}`, "type": "td" });
            rowData.push({ "value": `${details.coupon_code}`, "type": "td" });
            rowData.push({ "value": `${details.currency_symbol}${details.amount}`, "type": "td" });
            if (details.payment_status === 1) {
                rowData.push({ "value": "Paid", "type": "td" });
            }
            else {
                rowData.push({ "value": "Unpaid", "type": "td" });

            }
            rowData.push({ "value": billing, "type": "td" });
        }
        billingTableRows.push(rowData);
        index = index + 1;
    }

    return billingTableData;
}


function consume_group_table(input_data) {
    let headers = ["Notification Name", "Notification Type", "Created by", "Created Date", "Actions"];
    let final_op = [];

    for (let i = 0; i < input_data.length; i++) {
        let row = [];
        const consume_data = input_data[i];
        row.push({ "value": consume_data["consume_group_name"], "type": "td" })
        row.push({ "value": consume_data["consume_group_type"], "type": "td" })
        row.push({ "value": localStorage.getItem('user_name'), "type": "td" });
        row.push({ "value": consume_data['created_time'], "type": "td" });
        row.push({ "consume": consume_data, "type": "component" })
        final_op.push(row)
    }

    return { "headers": headers, "data": final_op };
}

export function getApi(api_name, details = {}) {
    const team_id = localStorage.getItem('team_id');
    const user_id = localStorage.getItem('user_id');
    let data = { "tenant_id": team_id }
    switch (api_name) {
        case "consume_group":
            call_get_api(ui_service_ip, "consume_group", data).then((response) => {
                if (response === undefined) {
                    this.setState({ errorOccurred: true });
                } else if (response.code === 200) {
                    let res = consume_group_table(response.consume_group);
                    this.setState({ data: res })
                    this.setState({ notification_title: "AVAILABLE NOTIFICATIONS" })
                } else if (response.code === 403) {
                    localStorage.removeItem("sessionToken");
                    window.location = '/';
                } else {
                    this.setState({ errorOccurred: true });
                }
            })
            break;

        case "accountDetails":
            call_get_api(ui_service_ip, "account/features", data).then((res_1) => {
                if (res_1.code === 200) {
                    const accPlanDetails = res_1.plan_details;
                    this.setState({
                        mlLimitExceed: accPlanDetails.ml_usage_exceeded,
                        maxModel: accPlanDetails.max_ml_model_limit,
                        planName: accPlanDetails.plan_name,
                        dataLimitExceed: accPlanDetails.usage_exceeded
                    });
                } else {
                    this.setState({ mlLimitExceed: null, maxModel: null });
                }
            });
            break;

        case "get_user_payments_and_billing_history":
            // data['deployment_id'] = localStorage.getItem('deployment_id')
            data['user_id'] = localStorage.getItem('user_id');
            call_get_api(iam_ip, "customer/payment", data).then(
                (response) => {
                    if (response === undefined) {
                        this.setState({ errorOccurred: true });
                    } else if (response.code === 200) {
                        let data = response.data;
                        let paymentDetails = data.payment_details;
                        let billingHistory = data.deployment_details;
                        let plan_details = data.plan_details;
                        let billingAddress = data.billing_address
                        if (plan_details === undefined) {
                            plan_details = {};
                        }

                        let auto_debit_status = plan_details.auto_debit_status === 1;
                        let nextPaymentDates = billingHistory.map((billingHistory) => billingHistory.next_payment_date);
                        let upcomingPayment = getupcomingPaymentTableData(billingHistory)
                        let pendingPayment = getpendingPaymentTableData(billingHistory)
                        let allDetails = getPaymentDetailsTableData(paymentDetails);
                        billingAddress = getBillingAddressDetails(billingAddress);
                        let billingAddressTableData = getBillingAddressDetailsTableData(data.billing_address)
                        let billingHistoryTableData = getBillingHistoryTableData(billingHistory);
                        let active_deployment = [];
                        let pending_payment_deployment = [];
                        for (let deployment of billingHistory) {
                            if (deployment.is_active === 1 && (deployment.status === 'deployed' || deployment.status === 'deletion initiated' || deployment.status === 'deleted' || deployment.status === 'active' || (deployment.is_active === 0 && deployment.status === 'deletion failed'))) {
                                active_deployment.push(deployment)
                            }
                        }
                        for (let deployment of billingHistory) {
                            if (deployment.payment_status === 0 && deployment.is_active === 1) {
                                pending_payment_deployment.push(deployment)
                            }
                        }
                        this.setState({
                            deployment_details: active_deployment,
                            pendingDeploymentDetails:pending_payment_deployment,
                            upcomingPaymentData: upcomingPayment,
                            pendingPaymentData: pendingPayment,
                            next_payment_date: nextPaymentDates,
                            availableCards: allDetails,
                            billingHistory: billingHistoryTableData,
                            autoDebit: auto_debit_status,
                            disableAutoDebitToggle: false,
                            billinginitalloader: false,
                            billing_address: billingAddressTableData,
                            billingAddresses: billingAddress,
                            receiptData: billingHistory,

                        });
                        if (billingHistory.length !== 0) {
                            localStorage.setItem('firstPayment', false)
                        }

                    } else {
                        this.setState({ errorOccurred: true });
                    }

                    this.setState({ cardGroupLoading: false });
                });
            break;
        case "fetchQualdoPlans":
            let plans = SUBSCRIPTION_PLANS;
            this.setQualdoPlans(plans);
            break;
        case "get_payment_page_details":
            let getPaymentDetailsEndPoint = "customer/payment_page";
            let planData = { "tenant_id": team_id }
            planData['user_id'] = user_id
            planData['deployment_id'] = localStorage.getItem("deployment_id");
            let srcIsChoosePlan = this.srcIsChoosePlan;
            let planType = localStorage.getItem("qualdoPlan");
            // Check whether there is change in plan. If there is a change update the end point
            if (srcIsChoosePlan === true &&
                planType !== undefined &&
                VALID_QUALDO_PLANS.includes(planType)) {
                let qualdoPlanCycle = this.getQualdoPlanCycle();
                let planChanged = isPlanChanged(planType, qualdoPlanCycle);
                if (planChanged) {
                    if (planType === QualdoPlanVersions.FREE) {
                        qualdoPlanCycle = "monthly";
                    }
                    planData["user_plan"] = planType;
                    planData["plan_cycle"] = qualdoPlanCycle;
                } else {
                    this.showErrorMessage("You are already in the " + qualdoPlanCycle + " plan");
                    return;
                }
            }
            call_get_api(iam_ip, getPaymentDetailsEndPoint, planData).then(
                (response) => {
                    if (response === undefined) {
                        this.setState({ errorOccurred: true });
                    } else if (response.code === 200) {
                        let data = response.data;
                        if (data["error_message"] !== undefined && data["error_message"] !== null) {
                            this.showErrorMessage(data["error_message"])
                        } else if (data["is_downgrade"] !== undefined && data["is_downgrade"] === true) {
                            // show popup
                            this.showDowngradePopup(data)
                        }
                        else {
                            let available_cards = getPaymentDetailsTableData(data.available_cards);
                            data["available_cards"] = available_cards;
                            data["show_payment_details"] = true;
                            this.changePaymentPageDetails(data)
                        }
                    } else {
                        this.setState({ errorOccurred: true });
                    }
                });
            break;
        default:
            break;
    }
}

export function getBrainTreeAuthToken() {
    return BRAIN_TREE_AUTH_TOKEN;
}

export function submitUsersPayment(paymentData) {
    /**
     * this function is used by both deployment(Wizard) and payment page for payment process
     */
    const teamId = localStorage.getItem('team_id');
    let user_id = localStorage.getItem('user_id');
    const deploymentId = localStorage.getItem('deployment_id');
    const session = localStorage.getItem("sessionToken");
    let endPoint = "customer/payment_page";
    paymentData["tenant_id"] = teamId;
    paymentData["user_id"] = user_id;
    paymentData["deployment_id"] = deploymentId;
    call_post_api(iam_ip, endPoint, paymentData, session).then((response) => {
        $("#icon-arrow").addClass("d-none");
        $(".spinner-border").addClass("d-inline-block")
        if (response === undefined) {
            $(".spinner-border").removeClass("d-inline-block")
            $("#icon-arrow").removeClass("d-none");
            this.setState({ errorOccurred: true });
            this.setState({ disabled: false });
        }
        else if (response.code === 200) {
            $("#check-circle").removeClass("d-none");
            $(".spinner-border").removeClass("d-inline-block")
            this.setState({ disabled: true });
            this.setState({ errorOccurred: false });
            if((localStorage.getItem('pay') !== null || localStorage.getItem('pay') !== 'null') && localStorage.getItem('pay') === 'true' ){
                /**
                 * Condition to handle payment page flow
                 */
                browserHistory.push('/dashboard')
                localStorage.removeItem('pay')
            }
            else if (localStorage.getItem('currentStatus') === 'step2'){
                setTimeout(()=>{
                            let status = { 'status': 'payment success' }
                            let endPoint = "/deployments/" + deploymentId
                            call_update_api(iam_ip, endPoint, status).then((response) => {
                                if (response !== null && response.code === 200) {
                                    localStorage.setItem("deployment_id", response['deployment_id']);
                                    localStorage.setItem("currentStatus", "step3");
                                    localStorage.removeItem('firstPayment')
                                }
                            });
                    },3000)
                }
        }
        else {
            $(".spinner-border").removeClass("d-inline-block")
            $("#icon-arrow").removeClass("d-none");
            this.setState({ errorOccurred: true,
                            disabled: false,
                            showModal:true,
                            failure_message : response['message']
                        });
        }
    });
}


export function handleClick(event) {
    let session = localStorage.getItem("sessionToken");
    let name = event.target.name;
    switch (name) {
        case "logout":
            let team_id = localStorage.getItem('team_id');
            let user_id = localStorage.getItem('user_id');
            let rememailId = localStorage.getItem('rememailId')
            let rememberMe = localStorage.getItem('rememberMe')
            const logout_data = { token: session, team_id: team_id, user_id: user_id };
            call_post_api(iam_ip, "logout", logout_data).then((response) => {
                if (response === undefined) {
                    this.setState({ errorOccurred: true });
                } else if (response.code === 200) {
                    /**
                     * Clearing all local storage varibale expect Remember Me local variables
                     */
                    localStorage.clear();
                    localStorage.setItem('rememailId',rememailId)
                    localStorage.setItem('rememberMe',rememberMe)
                    window.location = '/';
                }
            });
            postMixpanel('User logged out');

            break;
        default:
            break
    }
}

export function setShow(key, value) {
    this.setState({ [key]: value })
}


export function handleSubmit(event) {
    event.preventDefault();
    let errors = this.state.errors;
    const { name } = event.target;
    if (typeof (errors) !== 'undefined' || errors != null) {
        this.setState({ formValid: validateForm(this.state.errors) });
        this.setState({ errorCount: countErrors(this.state.errors) });

    }
    switch (name) {
        case "login":
            const data = { email: this.state.email, password: this.state.password };
            call_post_api(iam_ip, "login", data).then((response) => {
                if (response === undefined) {
                    errors.password = "Could not login";
                    this.setState({ errors: errors });
                } else if (response.code === 200) {
                    const now = new Date();
                    const item = {
                        value: response['response']['auth_token'],
                        expiry: now.getTime() + 150 * 60000
                    };
                    setTimeout(() => browserHistory.push('/'), 9000000);
                    localStorage.setItem('sessionToken', JSON.stringify(item));
                    localStorage.setItem('team_id', response['response']['team_id']);
                    localStorage.setItem('user_name', response['response']['user_name']);
                    localStorage.setItem('user_id', response['response']['user_id']);
                    localStorage.setItem('emailId', response['response']['email']);
                    if (response['response']["setup_status"] === "completed") {
                        window.location = '/configure';
                    } else {
                        window.location = '/setup';
                    }
                } else {
                    errors.password = response.message;
                    this.setState({ errors: errors });
                }
            });
            break;
        case "forgot-password":
            const forgot_data = { email: this.state.email };
            call_post_api(iam_ip, "forgot-password", forgot_data).then((response) => {
                errors.email = response.message;
                this.setState({ errors: errors, isRedirectToThankYou: true });

            });
            break;
        case "reset-password":
            let token = localStorage.getItem('resetPwdToken');
            const password = { password: this.state.password };
            call_post_api(iam_ip, 'reset-confirm?token=' + token, password).then((response) => {
                localStorage.removeItem("resetPwdToken");
                if (response.code === 200) {
                    window.location = '/';
                } else {
                    errors.tokenStatus = response.message;
                    this.setState({ errors: errors });
                }
            });
            break

        default:
            break;
    }
}

export function submitPrimaryCardRequest(cardDetail) {
    let user_id = localStorage.getItem('user_id');
    let paymentID = cardDetail.id;
    let last4 = cardDetail.lastFourDigits;
    let cardType = cardDetail.brand;
    let session = localStorage.getItem("sessionToken");
    let teamID = localStorage.getItem('team_id');
    let data = { "payment_id": paymentID, "tenant_id": teamID, 'user_id': user_id }
    let end_point = "customer/payment/primary";

    call_get_api(iam_ip, end_point, data, session).then(
        (response) => {
            if (response === undefined) {
                this.showAlertSection("Error!", "Failed to update the primary payment card", false, "danger");
                return;
            }

            if (response.code === 200) {
                let successMsg = `${cardType} (ending with #${last4}) is now your primary payment`;
                this.showAlertSection("Success!", successMsg, false, "success");
                this.updatePrimaryStatusOfCards(paymentID);
            } else {
                let message = "Error while updating primary card";
                this.showAlertSection("Error!", message, false, "danger");
            }
        });
}

export function deleteCardDetails(cardDetails) {
    let session = localStorage.getItem("sessionToken");
    let teamID = localStorage.getItem('team_id');
    let user_id = localStorage.getItem('user_id');
    let cardID = cardDetails.id;
    let brand = cardDetails.brand;
    let last4Digits = cardDetails.lastFourDigits;
    let end_point = "customer/payment";
    let data = { "tenant_id": teamID, "card_detail_id": cardID, 'user_id': user_id };
    call_delete_api(iam_ip, end_point, data, session).then(
        (response) => {
            if (response === undefined) {
                this.props.showAlertSection("Error!", "Failed to delete the card details", true, "danger");
                return;
            }

            if (response.code === 200) {
                let successMsg = `${brand} (Card #${last4Digits}) has been removed successfully.`;
                this.props.showAlertSection("Success!", successMsg, false, "success");
                this.props.removeCardDetailFromList(cardID);
            } else {
                let message = response.message;
                let errorMessage = "Failed to delete the card details";
                postUIlogs(`${errorMessage}: ${message}`);
                this.props.showAlertSection("Error!", errorMessage, true, "danger");
            }
        });
}


export function updatePaymentCard(cardDetails) {
    let session = localStorage.getItem("sessionToken");
    let teamID = localStorage.getItem('team_id');
    let user_id = localStorage.getItem('user_id');
    cardDetails["user_id"] = user_id;
    let end_point = "customer/payment";
    let brand = cardDetails.brand;
    let last4Digits = cardDetails.lastFourDigits;
    cardDetails["tenant_id"] = teamID
    call_update_api(iam_ip, end_point, cardDetails, session).then(
        (response) => {

            if (response === undefined) {
                this.props.showAlertSection("Error!", "Failed to update the card details", true, "danger");
                return;
            }

            if (response.code === 200) {
                let successMsg = `${brand} (Card #${last4Digits}) has been updated successfully.`;
                this.props.showAlertSection("Success!", successMsg, false, "success");
                this.props.updateCardDetailToList(cardDetails);
            } else {
                let message = "Error while updating card detail";
                this.props.showAlertSection("Error!", message, true, "danger");
            }
            this.setState({ disableCardButton: false });
        });
}

export function postPaymentNonce(cardDataToPost) {
    let session = localStorage.getItem("sessionToken");
    let teamID = localStorage.getItem('team_id');
    let user_id = localStorage.getItem('user_id');
    cardDataToPost["user_id"] = user_id;
    let end_point = "customer/payment";
    cardDataToPost["tenant_id"] = teamID;
    call_post_api(iam_ip, end_point, cardDataToPost, session).then(
        (response) => {
            if (response === undefined) {
                this.props.showAlertSection("Error!", "Failed to add the given card", true, "danger");
                return;
            }

            if (response.code === 200) {
                let detail = response.detail;
                detail["id"] = response.payment_detail_id;
                let cardType = detail.card_type;
                let last4 = detail.last_4;
                let successMsg = `${cardType} (Card #${last4}) has been added successfully.`;
                detail["isPrimary"] = cardDataToPost["make_default"];
                this.props.showAlertSection("Success!", successMsg, false, "success");
                this.props.addNewCardDetailToList(detail);
            } else {
                let message = "Error while adding card information. Please recheck the card details";
                let submitBtn = document.getElementById('btn-add-card');
                submitBtn.removeAttribute('disabled');
                this.props.showAlertSection("Error!", message, true, "danger");
            }
        });
}


export function updateAutoDebitStatus(auto_debit_status) {
    let session = localStorage.getItem("sessionToken");
    let teamId = localStorage.getItem('team_id');

    let postError = { "auto_debit": auto_debit_status, "tenant_id": teamId };
    const autoDebitEndPoint = "payment/autodebit";
    call_update_api(ui_service_ip, autoDebitEndPoint, postError, session).then((res) => {

        // Server is not reachable. We did not receive any response from server
        if (res === undefined) {
            this.props.showToast("alert-danger", "Unable to reach server", "");
            return;
        }

        // Server response is 200. Successful update
        if (res.code === 200) {
            this.setState({ autoDebit: auto_debit_status });
            this.props.showToast("alert-success", "Successfully updated", "");
            return;
        }

        // Server response is NOT 200. Unsuccessful update
        this.props.showToast("alert-danger", "Error while updating the auto-debit status", "");
    });

}

export function submitPlanChangeRequest(data, showToast) {
    const teamId = localStorage.getItem('team_id');
    const session = localStorage.getItem("sessionToken");

    let endPoint = `customer/choose_model?tenant_id=${teamId}`;

    call_post_api(ui_service_ip, endPoint, data, session).then((response) => {
        if (response === undefined) {
            showToast("alert-danger", "Unable to contact server. Try after sometime", "");
        } else if (response.code === 200) {
            this.props.closeDowngradePopup();
            if (showToast !== undefined) {
                showToast("alert-success", response.message, "plan");
            } else {
                showToast("alert-success", response.message, "plan");
            }
        } else {
            this.props.closeDowngradePopup();
            if (showToast !== undefined) {
                showToast("alert-danger", response.message, "plan");
            } else {
                showToast("alert-danger", response.message, "plan");
            }

        }
    });
}


export function postDeployment(data) {
    const teamId = localStorage.getItem('team_id');
    const admin_email = localStorage.getItem('emailId');
    data['admin_email'] = admin_email
    data['tenant_id'] = teamId
    this.setState({ currentStep: 'step1' })
    let endPoint = "deployments/teams/" + data['teamId'];
    localStorage.setItem("deployment_name", data['deployment_name']);
    let id = localStorage.getItem('deployment_id')
    if (id === null) {
        call_post_api(iam_ip, endPoint, data).then((response) => {
            if (response.code === 401) {
                this.showToast("alert-danger", response.message, "")
                this.setState({ errorOccurred: true })
                return false
            } else if (response.code === 200) {
                this.setState({ post: false })
                this.setState({ errorOccurred: false })
                localStorage.setItem('currentStatus', 'step2')
                this.setState({ currentStep: 'step2' })
                localStorage.setItem('validate', false)
                localStorage.setItem("deployment_id", response['deployment_id']);
                localStorage.setItem("currentId", response['deployment_id']);
                return true
            }
        });
    }
}

export function updateDeployment(data) {
    const teamId = localStorage.getItem('team_id');
    const admin_email = localStorage.getItem('emailId');
    data['admin_email'] = admin_email
    data['tenant_id'] = teamId
    const deploymentId = localStorage.getItem('deployment_id');
    let endPoint = "/deployments/" + deploymentId
    localStorage.setItem("deployment_name", data['deployment_name']);
    call_update_api(iam_ip, endPoint, data).then((response) => {
        if (response.code !== 200) {
        } else if (response !== null && response.code === 200) {
            localStorage.setItem("deployment_id", response['deployment_id']);
        }
    });
}

export function triggerDeployment() {
    const deploymentId = localStorage.getItem('deployment_id');
    let endPoint = "/deployments/" + deploymentId + "/trigger";
    call_post_api(iam_ip, endPoint).then((response) => {
        if (response === undefined) {
        } else if (response !== null && response.code === 200) {
            this.setState({status: 'deployment initiated'});
        }
    });

}


export function getAllDeployment(data) {
    const teamId = localStorage.getItem('team_id');
    let endPoint = "/deployments/teams/" + teamId
    call_get_api(iam_ip, endPoint).then((response) => {
        if (response === undefined) {
            this.setState({ errorOccurred: true });
        } else if (response !== null && response.code === 200) {
            let allDeployments=[]
            let in_progressDeployment = []
            for (let deployment of response['deployments']) {
                if (deployment.status === 'active' || deployment.status === 'deployed' || deployment.status === 'deletion initiated' || deployment.status === 'deleted' || deployment.status === 'deletion failed') {
                    allDeployments.push(deployment)
                }
                if(deployment.status === 'initiated' || deployment.status === 'payment success' || deployment.status === 'deployment initiated' || deployment.status === 'active' || deployment.status === 'failed'){
                    in_progressDeployment.push(deployment)
                }
            }
            let tableData = deployment_updates_table(allDeployments)
            this.setState({ tableData: tableData });
            if(in_progressDeployment.length > 0){
                this.setState({ newDeploymentData: in_progressDeployment });
                this.setState({ status: in_progressDeployment[0]['status'] });
            }
            let deployments = response['deployments']
            if ((window.location.pathname === '/dashboard' || window.location.pathname === '/settings') && deployments.length >= 1) {
                for (let deployment of deployments) {
                    if (deployment['status'] === 'deployed' || deployment['status'] === 'deleted' || deployment['status'] === 'deletion initiated' || deployment.status === 'deletion failed') {
                        localStorage.setItem("deploymentStatus", 'deployed')
                        localStorage.setItem("currentStatus", 'deployed')
                        localStorage.setItem("secondDeployment",true)
                    }
                }
            }
            this.setState({loader:false})
        }
    });
}


function deployment_updates_table(inputData) {
    let headers = ["Deployment", "Edition", "Status", "Cloud", "Deployed In", "Region", "Deployed On", "Actions"];
    let final_op = [];
    for (let i = 0; i < inputData.length; i++) {
        let row = [];
        let d = inputData[i];
        row.push({ "value": { "name": d["deployment_name"], "url": d["qualdo_url"] }, "type": "td" });
        row.push({ "value": d["edition"], "type": "td" });
        row.push({ "value": d["status"], "type": "td" });
        row.push({ "value": d["cloud"], "type": "td" });
        row.push({ "value": d["cloud_support"], "type": "td" });
        row.push({ "value": d["region"], "type": "td" });
        row.push({ "value": d["created_on"], "type": "td" });
        row.push({ "value": d, "type": "td" });
        final_op.push(row);
    }
    let table_data = { "headers": headers, "data": final_op };
    return table_data;
}



export function getDeployment() {
    let currentId = localStorage.getItem('currentId')
    let deploymentId = ''
    if (currentId !== null) {
        deploymentId = localStorage.getItem('currentId');
    }
    else {
        deploymentId = localStorage.getItem('deployment_id');
    }
    let endPoint = "/deployments/" + deploymentId

    if (deploymentId !== null) {
        call_get_api(iam_ip, endPoint).then((response) => {
            if (response === undefined) {
                this.setState({ errorOccurred: true });
            } else if (response !== null && response.code === 200) {
                this.setState({ 
                                deploymentDetails: response['deployment'] , 
                                loader : false});
                localStorage.setItem("deployment_id", response['deployment']['deployment_id'])
                localStorage.setItem("currentState", response['deployment']['status']);
                let progressTime = 5
                if(response['deployment']['pipeline_status'] !== undefined || response['deployment']['pipeline_status'] !== null){
                    progressTime = response['deployment']['pipeline_status']
                }
                this.setState({ 
                                deployment_name: response['deployment']['deployment_name'],
                                cycle: response['deployment']['cycle'],
                                cloud: response['deployment']['cloud'],
                                cloud_support: response['deployment']['cloud_support'],
                                region: response['deployment']['region'],
                                account_id: response['deployment']['account_id'],
                                edition: response['deployment']['edition'],
                                service_account: response['deployment']['service_account'],
                                subnet: response['deployment']['subnet'],
                                deployment_config:response['deployment']['deployment_config'],
                                json_file: response['deployment']['json_file'],
                                subscription: response['deployment']['subscription'],
                                status: response['deployment']['status'],
                                progressStatus: response['deployment']['status'],
                                time: progressTime,
                                // elastic_search_key: response['deployment']['elastic_search_key'],
                            
                            });
                $('.custom-file-label').addClass("selected").html(response['deployment']['json_file']);
                let errorType = response['deployment']['pipeline_status_text']
                if(errorType !== null){
                    if(errorType.includes("Insufficient regional quota")){
                        this.setState({
                                        statusText:INSUFFICIENT_QUATO,
                                        prerequesties:REQUIRED_QUOTAS
                                    })
                    }
                    else if(errorType.includes("Permission denied")){
                        this.setState({
                                        statusText:PERMISION_DENIED,
                                        prerequesties:REQUIRED_PERMISSIONS,
                                    })
                    }
                    else if(errorType.includes("Service-Account name does not match")){
                        this.setState({
                                        statusText:ACCOUNT_MISMATCH,
                                    })
                    }
                    else{
                        this.setState({
                            statusText:DEPLOYMENT_FAILURE,
                        })
                    }
                }

                //Get, check and set network radio button
                let whitelisted = response['deployment']['whitelisted_ip']
                if(whitelisted.length === 1 && whitelisted[0] === DEPLOYMENT_DATA['whitelisted_ip'][0]){
                    this.setState({
                                    restricted_network : false,
                                    public_network : true,
                                    whitelisted_ip:[]
                                  })
                }
                else{
                    this.setState({
                        restricted_network : true,
                        public_network : false,
                        whitelisted_ip:response['deployment']['whitelisted_ip']
                      })
                }
                // if(response['deployment']['elastic_search_key'] !== null){
                //     this.setState({
                //                     elasticSearch : true, 
                //                     elastic_search_key: response['deployment']['elastic_search_key'],
                //                 })
                // }
            }
        });
    }
}

export function planDetails() {
    let endPoint = "/all_plan_details"
        call_get_api(iam_ip, endPoint).then((response) => {
            if (response === undefined) {
                this.setState({ errorOccurred: true });
            } else if (response !== null && response.code === 200) {
                // this.setState({planDetails: response['plan_details']});
                let data = response['plan_details']
                this.setState({loader:false})
                for (let price of data) {
                    if (price.cloud === "Qualdo's Private Cloud") {
                        if(price.plan_cycle === 'annually'){
                            this.setState({ plan_price: price.price })
                            localStorage.setItem("qualdoannualplantitle", price.plan_name);
                            localStorage.setItem("qualdoannualplanprice", price.price);
                        }
                        else{
                            this.setState({ plan_price: price.price })
                            localStorage.setItem("qualdomonthlyplantitle", price.plan_name);
                            localStorage.setItem("qualdomonthlyplanprice", price.price);
                        }
                    }
                    else if (price.cloud === "My Enterprise Private Cloud") {
                        if(price.plan_cycle === 'annually'){
                            this.setState({ plan_price: price.price })
                            localStorage.setItem("annualplantitle", price.plan_name);
                            localStorage.setItem("annualplanprice", price.price);
                        }
                        else{
                            this.setState({ plan_price: price.price })
                            localStorage.setItem("monthlyplantitle", price.plan_name);
                            localStorage.setItem("monthlyplanprice", price.price);
                        }
                    }
                }
                return response
            }
        });
}


export function updateCustomerAddress(data) {
    const teamId = localStorage.getItem('team_id');
    const userId = localStorage.getItem('user_id');
    const adminEmail = localStorage.getItem('emailId');
    let endPoint = "/customer/address"
    data['admin_email'] = adminEmail
    data['tenant_id'] = teamId
    data['user_id'] = userId
    call_update_api(iam_ip, endPoint, data).then((response) => {
        if (response === undefined) {

        } else if (response !== null && response.code === 200) {
            this.setState({ updatedAddress: true })

        }
    });

}

export function updateautoDebit(data) {
    const teamId = localStorage.getItem('team_id');
    const deploymentId = data['deployment_id'];
    let endPoint = "/payment/autodebit"
    data['tenant_id'] = teamId
    data['deployment_id'] = deploymentId
    call_update_api(iam_ip, endPoint, data).then((response) => {
        if (response === undefined) {

        } else if (response !== null && response.code === 200) {
            // this.setState({updatedAddress:true})
            // localStorage.setItem("deployment_id", response['deployment_id']);

        }
    });

}

export function getDeploymentPlanDetails() {
    const userId = localStorage.getItem('user_id');
    const teamId = localStorage.getItem('team_id');
    let deploymentId = localStorage.getItem('deployment_id');;
    let data = [];
    let endPoint = "/plan_details/deployments/" + deploymentId
    data['deployment_id'] = deploymentId
    data['user_id'] = userId
    data['team_id'] = teamId
    if(deploymentId !== null){
        call_get_api(iam_ip, endPoint, data).then((response) => {
            if (response === undefined) {
                this.setState({ errorOccurred: true });
            } else if (response !== null && response.code === 200) {
                this.setState({ 
                                cycleCost: response['plan_details']['price'], 
                                plan_price: response['plan_details']['price'],
                                cycle: response['plan_details']['plan_cycle'],
                                billingCycle: response['plan_details']['plan_cycle']
                            })
                localStorage.setItem('selectedplanprice', response['plan_details']['price'])
                localStorage.setItem('selectedPlan', response['plan_details']['plan_cycle'])
                localStorage.setItem('qualdoPlanCycle', response['plan_details']['plan_cycle'])
                localStorage.setItem('qualdoPlan', response['plan_details']['plan_name'])
                localStorage.setItem('plan_details', JSON.stringify(response['plan_details']))
            }
        });
    }

}

export function getuserrenewal(id) {
    const userId = localStorage.getItem('user_id');
    const teamId = localStorage.getItem('team_id');
    let deploymentId = id;
    let data = {};
    let endPoint = "/renew/" + deploymentId
    data['user_id'] = Number(userId)
    data['team_id'] = Number(teamId)

    call_update_api(iam_ip, endPoint, data).then((response) => {
        if (response === undefined) {
        } else if (response !== null && response.code === 200) {
        }
    });
}


export function updateplancycle(data) {
    const teamId = localStorage.getItem('team_id');
    const deploymentId = localStorage.getItem('deployment_id');
    let endPoint = "/deployments/" + deploymentId + "/updateplancycle"
    data['tenant_id'] = teamId
    localStorage.setItem("deployment_name", data['deployment_name']);
    call_update_api(iam_ip, endPoint, data).then((response) => {
        if (response !== 200) {

        } else if (response !== null && response.code === 200) {

        }
    });

}

export function submitUsersRenew(paymentData, setPaymentStatus) {
    const teamId = localStorage.getItem('team_id');
    let user_id = localStorage.getItem('user_id');
    const deploymentId = localStorage.getItem('deployment_id');
    const session = localStorage.getItem("sessionToken");
    let endPoint = "renew/" + deploymentId;
    paymentData["tenant_id"] = teamId;
    paymentData["user_id"] = Number(user_id)
    paymentData["deployment_id"] = deploymentId;
    call_update_api(iam_ip, endPoint, paymentData, session).then((response) => {
        $("#icon-arrow").addClass("d-none");
        $(".spinner-border").addClass("d-inline-block")
        if (response === undefined) {
            $(".spinner-border").removeClass("d-inline-block")
            $("#icon-arrow").removeClass("d-none");
            this.setState({ errorOccurred: true });
            this.setState({ disabled: false });
        }
        else if (response.code === 200) {
            this.setState({ disabled: true });
            this.setState({ errorOccurred: false });
            setPaymentStatus(true);
            browserHistory.push('/dashboard')
            localStorage.removeItem('renew')
        }
        else {
            $(".spinner-border").removeClass("d-inline-block")
            $("#icon-arrow").removeClass("d-none");
            this.setState({ errorOccurred: true });
            this.setState({ disabled: false });
            setPaymentStatus(false);
            this.showToast("alert-danger", response.message, "");
        }
    });
}

export function handleDeleteForDeployment() {
    let data = {}
    const userId = localStorage.getItem('user_id');
    const deploymentId = localStorage.getItem('deployment_id');
    let endPoint = "/validate_password";
    data['password'] = this.state.password
    data['user_id'] = userId
    data['deployment_id'] = deploymentId
    localStorage.setItem("deployment_name", data['deployment_name']);
    call_post_api(iam_ip, endPoint, data).then((response) => {
        if (response.code === 401) {
            this.setState({ errorOccurred: true,
                            deleteButtonDisabled: false,
                            errors: response['message']
                })
        } else if (response.code === 200) {
            this.setState({ errorOccurred: false })
            localStorage.setItem("deployment_id", response['deployment_id']);
            localStorage.setItem("currentId", response['deployment_id']);
            let session = localStorage.getItem("sessionToken");
            endPoint = "/deployments/" + deploymentId + "/trigger";
            let context = 'Deployment';
            call_delete_api(iam_ip, endPoint, session).then((response) => {
                if (response.code === 200) {
                    this.setState({ deleteButtonDisabled: true });
                    this.closeDeletepopup()
                    this.props.showToast("alert-success", `${context} deletion initiated`,
                        '', '', false);
                } else {
                    this.closeDeletepopup()
                    this.setState({deleteButtonDisabled:false})
                    this.props.showToast("alert-danger", `Could not delete ${context.toLowerCase()}`,
                        '', '', false);
                }
            });
        }
        else{
            this.setState({ deleteButtonDisabled: false ,
                            errorOccurred: true
                        });
        }
        return response
    });
}

export function validateCoupon(data) {
    let endPoint = '/coupon/validate'
    call_post_api(iam_ip, endPoint, data).then((response) => {
        if (response.code === 200) {
            // Remove the Is-invalid class in coupon input field
            this.setState({ couponClass: '' })


            this.setState({promo_form:'Hidden'})
            this.setState({promo_success_text : 'Show'})
            // $('.promocode-form').removeClass('show');
            // $('.promocode-success-text').addClass('d-block');
            localStorage.setItem("validCoupon", true)
            localStorage.setItem('coupon_code', data['coupon_code'])
            this.setState({ validCoupon: true })
            this.setState({ final_price: response['coupon_data']['final_price'] })
            this.setState({ discount: response['coupon_data']['discount'] })
            this.setState({ plan_price: response['coupon_data']['final_price'] })
            if (data['cycle'] === 'annually') {
                this.setState({ 'annualCoupon': true })
                this.setState({ 'montlyCoupon': false })

            }
            else {
                this.setState({ 'montlyCoupon': true })
                this.setState({ 'annualCoupon': false })
            }
        }
        else {
            // setting coupon input field as invalid once the coupon is invalid
            this.setState({ couponClass: 'is-invalid' })
            this.setState({ validCoupon: false })
            this.setState({coupon_code:null})
            $('.promo-code').removeClass('d-none')
        }

    });
}