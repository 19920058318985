import React from 'react';
import {browserHistory} from 'react-router';
import CopyRights from '../../components/copyrights';
import { faEnvelope, faLock, faEye, faEyeSlash, faUser, faBuilding, faPhoneAlt, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iam_ip } from "../../utils/ip_details";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Formik } from 'formik';
import * as yup from 'yup';
import {QUALDO_WEBSITE_URL} from "../../utils/constant";
import {getProtocol,userValidation} from "../../utils/common_utils";
import Modal from 'react-bootstrap/Modal';
import { PhoneNumberUtil } from 'google-libphonenumber';
import mixpanel from 'mixpanel-browser';
mixpanel.init('155c019784ab639716eb7828d60964e2', {debug: true});

class SignupForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formValid: false,
            errorCount: null,
            error: null,
            hideSubmitButton: false,
            errors: {
                email: '',
                username: '',
                company: '',
                password: '',
                confirmPassword: '',
                phone: ''
            },
            passwordIcon: faEye,
            passwordType: "password",
            confirmPasswordIcon: faEye,
            confirmPasswordType: "password"
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.changePasswordIcon = this.changePasswordIcon.bind(this);
        this.changeConfirmPasswordIcon = this.changeConfirmPasswordIcon.bind(this);
        this.disableButton = this.disableButton.bind(this);
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    handleSubmit = (event) => {
        this.disableButton();
        let { email, password, company, username, phone } = event;
        this.props.setEmail(email,"signup");
        mixpanel.track('New user signup', {
        distinct_id: email
    });
        mixpanel.identify(email);
        mixpanel.people.set({
        $first_name: username,
        $last_name: company,
        $email: email
    });

        const splitName = (name = '') => {
            const [firstName, ...lastName] = name.split(' ').filter(Boolean);
            return {
              firstName: firstName,
              lastName: lastName.join(' ')
            }
          }     
        let userName = splitName(username)   
        const data = {
            user_signup_type: "qualdo_db_auth", company: company, email: email,
            first_name: userName.firstName, last_name: userName.lastName, username: username, password: password,
            phone_number: phone
        };

        let protocol = getProtocol(iam_ip);
        fetch(protocol+'//'+iam_ip+'/signup', {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{ 'Content-Type': 'application/json', 'api-type':'qualdo_db_auth' }
        }).then(
            res => res.json()
        ).catch(
            error => console.log("signup api failed")
        ).then(
              (response) => {
                  if(response.code === 200) {
                    let res = response.response;
                    let redirect_to_login = res["redirect_to_login"];
                    if (redirect_to_login !== undefined && redirect_to_login ===  true) {
                        browserHistory.push("/");
                    } else {
                        this.props.setEmailPage("We have sent a verification link via your registered email. Please check your email to continue");
                    }
                  } else if(response.code === 401 && response.message === "invalid_plan"){
                    browserHistory.push("/invalid-request");
                  } else {
                    this.setState({error: response.message, hideSubmitButton: false});
                  }
              }
        )
    }

    hideAlert() {
        this.setState({
          error: null,
        });
    }

    goToQualdoWebsite(){
        window.location.href = QUALDO_WEBSITE_URL;
    }
    changePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
          this.setState({passwordIcon: faEyeSlash});
          this.setState({passwordType: "text"})
        } else {
          this.setState({passwordIcon: faEye});
          this.setState({passwordType: "password"})
        }

    }

    changeConfirmPasswordIcon() {
        let state = this.state.confirmPasswordIcon;
        if (state.iconName === "eye") {
          this.setState({confirmPasswordIcon: faEyeSlash});
          this.setState({confirmPasswordType: "text"});
        } else {
          this.setState({confirmPasswordIcon: faEye});
          this.setState({confirmPasswordType: "password"});
        }

    }

    render() {
        mixpanel.track('In normal signup page', { });
        const loginSchema = yup.object({
            email: yup.string().email("Email must be a valid email").required("Email is a required field"),
            password: yup.string().max(16,"Max Length of the password should be 16").required("Password is a required field").matches(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&._-])[A-Za-z\d@$!%*#?&._-]{8,}$/,
                      "Use at least 8 characters containing at least 1 number, 1 lowercase letter, 1 special character in @$!%*#?&._-"),
            confirmPassword: yup.string().required("Confirm Password is a required field").oneOf([yup.ref('password'), null],'Passwords must match',),
            username: yup.string().min(1,"Name must be at least 1 characters").required("Name is a required field").test(
                'Name test',
                'Name is invalid',
                function test(value) {                    
                   let val = userValidation(value);
                   return val;
            }
            ),
            company: yup.string().min(2,"Company must be at least 2 characters").matches(/^[A-Za-z ]+$/,"Company name should contains letters only").required("Company is a required field"),
            phone: yup.mixed().required("Mobile number is a required field").test(
              'mobile number test',
              'Mobile Number is not valid. Enter valid country code',
              function test(value) {
                  try{
                        const phoneUtil = PhoneNumberUtil.getInstance()
                        if(value[0] !== '+'){
                        value = '+' + value}
                        const val = phoneUtil.parse(value)
                        let result  = phoneUtil.isValidNumber(val)
                        return result
                      }
                    catch(error){
                    console.log(error)
                    return false
                    }
                }
            )

        });
        let invalidRequest = false;
        if (this.props.showInvalidRequestModal !== undefined && this.props.showInvalidRequestModal !== null && this.props.showInvalidRequestModal === true){
            invalidRequest = true;
        }
        return (
            <>
              <Modal show={invalidRequest}
                      className="modal__upgrade text-center"
                      centered={true}>
                <Modal.Body>
                    <br />
                    <br />
                   <h5 className="modal-title">Your are trying to subscribe to an Invalid plan.</h5>
                   <h5 className="text-muted m-0">
                        Please subscribe to one of the valid plans.
                   </h5>
                </Modal.Body>
                <Modal.Footer className=" border-0 justify-content-center">
                    <button type="button" onClick={this.goToQualdoWebsite}
                        className="btn btn-primary btn-circle">Choose your plan <FontAwesomeIcon className="mr-0 ml-2" icon={faArrowRight}/></button>
                </Modal.Footer>
            </Modal>
            <div className="prelogin-content">
                    {
                    this.state.error ?
                        <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                             {this.state.error}
                            <button type="button"
                                    className="close close-sm"
                                    onClick={() => this.hideAlert()}
                                    data-dismiss="alert"
                                    aria-label="Close"/>
                        </div> : ""
                    }
                    <Formik
                        validationSchema={loginSchema}
                        onSubmit={this.handleSubmit}
                        initialValues={{ email: '', username: '', company: '',
                        password: '', confirmPassword: '', phone: '', isJoinTeamButton: false}}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isValid,
                    }) => (
                        <Form noValidate name="signup"
                              className="login-form needs-validation"
                              onSubmit={handleSubmit}>
                            <div className="prelogin-form-container">
                            <Form.Group controlId="email">
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email"
                                        autoFocus
                                        className="form-group form-control-solid placeholder-no-fix"
                                        value={values.email}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                        isInvalid={errors.email && touched.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.email && errors.email }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="username">
                                <InputGroup bsPrefix="icon-input-group">
                                     <i>
                                        <FontAwesomeIcon icon={faUser}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Full Name"
                                        className="form-group form-control-solid placeholder-no-fix"
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.username && touched.username}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.username && errors.username }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="company">
                                <InputGroup bsPrefix="icon-input-group">
                                     <i>
                                        <FontAwesomeIcon icon={faBuilding}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Company"
                                        className="form-group form-control-solid placeholder-no-fix"
                                        value={values.company}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.company && touched.company}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.company && errors.company }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </i>
                                    <Form.Control
                                        type={this.state.passwordType}
                                        value={values.password}
                                        placeholder="Password"
                                        className="form-group"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.password && touched.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { touched.password && errors.password }
                                    </Form.Control.Feedback>
                                    <i className="security-eye">
                                        <FontAwesomeIcon
                                            onClick={this.changePasswordIcon}
                                            icon={this.state.passwordIcon}
                                            className="security-eye"/>
                                    </i>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="confirmPassword">
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </i>
                                    <Form.Control
                                        type={this.state.confirmPasswordType}
                                        value={values.confirmPassword}
                                        placeholder="Confirm Password"
                                        className="form-group"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={errors.confirmPassword && touched.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { touched.confirmPassword && errors.confirmPassword }
                                    </Form.Control.Feedback>
                                    <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.changeConfirmPasswordIcon}
                                                         icon={this.state.confirmPasswordIcon}
                                                         className="security-eye"/>
                                    </i>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="phone">
                                <InputGroup bsPrefix="icon-input-group">
                                     <i>
                                        <FontAwesomeIcon icon={faPhoneAlt}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Mobile Number"
                                        className="form-group form-control-solid placeholder-no-fix"
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.phone && touched.phone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.phone && errors.phone }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            <Form.Text className="text-muted font-italic">Please provide Country code</Form.Text><br/>
                            </Form.Group>
                            <CopyRights classDetails="text-copyright text-left"
                                        text="By signing up, you agree to the"/>
                            </div>
                            <div className="form-action-btn  prelogin-btn-group">
                                <button type="submit"  value="signup" disabled={this.state.hideSubmitButton}
                                         onClick={handleSubmit}
                                        className="btn btn-circle btn-secondary">
                                    Sign up
                                </button>
                                {
                                    this.state.hideSubmitButton ?
                                        <button type="button"
                                                className="btn loading-btn loading-btn--pending"/>
                                                :
                                        ''
                                }
                            </div>
                        </Form>
                        )}
                    </Formik>
            <a className="h6" href="/">
                <u>Already have an account?</u>
            </a>
          </div>
          </>
        );
    }
}

export default SignupForm;