import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { Formik } from 'formik';
import * as yup from 'yup';
import PaymentSectionHeader from "./paymentSectionHeader";
import { ZIP_DATA, COUNTRY } from "../../utils/billingAddressConstant";
import CustomSelect from "../customSelect"

class BillingAddress extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            "companyName": '', "addressLine1":'',"addressLine2": '',
            "country": '', "zipCode": '',"state":'',"city": '',
            "sectionTitle": "Personal + Business Contact Details",
            optionscountry:COUNTRY,

        }
        
        this.getAddressRadioButtons = this.getAddressRadioButtons.bind(this);
    }

    getAddressRadioButtons() {
        return (
            this.props.billingAddresses.map((address) =>
                <div
                    key={address.billing_address_id}
                    className="custom-control custom-radio">
                    <input type="radio"
                           id={`payment-address_${address.billing_address_id}`}
                           name="radio_sendDataAs"
                           className="custom-control-input"
                           defaultChecked={this.props.selectedAddress === address.billing_address_id
                           }
                           onClick={(e) => this.props.addressSelectionHandler(address.billing_address_id)}
                    />
                    <label className="custom-control-label d-block"
                           htmlFor={`payment-address_${address.billing_address_id}`}>
                        <div className={`payment-address ${
                            (
                                (this.props.selectedAddress === null && address.isPrimary) ||
                                (this.props.selectedAddress === address.billing_address_id) ||
                                (this.props.billingAddresses.length === 1)
                            )
                                ?
                                'primary-payment'
                                :
                                ''
                        }`}
                        >
                            <span className="payment-address__icon">
                                <i><FontAwesomeIcon icon={faAddressCard}/></i>
                            </span>
                            <address>
                                <h4>{address.customer_name}</h4>
                                <span>
                                    {address.company_name}
                                    <br/>{address.address_line_1},
                                    {(address.address_line_2 === undefined ||
                                        address.address_line_2 === null ||
                                        address.address_line_2.length === 0) ?
                                        ''
                                        :
                                        <>
                                        <br/>{address.address_line_2},
                                        </>

                                    }
                                    <br/>{address.city},{address.state}
                                    <br/>{address.zip_code},
                                    <br/>{address.country}
                                </span>
                            </address>
                        </div>
                    </label>
                </div>)
        );
    }


    render() {
        if (this.props.canShowAddBillingAddress) {
            let initialValues=this.state;
            let addressSchema =  yup.object({
                                     companyName: yup.string().min(3,"Company must be at least 3 characters").required("Company is a required field"),
                                     addressLine1: yup.string().min(3,"Address Line must be at least 3 characters").required("Address Line is a required field"),
                                     city: yup.string().required("City is a required field").matches(/^[A-Za-z ]+$/,"City should contains letters only").min(3,"City must be at least 3 characters"),
                                     state: yup.string().required("State is a required field").matches(/^[A-Za-z ]+$/,"State should contains letters only").min(3,"State must be at least 3 characters"),
                                     country: yup.string().required("Country is a required field"),
                                     zipCode: yup.string().required("Zip Code is a required field")
                                     .test(
                                         "Invalid Zipcode",
                                         function test(value){
                                            let country = this.parent.country
                                            if(country === undefined || country === 'Select Country'){
                                                const message = "Select a country";
                                                return this.createError({ message: message});
                                            }
                                            let regex = ZIP_DATA[country]
                                            return regex.test(value)
                                         }  
                                     ),
                                 });
            return (
                <>
                    <PaymentSectionHeader sectionIndex={this.props.sectionIndex}
                                          sectionTitle={this.state.sectionTitle}
                    />
                    <div className="qd-payment-body" ref={this.props.divRef}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={12}>
                                        <Formik
                                              validationSchema={addressSchema}
                                              onSubmit={this.onSubmit}
                                              initialValues={initialValues}
                                         >
                                         {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleBlur,
                                              handleSubmit,
                                              isSubmitting,
                                              isValid,
                                         }) => (
                                            <Form 
                                            name="customDefaultMetrics"
                                            className="needs-validation" noValidate method="POST"
                                            onSubmit={handleSubmit}
                                        >
                                            <Form.Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="companyName">
                                                        <Form.Label>Company Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      name="companyName"
                                                                      onChange={value => {
                                                                        handleChange("companyName")(value);
                                                                        this.props.saveNewAddress("company_name", value.target.value, isValid);
                                                                      }}
                                                                      value={values.companyName}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={errors.companyName && touched.companyName}
                                                                      placeholder="Abc Company"/>
                                                    <Form.Control.Feedback type="invalid">
                                                       { touched.companyName && errors.companyName }
                                                    </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="addressLine1">
                                                        <Form.Label>Address line 1 <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      name="addressLine1"
                                                                      value={values.addressLine1}
                                                                      onChange={value => {
                                                                        handleChange("addressLine1")(value);
                                                                        this.props.saveNewAddress("address_line_1", value.target.value, isValid);
                                                                      }}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={errors.addressLine1 && touched.addressLine1}
                                                                      placeholder="123 Abc Street"/>
                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.addressLine1 && errors.addressLine1 }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="addressLine2">
                                                        <Form.Label>Address line 2 (optional)</Form.Label>
                                                        <Form.Control type="text"
                                                                      value={values.addressLine2}
                                                                      onChange={value => {
                                                                        handleChange("addressLine2")(value);
                                                                        this.props.saveNewAddress("address_line_2", value.target.value);
                                                                      }}
                                                                      name="addressLine2"
                                                                      placeholder="#1"/>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="city">
                                                        <Form.Label>City <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      value={values.city}
                                                                      onBlur={handleBlur}
                                                                      onChange={value => {
                                                                        handleChange("city")(value);
                                                                        this.props.saveNewAddress("city",value.target.value, isValid);
                                                                      }}
                                                                      isInvalid={errors.city && touched.city}
                                                                      name="city"
                                                                      placeholder="Columbus"/>
                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.city && errors.city }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="state">
                                                        <Form.Label>State/Province/Region <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      value={values.state}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={errors.state && touched.state}
                                                                      onChange={value => {
                                                                        handleChange("state")(value);
                                                                        this.props.saveNewAddress("state", value.target.value, isValid);
                                                                      }}
                                                                      name="state"
                                                                      placeholder="Kentucky"/>
                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.state && errors.state }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>

                                                <Col md={6}>
                                                    <Form.Group controlId="country">
                                                        <Form.Label>Country <span className="text-danger">*</span></Form.Label>                                                      
                                                        <CustomSelect         
                                                                    name="Country"                                                 
                                                                    options={this.state.optionscountry}  
                                                                    onChange={selectedOption => {
                                                                        handleChange("country")(selectedOption.value);   
                                                                        this.props.saveNewAddress("country", selectedOption.value, isValid);                                                                 
                                                                    }}                                           
                                                                    onBlur={handleBlur}   
                                                                    isInvalid={errors.country && touched.country}                                                                         
                                                                    placeholder="Select a country"                                                                                                             
                                                                />
                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.country && errors.country }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="zipCode">
                                                        <Form.Label>ZIP code <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      name="zipCode"
                                                                      value={values.zipCode}
                                                                      onChange={value => {
                                                                        handleChange("zipCode")(value);
                                                                        this.props.saveNewAddress("zip_code", value.target.value, isValid);
                                                                      }}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={errors.zipCode && touched.zipCode}
                                                                      placeholder="12345"/>

                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.zipCode && errors.zipCode }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                               
                                            </Form.Row>

                                            <Form.Group controlId="formBasicCheckbox"
                                                        className="mt-2">
                                                <Form.Label>Would you like to make this address as default address?</Form.Label>
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    name={"isPrimary"}
                                                    className="text-muted"
                                                    onChange={this.props.makeDefaultBillingAddress}
                                                    id={"custom-checkbox1"}
                                                    label={"Yes, please make this my default address."}
                                                />
                                            </Form.Group>
                                            {
                                                (this.props.billingAddresses.length > 0)
                                                    ?
                                                    <Button
                                                        className="btn-outline btn-grey btn-circle"
                                                        onClick={this.props.hideAddBillingSection}
                                                        type="submit">
                                                        Choose from existing billing addresses
                                                    </Button>
                                                    :
                                                    ''
                                            }
                                        </Form>)}
                    </Formik>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </>
            );
        }

        return (
            <>
                <PaymentSectionHeader sectionIndex={this.props.sectionIndex}
                                      sectionTitle={this.state.sectionTitle}
                />

                <div className="qd-payment-body">
                    <Card>
                        <Card.Body>
                            <Form className="card-payment__control">
                                {this.getAddressRadioButtons()}

                                <button type="button"
                                        className="btn btn-outline-primary btn-circle"
                                        data-toggle="modal"
                                        onClick={this.props.showAddBillingSection}
                                        data-target="#modal__payment-method">
                                    <i>
                                        <FontAwesomeIcon icon={faAddressCard}/>
                                    </i>
                                    Add New Address
                                </button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}

export default BillingAddress;
