import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BreadCrumb from './breadCrumb';
import NavigationHeader from './navigationHeader';
import Image from './image';
import OrderDetails from "./paymentComponents/orderDetails";
import PaymentDetails from "./paymentComponents/paymentDetails";
import BillingAddress from "./paymentComponents/billingAddress";
import Form from "react-bootstrap/Form";
import Load from "./loadAction";
import Button from "react-bootstrap/Button";
import { browserHistory } from 'react-router';
import { CopyRightsFooter } from './copyrights';

import {
    DateParts, PAYMENT_CARD_TYPES, PAYMENT_COMPONENT_STYLES, PAYMENT_FIELD_ID_MAP, QualdoEditions,
    QualdoPlanCycle, QualdoPlanVersions
} from "../utils/constant";
import Card from "react-bootstrap/Card";

import {
    addTime, getCurrencySymbol,
    getCurrentPlanDetailOfUser,
    getQualdoPlanCycle,
    removeQualdoPlanCycle,
    toastObj,
} from "../utils/common_utils";
import { getApi, getDeploymentPlanDetails, planDetails, getBrainTreeAuthToken, getPaymentDetailsTableData, submitUsersRenew } from "../utils/event_handling";
import Toast from "react-bootstrap/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentSuccess from "./paymentComponents/payment_success";
import PaymentOptions from "./paymentComponents/paymentOptions";
import PaymentSectionHeader from "./paymentComponents/paymentSectionHeader";
import {faCheck } from '@fortawesome/free-solid-svg-icons';

let braintree = require('braintree-web');

class Renew extends React.Component {
    constructor(props) {
        super(props);
        this.cardDetailsRef = React.createRef();
        this.billingDetailsRef = React.createRef()
        // this.billingCycleHandler = this.billingCycleHandler.bind(this);
        this.saveNewAddress = this.saveNewAddress.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.clientDidCreate = this.clientDidCreate.bind(this);
        this.hostedFieldsDidCreate = this.hostedFieldsDidCreate.bind(this);
        this.showPaymentPage = this.showPaymentPage.bind(this);
        this.cardTypeProcessor = this.cardTypeProcessor.bind(this);
        this.cardValidator = this.cardValidator.bind(this);
        this.getCardTypeImage = this.getCardTypeImage.bind(this);
        this.tokenizeFunction = this.tokenizeFunction.bind(this);
        this.handleCustomerName = this.handleCustomerName.bind(this);
        this.getComponentClass = this.getComponentClass.bind(this);
        this.makeCurrentAsDefaultPayment = this.makeCurrentAsDefaultPayment.bind(this);
        this.toggleNewCardSection = this.toggleNewCardSection.bind(this);
        this.paymentSelectionHandler = this.paymentSelectionHandler.bind(this);
        this.addressSelectionHandler = this.addressSelectionHandler.bind(this);
        this.hasValidCardParts = this.hasValidCardParts.bind(this);
        //        this.canEnableSubmitButton = this.canEnableSubmitButton.bind(this);
        this.setQualdoPlans = this.setQualdoPlans.bind(this);
        this.setPaymentPageDetails = this.setPaymentPageDetails.bind(this);
        this.createPaymentDataForUser = this.createPaymentDataForUser.bind(this);
        this.showAddBillingDetailsSection = this.showAddBillingDetailsSection.bind(this);
        this.hideAddBillingDetailsSection = this.hideAddBillingDetailsSection.bind(this);
        this.addressUpdateHandler = this.addressUpdateHandler.bind(this);
        this.makeDefaultBillingAddress = this.makeDefaultBillingAddress.bind(this);
        this.getSelectedPlan = this.getSelectedPlan.bind(this);
        this.getPaymentDetailsTableData = getPaymentDetailsTableData.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.setPaymentStatus = this.setPaymentStatus.bind(this);
        this.setAutoDebitStatus = this.setAutoDebitStatus.bind(this);
        this.getApi = getApi.bind(this);
        this.getDeploymentPlanDetails = getDeploymentPlanDetails.bind(this);
        this.planDetails = planDetails.bind(this);
        this.getBrainTreeAuthToken = getBrainTreeAuthToken.bind(this);
        this.getQualdoPlanCycle = getQualdoPlanCycle.bind(this);
        this.submitUsersRenew = submitUsersRenew.bind(this);
        this.changePaymentPageDetails = this.changePaymentPageDetails.bind(this);
        
        if(localStorage.getItem('deploymentStatus') ===null || localStorage.getItem('deploymentStatus') === undefined){
            window.location.href = "/";
        }
        else if(localStorage.getItem('renew') !== 'true'){
            window.location.href = "/dashboard";
        }
        else if(localStorage.getItem('deploymentStatus') !== 'deployed' && localStorage.getItem('renew') !== 'true'){
            window.location.href = "/deployment";
        }

        localStorage.setItem("src_choose_plan", "true");
        this.getApi("get_payment_page_details");

        const srcPage = localStorage.getItem("src_choose_plan");
        let srcIsChoosePlan = (srcPage !== undefined && srcPage !== null);
        let billingCycle;
        if (srcIsChoosePlan) {
            // Get plan cycle from local storage. This is chosen by user in choose plan page
            // We will use the cycle they have chosen in previous page
            billingCycle = this.getQualdoPlanCycle();
        } else {
            // User came to this page by clicking 'Pay Now' button.
            // So we will try to get the plan cycle from the user's plan detail
            let currentPlanDetail = getCurrentPlanDetailOfUser();

            if (currentPlanDetail !== null) {
                billingCycle = currentPlanDetail.plan_cycle;
            } else {
                // This case will not happen. We are adding it to prevent any error
                billingCycle = this.getQualdoPlanCycle();
            }
        }

        if (billingCycle === QualdoPlanCycle.TRIAL_14_DAYS) {
            billingCycle = QualdoPlanCycle.MONTHLY;
        }

        // localStorage.removeItem("src_choose_plan");
        let expiryDate = undefined;
        let customerName = '';
        let make_default = false;
        const exCardDetails = this.props.exCardDetails;
        if (exCardDetails !== undefined && exCardDetails !== null) {
            expiryDate = exCardDetails.expires;
            customerName = exCardDetails.customerName;
            make_default = exCardDetails.make_default === 1;
        }

        this.state = {
            price: '',
            cycle: 'annually',
            deployment_details: null,
            error_message: null,
            showNotAllowedPopup: false,
            reRenderNavComponent: true,
            billingCycle: null,
            planDetails: {},
            addNewCard: false,
            canShowAddBillingAddress: false,
            hostedFields: null,
            orderID: null,
            toastStatus: false,
            errorOccurred: false,
            unitCostForData: 52,
            addOnCostForData: 0,
            unitCostForModel: 9,
            addOnCostForModel: 0,
            nextBillingDate: null,
            cycleCost: 0,
            addOnCycles: [],
            paymentPageLoading: false,
            cardType: null,
            customerNameClass: '',
            enableSubmitButton: true,
            braintreeAuthorizationToken: '',
            customerName: customerName,
            make_default: make_default,
            isEdit: this.props.isEdit !== undefined ? this.props.isEdit : false,
            previousExpiryDate: expiryDate,
            availableCards: null,
            billingAddresses: [],
            selectedPayment: null,
            selectedAddress: null,
            disableSubmitBtn: false,
            newBillingAddress: { "isPrimary": false },
            paymentSuccess: false,
            braintreeLoading: true,
            autoDebit: false,
            srcIsChoosePlan: srcIsChoosePlan,
            disableButton: false,
            validation: {
                cvv: {
                    is_valid: false,
                    is_invalid: false
                },
                expiration_date: {
                    is_valid: false,
                    is_invalid: false
                },
                card_number: {
                    is_valid: false,
                    is_invalid: false
                },
                cardHolderName: {
                    is_valid: false,
                    is_invalid: false
                },
            }
        }
        this.getApi("get_user_payments_and_billing_history")
        this.getDeploymentPlanDetails()
        // this.planDetails()
        this.getApi("get_payment_page_details");
    }

    setAutoDebitStatus() {
        this.setState({ autoDebit: !this.state.autoDebit })
    }

    setPaymentStatus(paymentSuccess) {
        let reRenderNavComponent = this.state.reRenderNavComponent;

        if (paymentSuccess) {
            // Since payment is successful, we can remove qualdo plan cycle from local storage
            removeQualdoPlanCycle();
        }

        this.setState({
            paymentSuccess: paymentSuccess,
            reRenderNavComponent: reRenderNavComponent,
            disableSubmitBtn: false
        });
    }

    componentDidUpdate() {

    }

    showAddBillingDetailsSection() {
        this.setState({ canShowAddBillingAddress: true })
    }

    hideAddBillingDetailsSection() {

        this.setState({ canShowAddBillingAddress: false, newBillingAddress: { "isPrimary": false } })
    }

    closeToast() {
        this.setState({ toastStatus: false }, this.getData);
    }

    hasValidCardParts() {
        const validation = this.state.validation;
        return (validation.card_number.is_valid &&
            validation.cvv.is_valid &&
            validation.expiration_date.is_valid &&
            validation.cardHolderName.is_valid
        );
    }


    scrollToCardDetails() {
        window.scrollTo(0, this.cardDetailsRef.current.offsetTop)
    }

    scrollToBillingDetails() {
        window.scrollTo(10, this.billingDetailsRef.current.offsetTop)
    }

    toggleNewCardSection() {
        const newState = !this.state.addNewCard;
        this.setState({ addNewCard: newState });
        if (newState) {
            this.setState({ braintreeLoading: true });
            this.showPaymentPage();
        } else {
            let availableCards = this.state.availableCards;
            if (availableCards === null) {
                availableCards = [];
            }

            this.setState({ enableSubmitButton: availableCards.length > 0 });
        }
    }

    paymentSelectionHandler(status) {
        this.setState({ selectedPayment: status });
    }

    addressSelectionHandler(status) {
        this.setState({ selectedAddress: status });
    }

    createPaymentDataForUser(details) {
        let newCard = this.state.addNewCard;

        let finalData = {
            "billing_cycle": this.state.billingCycle,
            "order_id": this.state.orderID,
            "auto_debit": this.state.autoDebit,
            "plan_type": this.getSelectedPlan(),
        }

        if (newCard) {
            finalData["nonce"] = details["nonce"];
            finalData["customer_name"] = details["customer_name"];
            finalData["make_default"] = details["make_default"];
        } else {

            let paymentDetail = this.state.availableCards.filter(x => (
                String(x.id) === String(this.state.selectedPayment)))
            let paymentDetailElement = paymentDetail[0];
            finalData["payment_id"] = parseInt(String(paymentDetailElement.id));
            finalData["customer_name"] = paymentDetailElement.customerName;
        }

        let hasNewAddress = this.state.canShowAddBillingAddress;
        if (hasNewAddress) {
            let newAddress = this.state.newBillingAddress;
            newAddress["customer_name"] = finalData.customer_name;
            finalData["billing_address"] = newAddress
        } else {
            finalData["billing_address_id"] = parseInt(String(this.state.selectedAddress));
        }

        return finalData;
    }

    submitHandler(event) {
        event.preventDefault();

        // If user has chosen to pay from "existing card"
        // and "existing billing address",
        // so we can directly submit both payment and billing address id information to server
        if (!this.state.addNewCard && !this.state.canShowAddBillingAddress) {
            let paymentData = this.createPaymentDataForUser({});
            this.setState({ disableSubmitBtn: true });
            this.submitUsersRenew(paymentData, this.setPaymentStatus);
            return;
        }

        // If user is adding new card detail, validate that all
        // corresponding values are filled correctly
        if (this.state.addNewCard) {
            let formIsInvalid = false;
            let retrievedState = this.state.hostedFields.getState();
            // Loop through the Hosted Fields and check
            // for validity, apply the is-invalid class
            // to the field container if invalid

            let validation = this.state.validation;
            for (let field of Object.keys(retrievedState.fields)) {
                if (!retrievedState.fields[field].isValid) {
                    // Mark the error component
                    let mappedID = PAYMENT_FIELD_ID_MAP[field];
                    if (validation === undefined) {
                        continue;
                    }

                    let currentCompValidation = validation[mappedID];
                    if (currentCompValidation === undefined || currentCompValidation == null) {
                        continue;
                    }

                    currentCompValidation.is_invalid = true;
                    formIsInvalid = true;
                }
            }

            if (this.state.customerName.trim().length === 0) {
                this.setState({ customerNameClass: 'is-invalid' });
                validation.cardHolderName.is_invalid = true;
                formIsInvalid = true;
            }

            this.setState({ validation: validation });

            if (formIsInvalid) {
                // skip tokenization request if any fields are invalid
                this.scrollToCardDetails();
                return;
            }
        }

        // If user is adding new billing address, validate that all
        // corresponding values are filled correctly
        if (this.state.canShowAddBillingAddress) {
            let isValidAddress = this.validateAddress();
            if (!isValidAddress) {
                this.scrollToBillingDetails();
                return;
            }
        }

        // If user has chosen to pay from "existing card",
        // but they are adding new billing address now
        // So we can directly submit the payment id information to server
        // but take the billing address details from the form filled by user
        if (!this.state.addNewCard && this.state.canShowAddBillingAddress) {
            let paymentData = this.createPaymentDataForUser({});
            this.setState({ disableSubmitBtn: true });
            this.submitUsersRenew(paymentData, this.setPaymentStatus);
            return;
        }

        // This case will happen, when user is adding new card detail
        // We will tokenize the user's card detail and then we will
        // use the tokenized card value along with the
        // newly added billing address or the chosen billing address which was already added
        this.state.hostedFields.tokenize(this.tokenizeFunction);
    }

    componentDidMount() {
        // Get plan details
        const bt_token = this.getBrainTreeAuthToken();
        this.setState({ braintreeAuthorizationToken: bt_token });

        let storedDetails = localStorage.getItem("qualdoPlanDetails");
        if (this.props.location.state !== undefined) {
            let show_payment_details = this.props.location.state["show_payment_details"];
            if (show_payment_details === true) {
                this.setPaymentPageDetails(this.props.location.state);
            }
        }

        if (storedDetails === undefined || storedDetails === null) {
            // Fetch available plan details from API
            let edition = localStorage.getItem("qualdoEdition");
            if (edition === undefined || edition === null) {
                edition = QualdoEditions.ML_EDITION;
            }

            this.getApi("fetchQualdoPlans", { "edition": edition });
        } else {
            this.setState({ planDetails: JSON.parse(storedDetails) })
        }
        setTimeout(() => {
            if (this.props.location.state !== undefined) {
                let show_payment_details = this.props.location.state["show_payment_details"];
                if (show_payment_details === true) {
                    this.setPaymentPageDetails(this.props.location.state);
                }
            }

            if (storedDetails === undefined || storedDetails === null) {
                // Fetch available plan details from API
                let edition = localStorage.getItem("qualdoEdition");
                if (edition === undefined || edition === null) {
                    edition = QualdoEditions.ML_EDITION;
                }

                this.getApi("fetchQualdoPlans", { "edition": edition });
            } else {
                this.setState({ planDetails: JSON.parse(storedDetails) })
            }


        }, 500);

        this.getApi("get_payment_page_details");
        this.showPaymentPage();
    }


    changePaymentPageDetails(data) {
        let planType = data["plan_details"]["plan_name"];
        data["hideModelInfo"] = this.props.hideModelInfo
        localStorage.setItem("qualdoPlan", planType);
        browserHistory.push({ pathname: '/renew', state: data })
    }


    setPaymentPageDetails(paymentPageDetail) {
        let currPlanDetails = paymentPageDetail.plan_details;
        if (currPlanDetails === undefined) {
            currPlanDetails = {};
        }
        let hideModelInfo = paymentPageDetail.hideModelInfo

        let auto_debit_status = currPlanDetails.auto_debit_status === 1;

        let availableCards = paymentPageDetail.available_cards;
        // let paymentCompletedForCycle = paymentPageDetail.payment_completed_for_cycle;
        let hasNoCardDetails = availableCards.length === 0;
        let billingAddresses = paymentPageDetail.billing_addresses;

        let nextBillingDate = '';
        let unitCostForData = paymentPageDetail.unit_cost_for_data;
        let addOnCostForData = paymentPageDetail.addon_data_usage_cost;
        let unitCostForModel = paymentPageDetail.unit_cost_for_model;
        let addOnCostForModel = paymentPageDetail.addon_model_cost;
        // let cycleCost = paymentPageDetail.cycle_cost;
        let orderID = paymentPageDetail.order_id;
        let addOnCycles = paymentPageDetail.add_on_cycles;
        let remainingAmountFromCurrentPlan = paymentPageDetail.remaining_amount_from_current_plan;

        let hasNoBillingAddress = billingAddresses === undefined || billingAddresses.length === 0;
        this.setState({
            addNewCard: hasNoCardDetails,
            unitCostForData: unitCostForData,
            addOnCostForData: addOnCostForData,
            unitCostForModel: unitCostForModel,
            addOnCostForModel: addOnCostForModel,
            // cycleCost: cycleCost,
            addOnCycles: addOnCycles,
            orderID: orderID,
            hideModelInfo: hideModelInfo,
            nextBillingDate: nextBillingDate,
            paymentPageLoading: false,
            autoDebit: auto_debit_status,
            remainingAmountFromCurrentPlan: remainingAmountFromCurrentPlan
        });

        if (hasNoCardDetails) {
            this.showPaymentPage();
        } else {
            let match = availableCards.filter(x => x.isPrimary);
            if (match.length === 1) {
                match = match[0];
            } else {
                match = availableCards[0];
            }
            this.setState({ selectedPayment: match.id });
        }

        if (!hasNoBillingAddress) {
            let match = billingAddresses.filter(x => x.isPrimary);
            if (match.length === 1) {
                match = match[0];
            } else {
                match = billingAddresses[0];
            }
            this.setState({ selectedAddress: match.billing_address_id });
        }

        this.setState({ availableCards: availableCards });
        this.setState({ billingAddresses: billingAddresses });
        this.setState({ canShowAddBillingAddress: hasNoBillingAddress });
        this.setState({ load: false });
    }

    setQualdoPlans(planInfo) {
        this.setState({ planDetails: planInfo })
    }

    showPaymentPage() {
        // This function which will render the braintree's payment components such as
        // Card number, CVV, expiration date fields will be rendered by braintree components
        let braintreeAuthorizationToken = this.getBrainTreeAuthToken();
        braintree.client.create({
            authorization: braintreeAuthorizationToken
        }, this.clientDidCreate);
    }

    clientDidCreate(err, client) {
        braintree.hostedFields.create({
            client: client,
            styles: PAYMENT_COMPONENT_STYLES,
            fields: {
                number: {
                    selector: '#card_number',
                    placeholder: 'Enter your card number'
                },
                cvv: {
                    selector: '#cvv',
                    type: "password"
                },
                expirationDate: {
                    selector: '#expiration_date',
                    placeholder: 'MM/YY',
                    prefill: this.state.previousExpiryDate
                }
            }
        }, this.hostedFieldsDidCreate);
    }

    cardTypeProcessor(event) {
        if (event.cards.length === 1) {
            const cardType = event.cards[0].type;
            this.setState({ cardType: cardType });
        } else {
            this.setState({ cardType: null });
        }
    }

    cardValidator(event) {
        const fieldName = event.emittedBy;
        let field = event.fields[fieldName];
        const currentFieldId = field.container.id;

        // Remove any previously applied error or warning classes
        let validation = this.state.validation;
        let isValidPreviously = validation[currentFieldId].is_valid;
        validation[currentFieldId].is_valid = false;
        validation[currentFieldId].is_invalid = false;

        if (field.isValid) {
            validation[currentFieldId].is_valid = true;
        } else if (field.isPotentiallyValid) {
            // skip adding classes if the field is
            // not valid, but is potentially valid
            if (isValidPreviously) {
                validation[currentFieldId].is_invalid = true;
            }
        } else {
            validation[currentFieldId].is_invalid = true;
        }

        this.setState({ validation: validation });
    }

    hostedFieldsDidCreate(err, hostedFields) {
        this.setState({ hostedFields: hostedFields });
        if (hostedFields !== undefined) {
            hostedFields.on('cardTypeChange', this.cardTypeProcessor);
            hostedFields.on('validityChange', this.cardValidator);
        }

        this.setState({ braintreeLoading: false });
    }

    tokenizeFunction(err, payload) {
        if (err) {
            this.props.enableSubmitButton(true);
            console.error(err);
        } else {
            let details = {
                "nonce": payload.nonce,
                "customer_name": this.state.customerName,
                "make_default": this.state.make_default
            };

            let finalData = this.createPaymentDataForUser(details);
            this.setState({ disableSubmitBtn: true });

            this.submitUsersRenew(finalData, this.setPaymentStatus);
        }
    }

    addressUpdateHandler(event) {
        let address = this.state.newBillingAddress;
        address[event.target.name] = event.target.value;
        this.setState({ newBillingAddress: address });
    }

    makeDefaultBillingAddress(event) {
        let address = this.state.newBillingAddress;
        address[event.target.name] = !address.isPrimary;
        this.setState({ newBillingAddress: address });
    }

    getCardTypeImage() {
        return (<Image src={PAYMENT_CARD_TYPES[this.state.cardType]} />);
    }

    getComponentClass(component) {
        let reqClass = "form-control";
        let validation = this.state.validation;
        let componentInfo = validation[component];

        if (componentInfo === undefined || componentInfo === null) {
            return reqClass;
        }

        if (componentInfo.is_valid) {
            reqClass = `${reqClass} is-valid`;
        } else if (componentInfo.is_invalid) {
            reqClass = `${reqClass} is-invalid`;
        }

        return reqClass;
    }

    handleCustomerName(event) {
        const customerName = event.target.value;
        this.setState({ customerName: customerName });
        let validation = this.state.validation;
        let cardHolderDetail = validation["cardHolderName"];

        cardHolderDetail.is_valid = false;
        cardHolderDetail.is_invalid = false;

        if (!customerName.trim()) {
            cardHolderDetail.is_invalid = true;
        } else {
            cardHolderDetail.is_valid = true;
        }

        this.setState({ validation: validation });
    }

    makeCurrentAsDefaultPayment(event) {
        this.setState({ make_default: !this.state.make_default });
    }

    validateAddress() {
        let newAddress = this.state.newBillingAddress;
        let keys = ["company_name", "address_line_1", "city", "state", "zip_code", "country"];

        for (let i = 0; i <= (keys.length - 1); i++) {
            const currentKey = keys[i];
            const currentValue = newAddress[currentKey];
            if (currentValue === undefined || currentValue.length < 3) {
                return false
            }
            if (currentKey === "zip_code") {
                return RegExp(/^[0-9]{6}(?:-[0-9]{5})?$/).test(currentValue);
            }
        }
        return true
    }

    canEnableSubmitButton() {
        let enableSubmitButton = this.state.enableSubmitButton;

        if (!this.state.addNewCard && !this.state.canShowAddBillingAddress) {
            return enableSubmitButton;
        }
        if (this.state.addNewCard) {
            enableSubmitButton = this.hasValidCardParts();
        }

        if (this.state.canShowAddBillingAddress) {
            enableSubmitButton = this.validateAddress();
        }

        return enableSubmitButton;
    }

    saveNewAddress(name, value, isValid) {
        let newBillingAddress = this.state.newBillingAddress;
        newBillingAddress[name] = value
        this.setState({ newBillingAddress: newBillingAddress });
    }


    getSelectedPlan() {
        let planType = localStorage.getItem("qualdoPlan");
        if (planType === undefined || planType === null) {
            planType = QualdoPlanVersions.STARTER;
        }
        return planType;
    }

    showToast(status, msg, link) {
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"]
        });

        if (link.trim().length > 0) {
            setTimeout(function () {
                window.location.href = link;
            }, 3000);
        }
    }

    render() {
        let sessionToken = localStorage.getItem('sessionToken');
        if (sessionToken === "undefined" || sessionToken === "null" || sessionToken === null || sessionToken === undefined) {
            window.location = '/';
            return;
        }

        let planDetails = this.state.planDetails;
        let planType = this.getSelectedPlan();

        if (planDetails === undefined || planDetails == null) {
            return (<Load />);
        }
        let planCycleDetail = planDetails[this.state.billingCycle];
        if (planCycleDetail === undefined || planCycleDetail === null) {
            return (<Load />);
        }

        let currentDetail = planCycleDetail[planType];
        let totalAmount;
        if (this.state.billingCycle === QualdoPlanCycle.YEARLY) {
            totalAmount = parseFloat(String(this.state.cycleCost));
        } else {
            totalAmount = parseFloat(String(this.state.cycleCost));
        }


        let nextBilling;
        let nextBillingDate;


        let cycle = this.state.billingCycle === QualdoPlanCycle.YEARLY ? DateParts.YEAR : DateParts.MONTH;
        nextBillingDate = addTime(new Date(), 1, cycle);

        const dateArray = nextBillingDate.toDateString().split(" ");

        // Remove first part which represents weekday
        dateArray.shift();
        nextBilling = dateArray.join(" ");

        let planName = `Qualdo ${currentDetail.title}`;

        const currencySymbol = getCurrencySymbol();

        return (
            <>
                {sessionToken === null ?
                    <header className="qd-header">
                        <div className="qd-header-container navbar navbar-expand-md">
                            <div className="qd-header__brand">
                                <a href="#!">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src="logoLight"
                                    />
                                </a>
                            </div>
                        </div>
                    </header>
                    : <NavigationHeader  {...this.props} key={this.state.reRenderNavComponent} />
                }
                <main>
                    <div id="toastMsg">
                        <Toast className={this.state.toastClass}
                            onClose={() => this.closeToast()}
                            show={this.state.toastStatus} delay={5000} autohide>
                            <div className="d-flex align-items-center">
                                {
                                    this.state.toastStatus ?
                                        <i className="toast-icon">
                                            <FontAwesomeIcon icon={this.state.toastIcon} />
                                        </i>
                                        :
                                        ''
                                }

                                <Toast.Body>
                                    <strong className="toast-title">{this.state.toastHeading}</strong>
                                    <p>{this.state.toastMsg}</p>
                                </Toast.Body>
                                <button type="button"
                                    onClick={this.closeToast}
                                    className="close"
                                    data-dismiss="toast"
                                    aria-label="Close" />
                            </div>
                        </Toast>
                    </div>
                    <BreadCrumb icon='privacy' title='Billing' separator={true} separatorData="Payment" />
                    <div className="qd-container">
                        <div className="qd-body no-tabs bg-white p-3 p-sm-4">
                            {
                                this.state.paymentSuccess
                                    ?
                                    <PaymentSuccess />
                                    :
                                    <div className="billing-payment">
                                        <div className="heading-section">
                                            <h3 className="heading-section-title">You're almost there</h3>
                                            <p>Engage More Leads with our {planName} Plan</p>
                                        </div>

                                        {this.state.deployment_details !== null ?

                                            <div className="sticky__billing-summary-card">
                                                <Row>
                                                    <Col lg={8}>

                                                        <div className="qd-payment-block">
                                                            <div className="qd-payment-header">
                                                                <h4 className="qd-payment-title">
                                                                    <span className="circle-number circle-number-gradient">
                                                                        1
                                                                    </span>
                                                                    Cloud Configuration Details
                                                                </h4>
                                                            </div>
                                                            <div className="qd-payment-body">
                                                                <Card className="fancy-card">
                                                                    <Card.Body>
                                                                        <Row className="row-sm">
                                                                            <Col md={6}>
                                                                                <h6 className="card-subtitle text-muted mb-2">Cloud Settings</h6>
                                                                                <p className="card-text mb-3">{this.state.deployment_details[0]['deployment_name']}</p>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <h6 className="card-subtitle text-muted mb-2">Edition</h6>
                                                                                <ul className="pl-0">
                                                                                    <li key={"ds"}><strong>{this.state.deployment_details[0]['edition']}</strong>
                                                                                        {/* Edition */}
                                                                                    </li>
                                                                                </ul>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <h6 className="card-subtitle text-muted mb-2">Cloud</h6>
                                                                                <div className="cloud-deployment-card">
                                                                                    <div className="card-icon">
                                                                                        <Image src="gcp_brandMark" />
                                                                                    </div>
                                                                                    <div className="card-stacked">
                                                                                        <h5 className="card-title">{this.state.deployment_details[0]['cloud']}</h5>
                                                                                        <p className="card-text">{this.state.deployment_details[0]['cloud_support']}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <h6 className="card-subtitle mb-2 text-muted mb-2">Benefits</h6>
                                                                                <ul className="pl-0 mb-0">
                                                                                    <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Data Monitoring</li>
                                                                                    <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Alerts</li>
                                                                                    <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Notifications</li>
                                                                                    <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Visualizations</li>
                                                                                </ul>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>
                                                        </div>

                                                   
                                                        <div className="qd-payment-block">
                                                            {
                                                                this.state.addNewCard ?
                                                                    <>
                                                                        <PaymentSectionHeader sectionIndex={2}
                                                                            sectionTitle={"Enter Payment Details"}

                                                                        />


                                                                        <div className="qd-payment-body" ref={this.cardDetailsRef}>
                                                                            <Card>
                                                                                <Card.Body>
                                                                                    <Row>
                                                                                        <Col md={12}>
                                                                                            <Form className={"payment__card"}>
                                                                                                <PaymentOptions />

                                                                                                {
                                                                                                    this.state.braintreeLoading ?
                                                                                                        <Load /> : ''
                                                                                                }


                                                                                                <Form.Row style={{ display: this.state.braintreeLoading ? "none" : "block" }}>
                                                                                                    <Col xs={7}>
                                                                                                        <Form.Group
                                                                                                            controlId="formCardName">
                                                                                                            <Form.Label>Name
                                                                                                                on
                                                                                                                card <span className="text-danger">*</span></Form.Label>
                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                placeholder="Name"
                                                                                                                className={this.getComponentClass("cardHolderName")}
                                                                                                                onChange={this.handleCustomerName}
                                                                                                                defaultValue={this.state.customerName}
                                                                                                            />
                                                                                                            <div
                                                                                                                className="invalid-feedback">
                                                                                                                Invalid
                                                                                                                cardholder
                                                                                                                name
                                                                                                            </div>
                                                                                                        </Form.Group>
                                                                                                    </Col>
                                                                                                </Form.Row>

                                                                                                <Form.Row >
                                                                                                    <Col xs={7}
                                                                                                        style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                                                        className="form-group position-relative">
                                                                                                        <Form.Label
                                                                                                            className="hosted-fields--label">Credit
                                                                                                            card
                                                                                                            number <span className="text-danger">*</span></Form.Label>
                                                                                                        <div
                                                                                                            className={this.getComponentClass("card_number")}
                                                                                                            id="card_number" />
                                                                                                        <div
                                                                                                            className="invalid-feedback">
                                                                                                            Credit
                                                                                                            card
                                                                                                            number
                                                                                                            is
                                                                                                            required
                                                                                                        </div>
                                                                                                        {this.state.cardType === null
                                                                                                            ?
                                                                                                            '' :
                                                                                                            <div
                                                                                                                className="credit-card-icon">
                                                                                                                {this.getCardTypeImage()}
                                                                                                            </div>
                                                                                                        }
                                                                                                    </Col>

                                                                                                    <Col
                                                                                                        style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                                                        sm={3}
                                                                                                        xs={5} >
                                                                                                        <label
                                                                                                            htmlFor="expiration_date">Expiry <span className="text-danger">*</span></label>
                                                                                                        <div
                                                                                                            className={this.getComponentClass("expiration_date")}
                                                                                                            id="expiration_date" />
                                                                                                        <div
                                                                                                            className="invalid-feedback">
                                                                                                            Please
                                                                                                            provide
                                                                                                            valid
                                                                                                            expiration
                                                                                                            date
                                                                                                        </div>
                                                                                                    </Col>

                                                                                                    <Col
                                                                                                        style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                                                        xs={2}
                                                                                                        className="form-group">
                                                                                                        <Form.Label
                                                                                                            className="hosted-fields--cvv">CVV <span className="text-danger">*</span></Form.Label>
                                                                                                        <div
                                                                                                            className={this.getComponentClass("cvv")}
                                                                                                            id="cvv" />
                                                                                                        <div
                                                                                                            className="invalid-feedback">
                                                                                                            Security
                                                                                                            code
                                                                                                            required
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Form.Row>

                                                                                                <Form.Group
                                                                                                    style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                                                    controlId="formBasicCheckbox"
                                                                                                    bscustomprefix="custom-control"
                                                                                                    className="mt-2">
                                                                                                    <Form.Label>Would
                                                                                                        you like
                                                                                                        to
                                                                                                        make this
                                                                                                        card your
                                                                                                        primary
                                                                                                        payment
                                                                                                        method?</Form.Label>
                                                                                                    <Form.Check
                                                                                                        custom
                                                                                                        type="checkbox"
                                                                                                        name={"isPrimary"}
                                                                                                        className="text-muted"
                                                                                                        onChange={this.makeCurrentAsDefaultPayment}
                                                                                                        defaultValue={this.state.make_default}
                                                                                                        id={"custom-checkbox2"}
                                                                                                        label={"Yes, please make the this my primary payment method"}
                                                                                                    />
                                                                                                </Form.Group>
                                                                                                {
                                                                                                    (this.state.availableCards !== null &&
                                                                                                        this.state.availableCards.length > 0)
                                                                                                        ?
                                                                                                        <Button
                                                                                                            style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                                                            className="btn-outline btn-grey btn-circle"
                                                                                                            onClick={this.toggleNewCardSection}
                                                                                                        >
                                                                                                            Choose
                                                                                                            from
                                                                                                            existing
                                                                                                            payment
                                                                                                        </Button>
                                                                                                        :
                                                                                                        ''
                                                                                                }
                                                                                            </Form>
                                                                                        </Col>
                                                                                    </Row>

                                                                                </Card.Body>
                                                                            </Card>
                                                                        </div>


                                                                    </>
                                                                    :
                                                                    <PaymentDetails
                                                                        sectionIndex={'2'}
                                                                        availableCards={this.state.availableCards}
                                                                        selectedPayment={this.state.selectedPayment}
                                                                        radioHandler={this.paymentSelectionHandler}
                                                                        toggleNewCardSection={this.toggleNewCardSection}
                                                                    />
                                                            }

                                                        </div>

                                                        <div className="qd-payment-block">
                                                            <BillingAddress
                                                                sectionIndex={'3'}
                                                                divRef={this.billingDetailsRef}
                                                                selectedAddress={this.state.selectedAddress}
                                                                makeDefaultBillingAddress={this.makeDefaultBillingAddress}
                                                                addressUpdateHandler={this.addressUpdateHandler}
                                                                canShowAddBillingAddress={this.state.canShowAddBillingAddress}
                                                                showAddBillingSection={this.showAddBillingDetailsSection}
                                                                hideAddBillingSection={this.hideAddBillingDetailsSection}
                                                                addressSelectionHandler={this.addressSelectionHandler}
                                                                billingAddresses={this.state.billingAddresses}
                                                                saveNewAddress={this.saveNewAddress}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>

                                                        <OrderDetails totalAmount={totalAmount}
                                                            loading={this.state.paymentPageLoading}
                                                            key={`order_details_${this.state.paymentPageLoading}`}
                                                            planName={planName}
                                                            remainingAmountFromCurrentPlan={this.state.remainingAmountFromCurrentPlan}
                                                            planCost={totalAmount}
                                                            // selectedBillingCycle={selectedBillingCycle}
                                                            addOnCostForData={this.state.addOnCostForData}
                                                            orderID={this.state.orderID}
                                                            unitCostForData={this.state.unitCostForData}
                                                            addOnCostForModel={this.state.addOnCostForModel}
                                                            unitCostForModel={this.state.unitCostForModel}
                                                            addOnCycles={this.state.addOnCycles}
                                                            nextBilling={nextBilling}
                                                            currencySymbol={currencySymbol}
                                                            disableSubmitBtn={this.state.disableSubmitBtn}
                                                            billingCycle={this.state.billingCycle === "annually" ?
                                                                "Yearly" : "Monthly"}
                                                            submitHandler={this.submitHandler} />
                                                    </Col>
                                                </Row>
                                            </div>

                                            :
                                            ''

                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </main>
                <CopyRightsFooter />
            </>
        );
    }
}

export default Renew;