import React from 'react';
import Image from '../../components/image';

class EmailNotification extends React.Component {

    render() {
        let isPassWordThankyou = this.props.isPasswordThankYou;
        if(isPassWordThankyou){
            return (
                <div className="prelogin-content">
                    <div className="prelogin-thankyou">
                        <i className="d-inline-flex p-2 mr-3">
                           <Image src="email"/>
                        </i>
                        <h2>Thank you!</h2>
                        <h3>Please check your email!</h3>
                        <p className="text-muted">Look out for an email from us with instructions on how to reset your password!</p>
                        <p className="text-muted"><small>Didn’t receive an email? <a href="/signup">Try a different email address</a></small></p>
                    </div>
                </div>
            );

        } else{
            if(this.props.signupType === "joinTeam") {
               return (
                <div className="prelogin-content">
                    <div className="prelogin-thankyou">
                        <i className="d-inline-flex p-2 mr-3">
                           <Image src="email"/>
                        </i>
                        <h2>Thank you!</h2>
                        <p className="text-muted">We have sent your request to the Admin({this.props.email}).</p>
                        <p className="text-muted"> You will receive an email if admin approves your request.</p>
                        <p className="text-muted"><small>Didn’t receive an email? <a href="/signup">Try a different email address</a></small></p>
                    </div>
                </div>
            );
            }else{
               return (
                <div className="prelogin-content">
                    <div className="prelogin-thankyou">
                        <i className="d-inline-flex p-2 mr-3">
                           <Image src="email"/>
                        </i>
                        <h2>Thank you!</h2>
                        <h3>Check your Email!</h3>
                        <p className="text-muted">We have sent a verification email to <strong>{this.props.email}</strong>. Follow instructions in the email to get started!</p>
                        <p className="text-muted"><small>Didn’t receive an email? <a href="/signup">Try a different email address</a></small></p>
                    </div>
                </div>
            );
            }
        }
        
    }

}

export default EmailNotification;
