import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, browserHistory} from 'react-router';
import Home from './login/index';
import Deployment from './deploy/index';
import Signup from './signup/index';
import HomeDashboard from './dashboard/index'
import resetPasswordForm from './reset_password/index';
import setPasswordForm from './set_password/index';
import InitiateSetPassword from './set_password/components/initiateSetPassword';
import ForgotPassword from './login/components/forgotPassword';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './sass/main.scss';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import store from "./redux/store";
import { postMixpanel } from './mixpanel';
import Settings from './settings/index';
import Payment from "./components/payment"
import Renew from "./components/renew"
import { getRedirectionLinkToChangePassword } from "./utils/common_utils";
import { MANDATE_RESET_PASSWORD } from "./utils/constant";
let persistor = persistStore(store);

const PrivateRoute = ({
        component: Component,
        ...rest }) => {
            let search = window.location.search;
            if(search.length > 0){
                let params = new URLSearchParams(search);
                let resetPwdToken = params.get('token');
                localStorage.setItem('resetPwdToken', resetPwdToken);
            }
            
            let reset_password = localStorage.getItem(MANDATE_RESET_PASSWORD.Reset_Password)
            if(reset_password === MANDATE_RESET_PASSWORD.One && window.location.pathname !== "/") {
                let redirectionLink = getRedirectionLinkToChangePassword();
                browserHistory.push(redirectionLink);
                return;
            }
        }

const onBeforeLift = () => {
    let reset_password = localStorage.getItem(MANDATE_RESET_PASSWORD.Reset_Password);
    if(reset_password === MANDATE_RESET_PASSWORD.One && window.location.pathname !== "/") {
        let redirectionLink = getRedirectionLinkToChangePassword();
        browserHistory.push(redirectionLink);
        return;
    }
    if (reset_password === MANDATE_RESET_PASSWORD.One) {
        let redirectionLink = getRedirectionLinkToChangePassword();
        this.props.router.push(redirectionLink);
    }
}

const routing = (    
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift} >
        <Router history={browserHistory}>
            <div>
                <Route exact path="/" component={Home} />
                <Route path="/login" component={Home} isFirstTimeLogin={true}/>
                <Route path="/forget-password" component={ForgotPassword} />
                <Route path="/signup" pageType={0} component = {Signup} />
                <Route path="/password-reset" component={resetPasswordForm} />
                <Route path="/set-password" component={setPasswordForm} />
                <Route path = "/initiate-set-password" component={InitiateSetPassword} />
                <PrivateRoute path="/invalid-request" component={Signup} pageType={4}/>
                <Route path="/deployment" component={Deployment} isFirstTimeLogin={true}/>
                <Route path="/dashboard" component={HomeDashboard} isFirstTimeLogin={true}/>
                <Route path="/settings" component={(props) => {
                                  postMixpanel("Profile Page");
                                  return (<Settings/>);
                              }
                              } navKey={"account"}/>
                <Route path="/paymentprofile" component={Settings} navKey={"paymentprofile"}/>
                <Route path="/usagepayment" component={Settings} navKey={"usagepayment"}/>
                <Route path="/billinghistory" component={Settings} navKey={"billinghistory"}/>
                <Route path="/payment" component={Payment}/>
                <PrivateRoute path="/renew" component={Renew} />
            </div>
        </Router>
        </PersistGate>
    </Provider>
)
ReactDOM.render(routing, document.getElementById('root'));
serviceWorker.unregister();
