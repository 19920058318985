import React from 'react';
import $ from 'jquery';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const TagsInput = props => {
	const [tags, setTags] = React.useState(props.tags);
	const removeTags = indexToRemove => {
		// Will remove the tag in input box
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);

		// will remove the ip state variable
		props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)])
	};
	const addTags = event => {
		if (event.target.value !== "") {
			let ip = event.target.value
			// regex to validate ip and cidr range
			if(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/.test(ip)){
				$("#ip-address").removeClass("input-group")
				setTags([...tags,event.target.value])
				props.selectedTags([...tags, event.target.value]);
				// props.seletedip([...tags,])
			}
			else{
				$("#ip-address").addClass("input-group")
				$(".whitelisted-ip").addClass('d-none')

			}
			event.target.value = "";
		}
	};

	const keyPress=e =>{
		if(e.keyCode === 188 || e.key ==="Enter"){
		   addTags(e);
		   e.preventDefault()
		}
	 }
	return (
		<div className="qd_tags-input">
			<div className="tag-list">
				{tags.map((tag, index) => (
					<div key={index} className="tag-item">
						<div className="tag">
							<span className='tag-title'>{tag}</span>
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip>
										Remove ?
									</Tooltip>}>
								<i className='tag-close-icon' onClick={() => removeTags(index)}></i>
							</OverlayTrigger>
						</div>
					</div>
				))}
				<input
					id = "tag-input"
					type="text"
					// onKeyUp={event => event.key ==="Enter" ? addTags(event) : null}
					onKeyDown={keyPress}
					onBlur={event => addTags(event)}
					placeholder={props.placeholder}
					disabled={props.placeholder === null ? true : false}
				/>
			</div>
		</div>
	);
};

export default TagsInput;