import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUserLock, faHandPointRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-bootstrap/Toast';
import {toastObj} from "../../utils/common_utils";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { iam_ip } from "../../utils/ip_details";
import { Formik } from 'formik';
import * as yup from 'yup';
import { call_post_api }  from "../../utils/event_handling";


class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.showToast =this.showToast.bind(this);
        this.closeToast= this.closeToast.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.changePasswordIcon = this.changePasswordIcon.bind(this);
        this.changeConfirmPasswordIcon = this.changeConfirmPasswordIcon.bind(this);
        this.changeOldPasswordIcon = this.changeOldPasswordIcon.bind(this);
        this.reset = this.reset.bind(this);
        this.state = {
                toastStatus: false,
                errorCount: null,
                passwordIcon: faEye,
                passwordType: "password",
                confirmPasswordIcon: faEye,
                confirmPasswordType: "password",
                oldPasswordType: "password",
                oldPasswordIcon: faEye,
                disableButton: false,
                errors: {
                  password: '',
                  confirmPassword: '',
                }
        };
    }
    closeToast() {
        this.setState({toastStatus:false});
    }

    showToast(status, msg, link) {
       this.setState({disableButton: false});
       this.setState({
          toastStatus: true,
          toastMsg: msg,
          toastClass: toastObj[status]["className"],
          toastIcon: toastObj[status]["icon"],
          toastheading: toastObj[status]["heading"]
       });
    }
    reset(values,{resetForm}) {
         resetForm({values: ''})
    }
    changePassword(values) {
        this.setState({disableButton: true});
        let session = localStorage.getItem("sessionToken");
        const passwordDataValue = {"user_id":localStorage.getItem('user_id'),
                                 "current_password":values.currentPassword,
                                 "reset_password":values.newPassword}
              call_post_api(iam_ip, "profile/reset-password", passwordDataValue, session).then(
                (response) => {
                    if(response.code === 200) {
                       this.showToast("alert-success", response.message, "");
                    }else if(response.code === 401){
                       this.showToast("alert-danger", response.message, "");
                    }else{
                     this.showToast("alert-danger", "could not change password", "");
                    }
                }
            );
    }

    changePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
          this.setState({passwordIcon: faEyeSlash});
          this.setState({passwordType: "text"})
        } else {
          this.setState({passwordIcon: faEye});
          this.setState({passwordType: "password"})
        }

    }

    changeConfirmPasswordIcon() {
        let state = this.state.confirmPasswordIcon;
        if (state.iconName === "eye") {
          this.setState({confirmPasswordIcon: faEyeSlash});
          this.setState({confirmPasswordType: "text"});
        } else {
          this.setState({confirmPasswordIcon: faEye});
          this.setState({confirmPasswordType: "password"});
        }

    }

    changeOldPasswordIcon() {
        let state = this.state.oldPasswordIcon;
        if (state.iconName === "eye") {
          this.setState({oldPasswordIcon: faEyeSlash});
          this.setState({oldPasswordType: "text"});
        } else {
          this.setState({oldPasswordIcon: faEye});
          this.setState({oldPasswordType: "password"});
        }

    }


    render() {
        const changePasswordSchema = yup.object({
            currentPassword: yup.string().min(6,"Current Password must be at least 6 characters").required("Current Password is a required field"),
            newPassword: yup.string().max(16,"Max Length of the password should be 16").required("New Password is a required field").matches(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&._-])[A-Za-z\d@$!%*#?&._-]{8,}$/,
                      "Use at least 8 characters containing at least 1 number, 1 lowercase letter, 1 special character in @$!%*#?&._-"),
            verifyPassword: yup.string().required("Verify Password is a required field").oneOf([yup.ref('newPassword'), null],'Passwords must match',)

        });
        return (
            <>
                <div id="toastMsg">
                    <Toast className={this.state.toastClass}
                           onClose={() =>  this.closeToast()}
                           show={this.state.toastStatus}
                           delay={3000} autohide>
                        <div className="d-flex align-items-center">
                            {this.state.toastStatus ?
                                <i className="toast-icon">
                                    <FontAwesomeIcon icon={this.state.toastIcon}/>
                                </i>
                                :
                                ''
                            }

                            <Toast.Body>
                                <strong className="toast-title">{this.state.toastheading}</strong>
                                <p>{ this.state.toastMsg }</p>
                            </Toast.Body>
                            <button type="button"
                                    onClick = {this.closeToast}
                                    className="close"
                                    data-dismiss="toast"
                                    aria-label="Close"/>
                        </div>
                    </Toast>
                </div>
                <div className="qd-section_content  shadow-sm settings-preferences">
                    <div className="qd-section-header">
                        <h4 className="qd-section-header-title">
                            <i>
                                <FontAwesomeIcon icon={faKey}/>
                            </i> Change Password
                        </h4>
                    </div>
                <div className="qd-section-body">

                  <div className="row row-sm">
                    <div className="col-md-12">
                      <div className="portlet mb-0">
                        <div className="portlet-title">
                          <div className="caption">
                            <span className="text-uppercase font-dark">Change Password</span>
                          </div>
                        </div>
                        <div className="portlet-body">
                        <Formik
                        validationSchema={changePasswordSchema}
                        initialValues={{ currentPassword: '',newPassword: '',verifyPassword: '' }}
                        onSubmit={(values,{resetForm},event) => { this.changePassword(values); this.reset(values,{resetForm})}}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        isValid,
                    }) => (
                        <Form className="needs-validation" onSubmit={handleSubmit}>
                          <div action="" className="form-label-right-md">
                            <Form.Group className="row">
                              <Form.Label className="col-md-3 col-form-label">Current Password <span>*</span> :</Form.Label>
                              <div className="col-md-6">
                                <InputGroup className="has-append-icon">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <FontAwesomeIcon icon={faUserLock}/>
                                    </div>
                                  </div>
                                  <Form.Control
                                        type={this.state.oldPasswordType}
                                        autoComplete="off"
                                        name="currentPassword"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.currentPassword}
                                        isInvalid={errors.currentPassword && touched.currentPassword}
                                        placeholder={"currentPassword"}
                                  />
                                  <div className="input-group-append-icon">
                                    <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.changeOldPasswordIcon} icon={this.state.oldPasswordIcon} className="security-eye"/>
                                    </i>
                                  </div>
                                  <Form.Control.Feedback type="invalid">
                                        { touched.currentPassword && errors.currentPassword }
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </div>
                            </Form.Group>
                            <Form.Group className="row">
                              <Form.Label className="col-md-3 col-form-label">New Password <span>*</span> :</Form.Label>
                              <div className="col-md-6">
                                <InputGroup className="has-append-icon">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <FontAwesomeIcon icon={faUserLock}/>
                                    </div>
                                  </div>
                                  <Form.Control
                                        type={this.state.passwordType}
                                        autoComplete="off"
                                        name="newPassword"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.newPassword}
                                        isInvalid={errors.newPassword && touched.newPassword}
                                        placeholder={"newPassword"}

                                  />
                                  <div className="input-group-append-icon">
                                    <i className="security-eye">
                                          <FontAwesomeIcon onClick={this.changePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                    </i>
                                  </div>
                                  <Form.Control.Feedback type="invalid">
                                        { touched.newPassword && errors.newPassword }
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </div>
                            </Form.Group>
                            <Form.Group className="row">
                              <label className="col-md-3 col-form-label">Verify password <span>*</span> :</label>
                              <div className="col-md-6">
                                <InputGroup className="has-append-icon">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <FontAwesomeIcon icon={faUserLock}/>
                                    </div>
                                  </div>
                                  <Form.Control
                                        type={this.state.confirmPasswordType}
                                        autoComplete="off"
                                        name="verifyPassword"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.verifyPassword}
                                        isInvalid={errors.verifyPassword && touched.verifyPassword}
                                        placeholder={"verifyPassword"}

                                  />
                                  <div className="input-group-append-icon">
                                    <i className="security-eye">
                                          <FontAwesomeIcon onClick={this.changeConfirmPasswordIcon} icon={this.state.confirmPasswordIcon} className="security-eye"/>
                                    </i>
                                  </div>
                                  <Form.Control.Feedback type="invalid">
                                        { touched.verifyPassword && errors.verifyPassword }
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </div>
                            </Form.Group>
                            <ul className="password-hints col-md-8 offset-md-3 clearfix">
                              <li className="text-danger"><FontAwesomeIcon icon={faHandPointRight}/> 8 characters minimum</li>
                              <li className="text-success"><FontAwesomeIcon icon={faHandPointRight}/> 4 unique characters</li>
                              <li className="text-success"><FontAwesomeIcon icon={faHandPointRight}/> One special character
                              </li>
                              <li className="text-danger"><FontAwesomeIcon icon={faHandPointRight}/> Passwords match</li>
                            </ul>
                            <div className="row">
                              <div className="col-md-6 offset-md-3">
                                <button type="submit" disabled={this.state.disableButton} className="btn btn-outline-primary btn-circle mr-2">Change
                                  Password</button>
                              </div>
                            </div>
                          </div>
                        </Form>)}
                    </Formik>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
            </div>
        </>
        );
    }

}
export default ChangePassword;



