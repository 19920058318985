import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { browserHistory } from 'react-router';
import { getApi, getuserrenewal, getDeploymentPlanDetails, planDetails } from '../../utils/event_handling';
import $ from 'jquery';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { faTrash, faSync, faUser, faLock, faExclamationTriangle, faEye, faEyeSlash, faCheck } from '@fortawesome/free-solid-svg-icons';
import Image from '../../components/image';
import Modal from 'react-bootstrap/Modal';
import { handleDeleteForDeployment, getAllDeployment } from "../../utils/event_handling";
import { Formik } from 'formik';
import * as yup from 'yup';
import { toastObj } from "../../utils/common_utils";
import Toast from 'react-bootstrap/Toast';
let rd = 31;
let renewDisabled = false;
let payDisabled = false;
class TableAction extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getApi = getApi.bind(this);
        this.planDetails = planDetails.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.renew = this.renew.bind(this);
        this.pay = this.pay.bind(this);
        this.getuserrenewal = getuserrenewal.bind(this);
        this.getDeploymentPlanDetails = getDeploymentPlanDetails.bind(this);
        this.deletePopup = this.deletePopup.bind(this);
        this.closeDeletepopup = this.closeDeletepopup.bind(this);
        this.handleDeleteForDeployment = handleDeleteForDeployment.bind(this);
        this.changePasswordIcon = this.changePasswordIcon.bind(this);
        this.delete = this.delete.bind(this);
        this.showToast = this.showToast.bind(this);
        this.getAllDeployment = getAllDeployment.bind(this);
        this.state = {
            props,
            remainingData: '',
            showPopup: false,
            deleteButtonDisabled: false,
            passwordType: "password",
            passwordIcon: faEye,
            errors: null,
            password: '',
            errorOccurred: null,
            toastStatus: false,
            closeToast: false,
        }


        if (this.props.cell !== null && this.props.header === 'Validity Period') {
            const current = new Date();
            var convertDateFormate = current.toGMTString();
            var currentDate = new Date(convertDateFormate);
            var nextBillingDate = new Date(this.props.nextBillingDate);
            let Rd = Math.floor((Math.abs(nextBillingDate - currentDate)) / (1000 * 60 * 60 * 24))
            this.setState({ remainingData: Rd });
            rd = Rd
            if (currentDate > nextBillingDate) {
                rd = 0
            }
            localStorage.setItem('rd', Rd)

        }

    }

        componentDidUpdate(){
            /**
             * Updating the class for the dropdown using onclick button
             * Fix for issue QPS_2252
             */
            $( "button" ).click(function() {
                $("td").closest("td").removeClass("z-index-1");
                $(this).closest("td").addClass("z-index-1");
              });
    }

    handleClick = (e) => {
        if (this.props.header === 'Actions' && this.props.cell === 'No') {
            let data = { 'deployment_id': this.props.id, 'auto_debit': true, 'renewal': this.props.cell }
            this.props.handleRenewOptions(data, this.props.data);
            this.props.handleChange();
        }
        else if (this.props.header === 'Actions' && this.props.cell !== 'No') {
            let data = { 'deployment_id': this.props.id, 'auto_debit': false, 'renewal': this.props.cell }
            this.props.handleRenewOptions(data, this.props.data);
            this.props.handleChange();

        }
        else if (this.props.header === 'DeleteAction') {
            let deploymentId = this.props.reqData['deployment_id']
            if(this.props.reqData['status'] === 'deletion failed'){
                const splitName = this.props.reqData['deployment_name'].split("__1__");
                this.props.reqData['deployment_name'] = splitName[0]
            }
            localStorage.setItem('deployment_id', deploymentId)
            this.deletePopup();
        }
    }

    pay() {
        localStorage.setItem("deployment_id", this.props.id)
        localStorage.setItem("pay", true)
        browserHistory.push('/payment')
        this.planDetails()
        this.getDeploymentPlanDetails(this.props.id);
        setTimeout(() =>
            browserHistory.push('/payment'), 700
        );
    }

    renew() {
        let data = { 'deployment_id': this.props.id, 'deployment_name': this.props.data['Deployment Name'] }
        this.props.handlerenew(data);
        this.getApi("get_user_payments_and_billing_history");

    }

    deletePopup() {
        this.setState({ showPopup: true })
    }

    closeDeletepopup() {
        this.setState({
            showPopup: false,
            errors: false,
            password: ''
        })
    }

    changePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
            this.setState({
                passwordIcon: faEyeSlash,
                passwordType: "text"
            });
        } else {
            this.setState({
                passwordIcon: faEye,
                passwordType: "password"
            });
        }
    }
    delete() {
        this.setState({ deleteButtonDisabled: true });
        if (this.state.password.length !== 0) {
            this.handleDeleteForDeployment();
        }
        else {
            this.setState({ errors: "Please Enter Your Password" });
            this.setState({ deleteButtonDisabled: false });
        }

    }

    hideAlert() {
        this.setState({
            errors: null,
            hideSubmitButton: false
        });
    }

    showToast(status, msg, link) {
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"],
            showPopup: false
        });

        if (link.trim().length > 0) {
            setTimeout(function () {
                window.location.href = link;
            }, 3000);
        }
        setTimeout(() => {
            this.getAllDeployment()
        }, 1000
        );
    }

    closeToast() {
        this.setState({ toastStatus: false });
    }


    render() {
        $("#payment_id").removeClass();
        // $('#btn btn-blue mr-1').removeClass('btn btn-blue mr-1');
        if (rd < 1) {
            renewDisabled = false;
        }
        if (rd < 1) {
            payDisabled = false

        }
        if (this.props.cell !== null && this.props.header === 'Validity Period') {
            const current = new Date();
            var convertDateFormate = current.toGMTString();
            var currentDate = new Date(convertDateFormate);
            var nextBillingDate = new Date(this.props.nextBillingDate);
            let Rd = Math.floor((Math.abs(nextBillingDate - currentDate)) / (1000 * 60 * 60 * 24))
            rd = Rd + 1
            if (currentDate > nextBillingDate) {
                rd = 0
            }

        }

        const loginSchema = yup.object({
            email: yup.string().email("Email must be a valid email").required("Email is a required field"),
            password: yup.string().required("Password is a required field"),
        });


        return (

            <>
                <div id="toastMsg">
                    <Toast className={this.state.toastClass}
                        onClose={() => this.closeToast()}
                        show={this.state.toastStatus}
                        delay={3000} autohide>
                        <div className="d-flex align-items-center">
                            <i className="toast-icon">
                                {this.state.toastIcon ?
                                    <FontAwesomeIcon icon={this.state.toastIcon} /> :
                                    ""
                                }
                            </i>
                            <Toast.Body>
                                <strong className="toast-title">{this.state.toastHeading}</strong>
                                {this.state.toastBulk === "bulk" ?
                                    this.state.toastMsg.map(function (row) {
                                        return <p>{row.message}</p>;
                                    }) : <p>{this.state.toastMsg}</p>
                                }
                            </Toast.Body>
                            <button type="button" onClick={this.closeToast} className="close" data-dismiss="toast" aria-label="Close" />
                        </div>
                    </Toast>
                </div>
                {(this.props.reqData !== undefined || this.props.reqData !== null) ?
                    <>
                        {
                            (this.props.cell !== null && this.props.header === 'Deployment Name') ?

                                <dl className="mb-0">
                                    <dt className="text-dark"><strong>{this.props.cell}</strong></dt>
                                    <dd className="text-muted mb-0"><small>My Enterprise Private Cloud</small></dd>
                                </dl>

                                :

                                ''
                        }
                        {this.props.header === 'Actions' ?

                            <>
                                {(this.props.reqData['payment_status'] === 0 && (this.props.reqData['renew'] === true || this.props.reqData['renew'] === false)) ?
                                    <>
                                        <button
                                            className="btn btn-blue mr-1"
                                            id='payment_renew_id'
                                            disabled={payDisabled}
                                            onClick={this.pay}>
                                            <i className="small">
                                                <FontAwesomeIcon
                                                    icon={faSync}
                                                />
                                            </i>
                                            Pay
                                        </button>
                                        <Dropdown className="tbl_action-more">
                                            <Dropdown.Toggle id="dropdown-custom-components" variant=" " className="btn-link">More</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="1" onClick={this.handleClick.bind(this)}>Enable Auto Renewal</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </>
                                    :

                                    ''
                                }
                                {(this.props.reqData['payment_status'] === 1 && this.props.reqData['renew'] === false) ?
                                    <>
                                        <button
                                            className="btn btn-sm btn-blue mr-1"
                                            id='payment_renew_id'
                                            disabled={renewDisabled}
                                            onClick={this.renew}
                                        >
                                            <i className="small">
                                                <FontAwesomeIcon icon={faSync} />
                                            </i>
                                            Renew
                                        </button>
                                        {
                                            this.props.cell !== 'No' ?
                                                <Dropdown className="tbl_action-more">
                                                    <Dropdown.Toggle id="dropdown-custom-components" variant=" " className="btn-link">More</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="1" onClick={this.handleClick.bind(this)}>Disable Auto Renewal</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                :


                                                <Dropdown className="tbl_action-more">
                                                    <Dropdown.Toggle id="dropdown-custom-components" variant=" " className="btn-link">More</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="1" onClick={this.handleClick.bind(this)}>Enable Auto Renewal</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                        }
                                    </>
                                    : this.props.reqData['renew'] === true ?

                                        <>
                                            <button
                                                className="btn btn-sm btn-blue mr-1"
                                                id='payment_renew_id'
                                                disabled={true}
                                                onClick={this.renew}
                                            >
                                                <i className="small">
                                                    <FontAwesomeIcon icon={faSync} />
                                                </i>
                                                Renew
                                            </button>
                                            {
                                                this.props.cell !== 'No' ?
                                                    <Dropdown className="tbl_action-more">
                                                        <Dropdown.Toggle id="dropdown-custom-components" variant=" " className="btn-link">More</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item eventKey="1" onClick={this.handleClick.bind(this)}>Disable Auto Renewal</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                    :


                                                    <Dropdown className="tbl_action-more">
                                                        <Dropdown.Toggle id="dropdown-custom-components" variant=" " className="btn-link">More</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item eventKey="1" onClick={this.handleClick.bind(this)}>Enable Auto Renewal</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                            }
                                        </>
                                        :
                                        ''

                                }
                            </>

                            : (this.props.header === 'DeleteAction') ?
                                <>
                                    <button
                                        className="btn btn-sm btn-outline-danger show-danger-toast mr-1"
                                        id='confirm_delete'
                                        disabled={this.props.disabled}
                                        onClick={this.handleClick.bind(this)}>
                                        <i>
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                            />
                                        </i>
                                        Delete
                                    </button>
                                </>
                                :
                                ''

                        }


                        {(this.props.cell !== null && this.props.header === 'Validity Period') ?
                            <>
                                {this.props.reqData['payment_status'] !== 0 ?

                                    <>
                                        {rd < 10 ?
                                            <dl className="mb-2 text-warning">
                                                <dt className="text-warning"><strong>{rd} Days</strong></dt>
                                                <dd className="mb-2 text-warning"><small>{this.props.cell}</small></dd>
                                            </dl>

                                            :
                                            <dl className="mb-0">
                                                <dt className="text-dark"><strong>{rd} Days</strong></dt>
                                                <dd className="text-muted mb-0"><small>{this.props.cell}</small></dd>
                                            </dl>
                                        }

                                    </>

                                    :

                                    <dl className="mb-2 text-warning">
                                        <dt className="text-warning"><strong>0 Days</strong></dt>
                                        <dd className="mb-2 text-warning"><small>{'Validity Period End'}</small></dd>
                                    </dl>

                                }

                            </>

                            :
                            ''}
                    </>

                    :

                    ''}

                <Formik
                    validationSchema={loginSchema}
                    onSubmit={this.delete}
                    initialValues={{ email: this.state.email, password: this.state.password }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                    }) =>
                    (
                        <Form noValidate name="delete-deployment"
                            onSubmit={handleSubmit}>
                            {/* Modal Delete Confirmation */}
                            <Modal show={this.state.showPopup} centered size="sm">
                                <Modal.Body className="p-0">
                                    <div className="modal_confirmation-alert">
                                        <div className="confirmation-alert_img">
                                            <Image src="logo" />
                                        </div>
                                        <div className="confirmation-alert_text">
                                            <h4>Delete Deployment</h4>
                                            <p>Please provide your credentials to delete the Deployment : <strong>{this.props.reqData !== undefined ? this.props.reqData['deployment_name']: ''}</strong>
                                                {/* created on 20 Jan 2022 */}
                                            </p>
                                        </div>
                                        <div className="confirmation-alert_form">
                                            <Form.Group controlId="email">
                                                <InputGroup bsPrefix="icon-input-group">
                                                    <i>
                                                        <FontAwesomeIcon icon={faUser} />
                                                    </i>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Email Address"
                                                        className="form-group"
                                                        value={localStorage.getItem('emailId')}
                                                        disabled
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                            {this.state.errors ?
                                                <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                                                    {this.state.errors}
                                                    <button type="button" className="close close-sm"
                                                        onClick={() => this.hideAlert()}
                                                        data-dismiss="alert" aria-label="Close" />
                                                </div> : ""
                                            }
                                            <Form.Group controlId="password" >
                                                <InputGroup bsPrefix="icon-input-group">
                                                    <i>
                                                        <FontAwesomeIcon icon={faLock} />
                                                    </i>
                                                    <Form.Control
                                                        type={this.state.passwordType}
                                                        value={this.state.password}
                                                        placeholder="Password"
                                                        autoComplete='new-password'
                                                        className="form-group"
                                                        onChange={value => {
                                                            handleChange("password")(value);
                                                            this.setState({ password: value.target.value });
                                                        }}
                                                    />

                                                    <i className="security-eye">
                                                        <FontAwesomeIcon
                                                            onClick={this.changePasswordIcon}
                                                            icon={this.state.passwordIcon}
                                                            className="security-eye" />
                                                    </i>
                                                    <div className="alert alert-danger text-left">
                                                        <i className="text-danger mr-2">
                                                            <FontAwesomeIcon icon={faExclamationTriangle} />
                                                        </i>
                                                        Deleting this instance will not be restored, and will delete all instance resources.Kindly make sure all resource are not in use(eg.DB).
                                                    </div>
                                                    <div className="confirmation-alert_action">
                                                    <button
                                                        type="button"
                                                        onClick={this.closeDeletepopup}
                                                        class="btn btn-outline btn-grey btn-circle mr-2">
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        onClick={this.delete}
                                                        disabled={this.state.deleteButtonDisabled}
                                                        className="btn btn-primary btn-circle mr-2">
                                                        <FontAwesomeIcon icon={faCheck} />
                                                                    Confirm
                                                    </button>
                                                </div>
                                                </InputGroup>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

export default TableAction;