import React from 'react';
import {browserHistory} from 'react-router';
import CopyRights from '../../components/copyrights';
import { call_post_api, getAllDeployment,planDetails }  from "../../utils/event_handling";
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { iam_ip } from "../../utils/ip_details";
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router';
import Alert from 'react-bootstrap/Alert'
import {encryptForLocalUsage} from "../../utils/common_utils";

import mixpanel from 'mixpanel-browser';
// mixpanel.init('155c019784ab639716eb7828d60964e2', {debug: true});

let currentStatus = "";
class Login extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.getAllDeployment = getAllDeployment.bind(this);
        this.planDetails = planDetails.bind(this)
        let errorMsg = localStorage.getItem("error_message");
        let errors = null;
        if (errorMsg !== undefined && errorMsg !== null && errorMsg !== "undefined" && errorMsg !== 'null'){
            errors = errorMsg;
            localStorage.removeItem("error_message");
        }

        let rememailId,rempassword,remcheckbox;
        if(localStorage.getItem('rememberMe')=== "" || localStorage.getItem('rememberMe')=== null || localStorage.getItem('rememberMe')===false || localStorage.getItem('rememberMe')===undefined){
            rememailId = "";
            rempassword = "";
            remcheckbox = "";
        }else {
            rememailId = localStorage.getItem("rememailId");
            rempassword = localStorage.getItem("rempassword");
            remcheckbox = localStorage.getItem("rememberMe");
        }
        this.state = {
            planDetails : {'code':'','message':'','plan_details':''},
            tableData :{"headers": [], "data": []},
            passwordIcon: faEye,
            passwordType: "password",
            errors: errors,
            setPasswordLink: false,
            rememailId:rememailId,
            rempassword:rempassword,
            remcheckbox:remcheckbox,
            showAlert:true,
        };



        this.hideAlert = this.hideAlert.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.changePasswordIcon = this.changePasswordIcon.bind(this);
        this.login = this.login.bind(this);
    }
    componentDidMount() {

    }
    hideAlert() {
        this.setState({
          errors: null,
          hideSubmitButton: false
        });
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    changePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
          this.setState({passwordIcon: faEyeSlash});
          this.setState({passwordType: "text"})
        } else {
          this.setState({passwordIcon: faEye});
          this.setState({passwordType: "password"})
        }
    }

    login(event) {
        localStorage.clear();
        this.disableButton();
        let { email, password} = event;
        const {rememberMe } = this.state;
        const data = {email: email, password: password};
        localStorage.setItem('emailId', email);
        call_post_api(iam_ip, "login", data).then((response) => {
            if (response === undefined) {
                this.setState({errors:"Could not login"});
            } else if (response.code === 200) {
                this.planDetails()
                if(rememberMe===true){
                    localStorage.setItem("rememailId", email);
                    localStorage.setItem("rempassword", password);
                    localStorage.setItem("rememberMe", rememberMe);

                }else {

                    localStorage.setItem("rememailId", "");
                    localStorage.setItem("rempassword", "");
                    localStorage.setItem("rememberMe","");
                }

                const now = new Date();
                const responseData = response['response'];
                const item = {
                            value: responseData['auth_token'],
                            user_id : responseData['user_id'],
                            team_id: responseData['team_id'],
                            expiry: now.getTime() + 150*60000
                        };
                setTimeout(() => browserHistory.push('/'), 9000000);
                
                // These keys used only for new user when redirected from web page
                localStorage.removeItem("isPlanDetailAvailable");
                localStorage.removeItem("qualdoPlan");
                localStorage.removeItem("qualdoPlanCycle");
                localStorage.removeItem("qualdoEdition");

                localStorage.setItem('sessionToken', JSON.stringify(item));
                let teamID = responseData['team_id'];
                localStorage.setItem('team_id', teamID);
                localStorage.setItem('user_name', responseData['user_name']);
                localStorage.setItem('user_id', responseData['user_id']);
                localStorage.setItem("org_id", responseData['org_id']);
                localStorage.setItem('user_signup_type', responseData['user_signup_type']);
                localStorage.setItem("permissions", response["response"]["permissions"]);
                localStorage.setItem("v_ky", encryptForLocalUsage(responseData['video_key']));
                localStorage.setItem("v_tk", encryptForLocalUsage(responseData['video_token']));

                if(response['response']['deployments'].length === 0 ){
                        currentStatus = 'step1'
                        localStorage.setItem('validate',true)
                        localStorage.setItem('deployment_name',"")
                        localStorage.setItem('currentStatus', currentStatus);
                        localStorage.setItem('selectedPlan', 'annually');
                        localStorage.setItem('selectedplanprice', 1999);
                        browserHistory.push("/deployment");

                }
                
                if(response['response']['deployments'].length === 1 ){
                    localStorage.setItem('validate',false)
                    let deployStatus = response['response']['deployments'][0]['status']
                    let buildNumber = response['response']['deployments'][0]['build_number']
                    let deployment_id = response['response']['deployments'][0]['deployment_id']
                    let cycle = response['response']['deployments'][0]['cycle']
                    localStorage.setItem('cached_product', JSON.stringify(deployment_id));
                    if(cycle === 'monthly'){
                        localStorage.setItem('selectedPlan', cycle);
                        localStorage.setItem('selectedplanprice', 200);
                    }
                    else{
                        localStorage.setItem('selectedPlan', cycle);
                        localStorage.setItem('selectedplanprice', 1999);
                    }
                    localStorage.setItem('deployment_id', deployment_id);
                    localStorage.setItem('currentId', deployment_id);
                    localStorage.setItem('deployment_name', response["response"]["deployments"][0]['deployment_name']);
                    if(deployStatus ==="initiated" || deployStatus === "payment success" || deployStatus === "deployment initiated" || deployStatus ==='failed' || deployStatus === "active"){
                        if(deployStatus === "payment success" && (buildNumber === 0 || buildNumber === null || buildNumber === undefined)){
                            currentStatus = 'step3'
                            localStorage.setItem('currentStatus', currentStatus);
                            browserHistory.push("/deployment");
                        }
                        else if (deployStatus === "initiated"){
                            currentStatus = 'step2'

                            localStorage.setItem("DeploymentDetails", JSON.stringify(response["response"]["deployments"][0]));
                            localStorage.setItem('currentStatus', currentStatus);
                            localStorage.setItem('deployment_name', response["response"]["deployments"][0]['deployment_name']);
                            setTimeout(()=>
                            browserHistory.push("/deployment"),1000)

                        }
                        else if((buildNumber !== null || buildNumber !== undefined) &&  buildNumber !== 0 && (deployStatus === "deployment initiated" || deployStatus === "active" || deployStatus ==="failed")){
                            currentStatus = 'step4'
                            localStorage.setItem('currentStatus', currentStatus);
                            localStorage.setItem('wizardStage', currentStatus);
                            browserHistory.push("/deployment");
                        }
                        else{
                            currentStatus = 'step1'
                            localStorage.setItem('selectedPlan','annually')
                            localStorage.setItem('deployment_name',"")
                            localStorage.setItem('currentStatus', currentStatus);
                            browserHistory.push("/deployment");
                        }

                    }
                    else if(deployStatus==='deployed' || deployStatus==="deletion initiated" || deployStatus==='deleted' || deployStatus ==='deletion failed'){
                        localStorage.setItem("deploymentStatus", 'deployed');
                        browserHistory.push("/dashboard");
                    }

                }
                else if(response['response']['deployments'].length > 1)
                {
                    localStorage.setItem("deploymentStatus", 'deployed');
                    browserHistory.push("/dashboard");
                }

            } else {
                let errorMsg = response.message;
                if (errorMsg === "Bad request") {
                    errorMsg = "An unexpected error occurred. Please try logging in again.";
                }
                let setPasswordLink = this.state.setPasswordLink;
                if (errorMsg === "Please set password or you can login using google!.") {
                    localStorage.setItem("setPassword", true);
                    setPasswordLink = true;
                } else {
                    setPasswordLink = false;
                }
                this.setState({errors:errorMsg, hideSubmitButton: false, setPasswordLink: setPasswordLink});
            }
        });
        mixpanel.identify(email);
        mixpanel.track('Users logged in', { });
        mixpanel.people.set({
        $email: email
    });
    }

    handleChange = (event) => {

        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        if(input.type === 'checkbox'){
            this.setState({ [input.name]: value });
            this.setState({"remcheckbox": value });
        }
    };

    render() {
        const loginSchema = yup.object({
            email: yup.string().email("Email must be a valid email").required("Email is a required field"),
            password: yup.string().required("Password is a required field"),
        });
        return (
            <div className="prelogin-content">
                { this.props.isFirstTimeLogin && this.state.showAlert ?
                    <Alert 
                            display={false} 
                            variant="success" 
                            className="prelogin-alert-success" 
                            dismissible
                            onClose={() =>  this.setState({showAlert:false})}
                            >
                            <Alert.Heading><FontAwesomeIcon icon={faUserCheck} /> <span>Email Verified!</span></Alert.Heading>
                            <p>You have successfully verified your email!</p>
                    </Alert>
                    : <h4 className="login-welcome-text">Welcome, Please login
                    <br/>to your account.</h4>
                }
                <div className="prelogin-form-container">
                    { this.state.errors ?
                        <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                             {this.state.errors}
                              { this.state.setPasswordLink ?
                                <a href='/initiate-set-password'
                                   show={this.state.setPasswordLink}>
                                    Click the link to set password.
                                </a>
                                  :
                                  ''
                              }
                            <button type="button" className="close close-sm"
                                    onClick={() => this.hideAlert()}
                                    data-dismiss="alert" aria-label="Close"/>
                        </div> : ""
                    }

                    <Formik
                        validationSchema={loginSchema}
                        onSubmit={this.login}
                        initialValues={{ email: this.state.rememailId, password: this.state.rempassword, rememberMe: this.state.remcheckbox }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <Form noValidate name="login" className="login-form needs-validation" onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label> Email Address <span className="text-danger">*</span></Form.Label>
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email Address"
                                        className="form-group"
                                        value={values.email}
                                        autoFocus
                                        onChange={handleChange}
                                        isInvalid={errors.email && touched.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                       { touched.email && errors.email }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label> Password <span className="text-danger">*</span></Form.Label>
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </i>
                                    <Form.Control
                                        type={this.state.passwordType}
                                        value={values.password}
                                        placeholder="Password"
                                        className="form-group"
                                        onChange={handleChange}
                                        isInvalid={errors.password && touched.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { touched.password && errors.password }
                                    </Form.Control.Feedback>
                                    <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.changePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                    </i>
                                </InputGroup>
                            </Form.Group>
                            <div className="form-extra">
                                <Form.Group controlId="rememberMe">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        id={"login-checkbox"}
                                        onChange={this.handleChange}
                                        className="custom-control custom-checkbox"
                                        label="Remember Me"
                                        name="rememberMe"
                                        checked={this.state.remcheckbox}
                                    />
                                </Form.Group>
                                <a href="/forget-password" id="forget-password" className="forget-password">Forgot Password?</a>
                            </div>
                            <div className="form-action-btn">
                                <Link to="/signup"
                                      className="btn btn-circle btn-outline-secondary"
                                      onlyActiveOnIndex>Sign Up
                                </Link>
                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-secondary">Login</button>
                                {this.state.hideSubmitButton ? <button type="submit" className="btn loading-btn loading-btn--pending"></button> : ""}
                            </div>
                        </Form>
                        )}
                    </Formik>
                    <CopyRights classDetails="text-copyright" showCopyRight='true'/>
                </div>
            </div>
        );
    }

}
export default Login;