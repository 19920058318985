import React from 'react';
import Image from '../components/image';
import Banner from '../components/banner';
import ResetForm from './components/reset_password';
import ErrorHandler from '../components/error_500';
import { postUIlogs } from "../utils/event_handling";


class resetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.postUIlogs = postUIlogs.bind(this);
         this.state = {
             errorOccurred: false
         }
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    render() {
        let search = window.location.search;
        if(search.length > 0){
            let params = new URLSearchParams(search);
            let resetPwdToken = params.get('token');
            if(resetPwdToken !== undefined || resetPwdToken !== null){
                localStorage.setItem('resetPwdToken', resetPwdToken);
            }
        }
        let reset_password_token = localStorage.getItem("resetPwdToken");
        if (reset_password_token === undefined || reset_password_token === "undefined" || reset_password_token === null) {
            window.location = '/';
            return;
        }

        return (
            this.state.errorOccurred ?
                <ErrorHandler></ErrorHandler>
            :
            <div className="user-prelogin">
                <div className="row gutter-reset">
                    <div className="col">
                        <div className="prelogin-container">
                            <Image
                                className="login-logo"
                                src="logo"
                            />
                           <ResetForm />
                        </div>
                    </div>
                    <Banner/>
                </div>
            </div>
        );
    }
}

export default resetPassword;