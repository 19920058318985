  export const iam_ip = window.location.hostname+"/centralconsole"
  export const iam_google_signup = window.location.hostname+"/centralconsole"
  export const ui_service_ip = window.location.hostname+"/centralconsole"


// export const iam_ip = "subscriptions.gcp-qualdo.ai/centralconsole"
// export const iam_google_signup="subscriptions.gcp-qualdo.ai/centralconsole"
// export const ui_service_ip = "subscriptions.gcp-qualdo.ai/centralconsole"


// export const iam_ip = "localhost:8096"
// export const iam_google_signup="localhost:8091"
// export const ui_service_ip = "localhost:3007"
// export const console_ip = "localhost:3000"
