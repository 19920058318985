export const CHART_TITLE_SEPARATOR = " > ";
export const CHART_TITLE_METRIC_SEPARATOR = " - drift in ";
// export const PROTOCOL = window.location.protocol;
export const PROTOCOL = "https:";
export const HTTPS = "https://"
export const LIST_VIEW_OPTIONS = [{"label": "Dataset + Attribute", "value": "all"},
                                  {"label": "Dataset Level", "value": "datasets"},
                                 {"label": "Attribute Only", "value": "attributes"},
                                 {"label": "Followed Attribute Only", "value": "followedAttributes"}]
export const BUTTON_WAIT_TIME = 2000;
export const ENVIRONMENT = 0;
export const DATA_SOURCE = 1;
export const DATA_SET = 2;
export const ATTRIBUTE = 3;

export const PRIVACY_POLICY_LINK = "https://www.termsfeed.com/live/239e61f8-71af-48ac-8bb8-67cd9ddd24c9"
export const COOKIE_POLICY_LINK = "https://www.termsfeed.com/live/3b7b5f77-42fb-4494-98ad-7b1155fc05b7"
export const TERMS_CONDITIONS_LINK = "https://www.termsfeed.com/live/51fabc0d-1f6b-4290-a0c0-d7ce653cb580"

export const NO_DATA_SET_FOUND_TEXT = "No Datasets found yet";
//status code for default metrics run failure
export const DEFAULT_METRICS_RUN_FAILURE = -2;

export const RUN_IN_QUEUE = 0;

export const RUN_TRIGGERED = 200;
export const RUN_STARTED = 201;

export const RUN_PROGRESS_STATES = [RUN_IN_QUEUE, RUN_TRIGGERED, RUN_STARTED];

export const RUN_SUCCESS = 1;

// Currently the display message is same as text. But if required the tooltip display can be changed later
export const NO_DATA_SET_FOUND_TOOLTIP = "No Datasets found yet";

// The display message that we show on top of Profile/Monitor page
export const NO_DATASET_NO_DATA_PROFILE = "No data available to profile";
export const NO_DATASET_NO_ERROR = "No data available for processing. Kindly check the configure datasource.";
export const DATA_PROFILING_FAILED = "No data available to profile";
export const ROW_WARN_CLASS = "has-table-warning";
export const ROW_ERROR_CLASS = "has-table-error";


export const configure = {
    ML_MODEL_TYPE: "ML",
    DNN_MODEL_TYPE: "DNN",
    CNN_MODEL_TYPE: "CNN"
}

export const DQ_MONITOR_SECTIONS = {
    COMPLETENESS: "completeness",
    UNIQUENESS: "uniqueness",
    TIMELINESS: "timeliness",
    ACCURACY: "accuracy",
    CONFORMITY: "conformity",
    CONSISTENCY: "consistency",
    DRIFT: "drift",
}

export const defaultMetrics = {
    defaultQuaityGatesCount: 22
}

export const ALERT_NAME_DESC = {
    "Feature Drift" : " Feature Drift of Serving Data vs Training Data",
    "Model Drift": " Feature Drift of Serving Data",
}

export const metricType = {
    DEFAULT: "default",
    COMBINED: "combined"
}

export const triggers = {
    INSTANT_NOTIFICATION: "instant",
    RECURRING_NOTIFICATION: "recurring",
    EMPTY_NOTIFICATION_KEY: "emptyNotificationKey",
    UPDATED_NOTIFICATION_KEY: "updatedNotificationKey",
    EMPTY_ALERT: "emptyAlert",
    EMPTY_PERIOD: "emptyPeriod",
    EMPTY_ASSOCIATED: "emptyAssociated",
    EMPTY_ALERT_SCHEDULE: "emptyAlertScheule",
    EMPTY_DATASET_WITH_KEY: "emptyDatasetWithKey",
    EMPTY_ATTRIBUTE_WITH_KEY: "emptyAttributeWithKey",
    EMPTY_DATA_SOURCE_WITH_KEY: "emptyDataSourceWithKey",
    EMPTY_MODEL_WITH_KEY: "emptyModelWithKey",
    EMPTY_ALERT_MECHANISM_WITH_KEY: "emptyMechanismWithKey",
    UPDATED_ALERT: "updatedAlertTypeWithkey",
    UPDATED_PERIOD: "updatedAlertPeriodWithkey",
    UPDATED_ASSOCIATED: "updatedAssociation",
    UPDATED_ALERT_SCHEDULE: "updatedAlertSchedule",
    UPDATED_DATASET_WITH_KEY: "updatedAlertDatasetWithKey",
    UPDATED_ATTRIBUTE_WITH_KEY: "updatedAlertAttributeWithKey",
    UPDATED_DATASOURCE_WITH_KEY: "updatedAlertDataSourceWithKey",
    UPDATED_MODEL_WITH_KEY: "updatedAlertModelWithKey",
    UPDATED_ALERT_MECHANISM_WITH_KEY: "updatedAlertMechanismWithKey",
    HOUR_SCHEDULE: "1hour",
    DAY_SCHEDULE: "24hours",
    WEEK_SCHEDULE: "1week",
    MONTH_SCHEDULE: "1month",
}

export const dataRunStatuses = {
       "inProgress1": 200,
       "inProgress2": 201,
       "failed": -2,
       "success": 1,
       "inQueue": 0

}

export const IN_PROGRESS_STATUSES = [dataRunStatuses.inProgress1, dataRunStatuses.inProgress2];

export const modelPerformance = {
    CONFUSION_MATRIX: "Confusion matrix",
    F1_SCORE: "f1 score",
    PRECISION: "precision",
    RECALL: "recall",
    MAE: "mae",
    MSE: "mse",
    RMSE: "rmse",
    R2: "r2",
    ROC_CURVE: "ROC Curve",
    SENSITIVITY: "Sensitivity",
    SPECIFICITY: "Specificity",
    ACCURACY: "Accuracy",
    CPU_USAGE: "cpu_usage",
    MEMORY_USAGE: "memory_usage",
    MODEL_LATENCY: "model_latency",
    OVERHEAD_LATENCY: "overhead_latency",
    MEAN_SHAP_VALUE: "mean_shap_value"
}

export const chartTypes = {
    CONFUSION_MATRIX: "confusionMatrix",
    HORIZONTAL_BAR_CHART: "horizontalBarChart",
    GROUPED_HORIZONTAL_BAR_CHART: "groupedHorizontalBarChart",
    HORIZONTAL_COMPARE_CHART: "horizontalCompareChart",
    MULTILINE_FEATURE_CHART: "multiLineFeatureChart"
}

export const SEE_FULL_VIEW_ICONS = [chartTypes.MULTILINE_FEATURE_CHART, chartTypes.HORIZONTAL_BAR_CHART, chartTypes.GROUPED_HORIZONTAL_BAR_CHART ]
export const showMoreVariants = ["mlModelPerformanceListView", "mlModelPerformanceChartView",
    "model_perf_comp_view"];


export const cardTitlesMap = {
    "Normalization Consistency": "Normalisation Errors (in %)",
    "Categorical Ordinal": "Categorical Ordinal Errors (in %)",
    "Categorical Encode": "Categorical Encoding Errors (in %)",
    "Data Type Consistency": "Data Type Errors (in %)",
    "Fill Rate": "Average Fill Rate (in %)",
    "Row Count": "Row Count",
    "Unique Values": "Unique Values",
    "Recency": "Average Recency (in days)",
    "URL Conformity": "URL Conformity Errors (in %)",
    "Credit Card Conformity": "Credit Card Conformity Errors (in %)",
    "Gender Conformity": "Gender Conformity Errors (in %)",
    "IP Conformity": "IP Conformity Errors (in %)",
    "Http Conformity": "HTTP Conformity Errors (in %)",
    "Date Conformity": "Date Conformity Errors (in %)",
    "Email Conformity": "Email Conformity Errors (in %)",
    "SSN Conformity": "SSN Conformity Errors (in %)",
    "Boolean Conformity": "Boolean Conformity Errors (in %)",
    "Data Outlier": "Data Outlier Errors (in %)",
}

export const chartDataCombinations = [{"combinations": [modelPerformance.PRECISION, modelPerformance.RECALL]}]

export const metricCategory = {
    DQM: "dqm",
    MLM: "mlm"
}


export const metricMapping = {
    1: "Accuracy Drift",
    2: "Categorical Encoding Errors",
    3: "Categorical Ordinal Errors",
    4: "Credit Card Conformity Errors",
    5: "Data Drift",
    6: "Data Outliers",
    7: "Data Type Errors",
    8: "Date Conformity Errors",
    9: "Email Conformity Errors",
    10: "Feature Drift",
    11: "Fill Rate",
    12: "Gender Conformity Errors",
    13: "Http Conformity Errors",
    14: "IP Conformity Errors",
    15: "ML Data Consistency",
    16: "Model Drift",
    17: "Normalization Consistency",
    18: "Prediction Drift",
    19: "Recency",
    20: "Response Drift",
    21: "SSN Conformity Errors",
    22: "URL Conformity Errors",
    25 : "Row Count",
    26: "Unique Values",
    27: "Boolean Conformity"
}
export const METRIC_SCALE = {
  "Recency": "days.",
  "Row Count": "rows.",
};

export const RELATIONSHIP_METRIC = {
    11: "Data Completeness",
    19: "Data Timeliness",
    6: "Data Accuracy Errors",
    "Data Conformity Errors": [4, 8, 9, 12, 13, 14, 21, 22, 27],
    "Data Consistency Errors": [2, 3, 17, 7]
}

export const RELATIONSHIP_METRIC_ORDER = [11, 19, "Data Conformity Errors", 6, "Data Consistency Errors"];


export const PROFILE_SEPARATOR = ".";

// Time is in minutes. If 2 results of same dataset are within this interval,
// we will consider the results as same refresh
export const BUFFER_TIME_TO_IDENTIFY_REFRESH = 10;


export const RELATIONSHIP_STATIC_CARD = {
    11: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
    19: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
    5: [7.0, 9.5, 18.4, 25.2, 23.3, 18.3, 13.9, 9.6],
    6: [3.7, 3.3, 3.9, 5.1, 3.5, 3.8, 4.0, 5.0, 6.1, 3.7, 3.3, 6.4,
        6.9, 6.0, 6.8, 4.4, 4.0, 3.8, 5.0, 4.9, 9.2, 9.6, 9.5, 6.3,
        9.5, 10.8, 14.0, 11.5, 10.0, 10.2, 10.3, 9.4, 8.9, 10.6, 10.5, 11.1,
        10.4, 10.7, 11.3, 10.2, 9.6, 10.2, 11.1, 10.8, 13.0, 12.5, 12.5, 11.3,
        10.1
    ],
    "Data Conformity Errors": [1, 2, 4, 8, 16, 32, 64, 128, 256, 512],
    "Data Consistency Errors": [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
}

export const STATIC_CARDS = [
    [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
    [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
    [7.0, 9.5, 18.4, 25.2, 23.3, 18.3, 13.9, 9.6],
    [3.7, 3.3, 3.9, 5.1, 3.5, 3.8, 4.0, 5.0, 6.1, 3.7, 3.3, 6.4, 6.9, 6.0, 6.8, 4.4, 4.0, 3.8, 5.0, 4.9, 9.2, 9.6, 9.5, 6.3, 9.5, 10.8, 14.0, 11.5, 10.0, 10.2, 10.3, 9.4, 8.9, 10.6, 10.5, 11.1, 10.4, 10.7, 11.3, 10.2, 9.6, 10.2, 11.1, 10.8, 13.0, 12.5, 12.5, 11.3, 10.1],
    [1, 2, 4, 8, 16, 32, 64, 128, 256, 512],
    [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
    [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
    [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
    [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
]


export const THUMBNAIL_COLORS = {
    "VIOLET_VARS": ["#dac8f3", "#9690ea", "#796bef", "#545fef",
        "#689dee"],
    "GREEN_VARS": ["#bdecd0", "#8cf3b7", "#7cf1ac", "#2df381",
        "#06dc55"],
    "ORANGE_VARS": ["#f3e3e0", "#f6cc9a", "#eeae69", "#efbc50",
        "#e0bd40"],
    "RED_VARS": ["#FF6347", "#D3D3D3", "#FF6347", "#D3D3D3"]
};

export const ML_METRIC = {
    24: "Model Performance",
    23: "Model Performance",
    10: "Serving Features Drift Over Training",
    16: "Serving Features Drift",
    15: "ML Data Consistency Error",
}

export const ML_METRIC_2 = {
    24: "Model Performance",
    23: "Model Performance",
    10: "Serving Features Drift Over Training",
    16: "Serving Features Drift",
    15: "ML Data Consistency Error",
    18: "Prediction Drift",
    20: "Response Drift"
}



export const ML_METRIC_ORDER = [24, 23, 15, 16, 10];

export const ML_METRIC_COLOR = {
    1: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    10: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    15: {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    16: {"cardBackground": "dashboard-stat-danger", "textColor": "text-danger"},
    18: {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"},
    20: {"cardBackground": "dashboard-stat-royalblue", "textColor": "text-royalblue"},
    24: {"cardBackground": "dashboard-stat-gray", "textColor": "text-gray"},
    23: {"cardBackground": "dashboard-stat-gray", "textColor": "text-gray"}
}

export const COMPLETENESS_METRIC = {
    11: "Fill Rate",
    25: "Row Count"

};
export const DUPLICATE_METRIC = 26
export const UNIQUENESS_METRIC = {
    26: "Unique Values"
}

export const COMPLETENESS_CARDS = {
    "Attribute Fill Rate < 25%": 25, "Attribute Fill Rate 25% to 75%": 75, "Attribute Fill Rate  > 75%": 100, "Total number of Rows": 100,
}

export const COMPLETENESS_METRIC_COLOR = {
    "Attribute Fill Rate < 25%": {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    "Attribute Fill Rate 25% to 75%": {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    "Attribute Fill Rate  > 75%": {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    "Total number of Rows": {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"}

}

export const TIMELINESS_CARDS = {
    "Datasets with Recency < One Day": 1, "Datasets with Recency < One Week": 7, "Datasets with Recency < One Month": 30, "Datasets with Recency > One Month": 9999999
}

export const TIMELINESS_METRIC_COLOR = {
    "Datasets with Recency < One Day": {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"},
    "Datasets with Recency < One Week": {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    "Datasets with Recency < One Month": {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    "Datasets with Recency > One Month": {"cardBackground": "dashboard-stat-info", "textColor": "text-info"}
}

export const ACCURACY_METRIC = {
    6: "Data Outliers"
};

export const NON_EDITABLE_METRIC = ["Row Count", "Unique Values"];

export const ACCURACY_METRIC_COLOR = {
    5: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    6: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"}
}

export const TIMELINESS_METRIC = {
    19: "Recency"
}

export const CONFORMITY_METRIC = {
    4: "Credit Card Conformity Errors",
    8: "Date Conformity Errors",
    9: "Email Conformity Errors",
    12: "Gender Conformity Errors",
    13: "Http Conformity Errors",
    14: "IP Conformity Errors",
    21: "SSN Conformity Errors",
    22: "URL Conformity Errors",
    27: "Boolean Conformity Errors"
}

export const METRIC_CARD_COLORS = {
    0: { "cardBackground": "dashboard-stat-purple", "textColor": "text-purple", "chartLine": "areachartPurple"  },
    1: { "cardBackground": "dashboard-stat-success", "textColor": "text-success", "chartLine": "areachartsuccess" },
    2: { "cardBackground": "dashboard-stat-warning", "textColor": "text-warning", "chartLine": "areachartwarning" },
    3: { "cardBackground": "dashboard-stat-danger", "textColor": "text-danger", "chartLine": "areachartdanger" },
    4: { "cardBackground": "dashboard-stat-orange", "textColor": "text-orange", "chartLine": "areachartOrange" }
}

export const CONFORMITY_METRIC_COLOR = {
    4: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    8: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    9: {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    12: {"cardBackground": "dashboard-stat-danger", "textColor": "text-danger"},
    13: {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"},
    14: {"cardBackground": "dashboard-stat-royalblue", "textColor": "text-royalblue"},
    21: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    22: {"cardBackground": "dashboard-stat-gray", "textColor": "text-gray"},
    27: {"cardBackground": "dashboard-stat-orange", "textColor": "text-orange"}
}

export const RELATIONSHIP_METRIC_COLOR = {
    11: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    19: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    5: {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    6: {"cardBackground": "dashboard-stat-danger", "textColor": "text-danger"},
    "Data Conformity Errors": {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"},
    "Data Consistency Errors": {"cardBackground": "dashboard-stat-royalblue", "textColor": "text-royalblue"}
}

export const CONSISTENCY_METRIC = {
    2: "Categorical Consistency Errors",
    17: "Normalization Consistency Errors",
    7: "Data Type Consistency Errors",
}

export const CONSISTENCY_METRIC_COLOR = {
    2: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    3: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    17: {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    7: {"cardBackground": "dashboard-stat-danger", "textColor": "text-danger"}
}

const compare_a_line_0 = "rgb(250,70,22)";
const compare_a_line_1 = "rgba(250,70,22,0.5)";
const compare_b_line_0 = "rgb(62,155,254)";
const compare_b_line_1 = "rgb(62,155,254, 0.5)";
const compare_c_line_0 = "rgb(78,210,204)";
const compare_c_line_1 = "rgb(78,210,204, 0.5)";

export const CLASS_COLORS = {
    "text-info": {"line": "rgb(76, 163, 255)", "area": "rgba(76, 163, 255,0.2)", "line1": "rgb(121,171,241)",},
    "text-success": {"line": "rgb(78,210,204)", "area": "rgba(78,210,204,0.2)", "line1": "rgb(130,246,185)"},
    "text-warning": {"line": "rgb(255,168,0)", "area": "rgba(255,168,0,0.2)", "line1": "rgb(243,203,111)",},
    "text-danger": {"line": "rgb(246,78,96)", "area": "rgba(246,78,96,0.2)", "line1": "rgb(236,130,142)",},
    "text-purple": {"line": "rgb(145,94,249)", "area": "rgba(145,94,249,0.2)", "line1": "rgb(88,84,137)"},
    "text-royalblue": {"line": "rgb(73,95,184)", "area": "rgba(73,95,184,0.2)", "line1": "rgb(145,164,243)"},
    "text-gray": {"line": "rgb(125,125,125)", "area": "rgba(125,125,125,0.2)", "line1": "rgb(135,85,85)"},
    "text-orange": {"line": "rgb(255,168,0)", "area": "rgba(255,168,0,0.2)", "line1": "rgb(243,203,111)",},
    "compare_a": {"line": compare_a_line_0, "area": "rgba(250,70,22,0.2)", compare_a_line_1,},
    "compare_b": {"line": compare_b_line_0, "area": "rgba(62,155,254,0.2)", "line1": compare_b_line_1,},
    "compare_c": {"line": compare_c_line_0, "area": "rgba(78,210,204,0.2)", "line1": compare_c_line_1,},
    "multi-line-colors": {
        "line1": compare_a_line_0, "line1_a": "rgba(250,70,22,0.5)",
        "line2": compare_b_line_0, "line2_a": compare_b_line_1,
        "line3": compare_c_line_0, "line3_a": compare_c_line_1
    },
}

export const MODEL_PERFORMANCE_THRESHOLDS = {
    RECALL: 0.6,
    PRECISION: 0.6,
    F1_SCORE: 0.6,
    ACCURACY: 0.6,
    RMSE: 50,
}

export const RECOMMENDATION_MODEL_DEFAULTS = {
    K_VALUE: null
}

export const K_RESULTS = ["Mean Average Recall @K", "Mean Average Precision @K"]
export const COEFFICIENT_VALUES = ["Spearman correlation coefficient", "Pearson's correlation coefficient"]
export const OTHER_ACCURACY_METRIC = ["mse", "mae", "rmse","r2"]

export const MODEL_ACCURACY = "Model Accuracy"
export const MODEL_EVALUATION = "Model Evaluation"
export const MODEL_MONITORING = "Model Monitoring"
export const FEATURE_IMPORTANCE = "Feature Importance"
export const MODEL_EXPLAINABILITY = "Model Explainability"


export const ML_MODEL_WITH_MODEL_EVALUATION_METRICS = ["classification"]

export const MODEL_PERFORMANCE_GROUPS = {
    "Model Accuracy": {
        "data": [],
        "type": "all_accuracy",
        "header": "Model Accuracy Metrics",
        "sub_header": "Following are the charts which show the model accuracy metrics (eg: f1 score, precision, recall) of the configured model",
    },
    "Model Evaluation": {
        "data": [],
        "type": "all_evaluation",
        "header": "Model Evaluation Metrics",
        "sub_header": `Following are the charts which show the model evaluation metrics (eg: TP/TN/FP/FN, AUC) of the configured model`
    },
    "Model Monitoring": {
        "data": [],
        "type": "model_runtime",
        "header": "Model Runtime-Profile Metrics",
        "sub_header": `Following are the charts which show the model runtime-profile metrics (eg: cpu usage, memory usage, latency, invocation) of the configured model`
    },
    "Model Explainability": {
        "data": [],
        "type": "model_explainability",
        "header": "Model Explainability",
        "sub_header": `Following are the charts which show the Model Explainability of the serving data and training data`
    }
};

export const MODEL_PERFORMANCE_RESULT_GROUP_MAP = {
    "precision": MODEL_ACCURACY,
    "Weighted precision": MODEL_ACCURACY,
    "f1 score": MODEL_ACCURACY,
    "Weighted F(1) Score": MODEL_ACCURACY,
    "Accuracy": MODEL_ACCURACY,
    "recall": MODEL_ACCURACY,
    "Weighted recall": MODEL_ACCURACY,
    "Sensitivity": MODEL_ACCURACY,
    "Specificity": MODEL_ACCURACY,
    "precision vs recall": MODEL_ACCURACY,
    "Sensitivity vs Specificity": MODEL_ACCURACY,

    "Confusion matrix": MODEL_EVALUATION,
    "areaUnderPR": MODEL_EVALUATION,
    "areaUnderPR Error": MODEL_EVALUATION,
    "areaUnderROC": MODEL_EVALUATION,
    "areaUnderROC Error": MODEL_EVALUATION,
    "True Positive": MODEL_EVALUATION,
    "False Positive": MODEL_EVALUATION,
    "True Negative": MODEL_EVALUATION,
    "False Negative": MODEL_EVALUATION,
    "ROC Curve": MODEL_EVALUATION,

    "cpu_usage": MODEL_MONITORING,
    "memory_usage": MODEL_MONITORING,
    "model_latency": MODEL_MONITORING,
    "overhead_latency": MODEL_MONITORING,

    "mean_shap_value": MODEL_EXPLAINABILITY,


};

export const ATTRIBUTE_LEVEL_MODEL_ERROR_DESCRIPTIONS = {
    "Model Drift": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "features of the serving data in comparison with themselves over a period of time.",
        "title": "Feature drifts - By Serving data Attributes"
    },
    "Feature Drift": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "features of the serving data in comparison with the same features from training data.",
        "title": "Feature drifts - Serving data vs Training data"
    },
    "Feature Drift_temporal": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "different features used in the serving data over a period of time.",
        "title": "Drifts in the features of the Serving data over a period of time"
    },
    "Prediction Drift": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "prediction of the configured model.",
        "title": "Prediction Metrics - Drifts"
    },
    "Response Drift": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "business response of the configured model.",
        "title": "Business Response Metrics - Drifts"
    },
    "Accuracy Drift": {
        "desc": "Following are the model performance metrics with threshold errors (f1 score, Root Mean Squared Error, etc.).",
        "title": "Key 'Model Performance Metrics' Crossing Thresholds"
    },
    "ML Data Consistency": {
        "desc": "Following are the charts which showcase the errors noticed in " +
            "the consistency of serving data in comparison with the training data at individual features level.",
        "title": "Consistency Errors - Serving data versus Training data"
    },
    "default": {
        "desc": "Following are the charts which showcase the errors in " +
            "Models for ",
        "title": "Errors in the configured Model"
    },
}


export const DATA_SET_LEVEL_MODEL_ERROR_DESCRIPTIONS = {
    "Model Drift": {
        "desc": "Following are the charts which showcase the feature drifts in the " +
            "serving data of the Models",
        "title": "Feature Drifts - Entire Serving dataset"
    },
    "ML Data Consistency": {
        "desc": "Following chart highlights data consistency issues between serving and training data.",
        "title": "Consistency Errors - Serving data vs Training data"
    },
    "default": {
        "desc": "Following are the charts which showcase the errors in " +
            "Models for ",
        "title": "Errors in the configured Model"
    },
    "Custom Metrics": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "custom metrics ",
        "title": "Custom ML Metrics - Drifts"
    },
}

export const SECTION_TYPE_MAPPING = {"type8": "Custom ML Metrics - Drifts" }

export const MODEL_DRIFT = "Model Drift";
// Alias name to be used for Model Drift while showing the chart
export const SERVING_DATA_DRIFT = "Serving Data Drift";
// Alias name for Attribute level Model drift charts
export const FEATURE_DRIFT_TEMPORAL = "Feature Drift in Serving Data";

// Alias name for feature drift in comparison with training attributes

export const FEATURE_DRIFT_ALIAS_SINGLE_ARROW_GREEN_TEXT = "Number of rows in serving data drifted from training data";
export const FEATURE_DRIFT_ALIAS_SINGLE_ARROW_RED_TEXT = "Number of rows in serving data drifting from training data exceeds threshold";

export const FEATURE_DRIFT_ALIAS_GREEN_TEXT = "% drift between serving and training attribute";
export const FEATURE_DRIFT_ALIAS_RED_TEXT = "% drift between serving and training attribute exceeds threshold";

export const FEATURE_DRIFT_ALIASES = [FEATURE_DRIFT_ALIAS_GREEN_TEXT, FEATURE_DRIFT_ALIAS_RED_TEXT];

export const FEATURE_DRIFT = "Feature Drift";

export const ML_BOX_PLOT_LIST = [MODEL_DRIFT, SERVING_DATA_DRIFT, FEATURE_DRIFT_TEMPORAL, FEATURE_DRIFT,
    "Response Drift", "Prediction Drift", "Accuracy Drift"]

export const DEFAULT_METRICS_MAP = {
    "dq": ["Fill Rate", "Data Outlier", "Data Drift",
        "Data Type Consistency", "Normalization Consistency", "Normalisation Consistency",
        "Categorical Encode", "Recency",
        "Gender Conformity", "URL Conformity", "IP Conformity",
        "SSN Conformity", "Credit Card Conformity", "Http Conformity",
        "Email Conformity", "Date Conformity", "Row Count", "Unique Values", "Boolean Conformity"],
    "ml": ["Model Drift", "Prediction Drift",
        "Response Drift", FEATURE_DRIFT, "Accuracy Drift", "ML Data Consistency",
        SERVING_DATA_DRIFT, FEATURE_DRIFT_TEMPORAL, ...FEATURE_DRIFT_ALIASES]
}

export function getMetaInformationAboutChart(isDataSet, key) {
    let description;
    let isDefault = DEFAULT_METRICS_MAP.dq.includes(key);
    let metricType = isDefault ? "default" : "custom";
    let header = ''
    switch(key) {
       case "Data Drift":
           if (isDataSet) {
                 description = "Following are the charts which showcase the net aggregated data drift for the selected dataset(s)."
                 header = "Data Drift Errors - By Datasets";
            }
           else {
                  description = "Following are the charts which showcase data drift errors for a selected attribute."
                  header = "Data Drift Errors - By Attributes";
           }
           break
       case "Data Outlier":
           if (isDataSet) {
                 description = "Following are the charts which showcase data accuracy drift at a dataset level"
                 header = "Data Accuracy Errors - Dataset";
            }
           else {
                  description = "Following are the charts which showcase data accuracy drifts for a selected attribute."
                   header = "Data Accuracy Errors - By Attributes";
           }
           break
       case "Fill Rate":
           if (isDataSet) {
                 description = "Following are the charts which showcase fill rate drift at a dataset level"
                 header = "Data Completeness Drift - Dataset";
            }
           else {
                  description = "Following are the charts which showcase fill rate drifts for a selected attribute."
                   header = "Data Completeness Drift - By Attributes";
           }
           break
       case "Row Count":
            if (isDataSet) {
                 description = "Following are the charts which showcase row count drift at a dataset level"
                 header = "Data Completeness - Row Count Drift - Dataset";
            }
           else {
                  description = "Following are the charts which showcase row count drifts for a selected attribute."
                   header = "Row Count Drift - By Attributes";
           }
           break
       case "Unique Values":
            if (isDataSet) {
                 description = "Following are the charts which showcase Unique Values drift at a dataset level"
                 header = "Data Uniqueness Drift - Dataset";
            }
           else {
                  description = "Following are the charts which showcase Unique Values drifts for a selected attribute."
                   header = "Data Uniqueness Drift - By Attributes";
           }
           break
       case "Recency":
           if (isDataSet) {
                 description = "Following are the charts which showcase timeliness drift at a dataset level"
                 header = "Data Timeliness Drift - Dataset";
            }
           else {
                  description = "Following are the charts which showcase timeliness drifts for a selected attribute."
                   header = "Data Timeliness Drift - By Attributes";
           }
           break
       default:
           if (isDataSet) {
                 description = `Following are the charts which showcase the drifts in custom metric ${key} at a dataset level`
                 header = `Drifts in the custom metric ${key} - Dataset`;
            }
           else {
                  description = `Following are the charts which showcase errors in custom metric ${key} for a selected attribute.`
                  header = `Drifts in the custom metric ${key} - By Attributes`;
           }
    }
    if ( key.includes("Consistency") || key.includes('Categorical' ) || key.includes('Conformity')) {
       if (isDataSet) {
                 description = "Following are the charts which showcase " + key +" errors at a dataset level"
                 header = key + " Errors - Dataset";
            }
       else {
                  description = "Following are the charts which showcase "+ key +" errors for a selected attribute."
                  header = key +" Errors - By Attributes";
           }
    }
    return {
        "header": header,
        "description": description,
        "metricType": metricType
    }
}


export const MODEL_PERFORMANCE_DEFAULT_CHART_MAPPING = {
    "True Positive": "barChart",
    "False Positive": "barChart",
    "True Negative": "barChart",
    "False Negative": "barChart",
    "areaUnderPR": "areaChart",
    "areaUnderROC": "areaChart",
    "areaUnderROC Error": "areaChart",
    "areaUnderPR Error": "areaChart",
}

export const DEFAULT_ML_METRICS = ["Model Drift", "Feature Drift", "Prediction Drift",
    "Response Drift", "Accuracy Drift", "ML Data Consistency"];


export const CUSTOM_ML_METRIC ="Custom Metrics";
export const DATASET_LEVEL_ALLOWED_ML_METRICS = ["Model Drift", "ML Data Consistency"];
export const ATTRIBUTE_LEVEL_ALLOWED_ML_METRICS = ["Model Drift", "Prediction Drift",
    "Response Drift", "Feature Drift", "Accuracy Drift"];

export const MODEL_ALERT_ALLOWED_METRICS = {
    "feature": ["Feature Drift", "ML Data Consistency", "Model Drift"],
    "accuracy": ["Accuracy Drift"],
    "prediction": ["Prediction Drift"],
    "response": ["Response Drift"],
    }

export const REQUIRES_FILTER = ["Prediction Drift", "Response Drift"];

/*
* Contains keys required to access model related sections.
* For Feature Drift we didn't have columnKey currently.
* There can be 2 type of columnKey for Feature Drift
* Training feature x Serving feature
 */
export const ML_FEATURE_KEYS = {
    "Prediction Drift": {
        "key": "prediction",
        "columnKey": "target_column_names"
    },
    "Response Drift": {
        "key": "response",
        "columnKey": "target_column_names"
    },
    "Feature Drift": {
        "key": "feature",
    },
    "Accuracy Drift": {
        "key": "accuracy",
        "columnKey": "target_column_names"
    }
}

export const MODEL_PERFORMANCE_COMBINATION_CHART = [
    {"y": modelPerformance.PRECISION, "y1": modelPerformance.RECALL},
    {"y": modelPerformance.SENSITIVITY, "y1": modelPerformance.SPECIFICITY}];

/*
* Contains priority score for each ml model metric for sorting purpose
*
* */
export const DEFAULT_ML_METRIC_PRIORITY = {
    "Model Drift": {"Dataset": 3, "Attribute": 4},
    "Feature Drift": {"Dataset": 100, "Attribute": 5},
    "Prediction Drift": {"Dataset": 10, "Attribute": 6},
    "Response Drift": {"Dataset": 10, "Attribute": 7},
    "Accuracy Drift": {"Dataset": 10, "Attribute": 1},
    "ML Data Consistency": {"Dataset": 2, "Attribute": 100},
}


/*
* Contains priority score for each metric for sorting purpose
*
* */
export const DEFAULT_METRIC_PRIORITY = {
    "Fill Rate": 10,
    "Row Count": 10,
    "Unique Values": 10,
    "Recency": 10,
    "Data Drift": 10,
    "Data Outlier": 10,
    "Data Type Consistency": 6,
    "Gender Conformity": 10,
    "Model Drift": 10,
    "Feature Drift": 10,
    "Prediction Drift": 10,
    "Response Drift": 10,
    "Email Conformity": 10,
    "Accuracy Drift": 10,
    "ML Data Consistency": 10,
    "SSN Conformity": 10,
    "Credit Card Conformity": 10,
    "Http Conformity": 10,
    "URL Conformity": 10,
    "IP Conformity": 10,
    "Normalization Consistency": 9,
    "Categorical Encode": 7,
    "Categorical Ordinal": 8,
    "Date Conformity": 10,
    "Boolean Conformity": 10
}


/*
* Contains priority score for each error for sorting purpose
*
* */
export const DEFAULT_ERROR_PRIORITY = {
    "NO_DRIFT": 10,
    "not_computed": 10,
    "Threshold Alert": 1
}

export const MODEL_PERFORMANCE_PREVIEW_PAGE = [modelPerformance.F1_SCORE,
    modelPerformance.PRECISION, modelPerformance.RECALL,
    modelPerformance.ACCURACY,
    modelPerformance.SENSITIVITY, modelPerformance.SPECIFICITY];

export const MODEL_MONITOR_PREVIEW_PAGE = [modelPerformance.CPU_USAGE,
    modelPerformance.MEMORY_USAGE, modelPerformance.MODEL_LATENCY,
    modelPerformance.OVERHEAD_LATENCY]

export const MIN_MAX_SCALE = "minMaxScale";

export  const DATA_CONSISTENCY = "Data Consistency";

export const PAYMENT_CARD_TYPES = {
    "visa": "creditCard_visa",
    "mastercard": "creditCard_master",
    "american-express": "creditCard_amex",
    "diners-club": "",
    "discover": "creditCard_discover",
    "jcb": "creditCard_jcb",
    "union-pay": "",
    "maestro": "",
};


export const QualdoPlanDurations = {
    MONTHLY: 0,
    YEARLY: 1
}


export const QualdoPlanCycle = {
    MONTHLY: "monthly",
    YEARLY: "annually",
    TRIAL_14_DAYS: "14_days",
}


export const DateParts = {
    YEAR: 0,
    MONTH: 1,
    DAY: 2,
}


export const QualdoEditionMapping = {
    "MME" : "Model Monitoring Edition",
    "DQE" : "Data Quality Edition",
    "EE" : "Enterprise Edition"
}

export const QualdoEditions = {
    DQ_EDITION: 0,
    ML_EDITION: "Model Monitoring Edition",
    ENTERPRISE_EDITION: 2
}

export const QualdoPlanVersions = {
    FREE: "free",
    STARTER: "starter",
    PROFESSIONAL: "professional"
}

export const DQ_CHART_POINTS = "dq_chart_points";

export const SUBSCRIPTION_PLANS = {
                "monthly": {
                    "free": {
                        "title": "Basic",
                        "price": "0",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 10,
                            "ml_models_size": 2,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    },
                    "starter": {
                        "title": "Starter",
                        "price": "79",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 100,
                            "ml_models_size": 10,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    },
                    "professional": {
                        "title": "Professional",
                        "price": "295",
                        "duration": "month",
                        "details": {
                            "data_size": 750,
                            "ml_models_size": 40,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    }
                },
                "annually": {
                    "free": {
                        "title": "Basic",
                        "price": "0",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 10,
                            "ml_models_size": 2,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    },
                    "starter": {
                        "title": "Starter",
                        "price": "61",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 100,
                            "ml_models_size": 10,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    },
                    "professional": {
                        "title": "Professional",
                        "price": "277",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 750,
                            "ml_models_size": 40,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    }
                },
            }


export const BRAIN_TREE_AUTH_TOKEN = process.env.REACT_APP_BRAINTREE_TOKEN;

// ============= GTM RELATED CONSTANTS START ========
export const GTM_INTEGRATION_TOKEN = process.env.REACT_APP_GTM_AUTH;
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW_ENV;
export const GTM_EVENTS = {
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
};
// ============= GTM RELATED CONSTANTS END ========

export const trigger_name_regex = /[^A-Za-z0-9]/
export const trigger_special_char_regex = /^[!@#$%^&*)(+=._-]{0,}$/

export const NON_NUMERIC_COLUMNS = ["string", "date", "timestamp" ]

export const DATA_DRIFT = "Data Drift";
export const DATA_TYPE_CONSISTENCY = "Data Type Consistency";
export const NORMALIZATION_CONSISTENCY = "Normalization Consistency";
export const CATEGORICAL_ENCODE = "Categorical Encode";

export const ML_DATA_CONSISTENCY = "ML Data Consistency";
export const ML_ACCURACY_DRIFT = "Accuracy Drift";

export const REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART = [DATA_TYPE_CONSISTENCY, NORMALIZATION_CONSISTENCY,
    CATEGORICAL_ENCODE];

export const DATA_CONSISTENCY_METRICS = [CATEGORICAL_ENCODE, DATA_TYPE_CONSISTENCY, NORMALIZATION_CONSISTENCY];

export const ML_REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART = [ML_DATA_CONSISTENCY];

export const CONTRIBUTION_KEY = "contribution";

export const REFERENCE_TYPE_COLUMN_NAME = {
    [DATA_TYPE_CONSISTENCY]: "Reference Data type",
    [ML_DATA_CONSISTENCY]: "Training Data type"
};

export const CONSISTENCY_CONTRIBUTION_HEADERS = ["Date", "Attribute Name", "Data type",
    REFERENCE_TYPE_COLUMN_NAME[DATA_TYPE_CONSISTENCY]];

export const CONSISTENCY_CONTRIBUTION_HEADERS_ML = ["Date", "Attribute Name", "Data type",
    REFERENCE_TYPE_COLUMN_NAME[ML_DATA_CONSISTENCY]];

export const MODEL_PERFORMANCE_AS_MODEL_ERRORS = [modelPerformance.F1_SCORE,
    modelPerformance.PRECISION, modelPerformance.RECALL,
    modelPerformance.ACCURACY,
    modelPerformance.RMSE]


export const DATA_TYPE_CONSISTENCY_HEADERS = {
    [DATA_TYPE_CONSISTENCY]: CONSISTENCY_CONTRIBUTION_HEADERS,
    [ML_DATA_CONSISTENCY]: CONSISTENCY_CONTRIBUTION_HEADERS_ML
};


export const PAID_PLANS = [QualdoPlanVersions.STARTER, QualdoPlanVersions.PROFESSIONAL]

export const VALID_QUALDO_PLANS = [QualdoPlanVersions.STARTER, QualdoPlanVersions.PROFESSIONAL,
    QualdoPlanVersions.FREE];

export const VALID_QUALDO_PLAN_CYCLES = [QualdoPlanCycle.MONTHLY, QualdoPlanCycle.YEARLY, QualdoPlanCycle.TRIAL_14_DAYS];

export const FREE_PLAN_CYCLES = [QualdoPlanCycle.MONTHLY, QualdoPlanCycle.TRIAL_14_DAYS];
export const STARTER_PLAN_CYCLES = [QualdoPlanCycle.MONTHLY, QualdoPlanCycle.YEARLY];
export const PROFESSIONAL_PLAN_CYCLES = [QualdoPlanCycle.MONTHLY, QualdoPlanCycle.YEARLY];

export const VALID_QUALDO_EDITIONS = [QualdoEditions.ML_EDITION]
export const QUALDO_WEBSITE_URL = "https://www.qualdo.ai/monitor-ml-model-performance-monitoring/#pricing";
export const PAYMENT_FIELD_ID_MAP = {
    number: "card_number",
    cvv: "cvv",
    expirationDate: "expiration_date"
}

export const PAYMENT_COMPONENT_STYLES = {
    'input': {
        'font-size': '16px',
        'color': '#020202'
    },

    ':-moz-placeholder': {
        'font-family': "'Poppins', sans-serif",
        'font-size': '14px',
        'font-weight': '400',
        'color': '#c9c9c9',
        'opacity': '1'
    },
    '::-moz-placeholder': {
        'font-family': "'Poppins', sans-serif",
        'font-size': '14px',
        'font-weight': '400',
        'color': '#c9c9c9',
        'opacity': '1'
    },
    ':-ms-input-placeholder': {
        'font-family': "'Poppins', sans-serif",
        'font-size': '14px',
        'font-weight': '400',
        'color': '#c9c9c9',
        'opacity': '1'
    }
};

export const DRIFT_PATTERN_TYPES = {
    NO_DRIFT: "NO_DRIFT",
    NOT_COMPUTED: "not_computed",
    THRESHOLD_ALERT: "Threshold Alert",
    ERRORS_DETECTED: "Errors Detected"
}

export const NON_ERROR_DRIFT_PATTERNS = [DRIFT_PATTERN_TYPES.NO_DRIFT, DRIFT_PATTERN_TYPES.NOT_COMPUTED];

export const MIN_MAX_SCALE_LIST = ["Recency", "Data Drift", "Data Type Consistency",
    "Normalization Consistency", "Normalisation Consistency"];

export const METRIC_ALIAS_NAMES = {
    "precision" : "Precision",
    "recall" : "Recall",
    "precision vs recall": "Precision vs Recall",
    "f1 score": "F1 score",
    "Weighted F(1) Score": "Weighted F1 Score",
    "Weighted precision": "Weighted Precision",
    "Weighted recall": "Weighted Recall",
    "rmse": "Root Mean Squared Error",
    "mse": "Mean Squared Error",
    "r2": "R-squared",
    "mae": "Mean Absolute Error",
    "memory_usage": "Memory Usage",
    "cpu_usage": "CPU Usage",
    "overhead_latency": "Overhead Latency",
    "model_latency": "Model Latency",
    "areaUnderROC": "Area Under ROC",
    "areaUnderPR": "Area Under PR",
    "areaUnderROC Error": "Area Under ROC Error",
    "areaUnderPR Error": "Area Under PR Error",
    "Accuracy": "Accuracy",
    "Sensitivity": "Sensitivity",
    "Specificity": "Specificity",
    "True Positive": "true positive",
    "False Positive": "false positive",
    "True Negative": "true negative",
    "False Negative": "false negative",
    "Spearman correlation coefficient": "Spearman Correlation Coefficient",
    "Pearson's correlation coefficient": "Pearson's Correlation Coefficient",
    "Matthews Correlation Coefficient": "Matthews Correlation Coefficient",
    "mean_shap_value": "Mean Shap Value"
}

// Should be in sync with QualdoPaymentStatus in qualdo/qualdo_utils/constants.py
export const QUALDO_PAYMENT_STATUS = {
    TRIAL_EXPIRED: "trial_expired",
    PAYMENT_COMPLETED: "payment_completed",
    PAYMENT_PENDING: "payment_pending"
}

export const PENDING_PAYMENTS = [QUALDO_PAYMENT_STATUS.TRIAL_EXPIRED,
    QUALDO_PAYMENT_STATUS.PAYMENT_PENDING];

export const BUTTON_NAMES = {
    PAY_NOW: "Pay now",
    CHOOSE_PLAN: "Choose plan",
    INCREASE_LIMIT: "Increase limit"
}

export const EXCLUDED_COMPONENTS = ["dqErrorListViewTable"]
export const PLAN_PAGE_WARNINGS = {
    [QUALDO_PAYMENT_STATUS.TRIAL_EXPIRED]: [ "Free trial expiration. ",
    "Free-trial period and the extension has expired. Please subscribe to one of the plans to continue using Qualdo\u2122",
    BUTTON_NAMES.CHOOSE_PLAN],
    [QUALDO_PAYMENT_STATUS.PAYMENT_PENDING]: ["There is a pending payment.",
    "Your current bill is past-due. The warning extension period is also past. To continue using Qualdo\u2122, please pay immediately. For any assistance please contact the help desk below.",
    BUTTON_NAMES.PAY_NOW ],
    "data_limit_exceeded_paid_plans" : ["Data size limit reached.",
    "The size limit of the Total Data Processed by Qualdo\u2122 for the current plan is (100 GB).  You are starting to breach this limit. Please remember, you will be charged an extra $52 for every 100 GB data processing increase. This will show up in the next bill/invoice. You can also upgrade to another plan to continue using Qualdo\u2122.",
    BUTTON_NAMES.INCREASE_LIMIT ],
    "free_trial_exceeded": ["Data size limit reached.",
    "The size limit of the Total Data Processed by Qualdo\u2122 for the current plan is (10 GB). " +
    "You are starting to breach this limit. Please subscribe to one of the plans to continue using Qualdo\u2122.",
    BUTTON_NAMES.CHOOSE_PLAN]
    }

export const TEST_CONNECTION_MESSAGES = {
"azure_blob": {'stages': [{'stage': 1, 'name': 'Validate Storage Name', 'status': "in-progress"},
                                         {'stage': 2, 'name': 'Validate Secret Key', 'status': "in-progress"},
                                         {'stage': 3, 'name': 'Validate Container', 'status': "in-progress"}
                                         ]},
"azure_data_lake": {'stages': [{'stage': 1, 'name': 'Validate Tenant Id', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate Client Id', 'status': "in-progress"},
                                             {'stage': 3, 'name': 'Validate Client Secret', 'status': "in-progress"},
                                             {'stage': 4, 'name': 'Connect to Bucket', 'status': "in-progress"}
                                             ]},
"big_query": {'stages': [{'stage': 1, 'name': 'Validate Service Account JSON', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate schema Name', 'status': "in-progress"}
                                             ]},
"gcs": {'stages': [{'stage': 1, 'name': 'Validate Json', 'status': "in-progress"},
                                         {'stage': 2, 'name': 'Validate Bucket Name', 'status': "in-progress"}
                                         ]},
"s3": {'stages': [{'stage': 1, 'name': 'Validate Access key', 'status': "in-progress"},
                                         {'stage': 2, 'name': 'Validate Secret Access key', 'status': "in-progress"},
                                         {'stage': 3, 'name': 'Validate Bucket Name', 'status': "in-progress"},
                                         ]},
"snow_flake": {'stages': [{'stage': 1, 'name': 'Validate Account Name', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate Security Credentials', 'status': "in-progress"},
                                             {'stage': 3, 'name': 'Validate Database/schema/Table', 'status': "in-progress"}
                                             ]},
"postgresql": {'stages': [{'stage': 1, 'name': 'Validate Host name', 'status': "in-progress"},
                                     {'stage': 2, 'name': 'Validate User name', 'status': "in-progress"},
                                     {'stage': 3, 'name': 'Validate Password', 'status': "in-progress"},
                                     {'stage': 4, 'name': 'Validate Schema Name', 'status': "in-progress"},
                                     {'stage': 5, 'name': 'Validate Table Name', 'status': "in-progress"}
                                     ]},
"mysql": {'stages':[{'stage': 1, 'name': 'Validate  Host', 'status': "in-progress"},
                     {'stage': 2, 'name': 'Validate Username/Password', 'status': "in-progress"},
                     {'stage': 3, 'name': 'Validate Database/Table', 'status': "in-progress"}
                     ]},
"azure_sql": {'stages': [{'stage': 1, 'name': 'Validate Host', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate Username/Password', 'status': "in-progress"},
                                             {'stage': 3, 'name': 'Validate Database/Schema/Table', 'status': "in-progress"}
                                             ]},
"gcp_sql": {'stages': [{'stage': 1, 'name': 'Validate Host', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate Username/Password', 'status': "in-progress"},
                                             {'stage': 3, 'name': 'Validate Database/Schema/Table', 'status': "in-progress"}
                                             ]},
}

export const DATA_LIMIT_EXCEED = {
    TITLE: "You are going to exceed your data usage limit",
    DESCRIPTION: "For extra data usage you will be charged $52 per 100 GB as part of the next" +
    " month billing cycle. To use extra data, please provide us the confirmation for further processing."
}


export const PLAN_ICON_MAPS = {
    "Starter": "plan_starter",
    "starter": "plan_starter",
    "Professional": "pricingIcon_professional",
    "professional": "pricingIcon_professional",
    "Free": "pricingIcon_free",
    "free": "pricingIcon_free"
}

export const PLAN_CARD_CLASS_MAPS = {
    "Starter": "card__qualdo-edition your-plan__starter",
    "Professional": "card__qualdo-edition your-plan__professional",
    "Free": "card__qualdo-edition your-plan__starter",
}

export const NO_ERROR_CHART_COLORS = {
    "Purple" : "#915EF9",
    "Green" : "#4ED2CC",
    "Blue" : "#4CA3FF",
    "Yellow" : "#FFA800"
}

export const NO_ERROR_COLOR_CLASS_MAP = {
    "#915EF9" : "f-16 lh-20 text-purple",
    "#4ED2CC" : "f-16 lh-20 text-success",
    "#4CA3FF" : "f-16 lh-20 text-info",
    "#FFA800" : "f-16 lh-20 text-warning"
}

export const LIGHT_COLOR_MAP = {
    "#915EF9" : "rgba(145, 94, 249, 0.25)",
    "#4ED2CC" : "rgba(78, 210, 204, 0.25)",
    "#4CA3FF" : "rgba(76, 163, 255, 0.25)",
    "#FFA800" : "rgba(255, 168, 0, 0.25)"
}
/*
 * These URLs will be shown only when the user is not logged.
 * When the user is logged in and they are reaching these URLS,
 * we will redirect them to configure page or any other page
 * based on their plan status and plan types
 */
export const ACCOUNT_FREE_URLS = ["/", "/signup", "/login", "/google_signup"];


export const DEFAULT_CURRENCY_SYMBOL = "$";

export const BOX_PLOT_METRICS = ["Data Drift", "Model Drift", "Prediction Drift", "Response Drift", "Feature Drift",
                                    "Accuracy Drift"];


export const MEMORY_UNITS = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export const SINGLE_ARROW_CHART_TYPE = "singleArrowChart";


export const GENERIC_CONSTANTS = {
    NA: "NA",
}

export const MONITOR_DQ_LIST_VIEW_HEADERS = ["Datasource Name", "Dataset Name", "Attribute Name", "DQ Metrics with Issues", "Action"];
export const MONITOR_DQP_LIST_VIEW_HEADERS = ["Datasource Name", "Dataset Name", "Attribute Name", "DQ Metrics", "Action"];

export const MONITOR_MODEL_ERROR_LIST_VIEW_HEADERS = ["Model Name", "Model Summary", "Model Error Summary", "Data Quality Issues", "Serving Features Drifting", "Performance Metrics with Issues", "Action"];

export const MONITOR_MODEL_PERF_LIST_VIEW_HEADERS = ["Model Name", "Model Summary",
    "Model Error Summary", "Performance Metrics", "Action"];

export const MONITOR_PERF_LIST_VIEW = {
    "Model Accuracy Metrics": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": THUMBNAIL_COLORS.GREEN_VARS
    },
    "Model Evaluation Metrics": {
        "cardBackground": "dashboard-stat-purple", "textColor": "text-purple",
        "colorArray": THUMBNAIL_COLORS.VIOLET_VARS

    },
    "Model Runtime-Profile Metrics": {
        "cardBackground": "dashboard-stat-warning", "textColor": "text-warning",
        "colorArray": THUMBNAIL_COLORS.ORANGE_VARS
    },
    "Model Explainability": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": THUMBNAIL_COLORS.GREEN_VARS
    },
    "Monitor Error": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": THUMBNAIL_COLORS.RED_VARS
    },
    "Monitor Data Quality Performance-1": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": THUMBNAIL_COLORS.GREEN_VARS
    },
    "Monitor Data Quality Performance-2": {
        "cardBackground": "dashboard-stat-purple",
        "textColor": "text-purple",
        "colorArray": THUMBNAIL_COLORS.VIOLET_VARS

    }
};

export const IMAGE_NAMES = {
    ICON_DOWN_RED: "icon_down_red",
    ICON_UP_GREEN: "icon_up_green",
};

export const LIMIT_ABOUT_TO_REACH = "You are going to breach the current plan’s data limit";

// This value should be in sync with
// 'qualdo/qualdo_utils/constants.py' -> MONITOR_RESULTS_PER_PAGE
export const MAX_CHARTS_PER_API = 500;


export const CONFIGURE_DATA_SOURCE_URL = "https://eastus.av.mk.io/video-prod-media-kind/8000d890-ad15-478c-8d3a-1f3c68cfd304/Configure_Datasource_V2.ism/manifest(format=m3u8-cmaf,encryption=cbc)";
export const CONFIGURE_MODEL_URL = "https://videos.qualdo.ai/94c4245a-fdbc-40b6-bc02-fce622569628/Configure_Model_V2.ism/manifest";
export const PIPELINE_CHANNEL_URL  = "https://videos.qualdo.ai/c96e8628-659a-4a78-a26e-8e96c2b77f74/pipeline_channel.ism/manifest";
export const MONITOR_MODEL_URL = "https://videos.qualdo.ai/a7638360-a5d8-43f7-84a0-54c581a93860/Model_Monitor_Errors_V4.ism/manifest";
export const MONITOR_DATA_URL = "https://videos.qualdo.ai/5bfcef99-100f-4c6f-a819-929eda03fc80/Data_Quality_Errors_V3.ism/manifest";
export const PROFILE_DATA_URL = "https://videos.qualdo.ai/d7b81a87-7c81-47a1-9342-c7c873b6fd52/Profile_Data.ism/manifest";
export const PROFILE_MODEL_URL = "https://videos.qualdo.ai/c562ebc8-b6e1-4129-94df-08e426586d3f/Profile_Model.ism/manifest";
export const NOTIFICATION_URL = "https://videos.qualdo.ai/ed9ffb62-63a4-4964-91da-cf48cd05c74e/notification_channel.ism/manifest";
export const DATA_QUALITY_URL = "https://videos.qualdo.ai/43ac9b88-bcb0-4c06-bce1-623894ffb547/Dataquality_Alert_V2.ism/manifest";
export const MONITOR_MODEL_PERFORMANCE_URL = "https://videos.qualdo.ai/de3ae8b8-c047-4b5d-b21e-c3f81dbc605c/Model_Monitor_Performance_V3.ism/manifest";


export const VERSION_FORMATS = {
    "dd-mm-yyyy": "[\\d]{1,2}-[\\d]{1,2}-[\\d]{4}",
    "yyyy-mm-dd": "[\\d]{4}-[\\d]{1,2}-[\\d]{1,2}",
    "dd-mm-yy": "[\\d]{1,2}-[\\d]{1,2}-[\\d]{2}",
    "yy-mm-dd": "[\\d]{2}-[\\d]{1,2}-[\\d]{1,2}",
    "ddmmyyyy": "[\\d]{8}"
};



export const FILE_TYPE_FORMATS = {
    "file_replace" : "File Replace",
    "file_version" : "File Version",
    "folder_version" : "Folder Version"
}



export const DISPLAY_DATE_FORMAT = 'MMM DD, YYYY';
export const MAX_DATA_POINTS_FOR_LIST_VIEW = 30;
export const MAX_POINTS_THUMBNAIL = 7;

export const DISABLE_CHECKBOXES_IN_CARDS = ["Categorical Encode", "Data Type Consistency",
    "Normalization Consistency"]


export const SQL_QUERY_DUMMY_PLACE_HOLDER = "select count(*) from $dataset$ where `attribute1` = 1";

export const SQL_QUERY_NOTE = "In the query, use $dataset$ instead of dataset name. " +
    "Enclose attributes name with (`). Don’t use semicolon(;) at the end.";

// This count will be used for fetch unread notifications during pre-fetch API call
export const MAX_UNREAD_PER_PAGE = 15;

// This count will be used for fetching all notifications during fetch API calls in /notification page.
export const MAX_NOTIFICATIONS_PER_PAGE = 200;

export const IS_NONE_CHECK = [null, undefined];

// give in lower case
export const NOT_SORTABLE = ["summary", "profile status", "datasets", "errors", "model summary",
                            "model error summary", "performance metrics", "data quality issues",
                            "serving features drifting", "performance metrics with issues",
                            "dq metrics with issues", 'action', "actions"]


// To be in sync with 'qualdo/qualdo_utils/constants.py' => ALLOWED_ML_STACKS
export const SUPPORTED_ML_STACK_OPTIONS = [{label: 'Tensor Flow', value: 'TF'}, {label: 'MXNet', value: 'MXNet'},
    {label: 'DataBricks', value: 'DataBricks'}, {label: 'Others', value: 'others'}];


// To be in sync with 'qualdo/qualdo_utils/constants.py' => ALLOWED_ML_TYPES
// and qualdo/compute_engine/compute_measure/estimators/ml_constants.py => SUPPORTED_MODELS
export const SUPPORTED_ML_TYPE_OPTIONS = [{label: 'Classification', value: 'classification'},
    {label: 'Regression', value: 'regression'},
    {label: 'Clustering', value: 'clustering'},
    {label: 'Recommendation', value: 'recommendation'}];
// Removed
// {label: 'Others', value: 'others'}

export const SUPPORTED_ML_COLUMN_TYPES = [{label: 'Item', value: 'item'},
    {label: 'Rating', value: 'rating'}];

// To be in sync with 'qualdo/qualdo_utils/constants.py' => ALLOWED_ML_ALGO_TYPES
export const SUPPORTED_ML_ALGO_TYPES = [
    {label: 'Content based recommendation system', value: 'Content based recommendation system', type: "recommendation"},
    {label: 'Collaborative recommendation system', value: 'Collaborative recommendation system', type: "recommendation"},
    {label: 'Logistic Regression', value: 'Logistic Regression', type: "classification"},
    {label: 'k-Nearest Neighbors', value: 'k-Nearest Neighbors', type: "classification"},
    {label: 'Support Vector Machine', value: 'Support Vector Machine', type: "classification"},
    {label: 'Naive Bayes', value: 'Naive Bayes', type: "classification"},
    {label: 'Random Forest', value: 'Random Forest', type: "classification"},
    {label: 'Gradient Boosting', value: 'Gradient Boosting', type: "classification"},
    {label: 'Linear Regression', value: 'Linear Regression', type: "regression"},
    {label: 'Tree Regression', value: 'Tree Regression', type: "regression"},
    {label: 'GBT Regression', value: 'GBT Regression', type: "regression"},
    {label: 'Polynomial Regression', value: 'Polynomial Regression', type: "regression"},
    {label: 'Lasso Regression', value: 'Lasso Regression', type: "regression"},
    {label: 'K-Means', value: 'K-Means', type: "clustering"},
    {label: 'Affinity Propagation', value: 'Affinity Propagation', type: "clustering"},
    {label: 'Agglomerative Clustering', value: 'Agglomerative Clustering', type: "clustering"},
    {label: 'Spectral Clustering', value: 'Spectral Clustering', type: "clustering"},
    {label: 'Mean Shift', value: 'Mean Shift', type: "clustering"},
    {label: 'CNN', value: 'CNN', type: "regression classification"},
    {label: 'DNN', value: 'DNN', type: "regression classification"}
    ];
//{label: 'Others', value: 'others', type: "others"}

export const QUALITY_PERF_CHART_VIEW = {
    'drift': {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#915EF9", "#4ED2CC", "#4CA3FF", "#FFA800"]
    },
    "completeness": {
        "cardBackground": "dashboard-stat-purple",
        "textColor": "text-purple",
        "colorArray":  [ "#4ED2CC", "#4CA3FF", "#FFA800", "#915EF9"]

    },
    "uniqueness":{
        "cardBackground": "dashboard-stat-purple",
        "textColor": "text-purple",
        "colorArray":  [ "#4ED2CC", "#4CA3FF", "#FFA800", "#915EF9"]

    },
    "timeliness": {
        "cardBackground": "dashboard-stat-warning",
        "textColor": "text-warning",
        "colorArray": ["#4CA3FF", "#FFA800", "#915EF9", "#4ED2CC"]
    },
    "accuracy": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#FFA800", "#915EF9", "#4ED2CC", "#4CA3FF"]
    },
    "conformity": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#915EF9", "#4ED2CC", "#4CA3FF", "#FFA800"]
    },
    "consistency":  {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#4ED2CC", "#4CA3FF", "#FFA800", "#915EF9"]
    },
};

export const REQUIRES_EXCLAMATION_TRIANGLE_ICON = ["Datasource Configuration Alert",
    "Model Monitoring Metrics Alert",
    "Datasets deletion info", "No dataset found", "Model dataset has null values"];


export const CONFORMITY_METRIC_KEYS = ["Credit Card Conformity", "Date Conformity",
"Email Conformity", "Gender Conformity", "Http Conformity", "IP Conformity",
"SSN Conformity", "URL Conformity", "Boolean Conformity"]

export const METRICS_INFO = {
    "rmse": "Root Mean Squared Error",
    "mae": "Mean Absolute Error",
    "mean absolute error": "Mean Absolute Error",
    "root mean squared error":  "Root Mean Squared Error",
    "precision": "It is the ratio of correctly predicted positive observations to the total predicted positive observations.",
    "mean average precision @k": "Precision at k is the proportion of recommended items in the top-k set that are relevant.",
    "weighted precision": "Weighted mean of precision with weights equal to class probability.",
    "f1 score": "It is the weighted average of Precision and Recall.",
    "Feature Importance": "Feature Importance of the attribute",
    "weighted f(1) score": "Weighted mean of f1-score with weights equal to class probability.",
    "accuracy": "It is a ratio of correctly predicted observation to the total observations.",
    "recall": " It is is the ratio of correctly predicted positive observations to the all observations in actual class - yes.",
    "mean average recall @k":"Recall at k is the proportion of relevant items found in the top-k recommendations.",
    "weighted recall": "Weighted mean of recall with weights equal to class probability.",
    "sensitivity" : " It is is the ratio of correctly predicted positive observations to the all observations in actual class - yes.",
    "specificity": "It is the ratio of correctly predicted negative observations to the all observations in actual class - no.",
    "confusion matrix": " It is a table with different combinations of predicted and actual values.",
    "areaunderpr": "It is a singular value that summarizes the it the precision vs recall curve.",
    "areaunderroc": "It is a single scalar value that measures the overall performance of a binary classifier on the positive class.",
    "true positive": "These are the correctly predicted positive values which means that the value of actual class is yes and the value of predicted class is also yes.",
    "false positive": "These are wrongly predicted positives which means when the actual class is no and predicted class is yes.",
    "true negative": "These are the correctly predicted negative values which means that the value of actual class is no and value of predicted class is also no.",
    "false negative": "These are wrongly predicted negative values which means the actual class is yes but predicted class in no.",
    "spearman correlation coefficient": "It is a technique which can be used to discover the strength of a link between actual observation and predicted values. The coefficient closer to +/-1, the stronger the likely correlation.",
    "pearson's correlation coefficient": "It is a measure of the strength of a linear association between the actual observation and predicted variables. +/-1 indicates strong +/- accosiation.",
    "matthews correlation coefficient": "It is a measure of the correlation of actual observation with the predicted values. MCC value is always between -1 and 1, with 0 meaning that the prediction value is random w.r.t actual observation.",
    }

export const K_VALUE_TOOLTIP = "In the context of recommendation systems we are most likely " +
    "interested in recommending top-N items to the user. k is a user definable integer " +
    "that is set by the user to match the top-N recommendations objective.";

// Delimiter options are used by recommendation models to split data
// To be in sync with qualdo/qualdo_utils/constants.py -> ALLOWED_DELIMITER_FOR_RECO
export const DELIMITER_OPTIONS = [
    {"label": ",", "value": ","},
    {"label": ";", "value": ";"},
    {"label": "|", "value": "|"},
    {"label": "custom", "value": "custom"},
];


export const TABLE_VIEW_CLASS = "btn btn-sm btn-outline-success mr-1";
export const TABLE_EDIT_CLASS = "btn btn-sm btn-outline-warning mr-1";
export const TABLE_DELETE_CLASS = "btn btn-sm btn-outline-danger show-danger-toast mr-1";
export const TABLE_COMP_MODELS_CLASS = "btn btn-sm btn-outline-purple mr-1";


export const MODEL_AB_TABLE_HEADERS = ["Model Name", "Model Version", "Datasource",
    "Environment", "Created By", "Created Time", "Actions"];

export const AB_MODELS_TABLE_TITLE = "CONFIGURED MODELS FOR AB TESTING";


export const AB_COMPARE_VIEW_TABLE_HEADERS = ["Model Name", "Model Summary",
    "Performance Metrics", "Action"];

export const SUFFIXED_DATE = {
    "1": "st", "2": "nd", "3": "rd"
};


export const MODEL_AB_COMPARE_SRC = "model_ab_compare_dc_src";

export const INDEX_IN_AB = {1: "A", 2: "B", 3: "C"};

export const AB_THUMBNAIL_LIMIT = 10;


// Has mapping between names in Advanced filter and chart names
export const AB_ADVANCE_FILTER_METRIC_NAMES = {
    "accuracy": "Accuracy",
    "precisionVsRecall": "precision vs recall",
    "sensitivityVsSpecificity": "Sensitivity vs Specificity",
    "weightedRecall": "Weighted recall",
    "f1score": "f1 score",
    "weightedF1Score": "Weighted F(1) Score",
};

export const DOCUMENT_BASE_LINK = "https://docs.qualdo.ai/";
// DRX Documentation
export const DRX_DOCUMENT_BASE_LINK = "https://drx-docs.gcp-qualdo.ai/";
export const DOCUMENT_LINK_MAPPING = {
    "index": "index.html",
    "data_source": "tutorials/datasources.html",
    "profile_data": "profile.html#profile-data",
    "profile-data-relationship": "tutorials/profile.html#relationship",
    "profile-data-completeness": "tutorials/profile.html#completeness",
    "accuracy": "tutorials/profile.html#data-accuracy",
    "profile-data-timeliness": "tutorials/profile.html#data-timeliness",
    "conformity": "tutorials/profile.html#conformity",
    "profile-data-consistency": "tutorials/profile.html#consistency",
    "profile-data-attribute": "tutorials/profile.html#attribute",
    "monitor-model-performance":"tutorials/insights.html#model-performance",
    "monitor-model-errors": "tutorials/insights.html#model-errors",
    "data-quality-errors": "tutorials/insights.html#data-quality-errors",
    "profile-models": "tutorials/profile.html#profile-models",
    "consume": "tutorials/consume.html#notification-channels",
    "consume_pipeline": "tutorials/consume.html#pipeline-channels",
    "alert": "tutorials/consume.html#configuring-an-alert",
    "metrics":"tutorials/metrics.html#custom-metrics",
    "datasource":"tutorials/datasources.html",
    "ml_model":"tutorials/model.html",
    "deployment":"tutorials/deployments.html",
    "dashboard":"tutorials/dashboard.html",
    }


export const MONITOR_DATA_KEYS = ["dataFrom", "dataTo", "data_points_found", "filterEndDate", "filterStartDate", "min_difference"]

export const ALERT_TYPE = {
  "metric_value": "Metric value at dataset level",
  "metric_attribute_level": "Metric value at attribute level",
  "drift_value": "Drift value at dataset level",
  "drift_attribute_level": "Drift value at attribute level",
};

export const ABOVE_ALERT_SUPPORTED_TABLES = ["dqErrorListViewTable", "mpCompareViewTable"];

export const IAM_SECRET_KEY = process.env.REACT_APP_IAM_DECRYPT_KEY;


export const DATA_SOURCE_OPTIONS = [
            {
                "options": [{"label": "Azure Blob", "value": 'azure_blob'},
                    {"label": "Azure Data Lake", "value": 'azure_data_lake'},
                    {"label": 'Azure SQLServer', "value": 'azure_sql'}],
                "label": "Azure Connectors"
            },
            {
                "options": [{"label": "AWS S3", "value": 's3'}],
                "label": "AWS Connectors"
            },
            {
                "options": [{"label": "Google BigQuery", "value": 'big_query'},
                    {"label": "GCP SQLServer", "value": 'gcp_sql'},
                    {"label": "Google Cloud Storage", "value": "gcs"}],
                "label": "GCP Connectors"
            },
            {
                "options": [{"label": 'Snowflake', "value": 'snowflake'},
                    {"label": "Postgres", "value": 'postgresql'},
                    {"label": "MySQL", "value": 'mysql'}],
                "label": "Database Connectors"
            }
        ];

export const GCP_CLOUD_PREFERENCE = [{"label": "GCP", "value": "gcp"}];

export const GCP_EDITIONS =[{"label": "Data Quality Edition", "value": "data_quality_edition"},
{"label":"Model Monitoring Edition","value": "Model Monitoring Edition"}]

// REGION LIST GOT FROM https://www.parkmycloud.com/blog/google-cloud-regions/
export const GCP_REGIONS = [{"label": "Taiwan (asia-east1)", "value": "asia-east1"},
{"label": "Hong Kong (asia-east2)", "value": "asia-east2"},
{"label": "Tokyo (asia-northeast1) ", "value": "asia-northeast1"},
{"label": "Osaka (asia-northeast1) ", "value": "asia-northeast1"},
{"label": "Seoul (asia-northeast3) ", "value": "asia-northeast3"},
{"label": "Mumbai (asia-south1) ", "value": "asia-south1"},
{"label": "Singapore (asia-southeast1) ", "value": "asia-southeast1"},
{"label": "Sydney (australia-southeast1) ", "value": "australia-southeast1"},
{"label": "Finland (europe-north1) ", "value": "europe-north1"},
{"label": "Belgium (europe-west1) ", "value": "europe-west1"},
{"label": "London (europe-west2) ", "value": "europe-west2"},
{"label": "Frankfurt (europe-west3) ", "value": "europe-west3"},
{"label": "Netherlands (europe-west4) ", "value": ""},
{"label": "Zürich (europe-west6) ", "value": "europe-west6"},
{"label": "Montréal (northamerica-northeast1) ", "value": "northamerica-northeast1"},
{"label": "Sao Paulo (southamerica-east1) ", "value": "southamerica-east1"},
{"label": "Iowa (us-central1) ", "value": "us-central1"},
{"label": "South Carolina (us-east1) ", "value": "us-east1"},
{"label": "Northern Virginia (us-east4) ", "value": "us-east4"},
{"label": "Oregon (us-west1) ", "value": "us-west1"},
{"label": "Los Angeles (us-west2) ", "value": "us-west2"},
{"label": "Salt Lake City (us-west3) ", "value": "us-west3"},]

export const COMPUTE_PROFILE = [
                                {"label": "Dummy", "value": "dummy"},
                                {"label": "Profile 1", "value": "cp1"},
                                {"label": "Profile 2", "value": "cp2"},
                                {"label": "Profile 3", "value": "cp3"},
                                ]


export const MANDATE_RESET_PASSWORD = {
    "Reset_Password": "cmVzZXRQYXNzd29yZAo=",
    "One": "b25lCg==",
    "Zero": "emVybwo="
}
export const SUBSCRIPTION_DOC= "https://docs-subscription.gcp-qualdo.ai/"

export const LEGAL_AGREEMENT_LINK = "https://www.qualdo.ai/legal-agreement/"

export const DEFAULT_SUBNET_RANGE = '10.101.0.0/16';

export const CYCLE ={
    'annually':"Annually",
    'monthly': "Monthly",
}

export const DEPLOYMENT_DATA = {
    "cloud":"Google Cloud",
    "edition": "DRX",
    "compute_config": "Standard",
    "cloud_support": "Qualdo's Private Cloud",
    "subscription": "professional",
    "cycle": "annually",
    "status": "initiated",
    "whitelisted_ip":['0.0.0.0/0']
}

export const QUALDO_CLOUD = "Qualdo's Private Cloud"

export const PRIVATE_CLOUD = "My Enterprise Private Cloud"

export const DEPLOYMENT_FAILURE = [
    "Unable to create the Deployment",
    "Please check the given details else click on Try again to redeploy"
]

export const PERMISION_DENIED = [
        "Insufficient permission(s) to satisfy the request.",
        "Please make sure the necessary permissions are enabled."
]

export const INSUFFICIENT_QUATO = [
    "Insufficient regional quota(s) to satisfy request.",
    "Please make sure the required resources quotas are available."
]

export const ACCOUNT_MISMATCH = [
    "The given account name does not match the account name in the service account key file.",
    "Please check the uploaded json key file."
]

export const REQUIRED_QUOTAS = [
        "CPUS - 72",
        "DISKS_TOTAL_GB - 4096",
        "STATIC_ADDRESSES - 4",
        "IN_USE_ADDRESSES - 18",
        "SSD_TOTAL_GB - 3000",
        "LOCAL_SSD_TOTAL_GB - 6000"
]

export const REQUIRED_PERMISSIONS=[
    "Cloud SQL Admin",
    "Cloud SQL Client",
    "Dataproc Editor",
    "GKE Hub Service Agent",
    "Kubernetes Engine Service Agent",
    "Secret Manager Admin",
    "Service Networking Admin",
    "Storage Admin",
]
export const LOCAL_STORE_KEY = "yek_odlauq"