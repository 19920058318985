import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getApi } from "../../utils/event_handling";
import { toastObj } from "../../utils/common_utils";
import QualdoDataTable from '../../components/bootstrapTable';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import {IS_NONE_CHECK,CYCLE} from "../../utils/constant";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const renderTooltip = (props) => (
  <Tooltip {...props}>
    Payment Success
  </Tooltip>
);
class BillingHistory extends React.Component {
  constructor(props) {
    super(props);
    this.getApi = getApi.bind(this);
    this.showToast = this.showToast.bind(this);
    this.closeToast = this.closeToast.bind(this);
    this.deploymentTableComponent = this.deploymentTableComponent.bind(this);
    this.pdfdownload = this.pdfdownload.bind(this);

    this.state = {
      toastStatus: false,
      emailsubscription: [],
      chechkall: false,
      btnchkalltext: "Unsubscribe All",
      emailsubscriptioninitalloader: true,
      billing_address: null,
      billingHistory: null,
      receiptData:null
    };
    this.getData();
  }

  getData() {
    this.getApi("get_user_payments_and_billing_history");
  }

  selectAll() {
    let checkallarr;
    if (this.state.chechkall) {

      checkallarr = this.state.emailsubscription;
      checkallarr.map(function (emailsubscriptiondata, i) {
        checkallarr[i].email_user_status = 1;
        // emailsubscriptiondata.email_user_status
        return checkallarr;
      });

      this.setState({
        btnchkalltext: "Unsubscribe All",
        chechkall: false,
        emailsubscription: checkallarr,
      });

    } else {
      checkallarr = this.state.emailsubscription;
      checkallarr.map(function (emailsubscriptiondata, i) {

        checkallarr[i].email_user_status = 0;
        return checkallarr;
      });

      this.setState({
        btnchkalltext: "Subscribe All",
        chechkall: true,
        emailsubscription: checkallarr,
        billingHistory: null
      });
    }
    this.getApi("get_user_payments_and_billing_history");
  }

  closeToast() {
    this.setState({ toastStatus: false });
  }
  showToast(status, msg, link) {
    this.setState({
      toastStatus: true,
      toastMsg: msg,
      toastClass: toastObj[status]["className"],
      toastIcon: toastObj[status]["icon"],
      toastheading: toastObj[status]["heading"]
    });
  }



  toggleEmailGroup = (i) => (event) => {
    let checkedlist = this.state.emailsubscription;
    checkedlist[i].email_user_status = (event.target.checked === true ? 1 : 0);
    // let textflag = 0;
    // checkedlist.map(function (emailsubscriptiondata, i) {
    //   if(checkallarr[i].email_user_status = 1){
    //     textflag = 
    //   }
    // });
    this.setState({ emailsubscription: checkedlist })
    this.getApi("get_user_payments_and_billing_history");
  }

  deploymentTableComponent(actionType, headerValue, cell, tableProps, row) {
    if (headerValue === "Order Id") {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <small className="text-muted ml-1">{cell}</small>
        </div>

      );
    }

    if (headerValue === "Status") {
      return (
        <>
          <strong className="text-success mr-1">Paid</strong>
          <OverlayTrigger placement="top" overlay={renderTooltip}>
              <i><FontAwesomeIcon icon={faInfoCircle} /></i>
          </OverlayTrigger>
        </>
      );
    }

    if (headerValue === "Amount") {
      return (
        <strong>{cell}</strong>
      );
    }

    if (headerValue === "Coupon") {
      if(cell === "null"){
        return (
          <strong>{"NA"}</strong>
        );
      }
      else{
        return (
          <strong>{cell}</strong>
        );
      }
      
    }

    if (headerValue === "Deployment Name") {
      return (
        <dl className="mb-0">
          <dt className="text-dark"><strong>{cell}</strong></dt>
          <dd className="text-muted mb-0"><small>My Enterprise Private Cloud</small></dd>
        </dl>
      );
    }
    return cell;
  }



  pdfdownload(row) {
    // let reqAddressID = row["Pdf"]["billing_history"][0]["billing_address_id"];
    let pdfinfo = row["Pdf"]["billing_history"][0];
    let cycle = row["Pdf"]["billing_history"][0]['details']['billing_cycle'];
    cycle = CYCLE[cycle]
    let coupon = ''
    let discount = ''
    let status = ''
    let couponPrice=''
    let actualPrice = pdfinfo.amount
    if(row["Pdf"]["billing_history"][0]['coupon_code'] !== null){
        coupon = row["Pdf"]["billing_history"][0]['coupon_code'];
        coupon = '('+coupon+')'
        status = "Coupon Applied"
        discount = row["Pdf"]["billing_history"][0]['amount'];
        couponPrice =  discount-actualPrice
    }
    let createddate   =   pdfinfo.created_time;
    let aordercreated = createddate.split(" "); 
    let next_payment_date   =   row["Pdf"]['validity_date'];

    let anext_payment_date = next_payment_date.split(" ");  

     let companyName = "";
     let addressLine1 = "";
     let addressLine2 =   "";
     let city =   "";
     let state =   "";
     let zipCode =   "";
     let country =  "";
     let billing_address = pdfinfo['details']['billing_address'][0]['details']
    let matchedBillingAddress = billing_address;
     if (IS_NONE_CHECK.includes(matchedBillingAddress)) {
        matchedBillingAddress = {};
     }

    companyName = matchedBillingAddress.company_name;
     addressLine1 = matchedBillingAddress.address_line_1;
     addressLine2 = matchedBillingAddress.address_line_2 === undefined ? "" : matchedBillingAddress.address_line_2;
     city =   matchedBillingAddress.city;
     state =  matchedBillingAddress.state;
     zipCode = matchedBillingAddress.zip_code;
     country = matchedBillingAddress.country;
     if(country === undefined){
         country = "";
     }
    let pdfsize = 850;
    let percesize = 325;
    var html = htmlToPdfmake(`
    <div style="max-width: `+pdfsize+`px;margin: 15px auto;">
       <table style="margin:0;">
        <tr style="border:none;margin:0;">
            <td style="text-align:left;width:`+(percesize)+`px;">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="130" height="34" viewBox="0 0 170 34">
                        <g id="Group_5" data-name="Group 5" transform="translate(-3 -3.3)">
                        <g id="Group_2" data-name="Group 2" transform="translate(3 3.4)">
                            <path id="Path_1" data-name="Path 1" d="M29.1,34.143a17.3,17.3,0,0,1-9.4,2.75C10.1,36.893,3,29.662,3,20.6v-.1A16.4,16.4,0,0,1,19.8,4.1,16.249,16.249,0,0,1,36.5,20.5v.1a16.354,16.354,0,0,1-2.7,9.064l3.2,2.75L32.7,37.3Zm-6.2-5.6-4.3-3.768,4.3-4.99,4.2,3.87a7.846,7.846,0,0,0,.5-3.055v-.1c0-4.583-3.2-8.656-8-8.656-4.7,0-7.9,3.972-7.9,8.555v.1c0,4.583,3.2,8.555,7.9,8.555a6.66,6.66,0,0,0,3.3-.512Z" transform="translate(-3 -3.4)" fill="#fa4616"/>
                            <path id="Path_2" data-name="Path 2" d="M40.3,27.4V11.7h8.4v13c0,2.6,1.4,4,3.4,4s3.5-1.4,3.5-4v-13H64v24H55.6V32.4a8.93,8.93,0,0,1-7.2,3.8C43.3,36.3,40.3,32.9,40.3,27.4Z" transform="translate(-3 -2.8)" fill="#fa4616"/>
                            <path id="Path_3" data-name="Path 3" d="M67.3,28.9h0c0-5.1,3.8-7.6,9.4-7.6a18.582,18.582,0,0,1,5.8.9v-.4c0-2.5-1.6-3.9-4.8-3.9a17.643,17.643,0,0,0-6.6,1.3l-1.7-5.9a24.217,24.217,0,0,1,9.7-1.9c4.3,0,7.2,1,9.1,2.9,1.8,1.8,2.6,4.3,2.6,7.6V35.7H82.5V33.2a9.066,9.066,0,0,1-7.1,3C70.9,36.3,67.3,33.6,67.3,28.9ZM82.6,27V25.9a7.788,7.788,0,0,0-3.3-.6c-2.4,0-3.9,1.2-3.9,3v.1c0,1.6,1.2,2.6,3,2.6a3.887,3.887,0,0,0,4.2-4Z" transform="translate(-3 -2.8)" fill="#fa4616"/>
                            <path id="Path_4" data-name="Path 4" d="M95.3,3.4h8.4v33H95.3Z" transform="translate(-3 -3.4)" fill="#fa4616"/>
                            <path id="Path_5" data-name="Path 5" d="M107.5,24.172h0c0-8.044,4.9-12.83,10.6-12.83a9.043,9.043,0,0,1,7.4,3.564V3.4h8.4V36.391h-8.4V33.133a8.717,8.717,0,0,1-7.3,3.767C112.4,36.9,107.5,32.012,107.5,24.172Zm18.1,0h0c0-3.258-2.2-5.6-4.9-5.6s-4.9,2.24-4.9,5.5v.1c0,3.258,2.2,5.6,4.9,5.6a5.314,5.314,0,0,0,4.9-5.6Z" transform="translate(-3 -3.4)" fill="#fa4616"/>
                            <path id="Path_6" data-name="Path 6" d="M137.4,23.8h0c0-6.9,5.6-12.6,13.3-12.6s13.2,5.6,13.2,12.5v.1c0,6.8-5.6,12.6-13.3,12.6C143,36.3,137.4,30.7,137.4,23.8Zm18.3,0h0a5.217,5.217,0,0,0-5.1-5.5c-3.1,0-5,2.4-5,5.3v.1a5.19,5.19,0,0,0,5.1,5.4C153.8,29.2,155.7,26.7,155.7,23.8Z" transform="translate(-3 -2.8)" fill="#fa4616"/>
                        </g>
                        <g id="Group_3" data-name="Group 3" transform="translate(162.4 3.3)">
                            <path id="Path_7" data-name="Path 7" d="M165,8.6h-1V4.3h-1.6v-1h4.2v.9H165Zm5.4-1.2h-.1l-1.6-2.5V8.6h-1V3.3h1.1l1.6,2.5L172,3.3h1V8.5h-1V4.9Z" transform="translate(-162.4 -3.3)" fill="#fa4616"/>
                        </g>
                        </g>
                  </svg>
                </div>
            </td>
            <td style="text-align:right;width:`+(percesize)+`px;">
                <h4 style="font-size: 28px;font-weight: 500;color:black;margin-bottom:5px;">Invoice</h4>
                <h5 style="font-size: 14px;font-weight: 500;color:#696969;">Invoice Number - `+pdfinfo.order_id+`</h5>
            </td>
        </tr>
       </table>
       <hr data-pdfmake="{&quot;color&quot;:&quot;gainsboro&quot;}">
       <table data-pdfmake="{noBorders&quot;,noPaddings&quot;}">
        <tr style="font-size: 14px;border: none;">
            <td style="text-align:left;width:`+(percesize)+`px;">
                <h5 style="font-size: 16px;font-weight: 600;color:black;">Invoiced To:</h5>
                <p style="line-height: 1.2;color:#696969;font-size: 14px;">
                    <strong>`+companyName+`</strong>
                    <br />`+addressLine1+ ' ' +addressLine2+`,
                    <br />`+city+', '+state+`, 
                    <br />`+zipCode+`
                    <br />`+country+`
                </p>
            </td>
            <td style="text-align:right;width:`+(percesize)+`px;">
                <h5 style="font-size: 16px;font-weight: 600;color:black;">Pay To:</h5>
                <p style="line-height: 1.2;color:#696969;font-size: 14px;">
                    <strong>Saturam Inc</strong>
                    <br />3260 Hillview Avenue,
                    <br />Palo Alto, CA 94304.
                    <br />contact@qualdo.ai
                </p>
            </td>
        </tr>
       </table>
       <table style="border: 1px solid Gainsboro;">
            <thead>
            <tr style="border: none;margin:10px 4px;color:#696969;font-size:14px;font-weight:600;">
                <th style="width:320px;background-color:#F5F5F5;">Description</th>
                <th style="width:70px;background-color:#F5F5F5;text-align:right;"></th>
                <th style="width:120px;background-color:#F5F5F5;text-align:right;">Cycle</th>
                <th style="width:120px;background-color:#F5F5F5;text-align:right;">Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr style="border: none;margin:15px 4px 5px;font-size:14px;font-weight:400;color:#696969;">
                <td style="border:none;">
                    <p style="margin: 0 0 0 4px;"><strong style="margin:0;font-weight:500;">`+row['Deployment Name']+`</strong> <br /> <span style="font-size:12px;margin-top:5px;">`+row['Cloud']+`  |  `+row['Edition']+`</span></p>
                </td>
                <td style="border:none;margin-top:30px;text-align:right;"></td>
                <td style="border:none;">
                    <p style="margin: 0 0 0 4px;">
                        <strong style="margin:0;font-weight:500;text-align:right;">
                          `+cycle+`
                        </strong> 

                        <br />
                        <span style="font-size:12px;margin-top:5px;">`+aordercreated[2]+` `+aordercreated[1]+` - `+anext_payment_date[2]+` `+anext_payment_date[1]+`, `+anext_payment_date[3]+`</span></p>
                    </td>
                </td>
                <td style="border:none;margin-top:30px;text-align:right;">`+pdfinfo.currency_symbol+pdfinfo.amount+`</td>
            </tr>
            <tr style="border: none;margin:10px 4px 5px;font-size:14px;font-weight:400;color:#696969;">
                <td style="border:none;border-top: 1px solid #F5F5F5;">
                   <p style="margin:0;font-size:12px;">`+status +coupon+`</p>
                </td>
                <td colspan="3" style="border:none;border-top: 1px solid #F5F5F5;margin-top:15px;text-align:right;">`+couponPrice+`</td>
            </tr>

            <!--<tr style="border: none;margin:5px 4px 0px;font-size:14px;font-weight:400;color:#696969;">
                <td style="border:none;border-bottom: 1px solid Gainsboro;">
                    <strong style="margin:0;font-weight:500;">Data Limit</strong>
                    <p style="margin-left:4px;">100GB Data Upgrade</p>
                </td>
                <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">$52.00</td>
                <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">1</td>
                <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">$52.00</td>
            </tr>
            <tr style="border: none;margin:5px 4px 0px;font-size:14px;font-weight:400;color:#696969;">
                <td style="border:none;border-bottom: 1px solid Gainsboro;">
                    <strong style="margin:0;font-weight:500;">Data Limit
                    <span style="background-color:Gray;color:white;font-size:12px;font-weight:500;">
                        <span style="line-height:1.4;margin:10px"> Last Month Pending </span>
                    </span></strong>
                    <p style="margin-left:4px;">100GB Data Upgrade</p>
                </td>
                <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">$52.00</td>
                <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">1</td>
                <td style="border:none;border-bottom: 1px solid Gainsboro;margin-top:15px;text-align:right;">$52.00</td>
            </tr>-->
            <!--<tr style="border: none;border-bottom: 1px solid Gainsboro;margin:10px 4px 10px;font-size:14px;color:#696969;">
                <td colspan="3" style="background-color:#F5F5F5;">
                    <strong style="text-align:right;font-weight:600;">Sub Total:</strong>
                </td>
                <td style="background-color:#F5F5F5;text-align:right;font-weight:500;">
                  <span>`+pdfinfo.currency_symbol+pdfinfo.amount+`</span>
                </td>
            </tr>-->
            <tr style="border: none;border-top: 1px solid #EBEBEB;margin:10px 4px 10px;font-size:14px;color:#696969;">
                <td colspan="3" style="background-color:#F5F5F5;">
                    <strong style="text-align:right;font-weight:600;">Discount:</strong>
                </td>
                <td style="background-color:#F5F5F5;text-align:right;font-weight:500;">
                    <span>-`+couponPrice+`</span>
                </td>
            </tr>
            <tr style="border: none;border-top: 1px solid #EBEBEB;border-bottom: 1px solid #EBEBEB;margin:10px 4px 10px;font-size:14px;color:#696969;">
                <td colspan="3" style="background-color:#F5F5F5;">
                    <strong style="text-align:right;font-weight:600;">Total:</strong>
                </td>
                <td style="background-color:#F5F5F5;text-align:right;font-weight:500;">
                    <span>`+pdfinfo.currency_symbol+pdfinfo.amount+`</span>
                </td>
            </tr>
            </tbody>
        </table>
        <div>
            <h5 style="font-size: 16px;font-weight: 600;color:black;margin: 30px 0 0;">Payment Date:</h5>
            <!--<p style="color:#696969;font-size: 14px;margin-bottom:20px;">June 14, 2021</p>-->
            <p style="color:#696969;font-size: 14px;margin-bottom:20px;">`+aordercreated[2]+` `+aordercreated[1]+`, `+aordercreated[3]+`</p>
        </div>
        <div>
            <h5 style="font-size: 16px;font-weight: 600;color:black;margin: 0;">Note:</h5>
            <p style="color:#696969;font-size: 14px;margin-bottom:50px;">All amount in United States Dollars (USD)</p>
        </div>
        <hr data-pdfmake="{&quot;color&quot;:&quot;Gainsboro&quot;}">
        <div style="text-align:center">
            <p style="line-height:1.5;color:#696969;font-size: 14px;margin-top:30px;">
                <strong style="font-weight:600;color:black;">Qualdo, a product of Saturam Inc</strong>
                <br />355 Bryant Street, Unit 403, San Francisco, CA 94107   |   +1 650-308-4857 
            </p>
        </div>
    </div>
`, {window:window, tableAutoSize:true});
    const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
   
    pdfMake.createPdf(documentDefinition).open();
}











  render() {
    let tableData = this.state.billingHistory
    if (this.state.upcomingPaymentData !== null && this.state.upcomingPaymentData !== undefined) {
      tableData = this.state.billingHistory
    }
    return (
      <>
        <div className="qd-section_content  shadow-sm settings-paymentprofile">
          <div className="qd-section-header">
            <h4 className="qd-section-header-title"><FontAwesomeIcon icon={faChalkboardTeacher} /> Billing History</h4>
          </div>
          <div className="qd-section-body">
            <div className="row row-sm">
              <div className="col-md-12">
                <div className="portlet">
                  <div className="portlet-title">
                    <div className="caption">
                      <div className="text-uppercase font-dark">
                        Billing History
                      </div>
                    </div>
                    {(tableData !== null && tableData !== undefined) ?
                  <QualdoDataTable
                    data={tableData}
                    showToast={this.showToast}
                    customGetActionComponent={this.deploymentTableComponent}
                    pdfdownload = {this.pdfdownload}
                    environment=" "
                    component_name="datasource"
                    updateTable=" "
                  /> : ''}
                  </div>
         
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="toastMsg">
          <Toast className={this.state.toastClass}
            onClose={() => this.closeToast()}
            show={this.state.toastStatus}
            delay={3000} autohide>
            <div className="d-flex align-items-center">

              {this.state.toastStatus ?
                <i className="toast-icon">
                  <FontAwesomeIcon icon={this.state.toastIcon} />
                </i>
                :
                ''
              }

              <Toast.Body>
                <strong className="toast-title">{this.state.toastheading}</strong>
                <p>{this.state.toastMsg}</p>
              </Toast.Body>
              <button type="button"
                onClick={this.closeToast}
                className="close"
                data-dismiss="toast"
                aria-label="Close" />
            </div>
          </Toast>
        </div>

      </>
    );
  }

}
export default BillingHistory;