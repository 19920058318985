import React from "react";
import Card from "react-bootstrap/Card";
import {QualdoPlanCycle} from "../../utils/constant";


class BillingCycle extends React.Component {

    render() {
        return (
            <Card>
                <Card.Body>
                    <div className="billing-period-selector">
                        <div className="billing-period-selector__radio">
                            <div className="custom-control custom-radio flex-column mb-sm-2">

                                <input type="radio"
                                       id="customRadio1"
                                       name="customRadio"
                                    //    disabled={disableButton}
                                       className="custom-control-input"
                                       defaultChecked={this.props.cycle === QualdoPlanCycle.YEARLY}
                                       onClick={(e) => this.props.radioHandler(QualdoPlanCycle.YEARLY)}
                                />

                                <label className="custom-control-label mr-1" htmlFor="customRadio1">Annual </label>

                                {
                                    this.props.savedPercentage === null || isNaN(this.props.savedPercentage) ?
                                        '' :
                                        <small className="text-success">
                                            {`You’re saving ${this.props.savedPercentage}%!`}
                                        </small>
                                }

                            </div>

                            <div className="custom-control custom-radio">
                                <input type="radio"
                                       id="customRadio2"
                                       name="customRadio"
                                    //    disabled={disableButton}
                                       defaultChecked={this.props.cycle === QualdoPlanCycle.MONTHLY}
                                       onClick={(e) => this.props.radioHandler(QualdoPlanCycle.MONTHLY)}
                                       className="custom-control-input"/>
                                <label className="custom-control-label" htmlFor="customRadio2">
                                    Monthly
                                </label>
                            </div>

                        </div>

                        <div className="billing-period-selector__price">
                            <h4>
                                {/* {
                                    this.props.absoluteAmount === null ?
                                        '' :
                                        <small>
                                            <s className="text-danger">
                                                {`${this.props.currencySymbol}${this.props.absoluteAmount}`}
                                            </s>
                                        </small>
                                } */}
                                {this.props.currencySymbol}{this.props.totalAmount}
                            </h4>
                            <small>
                                Total {this.props.cycle === QualdoPlanCycle.YEARLY
                                ?
                                'annual'
                                :
                                QualdoPlanCycle.MONTHLY
                            } Bill
                            </small>
                        </div>


                    </div>
                    <hr/>
                    <div className="renew-subscription-info">
                        <h6>
                            Automatically renew my subscription
                        </h6>

                        <div className="custom-control custom-switch custom-switch-sm orange-switch ml-2">
                            <input id="paymentPageAutoDebit"
                                   className="custom-control-input"
                                   disabled={this.props.paymentPageLoading}
                                   checked={this.props.autoDebitSelection}
                                   onChange={this.props.setAutoDebitStatus}
                                   type="checkbox"/>
                            <label className="custom-control-label"
                                   htmlFor={"paymentPageAutoDebit"}/>
                        </div>
                    </div>

                </Card.Body>
            </Card>
        );
    }
}

export default BillingCycle;
