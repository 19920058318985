import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import $ from "jquery";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
import Select from "react-select";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTable} from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight} from '@fortawesome/free-regular-svg-icons';
import {
    NO_DATA_SET_FOUND_TEXT, ROW_WARN_CLASS, EXCLUDED_COMPONENTS,
    NOT_SORTABLE, ABOVE_ALERT_SUPPORTED_TABLES, LIST_VIEW_OPTIONS,SUBSCRIPTION_DOC
} from "../utils/constant";
import {get_document_link} from "../utils/common_utils";



class QualdoDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
        this.paginationSize = this.props.paginationSize;
        this.onPageChange = this.onPageChange.bind(this);
        this.component_name = this.props.component_name;
        this.handleSearchOperation = null;
        this.handleDeleteOperation = this.handleDeleteOperation.bind(this);
        this.handleSeeMore = this.handleSeeMore.bind(this);
        this.input = null;
        this.state = {
            hideSizePerPage: this.props.hideSizePerPage !== undefined ? this.props.hideSizePerPage : false,
            paginationSize: this.props.paginationSize,
            tableData: this.props.data,
            isDelete: false,
            tempData: this.props.data,
            selectedGroup: this.props.selectedGroup ? this.props.selectedGroup : LIST_VIEW_OPTIONS[0],
            isSeeMore:this.props.isSeeMore !== undefined ? this.props.isSeeMore : false,
            isSeeMoreId:Math.random().toString(36).replace('0.','btn_seemore_' || '')
        }
    }

    componentDidMount() {
        if(this.state.isSeeMore===true){
            this.handleSeeMore();
        }
    }

    onPageChange() {

        if(ABOVE_ALERT_SUPPORTED_TABLES.includes(this.props.component_name)){
       $('tr.alert-warning-row').remove();
       setTimeout(function(){

        var prevClassFlag = 1;
        $('.table tbody tr').each(function() {

            if($(this).hasClass("row-highlight has-above-alert") && prevClassFlag === 1) {
                $(this).before('<tr class="alert-warning-row"><td colspan="6" class="p-0"><div class="alert alert-warning mb-0 py-sm-1 mt-2" role="alert"><p class="mb-0"><strong>No Refreshes found in the chosen date range. Displaying the data points of earlier refreshes.</strong></p></div></td></tr>');
            }

            if($(this).hasClass("alert-warning-row")){
                prevClassFlag = 0;
            }else {
                prevClassFlag = 1;
            }

        });

        }, 500);

     }

    }

    getActionComponent(item) {
        switch (this.props.component_name) {
            case 'datasource':
                return (
                    <div/>
                );

            default:
                return (
                    <div/>
                );
        }

    }

    getButtonComponent(item) {
        switch (this.props.component_name) {
            case 'requests':
                return (
                    <div/>
                );
            default:
                return (
                    <div/>
                );
        }
    }

    async navigateFirstPage(props) {
        return props.onPageChange(1);
    }

    async handlePageChange(value) {
        this.setState({paginationSize: value});
        return;
    }

    async clearSearch(props) {
        this.setState({isDelete: false});
        return;
    }

    renderRow() {
        let result = []
        let headerKeys = {}
        this.state.tableData.headers.forEach((headerValue) => {
            headerKeys[headerValue] = null;
        });
        let fullData = this.state.tableData.data;
        fullData = _.cloneDeep(fullData)
        for (let i = 0; i < fullData.length; i++) {
            let rowItems = fullData[i];
            let obj = _.cloneDeep(headerKeys);
            let keys = Object.keys(obj);
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (items.type === 'td') {
                    obj[keys[k]] = items.value
                } else if (items.type === "buttons") {
                    obj[keys[k]] = items;
                }
                else {
                    obj[keys[k]] = items;
                }
            }
            obj["ID"] = i + 1;
            result.push(obj);
        }
        return result
    }


    getDefaultPaginationOptions() {
        let pageLimit = [10,25,50,100]
        let pageList = [{text: 5, value: 5}]
        for(let i=0;i<=(pageLimit.length-1);i++) {
           if(this.state.tableData.data.length >= pageLimit[i]){
              pageList.push({text: pageLimit[i], value: pageLimit[i]});
           }else{
              break
           }

        }
        pageList.push({text: "All" , value: this.state.tableData.data.length});

        const options = {
            custom: true,
            totalSize: this.state.tableData.data.length,
            page: 1,  // which page you want to show as default
            sizePerPageList: pageList, // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };
        return options;
    }



    getCustomPaginationOptions(pageLimitCustom) {
        let pageLimit = this.props.pageOptions;
        if (pageLimit === undefined || pageLimit === null) {
            pageLimit = pageLimitCustom;
        }

        let sizePerPage = this.props.paginationSize;

        // Add the first option
        let pageList = [];

        for (let i = 0; i <= (pageLimit.length - 1); i++) {
            const currentValue = pageLimit[i];

            if (this.state.tableData.data.length >= currentValue) {
                pageList.push({text: currentValue, value: currentValue});
            } else {
                break;
            }

        }

        // Add the 'All' option
        pageList.push({text: "All" , value: this.state.tableData.data.length});

        const options = {
            custom: true,
            onPageChange: this.onPageChange,
            page: 1,  // which page you want to show as default
            totalSize: this.state.tableData.data.length,
            sizePerPageList: pageList, // you can change the dropdown list for size per page
            sizePerPage: sizePerPage,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationPosition: 'bottom'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };
        return options;
    }

    convertData(actionType, headerValue, cell, row) {
        if (headerValue === "Pdf") {
            return <label className="btn btn-link" onClick={() => this.props.pdfdownload(row)}>Receipt</label>
            // return <button onClick={() => this.props.pdfdownload(row)} type="button" class="btn btn-link">Download</button>
 
         }

        // If getActionComponent is provided in the props we can use that method itself
        if (this.props.customGetActionComponent !== undefined && this.props.customGetActionComponent !== null) {
            return this.props.customGetActionComponent(actionType, headerValue, cell, this.props,row);
        }
       
        return cell;
    }

     static getDerivedStateFromProps(props, state) {
        if (props.data !== state.tableData) {
            return {tableData : props.data};
        } else if (props.paginationSize !== state.paginationSize && !EXCLUDED_COMPONENTS.includes(props.component_name)){
            return {paginationSize: props.paginationSize}
        }

        return null;
     }



    componentDidUpdate(prevProps) {
        if (prevProps.data !== undefined && this.props.data !== undefined) {
            if (prevProps.data.data.length !== this.props.data.data.length) {
                this.setState({tableData: this.props.data, tempData: this.props.data, selectedGroup: this.props.selectedGroup});
                this.setState({paginationSize: this.props.paginationSize});
            }
        }
        if(prevProps.selectedGroup !== this.props.selectedGroup){
          this.setState({selectedGroup: this.props.selectedGroup})
        }

    }

    handleDeleteOperation() {
        this.setState({isDelete: this.props.data});
    }

    handleSeeMore() {
            var curobj = $("#"+this.state.isSeeMoreId);
            curobj.parent().parent().find('.react-bootstrap-table__footer').toggleClass("d-none");
            curobj.parent().parent().find('table tbody tr').toggleClass("d-none");
            curobj.parent().parent().find('table tbody tr:first').removeClass("d-none");
            curobj.parent().parent().find('.react-table__length').toggleClass("d-none");
            curobj.parent().parent().find('.react-table__filter').toggleClass("d-none");
            $("#"+this.state.isSeeMoreId+" span").text(function(i, v){
                return v === 'See Less' ? 'See More' : 'See Less'
            })
            curobj.toggleClass("active");
    }

    render() {
        const hasData = this.state.tableData.data.length > 0;
        const customTotal = (from, to, size) => (
            <span className="react-table__info">
                    Showing <b>{from}</b> to <b>{to}</b> of <b>{size}</b> results
            </span>
        );

        const addClassFailure = (row, rowIndex) => {
            if (row === undefined || row === null) {
                return "";
            }

            if (row.Actions === undefined || row.Actions === null) {
                return "";
            }

            let integration_data = row.Actions;
            if (integration_data === undefined || integration_data === null) {
                return "";
            }

            if (integration_data.integration_data === undefined || integration_data.integration_data === null){
                return "";
            }

            let connection_status = integration_data.integration_data["status_text"];
            if (connection_status === "connection_failure") {
                return "has-table-error";
            }

            if (connection_status === NO_DATA_SET_FOUND_TEXT) {
                return ROW_WARN_CLASS;
            }

            return "";
        };

        const pageListRenderer = ({
                                      pages,
                                      onPageChange
                                  }) => {
            return (
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        {
                            pages.map(p => (
                                <button className="btn btn-success"
                                        onClick={() => onPageChange(p.page)}>
                                    {p.page}
                                </button>
                            ))
                        }
                    </div>
                </div>
            );
        };

        let options;
        let lengthLimit = 0;
        if (this.state.paginationSize === undefined || this.state.paginationSize === null ) {
            options = this.getDefaultPaginationOptions();
            lengthLimit = 5;
        }
        else {

            if (this.props.pageOptions !== undefined) {
                options = this.getCustomPaginationOptions(this.props.pageOptions);
                lengthLimit = this.state.paginationSize;
            }else {
                options = this.getCustomPaginationOptions([3,6,10,25,50,100]);
                lengthLimit = this.state.paginationSize;
            }

        }

        options["paginationTotalRenderer"] = customTotal;
        options["pageListRenderer"] = pageListRenderer;
        options["hideSizePerPage"] = this.state.hideSizePerPage;

        let data = this.renderRow();

        const sizePerPageRenderer = ({
                                         options,
                                         currSizePerPage,
                                         onSizePerPageChange
                                     }) => (
            <div className="react-bootstrap-table__header">
                <div className="row">
                    <div className="col-6 col-md-6">
                        <div className="d-flex">
                            <div className="btn-group react-table__length" role="group">
                                <span>Show</span>
                                <Select
                                    classNamePrefix='select-control'
                                    defaultValue={lengthLimit > this.state.tableData.data.length ? {"label": "All","value":this.state.tableData.data.length} : {"label":lengthLimit,"value":lengthLimit}}
                                    options={options.map((option) => ({'label': option.text, 'value': option.page}))}
                                    onChange={(selectedOption) => {
                                        this.handlePageChange(selectedOption.value).then((res) => {
                                           onSizePerPageChange(selectedOption.value);
                                        });
                                    }}
                                />
                                <span>items</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6">
                        <div className="btn-group react-table__filter" role="group">
                            <span>Search:</span>
                            <div className="table-search">
                                <input
                                    placeholder={"Search"}
                                    id={"searchBox"}
                                    className="form-control"
                                    ref={n => this.input = n}
                                    onChange={this.handleSearchOperation}
                                    style={{backgroundColor: 'white', align: 'right'}}
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        options["sizePerPageRenderer"] = sizePerPageRenderer.bind(this);


        let actionType = null;
        if (this.state.tableData.data != null && this.state.tableData.data.length > 0) {
            let rowItems = this.state.tableData.data[0];
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (items.type === "buttons") {
                    actionType = "button";
                } else if (items.type === "component") {
                    actionType = "component";
                } else if (items.type === "image") {
                    actionType = "image";
                } else if (items.type === "billingComponent") {
                    actionType = "billingComponent";
                }
                else if (items.type === "billingComponentLink") {
                    actionType = "billingComponentLink";
                }
            }
        }

        const addClassForColumns = (cell, row, rowIndex, colIndex) => {
            return "";
        }

        const rowClasses = (row, rowIndex) => {

            return this.props.customRowHighlighter === undefined ?
            addClassFailure(row, rowIndex) : this.props.customRowHighlighter(row, rowIndex);
        };

        const classFuncForCols = this.props.getCustomClassForColumns === undefined ?
            addClassForColumns :
            this.props.getCustomClassForColumns;

        let is_table_not_sortable = (this.state.tableData.not_sortable !== undefined &&
            this.state.tableData.not_sortable === true);

        let columns = this.state.tableData.headers.map(x => ({
            'dataField': x,
            'text': x,
            'sort': (NOT_SORTABLE.includes(x.toLowerCase()) || is_table_not_sortable === true) ? false : true,
            'searchable': true,
            'formatter': this.convertData.bind(this, actionType, x),
            'classes': classFuncForCols
        }));

        switch (this.props.component_name) {
            case "modelABTestTable":
                columns = [{
                    'dataField': "ID",
                    'text': "ID",
                    'sort': false,
                    'classes': classFuncForCols,
                    'formatter': this.props.tableIDFormatter
                }, ...columns];
                break;
            default:
                columns = [{
                    'dataField': "ID",
                    'text': "ID",
                    'sort': true,
                    'classes': classFuncForCols
                }, ...columns];
                break;
        }

        const afterSearch = (newResult) => {
            $('tr.alert-warning-row').remove();
        };

        let CustomSearchBar = (props) => {
            if (this.state.isDelete) {
                this.clearSearch(props).then((res) => {
                    props.onClear();
                    if (this.input !== null && this.input !== undefined) {
                        this.input.value = "";
                    }
                });
            }
            const handleClick = () => {
                this.navigateFirstPage(props).then((res) => {
                    props.onSearch(this.input.value)
                });
            };
            this.handleSearchOperation = handleClick.bind(this);
            return (""
                // <div>
                //     <label htmlFor={"searchBox"}>Search: </label>
                //     <input
                //         placeholder={"Search"}
                //         id={"searchBox"}
                //         className="form-control"
                //         style={{backgroundColor: 'white', align: 'right'}}
                //         ref={n => input = n}
                //         onChange={handleClick}
                //         type="text"
                //     />
                // </div>
            );
        };

        let BindedCustomSearchBar = CustomSearchBar.bind(this);

        const contentTable = ({paginationProps, paginationTableProps}) => (
            <>
                {this.props.component_name === "dqErrorListViewTable" &&  data.length < 5 ? <div className="react-bootstrap-table__header">
                <div className="row">
                    <div className="col-6">
                        <div className="d-flex">
                        {this.props.component_name === "dqErrorListViewTable" ? <div className="row g-1 align-items-center ml-4 lv_tbl-filter">
                                                      <div className="col-auto  p-0">
                                                        <label className="col-form-label">Show: </label>
                                                      </div>
                                                      <div className="col-auto" style={{minWidth: "225px"}}>
                                                           <Select
                                                                classNamePrefix='select-control'
                                                                value={this.state.selectedGroup}
                                                                options={LIST_VIEW_OPTIONS}
                                                                isOptionDisabled={(option) => option.disabled}
                                                                onChange={this.props.changeGroup}
                                                           />
                                                       </div>
                                            </div> : ""
                                            }
                        </div>
                    </div>
                </div>
            </div> : ""}
                {
                    hasData && (this.state.tableData.data.length > lengthLimit )
                        ?
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        :
                        ''
                }

                {hasData ? <><ToolkitProvider
                    keyField="ID"
                    columns={columns}
                    data={data}
                    search={ { afterSearch } }
                >
                    {
                        toolkitprops => {
                            let classNameForTable = "";
                            if (this.props.classNameForTable !== undefined) {
                                classNameForTable = this.props.classNameForTable;
                            }

                            return (
                                <>
                                    {
                                        hasData
                                            ?
                                            <>
                                            <BindedCustomSearchBar {...toolkitprops.searchProps}
                                                                   {...paginationProps}/>
                                            </>
                                            :
                                            ''
                                    }


                                    <BootstrapTable
                                        bootstrap4
                                        bordered={false}
                                        responsive
                                        // striped
                                        // hover
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        wrapperClasses={"table-responsive action-sticky-cell"}
                                        classes={classNameForTable}
                                        tableHeaderClass="qd-table mb-0"
                                        tableBodyClass="qd-table mb-0"
                                        className="action-sticky-cell"
                                        rowClasses={rowClasses}

                                    />
                                </>
                            );
                        }
                    }
                </ToolkitProvider></> : ""}
                <div className="react-bootstrap-table__footer">
                    <div className="row">
                        {
                            hasData ? <div className="col-sm-12 col-md-6"> <PaginationTotalStandalone {...paginationProps} /> </div>
                                :
                                ''
                        }
                        <div className="col-sm-12 col-md-6">
                            {hasData && this.state.tableData.data.length > lengthLimit ?  <PaginationListStandalone {...paginationProps} /> : ''}
                        </div>
                    </div>
                </div>
                {
                     this.state.isSeeMore === true ?
                    <div  className="table__seemore">
                                <button id={this.state.isSeeMoreId} className="btn active" onClick={this.handleSeeMore} ><span>See Less</span>
                                <i>
                                            <FontAwesomeIcon icon={faArrowAltCircleRight}/>
                                </i>
                                 </button>
                    </div>
                    :
                    ''
                    }
            </>
        );

        if (hasData) {
            return (
                <>
                    <PaginationProvider
                        pagination={
                            paginationFactory(options)
                        }
                    >
                        {contentTable}
                    </PaginationProvider>
                </>
            );

        }

        else if(this.props.component_name === "dashboard"){
            return (<div className="text-center text-muted py-5">
                                <h1><i><FontAwesomeIcon icon={faTable}/></i></h1>
                                <h4>No data available for the deployment</h4>
                            </div>
                    );
        }

         else {
            return (<div className="text-center text-muted py-5">
                                <h1><i><FontAwesomeIcon icon={faTable}/></i></h1>
                                <h4>No data available</h4>
                                <p>For help, check out the <a href={get_document_link('index',SUBSCRIPTION_DOC)} rel="noopener noreferrer" target="_blank">Help Document</a></p>
                            </div>
                    );
        }
    }
}

export default QualdoDataTable;