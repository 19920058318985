import React from 'react';
import Image from "../components/image";
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Account from './components/account';
import ChangePassword from './components/changePassword';
import PaymentProfile from './components/paymentprofile';
import UsageAndPayment from './components/usagepayment';
import BillingHistory from './components/billinghistory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,
    faKey,
    faPaperPlane,
    faReceipt,
} from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
import {getApi} from "../utils/User_handling";
import ErrorHandler from '../components/error_500';
import {CopyRightsFooter} from '../components/copyrights';
import {postUIlogs} from "../utils/event_handling";
import {IS_NONE_CHECK } from "../utils/constant";
import Toast from "react-bootstrap/Toast";
import { toastObj } from "../utils/common_utils";
import { faAddressCard } from '@fortawesome/fontawesome-free-regular';


class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeWarningMessage = this.closeWarningMessage.bind(this);

        let navKey = "account";
        if ( this.props.route !== undefined && this.props.route.navKey !== null && this.props.route.navKey  !== undefined ) {
            navKey = this.props.route.navKey;
        } else if ( this.props.navKey !== null && this.props.navKey !== undefined ) {
            navKey = this.props.navKey;
        }
        this.state={
            toastStatus: false,
            reRenderNavComponent: false,
            errorOccurred: false,
            navKey: navKey,
            routeSelection: navKey,
            sideBarSelected: false,
            first_name: null,
            last_name: null,
            org_name: null,
            user_profile_image: null,
            email: null,
            phoneNo: null,
            edition_details: {},
            next_billing_date: null,
            change_request: {},
            planDetails: {
                size: null,
                showInformationBanner: false,
                mlModelCount: null,
                planName : null,
                planNameKey: null,
                planBenefits:[],
                monthlyLimit: [],
                cost: null,
                maxDataLimit:null,
                maxMlModelLimit:null,
                comments: [],
                modifiedTime: null,
                requiresDataLimitUpgrade: false,
            }
        }
        this.getData();
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }


    static getDerivedStateFromProps(props, state) {
        if (IS_NONE_CHECK.includes(props) || IS_NONE_CHECK.includes(state)) {
            return null;
        }

        let navKey = "account";
        if (props.route !== undefined && props.route.navKey !== null && props.route.navKey !== undefined) {
            navKey = props.route.navKey;
        } else if (props.navKey !== null && props.navKey !== undefined) {
            navKey = props.navKey;
        }

        // Props and state route will be compared to identify is selection is made/changed from Right-Top
        // Profile drop down menu
        if (navKey !== state.routeSelection) {
            return {
                navKey: navKey,
                routeSelection: navKey
            };
        }

        return null;
    }



    getData() {
        this.getApi("user_details");
        // this.getApi("edition_details");
        // this.getApi("usage");
        // this.getApi("planChangeRequest");
    }

    closeWarningMessage() {
        localStorage.removeItem("warning_message");
        const reRenderNavComponent = this.state.reRenderNavComponent;
        this.setState({
            reRenderNavComponent: !reRenderNavComponent,
        });
    }

    navSelect(selectedKey) {
        // Handles left side bar navigation selections
        this.setState({navKey: selectedKey});
    }

    closeToast() {
        this.setState({toastStatus: false});
    }

    showToast(status, msg, link) {
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"]
        });

        if (link.trim().length > 0) {
            setTimeout(function () {
                window.location.href = link;
            }, 3000);
        }

    }

    renderNavContent() {

        switch(this.state.navKey) {
            case "account":
                return <Account navKey = {this.state.navKey}/>;
            case "changePassword":
                return <ChangePassword navKey = {this.state.navKey}/>;
            case "paymentprofile":
                return <PaymentProfile navKey = {this.state.navKey}/>;
            case "usagepayment":
                return <UsageAndPayment navKey = {this.state.navKey}/>;
            case "billinghistory":
                return <BillingHistory navKey = {this.state.navKey}/>;
            default:
                break;
        }
    }

    render() {
        let sessionToken = localStorage.getItem("sessionToken");
        if (sessionToken === "undefined" || sessionToken === "null" || sessionToken === null || sessionToken === undefined) {
            window.location = '/';
            return;
        }
        let status = localStorage.getItem('deploymentStatus')
        return (
            this.state.errorOccurred ?
                <ErrorHandler/>
            :
                <>
                    <div id="settingsPageToastMsg">
                        <Toast className={this.state.toastClass}
                               onClose={() => this.closeToast()}
                               show={this.state.toastStatus}
                               delay={3000} autohide>

                            <div className="d-flex align-items-center">
                                {this.state.toastStatus ?
                                    <i className="toast-icon">
                                        <FontAwesomeIcon icon={this.state.toastIcon}/>
                                    </i>
                                    :
                                    ''
                                }

                                <Toast.Body>
                                    <strong className="toast-title">{this.state.toastHeading}</strong>
                                    <p>{this.state.toastMsg}</p>
                                </Toast.Body>
                                <button type="button"
                                        onClick = {this.closeToast}
                                        className="close"
                                        data-dismiss="toast"
                                        aria-label="Close"/>
                            </div>
                        </Toast>
                    </div>

                <NavigationHeader {...this.props} key={this.state.reRenderNavComponent}/>
                <main>
                    <BreadCrumb icon='userCog' title='Settings' />
                    <div className="qd-container">
                        <div className="qd-grid">
                            <div className="qd-body no-tabs qd-grid shadow-none bg-transparent">
                                <div id="qd-aside" className="qd-aside qd-settings shadow-sm qd-grid-item">
                                    <div className="profile-info d-flex align-items-center">
                                         <div className="profile-avatar-block align-self-start">
                                              <Image src="accountProfile"/>
                                         </div>
                                         <div className="w-100">
                                              <h5 className="avatar-name">{this.state.first_name} {this.state.last_name}</h5>
                                              <div className="text-muted">{this.state.org_name}</div>
                                         </div>
                                    </div>
                                    <div className="py-4">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                             <span className="mr-2">Email:</span>
                                                  <a href="#link" className="text-muted text-hover-primary">
                                                      {this.state.email}
                                                  </a>
                                        </div>
                                        {
                                           this.state.phoneNo !== null ?
                                                <div className="d-flex align-items-center justify-content-between mb-2">
                                                     <span className="mr-2">Phone:</span>
                                                     <span className="text-muted">
                                                         {this.state.phoneNo}
                                                     </span>
                                                </div>
                                           : ''
                                        }
                                   </div>
                                   <div className="qd-settings-nav">
                                    <Nav onSelect={(selectedKey) => this.navSelect(selectedKey)}>
                                        <div className={this.state.navKey === "account" ? "qd-sn-item active":"qd-sn-item"}>
                                                 <Nav.Link  className="qd-sn-nav-link" eventKey="account">
                                                 <span className="nav-icon"><FontAwesomeIcon icon={faUser}/></span>
                                                 <span className="nav-text">Profile Settings</span>
                                                 </Nav.Link>
                                        </div>
                                        <div className={this.state.navKey === "changePassword" ? "qd-sn-item active":"qd-sn-item"}>
                                                 <Nav.Link className="qd-sn-nav-link" eventKey="changePassword">
                                                 <span className="nav-icon"><FontAwesomeIcon icon={faKey}/></span>
                                                 <span className="nav-text">Change Password</span>
                                                 </Nav.Link>
                                        </div>
                                        
                                        {
                                             ((window.location.pathname !== '/deployment' && status ==='deployed') || localStorage.getItem('secondDeployment') === 'true') ?
                                                <>
                                                <div className={this.state.navKey === "paymentprofile" ? "qd-sn-item active":"qd-sn-item"}>
                                                 <Nav.Link className="qd-sn-nav-link" eventKey="paymentprofile">
                                                 <span className="nav-icon"><FontAwesomeIcon icon={faAddressCard}/></span>
                                                 <span className="nav-text">Payment Profile</span>
                                                 </Nav.Link>
                                                </div>
                                                <div className={this.state.navKey === "usagepayment" ? "qd-sn-item active":"qd-sn-item"}>
                                                         <Nav.Link className="qd-sn-nav-link" eventKey="usagepayment">
                                                         <span className="nav-icon"><FontAwesomeIcon icon={faPaperPlane}/></span>
                                                         <span className="nav-text">Upcoming Payment</span>
                                                         </Nav.Link>
                                                </div>
                                                <div className={this.state.navKey === "billinghistory" ? "qd-sn-item active":"qd-sn-item"}>
                                                         <Nav.Link className="qd-sn-nav-link" eventKey="billinghistory">
                                                         <span className="nav-icon"><FontAwesomeIcon icon={faReceipt}/></span>
                                                         <span className="nav-text"> Billing History</span>
                                                         </Nav.Link>
                                                </div>
                                                </>
                                            : ''
                                        }
                                    </Nav>
                                </div>
                            </div>
                            <div className="qd-aside-overlay"/>
                            <button className="close remove-slide" type="button"/>
                            <div id="qd-section__section" 
                            key = {this.state.navKey}
                            className="qd-section qd-grid-item ml-4">
                                 {this.renderNavContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <CopyRightsFooter/>
            </>
        );
    }
}

export default Settings;