import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

class PaymentCardsList extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.showEditCardsSection = this.showEditCardsSection.bind(this);
        this.makePrimaryCard = this.makePrimaryCard.bind(this);
    }

    showEditCardsSection(cardDetail) {
        this.props.showEditSection(cardDetail);
    }

    makePrimaryCard(cardDetail) {
        this.props.makeCardAsPrimary(cardDetail);
    }

    render() {
        if (this.props.cards === null || this.props.cards.length === 0){
            return '';
        }

        return (<div className="portlet">
            <div className="portlet-title">
                <div className="caption float-none pb-1">
                    <span className="text-info">Saved Cards</span>
                    <p>
                          Please add your credit card details here to subscribe
                          </p>
                    {/* <p>You can add your credit card for the feature purchase and
                        automatically renew.</p> */}
                </div>
            </div>
            <div className="portlet-body py-4">
                {this.props.cards.map(cardDetail =>
                    <div key={cardDetail.id}
                         className={`payment-savedCard ${cardDetail.isPrimary ? 'primary-payment' : ''}`}>
                        <Row>
                            <Col className="payCard-details">
                                <h4 className="card-title">Credit or Debit card:</h4>
                                <div className="payCard-content">
                                    <i><FontAwesomeIcon icon={faCreditCard}/></i>
                                    <div className="payCard-info">
                                        {/* <h5>{cardDetail.brand}</h5> */}
                                        {/* <p><span>....</span> <span>....</span>{" "}
                                            <span>....</span> {cardDetail.lastFourDigits} (Expires
                                            {` ${cardDetail.expires}`})</p> */}
                                            <p><span><small>....</small> <small>....</small>{" "}
                                            <small>....</small> {cardDetail.lastFourDigits}</span> <span>(Expires
                                            {` ${cardDetail.expires}`})</span></p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="payCard-action">
                                {
                                    cardDetail.isPrimary
                                        ?
                                        <label className="text-success mr-4">Primary</label>
                                        :
                                        ''
                                }
                                {
                                    cardDetail.isPrimary ?
                                        ''
                                        :
                                        <Button
                                            variant="light"
                                            className="mr-2"
                                            onClick={this.makePrimaryCard.bind(this, cardDetail)}
                                            type="submit">
                                            Make Primary
                                        </Button>
                                }

                                <Button
                                    variant="light"
                                    onClick={this.showEditCardsSection.bind(this, cardDetail)}
                                    type="submit">
                                    Edit
                                </Button>
                            </Col>
                        </Row>

                    </div>)}

                <button type="button"
                        className="btn btn-outline-primary btn-circle"
                        data-toggle="modal"
                        onClick={this.props.showAddCardSection}
                        data-target="#modal__payment-method">
                    <i>
                        <FontAwesomeIcon icon={faCreditCard}/>
                    </i> Add New Card
                </button>
            </div>
        </div>);
    }
}

export default PaymentCardsList;
