import React from 'react';


class Load extends React.Component {

    render(){
        return (
                 <div className="d-flex justify-content-center p-5">
                      <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                      </div>
                 </div>
        );
    }
}

export default Load;



