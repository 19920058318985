import React from 'react';
import BreadCrumb from '../components/breadCrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from '../components/image';
import NavigationHeader from '../components/navigationHeader';
import { CopyRightsFooter } from '../components/copyrights';
import { Row, Col } from 'react-bootstrap';
import { faArrowRight, faCheck, faExternalLinkAlt,faInfoCircle,faPlusCircle,} from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import $ from 'jquery';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { getApi, getAllDeployment, updateautoDebit } from "../utils/event_handling";
import BasicPortlet from '../components/basicPortlet';
import QualdoDataTable from '../components/bootstrapTable';
import { toastObj, get_document_link } from "../utils/common_utils";
import Toast from 'react-bootstrap/Toast';
import Load from "../components/loadAction";
import TableAction from '../settings/components/tableActions';
import { browserHistory } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import VideoContent from './components/videoContent'
import { HTTPS, DRX_DOCUMENT_BASE_LINK } from '../utils/constant';


let descriptionDatas = {};

class HomeDashboard extends React.Component {
    
    constructor(props, context) {
        super(props, context);
        let tab = "dashboard"
        this.getAllDeployment = getAllDeployment.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.paymentTableComponent = this.paymentTableComponent.bind(this);
        this.getApi = getApi.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlerenew = this.handlerenew.bind(this);
        this.handleRenewOptions = this.handleRenewOptions.bind(this);
        this.handleconfirm = this.handleconfirm.bind(this);
        this.handleRenewConfirmation = this.handleRenewConfirmation.bind(this);
        this.confirmpopup = this.confirmpopup.bind(this);
        this.closeRenewpopup = this.closeRenewpopup.bind(this);
        this.updateautoDebit = updateautoDebit.bind(this);

        if(localStorage.getItem('deploymentStatus') ===null || localStorage.getItem('deploymentStatus') === undefined){
            window.location.href = "/";
        }
        else if(localStorage.getItem('deploymentStatus') !== 'deployed'){
            window.location.href = "/deployment";
        }

        this.state = {
            isLoginPage: true,
            errorOccurred: false,
            current_tab: tab,
            data: "",
            tableData: { "headers": [], "data": [] },
            newDeploymentData : null,
            toastStatus: false,
            closeToast: false,
            upcomingPaymentData: { "headers": [], "data": [] },
            showAutoRenew: false,
            confirm: false,
            auto_debit: null,
            id: null,
            updateData: { 'deployment_id': '', 'auto_debit': '' },
            renewal: null,
            confirmRenewal: false,
            renewId: '',
            deploymentName:'',
            showModal: false,
            helpSection: '',
            post: null,
        };

        this.getData();
    }

    getData() {
        this.getApi("get_user_payments_and_billing_history");
    }
    componentDidMount() {
        $('body').addClass('theme-orange')
        localStorage.removeItem('deployment_id')
        localStorage.removeItem('deployment_name')
        this.getAllDeployment()
    }

    // To set Dashboard button as active.
    componentDidUpdate(){
        $('.dashboard').addClass('active');
    }

    showToast(status, msg, link) {
        this.getAllDeployment()
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"],
            showPopup: false
        });

        if (link.trim().length > 0) {
            setTimeout(function () {
                window.location.href = link;
            }, 3000);
        }

    }

    deploymentTableComponent(actionType, headerValue, cell, tableProps, row) {

        /**
         * Replace the deleted deployment name
         * deleted deployement name is modified in backend
         */
        if((row['Status'] === 'deleted' || row['Status'] === 'deletion initiated' || row['Status'] === 'deletion failed') && cell.name !== undefined){
            let name = cell.name
            const splitName = name.split("__1__");
            cell['name'] = splitName[0]
        }
        if (headerValue === "Deployment") {
            return (
                <div className="cloud-deployment-card">
                    <div className="card-icon">
                        <Image src="gcp_brandMark" />
                    </div>
                    {((cell.url !== undefined && row['Status'] === 'deployed') || (cell.url !== undefined && row['Status'] === 'active')) 
                    ?
                        <div className="card-stacked">
                            <a href={HTTPS + cell.url} target="_blank" rel="noopener noreferrer" className="card-title text-blue">{cell.name}<i className="small ml-1"><FontAwesomeIcon icon={faExternalLinkAlt} /></i></a>
                            <p className="card-text">Click the above link to goto Qualdo-DRX</p>
                        </div>
                        :
                        <>
                            {(row['Status'] === 'deleted') ?
                            <div className="card-stacked">
                                <p className="card-title">{cell.name}<i className="small ml-1"></i></p>
                                <p className="card-text">Deployment deleted</p>
                            </div>
                            :
                            (row['Status'] === 'deletion initiated') ?
                            <div className="card-stacked">
                                <p className="card-title">{cell.name}<i className="small ml-1"></i></p>
                                <p className="card-text">Deployment deletion initiated</p>
                            </div>
                            :
                            (row['Status'] === 'deletion failed') ?
                            <div className="card-stacked">
                                <p className="card-title">{cell.name}<i className="small ml-1"></i></p>
                                <p className="card-text text-danger"><i className="mr-1"><FontAwesomeIcon icon={faInfoCircle} /></i>Deployment deletion failed</p>
                            </div>
                            :
                            <div className="card-stacked">
                                <p className="card-title">{cell.name}<i className="small ml-1"></i></p>
                                <p className="card-text text-danger"><i className="mr-1"><FontAwesomeIcon icon={faInfoCircle} /></i>Deployment has issues</p>
                            </div>
                            }
                        </>
                    }
                </div>
            );
        }

        if (headerValue === "Status") {
            if (cell === 'deployed' || cell === 'active') {
                return (
                    <div className="badge badge-success">{"Healthy"}</div>

                );
            }
            else if (cell === 'deleted') {
                return (
                    <div class="badge badge-danger">{"Deleted"}</div>

                );
            }
            else if (cell === 'deletion initiated') {
                return (
                    <div class="badge badge-light-danger">
                        <i class="mr-1">
                            <svg id="rotate_right_black_24dp" class="d-block" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                <path id="Path_2106" data-name="Path 2106" d="M0,0H24V24H0Z" fill="none"/>
                                <path id="Path_2107" data-name="Path 2107" d="M14.83,4.83,12.7,2.7a1,1,0,0,0-1.7.71v.66A7.994,7.994,0,0,0,9.77,19.68.982.982,0,0,0,11,18.72v-.03a.969.969,0,0,0-.68-.94A5.99,5.99,0,0,1,11,6.09V7.62a1,1,0,0,0,1.7.71l2.13-2.08A.99.99,0,0,0,14.83,4.83Zm4.84,4.93a7.854,7.854,0,0,0-.66-1.59.987.987,0,0,0-1.56-.2l-.01.01a.972.972,0,0,0-.17,1.16,6.168,6.168,0,0,1,.48,1.16.975.975,0,0,0,.94.7h.02A.981.981,0,0,0,19.67,9.76ZM13,18.68v.02a.986.986,0,0,0,1.24.96A7.854,7.854,0,0,0,15.83,19a.987.987,0,0,0,.2-1.56l-.02-.02a.972.972,0,0,0-1.16-.17,5.606,5.606,0,0,1-1.16.49.978.978,0,0,0-.69.94Zm4.44-2.65a.987.987,0,0,0,1.56-.2,8.511,8.511,0,0,0,.67-1.59A.986.986,0,0,0,18.71,13h-.02a.966.966,0,0,0-.94.7,6.548,6.548,0,0,1-.48,1.17A.984.984,0,0,0,17.44,16.03Z" fill="#f64e60"/>
                            </svg>
                        </i>{"Deletion Initiated"}</div>

                );
            }
            else if (cell === 'deletion failed') {
                return (
                    <div class="badge badge-light-danger">
                        <i class="mr-1">
                            <svg class="d-block" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                <g id="Group_4878" data-name="Group 4878" transform="translate(2290 -1498)">
                                    <rect id="Rectangle_2695" data-name="Rectangle 2695" width="24" height="24" transform="translate(-2290 1498)" fill="none"/>
                                    <path id="Union_5" data-name="Union 5" d="M6.725,13.245A4.754,4.754,0,1,1,11.48,18a4.754,4.754,0,0,1-4.755-4.754Zm4.16,1.784a.594.594,0,1,0,.6-.6.594.594,0,0,0-.6.6Zm.149-3.715v2.08a.445.445,0,1,0,.891,0V11.314a.445.445,0,0,0-.891,0ZM7.153,17.661H3.487a1.659,1.659,0,0,1-1.652-1.552L1.1,4.415H14.35l-.2,3.252a6.182,6.182,0,0,0-6.994,9.991h0ZM1.1,3.311A1.1,1.1,0,1,1,1.1,1.1H4.415L4.663.61A1.093,1.093,0,0,1,5.65,0H9.8a1.093,1.093,0,0,1,.986.61l.249.493h3.311a1.1,1.1,0,1,1,0,2.208Z" transform="translate(-2286 1501)" fill="#f64e60"/>
                                </g>
                            </svg>
                        </i>{"Deletion Failed"}</div>

                );
            }
            else if (cell === 'failed') {
                return (
                    <div className="badge badge-danger">{"Deployment Failed"}</div>

                );
            }
            // else if (cell === 'deletion initiated') {
            //     return (
            //         <div class="badge badge-light-success">{"Deletion Initiated"}</div>

            //     );
            // }
        }

        if (headerValue === "Actions") {
            let disabled;
            if (cell['status'] === 'deleted' || cell['status'] === 'deletion initiated') {
                disabled = true
            }
            return (
                <>
                    <TableAction
                        cell={cell}
                        reqData={cell}
                        disabled={disabled}
                        nextBillingDate={row['Validity Period']}
                        header={'DeleteAction'}
                        showToast={this.showToast}
                    />

                </>
            );
        }

        return cell;
    }

    paymentTableComponent(actionType, headerValue, cell, tableProps, row) {
        if (headerValue === "Order Id") {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <small className="text-muted ml-1">{cell}</small>
                </div>

            );
        }

        if (headerValue === "Validity Period") {
            return (
                <TableAction
                    cell={cell}
                    reqData={tableProps['data']['requiredData'][0]}
                    nextBillingDate={row['Validity Period']}
                    header={headerValue}
                />

            );
        }

        if (headerValue === "Amount") {
            return (
                <strong>${cell}</strong>
            );
        }

        if (headerValue === "Deployment Name") {
            return (
                <TableAction
                    cell={cell}
                    reqData={cell}
                    nextBillingDate={row['Validity Period']}
                    header={headerValue}
                />


            );
        }

        if (headerValue === "Auto Renewal") {
            if (row['Auto Renewal'] === "No") {
                return (
                    <div className="d-flex alidn-items-center">
                        <Image src="icon_dollar" />
                        <span className="text-muted ml-1">None</span>
                    </div>
                );
            }
            else if (cell === 'annually') {
                return (
                    <strong>1 annum(s)</strong>
                );
            }
            else if (cell === 'monthly') {
                return (
                    <strong>1 month(s)</strong>
                );
            }
        }

        if (headerValue === "Actions") {
            if (row['Auto Renewal'] === "monthly" || row['Auto Renewal'] === "annually") {
                return (
                    <TableAction
                        cell={row['Auto Renewal']}
                        id={cell['deployment_id']}
                        data={row}
                        reqData={cell}
                        handleChange={this.handleChange}
                        handlerenew={this.handlerenew}
                        handleRenewOptions={this.handleRenewOptions}
                        handleRenewConfirmation={this.handleRenewConfirmation}
                        header={headerValue}
                        showToast={this.showToast}

                    />

                );
            }
            else {
                localStorage.setItem('currentId', cell)
                return (

                    <TableAction
                        cell={row['Auto Renewal']}
                        id={cell['deployment_id']}
                        data={row}
                        reqData={cell}
                        handleChange={this.handleChange}
                        handleRenewOptions={this.handleRenewOptions}
                        handlerenew={this.handlerenew}
                        handleRenewConfirmation={this.handleRenewConfirmation}
                        header={headerValue}
                        showToast={this.showToast}

                    />

                );
            }

        }

        return cell;
    }

    handleChange() {
        this.getApi("get_user_payments_and_billing_history");
    }

    handleRenewOptions(data, descriptionData) {
        descriptionDatas = descriptionData
        this.setState({ id: data['deployment_id'] })
        this.setState({ auto_debit: data['auto_debit'] })
        this.setState({ renewal: data['renewal'] })
        this.setState({ confirm: false })
        this.setState({ showAutoRenew: true })
        // this.setState({closeToast:!this.state.closeToast})

    }

    handleconfirm() {
        let data = { 'deployment_id': this.state.id, 'auto_debit': this.state.auto_debit }
        updateautoDebit(data)
        this.setState({ showAutoRenew: false })
        setTimeout(() => {
            this.getApi("get_user_payments_and_billing_history");
        }, 500);

    }

    handlerenew(data) {
        this.setState({ renewId: data['deployment_id'] })
        this.setState({ deploymentName: data['deployment_name'] })
        this.setState({ confirmRenewal: true })

    }

    handleRenewConfirmation() {
        localStorage.setItem('deployment_id', this.state.renewId)
        localStorage.setItem('renew', true)
        browserHistory.push('/renew')
        this.setState({ confirmRenewal: false })
        setTimeout(() => {
            this.getApi("get_user_payments_and_billing_history");
        }, 500);

    }

    closeToast() {
        this.setState({ toastStatus: false });
    }

    closeRenewpopup() {
        this.setState({ confirmRenewal: false })
    }

    confirmpopup() {
        this.setState({ showAutoRenew: false });
    }

    createDeployment() {
        localStorage.removeItem('deployment_id');
        localStorage.removeItem('wizardStage');
        localStorage.removeItem('currentId');
        localStorage.removeItem('deploymentStatus');
        localStorage.setItem('currentStatus', 'step1')
        localStorage.setItem('validate', 'true')
        setTimeout(() =>
            browserHistory.push('/deployment'))
    }

    handleClick(data) {
        this.setState({ showModal: true, helpSection: data });
    }

    hideModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        let tableData = this.state.tableData
        let paymenttableData = this.state.upcomingPaymentData;
        if (this.state.upcomingPaymentData !== null && this.state.upcomingPaymentData !== undefined) {
            paymenttableData = this.state.upcomingPaymentData
        }
        let newDeployStatus = ""
        if(this.state.newDeploymentData !== null && this.state.newDeploymentData.lenght > 0){
            newDeployStatus = this.state.newDeploymentData[0]['status']
        }
        return (
            <>
                {
                    this.state.showModal 
                    ?
                        <VideoContent
                            show={this.state.showModal}
                            onHide={this.hideModal}
                            name={"profile_data"}
                        /> 
                    : 
                        ""
                }
                <div id="toastMsg">
                    <Toast className={this.state.toastClass}
                        onClose={() => this.closeToast()}
                        show={this.state.toastStatus}
                        delay={3000} autohide>
                        <div className="d-flex align-items-center">
                            <i className="toast-icon">
                                {this.state.toastIcon ?
                                    <FontAwesomeIcon icon={this.state.toastIcon} /> :
                                    ""
                                }
                            </i>
                            <Toast.Body>
                                <strong className="toast-title">{this.state.toastHeading}</strong>
                                {this.state.toastBulk === "bulk" ?
                                    this.state.toastMsg.map(function (row) {
                                        return <p>{row.message}</p>;
                                    }) : <p>{this.state.toastMsg}</p>
                                }
                            </Toast.Body>
                            <button type="button" onClick={this.closeToast} className="close" data-dismiss="toast" aria-label="Close" />
                        </div>
                    </Toast>
                </div>
                <NavigationHeader
                    status = {newDeployStatus} 
                    newDeployment = {false}
                    secondDeployment = {true}
                    data = {this.state.newDeploymentData}             
                ></NavigationHeader>

                <BreadCrumb icon="fabox" title="Deployment Management" />
                <Tab.Container defaultActiveKey={this.state.current_tab}>
                    <div className="qd-tab__header">
                        <div className="container-fluid">
                            <div className="qd-tab__header-wrapper">
                                <div className="qd-tab__header-menu">
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="dashboard" >
                                                <span className="qd-menu__link-text">Dashboard</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="qd-container">
                        <div className="qd-body">
                            <div className="qd-tab__content">
                                <Tab.Content>
                                    <Tab.Pane eventKey="dashboard">
                                        <div
                                            id="jump-to_configure-datasource"
                                            className="mb-3"><BasicPortlet
                                                className="pb-0"
                                                id="configuredDatasources"
                                                title="List of Qualdo Deployments"
                                                content={
                                                    this.state.tableData.data.length < 1 ? <Load /> :
                                                        <QualdoDataTable
                                                            data={tableData}
                                                            showToast={this.showToast}
                                                            customGetActionComponent={this.deploymentTableComponent}
                                                            environment=" "
                                                            component_name="datasource"
                                                            updateTable=" "
                                                        />
                                                }
                                            />
                                        </div>



                                        <Row className="row-sm">
                                            <Col lg={9}>
                                                <div className="portlet">
                                                    <div className="portlet-title">
                                                        <div className="caption">
                                                            <div className="text-uppercase font-dark">
                                                                Upcoming Payment
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="portlet-body">

                                                        {(paymenttableData !== null && paymenttableData !== undefined) ?
                                                            <QualdoDataTable
                                                                data={paymenttableData}
                                                                showToast={this.showToast}
                                                                customGetActionComponent={this.paymentTableComponent}
                                                                environment=" "
                                                                component_name="dashboard"
                                                                updateTable=" "
                                                            /> : ''}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="portlet h-100">
                                                    <div className="portlet-title">
                                                        <div className="caption">
                                                            <div className="text-uppercase font-dark">
                                                                Help
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="portlet-body px-3">
                                                        <Card className="qd-link-card mb-2">
                                                            <div className="card-icon">
                                                                <Image src="icon_play" />
                                                            </div>
                                                            <Card.Link onClick={this.handleClick.bind(this, 'Cloud Service')}>Quick Start - Video Tutorial <FontAwesomeIcon icon={faArrowRight} /></Card.Link>
                                                        </Card>
                                                        <Card className="qd-link-card">
                                                            <div className="card-icon">
                                                                <Image src="qualdo_brandMark" />
                                                            </div>
                                                            <Card.Link rel="noopener noreferrer" target="_blank" href={get_document_link("index",DRX_DOCUMENT_BASE_LINK)}>Qualdo’s documentation! <FontAwesomeIcon icon={faArrowRight} /></Card.Link>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="manage" >
                                        {/* <button type="submit" onClick={this.createDeployment} class="btn btn-sm btn-outline-danger show-danger-toast mr-1" data-dismiss="toast" aria-label="Close" /> */}
                                        <button className="btn btn-blue mr-1" onClick={this.createDeployment}><i className="small"><FontAwesomeIcon icon={faPlusCircle} /></i>Create New Deployment</button>
                                        {/* <button className='new_deployment' onClick={this.createDeployment} ></button> */}

                                    </Tab.Pane>

                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
                <Modal show={this.state.confirmRenewal} size="md" centered>
                    <Modal.Body>
                        <button type="button" onClick={this.closeRenewpopup} class="icon-cross"><span class="sr-only">Close</span></button>
                        <div className="modal__alert p-sm-3">
                            <div className="modal_alert-icon">
                                <Image src="icon_renew" />
                            </div>
                            <div className="modal_alert-text">
                                <h4 className="mb-3">Renew <strong>Qualdo-DRX Edition</strong></h4>
                                <p>Are you sure you want to renew <strong>{this.state.deploymentName}</strong> Qualdo-DRX Edition?</p>
                            </div>
                            <div className="modal_alert-btn-group">
                                <button type="button" onClick={this.closeRenewpopup} class="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                <button type="button" class="btn btn-primary btn-circle" onClick={this.handleRenewConfirmation}><FontAwesomeIcon icon={faCheck} />Renew</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showAutoRenew} size="md" centered>
                    <Modal.Body>
                        {this.state.renewal === 'No' ?

                            <>
                                <button type="button" class="icon-cross" onClick={this.confirmpopup}><span class="sr-only">Close</span></button>
                                <div className="modal__alert p-sm-3">
                                    <div className="modal_alert-icon">
                                        <Image src="icon_renew" />
                                    </div>
                                    <div className="modal_alert-text">
                                        <h4 className="mb-3">Are you sure you want to Enable auto-renewal?</h4>

                                        {/* "Your deployment 'My-Deploy (Qualdo DRX Edition, GCP)' will be automatically renewed." */}
                                        <p>Your deployment "{(descriptionDatas['Deployment Name'])}"<strong> {"Qualdo DRX Edition - GCP"}</strong> will be automatically renewed.</p>
                                    </div>
                                    <div className="modal_alert-btn-group">
                                        <button type="button" class="btn btn-primary btn-circle" onClick={this.handleconfirm}><FontAwesomeIcon icon={faCheck} />Confirm</button>
                                    </div>
                                </div>
                            </>

                            :

                            <>
                                <button type="button" class="icon-cross" onClick={this.confirmpopup}><span class="sr-only">Close</span></button>
                                <div className="modal_payRenew-alert">
                                    <div className="payRenew-alert_icon">
                                        <Image src="icon_renew_disable" />
                                    </div>
                                    <div className="payRenew-alert_text">
                                        <h4>Are you sure you want to disable auto-renewal?</h4>
                                        <p>Your deployment "{descriptionDatas['Deployment Name']}"<strong> {"Qualdo DRX Edition - GCP"}</strong> will <strong>NOT</strong> be automatically renewed.</p>
                                    </div>
                                    <div className="payRenew-alert_action">
                                        <button type="button" class="btn btn-primary btn-circle" onClick={this.handleconfirm}><FontAwesomeIcon icon={faCheck} />Confirm</button>
                                    </div>
                                </div>
                            </>

                        }

                    </Modal.Body>
                </Modal>
                <Modal show={false} size="md" centered>
                    <Modal.Body>
                        <button type="button" onClick={this.closeRenewpopup} class="icon-cross"><span class="sr-only">Close</span></button>
                        <div className="modal__alert p-sm-3">
                            <div className="modal_alert-icon">
                                <Image src="icon_trash_failed" />
                            </div>
                            <div className="modal_alert-text">
                                <h4 className="mb-3">Deployment deletion failed!</h4>
                                <p>testing-drx/<strong>DRX Edition - asia-south1</strong> region deletion failed</p>
                            </div>
                            <div className="modal_alert-btn-group border-0 pt-2">
                                <button type="button" onClick={this.closeRenewpopup} class="btn btn-outline btn-grey btn-circle">Cancel</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={false} size="md" centered>
                    <Modal.Body>
                        <button type="button" onClick={this.closeRenewpopup} class="icon-cross"><span class="sr-only">Close</span></button>
                        <div className="modal__alert p-sm-3">
                            <div className="modal_alert-icon">
                                <Image src="icon_trash_success" />
                            </div>
                            <div className="modal_alert-text">
                                <h4 className="mb-3">Deployment deleted successfully</h4>
                                <p>testing-drx/<strong>DRX Edition - asia-south1</strong> region has been deleted successfully</p>
                            </div>
                            <div className="modal_alert-btn-group border-0 pt-2">
                                <button type="button" onClick={this.closeRenewpopup} class="btn btn-outline btn-grey btn-circle">Cancel</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <CopyRightsFooter />
            </>
        );

    }
}

export default HomeDashboard;