import React from 'react';
import CopyRights from '../../components/copyrights';
import { faEnvelope, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailNotification from '../../signup/components/email';
import { iam_ip } from "../../utils/ip_details";
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { call_post_api }  from "../../utils/event_handling";
import Image from '../../components/image';
import Banner from '../../components/banner';


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            formValid: false,
            errorCount: null,
            hideSubmitButton: false,
            errors: {
                email: '',
           },
           isRedirectToThankYou : false
        };
    }

    hideAlert() {
        let errors = this.state.errors;
        errors.email = '';
        this.setState({errors:errors,hideSubmitButton: false})
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }


    forgotPassword(event) {
        this.disableButton();
        let { email } = event;
        let errors = this.state.errors;
        const forgot_data= {email: email};
            call_post_api(iam_ip, "forgot-password", forgot_data).then((response) => {
                errors.email = response.message;
                this.setState({errors:errors,hideSubmitButton: false});
                if (response.code === 200) {
                    this.setState({isRedirectToThankYou :true})
                }

            });
    }

    render() {
        const forgotPasswordSchema = yup.object({
            email: yup.string().email("Email must be a valid email").required("Email is a required field"),
        });
        const {errors} = this.state;
        return (

            <div className="user-prelogin">
                <div className="row gutter-reset">
                    <div className="col">
                        <div className="prelogin-container">
                            <Image
                                className="login-logo"
                                src="logo"
                            />
                            {this.state.isRedirectToThankYou === false ?
                                <div className="prelogin-content">
                                    <h4 className="login-welcome-text">To Reset Password,</h4>
                                    <p>Enter your username or email below, and we’ll send you an email with a link that will allow you to reset your password.</p>
                                    <div className="prelogin-form-container mt-5">
                                        { errors.email !== '' ?
                                            <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                                                {errors.email}
                                                <button type="button" className="close close-sm" onClick={() => this.hideAlert()} data-dismiss="alert" aria-label="Close"></button>
                                            </div> : ""
                                        }
                                        <Formik
                                                validationSchema={forgotPasswordSchema}
                                                onSubmit={this.forgotPassword}
                                                initialValues={{ email: '' }}
                                            >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting,
                                                isValid,
                                            }) => (
                                            <Form  name="forgot-password" className="login-form" onSubmit={handleSubmit}>
                                                <Form.Group controlId="email">
                                                    <Form.Label> Email Address <span className="text-danger">*</span></Form.Label>
                                                        <InputGroup bsPrefix="icon-input-group">
                                                            <i><FontAwesomeIcon icon={faEnvelope}/></i>
                                                            <Form.Control
                                                                className="form-control-solid placeholder-no-fix form-group"
                                                                type="text"
                                                                placeholder="Email"
                                                                autoFocus
                                                                value={values.email}
                                                                //   onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                isInvalid={errors.email && touched.email}/>
                                                            <Form.Control.Feedback type="invalid">
                                                                { touched.email && errors.email }
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <div className="form-action-btn">
                                                        <a href="/" className="btn btn-circle btn-icon btn-outline-secondary"><i><FontAwesomeIcon icon={faArrowLeft}/></i> Back</a>
                                                        <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-secondary">Send Email</button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        <CopyRights classDetails="text-copyright mt-5" showCopyRight='true'/>
                                        </div>
                                    </div>
                                : 
                                    <EmailNotification setPage={this.EmailNotification} responseText={this.state.responseText} email={this.state.email} isPasswordThankYou={true}/>

                            }
                        </div>
                    </div>
                    <Banner/>
                </div>
            </div>
          
        );
    }

}

export default ForgotPassword;
