import React from "react";
import Card from "react-bootstrap/Card";
import OrderRow from "./orderRow";
import Load from "../loadAction";
import {TERMS_CONDITIONS_LINK} from "../../utils/constant";


class OrderDetails extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            disableSubmitBtn: this.props.disableSubmitBtn
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.disableSubmitBtn !== state.disableSubmitBtn) {
            return {
                disableSubmitBtn: props.disableSubmitBtn
            }
        }
        return null;
    }

    render() {
        let addOnCostForData = this.props.addOnCostForData;
        // let unitCostForData = this.props.unitCostForData;
        let addOnCostForModel = this.props.addOnCostForModel;
        // let unitCostForModel = this.props.unitCostForModel;
        // let addOnCycles = this.props.addOnCycles;

        let planSubscriptionAmount = this.props.totalAmount;
        let totalAmount = planSubscriptionAmount;
        if (addOnCostForData > 0) {
            totalAmount = totalAmount + addOnCostForData;
        }
        if (addOnCostForModel > 0) {
            totalAmount = totalAmount + addOnCostForModel;
        }

        if (this.props.remainingAmountFromCurrentPlan > 0){
            totalAmount = totalAmount - this.props.remainingAmountFromCurrentPlan;
            totalAmount = Math.ceil(totalAmount);
        }

        let orderSummaryTitle = "Order Summary";
        const orderID = this.props.orderID;
        if (orderID !== undefined && orderID !== null) {
            orderSummaryTitle = `${orderSummaryTitle} - #${orderID}`;
        }

        let planCostValue = this.props.currencySymbol + this.props.planCost;
        // if (this.props.selectedBillingCycle === QualdoPlanCycle.YEARLY) {
        //     planCostValue = `${planSubscriptionAmount} (${this.props.planCost} x 12)`;
        // }

        return (
            <Card className="billing-summary-card">
                <Card.Header>
                    <h4>{orderSummaryTitle}</h4>
                </Card.Header>

                {
                    this.props.loading ?
                        <Load/>
                        :
                        <Card.Body>
                            <div className="billing-summary-card__addons">

                                <OrderRow labelContent={"Plan"}
                                          valueContent={this.props.planName}/>

                                {
                                    this.props.planCost > 0 ?
                                        <OrderRow labelContent={"Plan cost"}
                                                  valueContent={planCostValue}/>
                                        :
                                        ''
                                }

                                {addOnCostForData > 0 ?
                                    <OrderRow labelContent={"Additional Data cost"}
                                              valueContent={this.props.currencySymbol + addOnCostForData}/>
                                    :
                                    ''
                                }

                                {addOnCostForModel > 0 ?
                                    <OrderRow labelContent={"Additional Model cost"}
                                              valueContent={this.props.currencySymbol + addOnCostForModel}/>
                                    :
                                    ''
                                }

                                <OrderRow labelContent={"Billing cycle"}
                                          valueContent={this.props.billingCycle}/>

                                <OrderRow labelContent={"Next billing date"}
                                          valueContent={this.props.nextBilling}/>

                                {
                                this.props.remainingAmountFromCurrentPlan !== undefined && this.props.remainingAmountFromCurrentPlan > 0 ?
                                    <OrderRow labelContent={"Balance amount in current plan(-)"}
                                              valueContent={Number.parseFloat(this.props.remainingAmountFromCurrentPlan).toFixed(2)}/>
                                    : ''
                                }
                            </div>

                            <hr className="billing-summary-card__divider"/>

                            <div className="billing-summary-card__total-cost">
                                <span>Total amount</span>
                                <span>{this.props.currencySymbol}{totalAmount}</span>
                            </div>

                            <div className="billing-summary-card__footer">
                                <button type="button"
                                        disabled={this.state.disableSubmitBtn}
                                        onClick={this.props.submitHandler}
                                        className="btn btn-lg btn-block btn-primary btn-circle">
                                    <strong>Pay</strong>
                                </button>
                                <p className="terms-text">
                                    By confirming this payment, you agree to the
                                    <br/>
                                    <a className="terms-of-service-link"
                                       href={TERMS_CONDITIONS_LINK}
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       >Qualdo Terms Service</a>
                                </p>
                            </div>

                        </Card.Body>
                }

            </Card>
        );
    }
}

export default OrderDetails;
