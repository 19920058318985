import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPaperPlane, faChevronRight,
    faArrowRight, faBell
} from '@fortawesome/free-solid-svg-icons';
import { toastObj } from "../../utils/common_utils";
import { Row, Col, Button } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Image from '../../components/image';
import Card from "react-bootstrap/Card";
import Accordion from 'react-bootstrap/Accordion';
import $ from "jquery";
import {
    getApi, updateautoDebit,
    getBillingHistoryTableData,
    getPaymentDetailsTableData, getBillingAddressDetails,
    planDetails, getDeploymentPlanDetails,
} from "../../utils/event_handling";
import QualdoDataTable from '../../components/bootstrapTable';
import TableAction from './tableActions';
import { browserHistory } from 'react-router';
import Modal from 'react-bootstrap/Modal'


let descriptionDatas = {};
class UsageAndPayment extends React.Component {
    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.addExpandClass1 = this.addExpandClass1.bind(this);
        this.addExpandClass2 = this.addExpandClass2.bind(this);
        this.addExpandClass3 = this.addExpandClass3.bind(this);
        this.addActiveClass = this.addActiveClass.bind(this);
        this.getPaymentDetailsTableData = getPaymentDetailsTableData.bind(this);
        this.getBillingHistoryTableData = getBillingHistoryTableData.bind(this);
        this.getBillingAddressDetails = getBillingAddressDetails.bind(this);
        this.paymentTableComponent = this.paymentTableComponent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateautoDebit = updateautoDebit.bind(this);
        this.handlerenew = this.handlerenew.bind(this);
        this.handleRenewOptions = this.handleRenewOptions.bind(this);
        this.handleconfirm = this.handleconfirm.bind(this);
        this.handleRenewConfirmation = this.handleRenewConfirmation.bind(this);
        this.confirmpopup = this.confirmpopup.bind(this);
        this.closeRenewpopup = this.closeRenewpopup.bind(this);
        // this.navigateToPaymentPage = this.navigateToPaymentPage.bind(this);
        this.handlepay = this.handlepay.bind(this);
        this.planDetails = planDetails.bind(this);
        this.getDeploymentPlanDetails = getDeploymentPlanDetails.bind(this);
        this.state = {
            toastStatus: false,
            emailsubscription: [],
            chechkall: false,
            btnchkalltext: "Unsubscribe All",
            emailsubscriptioninitalloader: true,
            secondopen: 2,
            firstopen: 2,
            thirdopen: 2,
            billingHistory: null,
            billing_address: null,
            next_payment_date: null,
            pendingDeploymentDetails: null,
            confirmRenewal: false,
            showAutoRenew: false,
            closeToast: false,
            confirm: false,
            renewId: '',
            renewal: null,
            deployment_details: null,
            updateData: { 'deployment_id': '', 'auto_debit': '' },
            auto_debit: null,
            id: null,
            upcomingPaymentData: { "headers": [], "data": [] },
            pendingPaymentData: { "headers": [], "data": [] },
        };
        this.getData();
        // this.navigateToPaymentPage();
    }

    getData() {
        // this.getApi("get_payment_page_details");
        this.getApi("get_user_payments_and_billing_history");
    }



    closeToast() {
        this.setState({ toastStatus: false });
    }
    showToast(status, msg, link) {
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastIcon: toastObj[status]["icon"],
            toastheading: toastObj[status]["heading"]
        });
    }

    addActiveClass() {
        $('.payment-notification-wrap').toggleClass("expand");
        $("#btn-expand-notification").text(($("#btn-expand-notification").text() === 'See More') ? 'See Less' : 'See More');
    }
    addExpandClass1() {
        $('#expand-accordion1').toggleClass("expand");
    }
    addExpandClass2() {
        $('#expand-accordion2').toggleClass("expand");
    }
    addExpandClass3() {
        $('#expand-accordion3').toggleClass("expand");
    }

    paymentTableComponent(actionType, headerValue, cell, tableProps, row) {
        if (headerValue === "Order Id") {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <small className="text-muted ml-1">{cell}</small>
                </div>

            );
        }

        if (headerValue === "Validity Period") {
            return (
                <TableAction
                    cell={cell}
                    reqData={tableProps['data']['requiredData'][0]}
                    nextBillingDate={row['Validity Period']}
                    header={headerValue}
                />

            );
        }

        if (headerValue === "Amount") {
            return (
                <strong>${cell}</strong>
            );
        }

        if (headerValue === "Deployment Name") {
            return (
                <TableAction
                    cell={cell}
                    reqData={cell}
                    nextBillingDate={row['Validity Period']}
                    header={headerValue}
                />


            );
        }

        if (headerValue === "Auto Renewal") {
            if (row['Auto Renewal'] === "No") {
                return (
                    <div className="d-flex alidn-items-center">
                        <Image src="icon_dollar" />
                        <span className="text-muted ml-1">None</span>
                    </div>
                );
            }
            else if (cell === 'annually') {
                return (
                    <strong>1 annum(s)</strong>
                );
            }
            else if (cell === 'monthly') {
                return (
                    <strong>1 month(s)</strong>
                );
            }
        }

        if (headerValue === "Actions") {
            if (row['Auto Renewal'] === "monthly" || row['Auto Renewal'] === "annually") {
                return (
                    <TableAction
                        cell={row['Auto Renewal']}
                        id={cell['deployment_id']}
                        data={row}
                        reqData={cell}
                        handleChange={this.handleChange}
                        handlerenew={this.handlerenew}
                        handleRenewOptions={this.handleRenewOptions}
                        handleRenewConfirmation={this.handleRenewConfirmation}
                        header={headerValue}
                        showToast={this.showToast}

                    />

                );
            }
            else {
                localStorage.setItem('currentId', cell)
                return (

                    <TableAction
                        cell={row['Auto Renewal']}
                        id={cell['deployment_id']}
                        data={row}
                        reqData={cell}
                        handleChange={this.handleChange}
                        handleRenewOptions={this.handleRenewOptions}
                        handlerenew={this.handlerenew}
                        handleRenewConfirmation={this.handleRenewConfirmation}
                        header={headerValue}
                        showToast={this.showToast}

                    />

                );
            }

        }

        return cell;
    }


    handleChange() {
        this.getApi("get_user_payments_and_billing_history");
    }

    // navigateToPaymentPage(planType) {
    //     localStorage.setItem("src_choose_plan", "true");
    //     localStorage.setItem("qualdoPlan", planType);
    // }

    handlepay() {
        let id = this.state.pendingPaymentData.requiredData[0]['deployment_id']
        localStorage.setItem('deployment_id', id)
        this.planDetails()
        this.getDeploymentPlanDetails(id);
        setTimeout(() =>
            browserHistory.push('/payment'), 2000
        );
        // this.navigateToPaymentPage(QualdoPlanVersions.PROFESSIONAL);
        // window.location.href = "/payment";
        // browserHistory.push('/payment')
    }

    handleRenewOptions(data, descriptionData) {
        descriptionDatas = descriptionData
        this.setState({ id: data['deployment_id'] })
        this.setState({ auto_debit: data['auto_debit'] })
        this.setState({ renewal: data['renewal'] })
        this.setState({ confirm: false })
        this.setState({ showAutoRenew: true })
        // this.setState({closeToast:!this.state.closeToast})

    }

    handleconfirm() {
        let data = { 'deployment_id': this.state.id, 'auto_debit': this.state.auto_debit }
        updateautoDebit(data)
        this.setState({ showAutoRenew: false })
        setTimeout(() => {
            this.getApi("get_user_payments_and_billing_history");
        }, 500);

    }

    handlerenew(data) {
        this.setState({ renewId: data['deployment_id'] })
        this.setState({ confirmRenewal: true })

    }

    handleRenewConfirmation() {
        browserHistory.push('/renew')
        // getuserrenewal(this.state.renewId);
        this.setState({ confirmRenewal: false })
        setTimeout(() => {
            this.getApi("get_user_payments_and_billing_history");
        }, 500);

    }

    closeRenewpopup() {
        this.setState({ confirmRenewal: false })
    }

    confirmpopup() {
        this.setState({ showAutoRenew: false });

    }



    render() {

        let tableData = null;
        let alertData = [];
        let deployment_details = [];
        if (this.state.upcomingPaymentData !== null && this.state.upcomingPaymentData !== undefined
            && this.state.pendingDeploymentDetails !== null && this.state.pendingDeploymentDetails !== undefined) {
            tableData = this.state.upcomingPaymentData
            alertData = this.state.pendingDeploymentDetails
            deployment_details = this.state.deployment_details
        }
        return (
            <>
                <div className="qd-section_content  shadow-sm settings-paymentprofile">
                    <div className="qd-section-header">
                        <h4 className="qd-section-header-title"><FontAwesomeIcon icon={faPaperPlane} /> Upcoming Payment</h4>
                    </div>
                    <div className="qd-section-body">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <div className="payment-notification-wrap">
                                    {(alertData !== null && alertData !== undefined) ?

                                        (alertData.map(details =>

                                            <div className="card__data-limit-exceed collapsible-layer">
                                            <button id="btn-expand-notification" className="btn btn-extralight-blue btn-expand btn-circle" onClick={() => this.addActiveClass()}>See More</button>
                                            <div className="row no-gutters">
                                                <div className="col">
                                                    <div className="data-limit-exceed-detail">
                                                        <i className="bell">
                                                            <FontAwesomeIcon icon={faBell} />
                                                        </i>
                                                        <div className="data-limit-exceed-info">
                                                        <h6>Your deployment "{details['deployment_name']}" DRX Edition subscription has expired.</h6>
                                                                <p>On {details['validity_date']} your DRX Edition will be expired. Click the Pay Now button to reactive your subscription</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col data-limit-exceed-action">
                                                    <button className="btn btn-primary btn-circle"
                                                        onClick={this.handlepay}
                                                        type="button">
                                                        Pay Now
                                                        <i><FontAwesomeIcon icon={faArrowRight} /></i>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>


                                        )
                                        
                                            )
                                    :
                                    ''
                                    }


                                </div>

                                {(deployment_details !== null && deployment_details !== undefined) ?
                                    (deployment_details.map(detail =>
                                    <div>
                                        <Accordion className="plan-usage-accordion" id="expand-accordion2">
                                            <Card>
                                                <div className="card-header">
                                                    <div className="cloud-deployment-card">
                                                        <div className="card-icon">
                                                            <Image src="gcp_brandMark" />
                                                        </div>
                                                        <div className="card-stacked">
                                                            <h5 className="card-title">{detail.deployment_name}</h5>
                                                            <p className="card-text"><strong>{detail.edition}</strong> Edition</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-action">
                                                        <Accordion.Toggle as={Button} eventKey="0" variant="extralight-blue" className="btn-circle" onClick={() => this.addExpandClass2()}>
                                                            <span className="mr-2">Plan</span><i><FontAwesomeIcon icon={faChevronRight} className="mr-0" /></i>
                                                        </Accordion.Toggle>
                                                    </div>
                                                </div>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <div className="row row-sm">
                                                            <div className="col-xl-8">
                                                                <Card className="fancy-card">
                                                                    <Card.Body>
                                                                        <Row className="row-sm">
                                                                            <Col md={6}>
                                                                                <h6 className="card-subtitle text-muted mb-2">Cloud Settings</h6>
                                                                                <p className="card-text mb-4">{detail.deployment_name}</p>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <h6 className="card-subtitle text-muted mb-2">Edition</h6>
                                                                                <ul className="pl-0">
                                                                                    <li key={"ds"}><strong>{detail.edition}</strong> Edition</li>
                                                                                </ul>
                                                                            </Col>

                                                                            <Col md={6}>
                                                                                <h6 className="card-subtitle text-muted mb-2">Cloud</h6>
                                                                                <div className="cloud-deployment-card">
                                                                                    <div className="card-icon">
                                                                                        <Image src="gcp_brandMark" />
                                                                                    </div>
                                                                                    <div className="card-stacked">
                                                                                        <h5 className="card-title">Google Cloud Platform</h5>
                                                                                        <p className="card-text">{detail.cloud_support}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <h6 className="card-subtitle mb-2 text-muted mb-2">Benefits</h6>
                                                                                <ul className="pl-0 mb-0">
                                                                                    <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Data Reliablity Monitoring</li>
                                                                                    <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Alerts</li>
                                                                                    <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Notifications</li>
                                                                                    <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Data Quality Metrics</li>
                                                                                </ul>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>
                                    ))
                                    :
                                    ''
                                }


                                <div className="portlet">
                                    <div className="portlet-title">
                                        <div className="caption">
                                            <div className="text-uppercase font-dark">
                                                UPCOMING PAYMENT
                                            </div>
                                        </div>
                                    </div>

                                    <div className="portlet-body">
                                        {(tableData !== null && tableData !== undefined) ?
                                            <QualdoDataTable
                                                data={tableData}
                                                showToast={this.showToast}
                                                customGetActionComponent={this.paymentTableComponent}
                                                environment=" "
                                                component_name="dashboard"
                                                updateTable=" "
                                            /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.confirmRenewal} size="md" centered>
                    <Modal.Body>
                        <button type="button" onClick={this.closeRenewpopup} class="icon-cross"><span class="sr-only">Close</span></button>
                        <div className="modal__alert p-sm-3">
                            <div className="modal_alert-icon">
                                <Image src="icon_renew" />
                            </div>
                            <div className="modal_alert_text">
                                <h4>Renew <strong>DRX Edition?</strong></h4>
                                <p>Are you sure you want to renew the DRX Edition?</p>
                            </div>
                            <div className="modal_alert-btn-group">
                                <button type="button" onClick={this.closeRenewpopup} class="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                <button type="button" class="btn btn-primary btn-circle" onClick={this.handleRenewConfirmation}><FontAwesomeIcon icon={faCheck} />Renew</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showAutoRenew} size="md" centered>
                    <Modal.Body>
                        {this.state.renewal === 'No' ?

                            <>
                                <button type="button" class="icon-cross" onClick={this.confirmpopup}><span class="sr-only">Close</span></button>
                                <div className="modal__alert p-sm-3">
                                    <div className="modal_alert-icon">
                                        <Image src="icon_renew" />
                                    </div>
                                    <div className="modal_alert-text">
                                        <h4 className="mb-3">Are you sure you want to Enable auto-renewal?</h4>

                                        {/* "Your deployment 'My-Deploy (Qualdo DRX Edition, GCP)' will be automatically renewed." */}
                                        <p>Your deployment "{(descriptionDatas['Deployment Name'])}"<strong> {"Qualdo DRX Edition - GCP"}</strong> will be automatically renewed.</p>
                                    </div>
                                    <div className="modal_alert-btn-group">
                                        <button type="button" class="btn btn-primary btn-circle" onClick={this.handleconfirm}><FontAwesomeIcon icon={faCheck} />Confirm</button>
                                    </div>
                                </div>
                            </>

                            :

                            <>
                                <button type="button" class="icon-cross" onClick={this.confirmpopup}><span class="sr-only">Close</span></button>
                                <div className="modal_payRenew-alert">
                                    <div className="payRenew-alert_icon">
                                        <Image src="icon_renew_disable" />
                                    </div>
                                    {/* for {descriptionDatas['Deployment Name']}  */}
                                    <div className="payRenew-alert_text">
                                        <h4>Are you sure you want to disable auto-renewal?</h4>
                                        <p>Your deployment "{descriptionDatas['Deployment Name']}"<strong> {"Qualdo DRX Edition - GCP"}</strong> will <strong>NOT</strong> be automatically renewed.</p>
                                    </div>
                                    <div className="payRenew-alert_action">
                                        <button type="button" class="btn btn-primary btn-circle" onClick={this.handleconfirm}><FontAwesomeIcon icon={faCheck} />Confirm</button>
                                    </div>
                                </div>
                            </>

                        }

                    </Modal.Body>
                </Modal>

                <div id="toastMsg">
                    <Toast className={this.state.toastClass}
                        onClose={() => this.closeToast()}
                        show={this.state.toastStatus}
                        delay={3000} autohide>
                        <div className="d-flex align-items-center">

                            {this.state.toastStatus ?
                                <i className="toast-icon">
                                    <FontAwesomeIcon icon={this.state.toastIcon} />
                                </i>
                                :
                                ''
                            }

                            <Toast.Body>
                                <strong className="toast-title">{this.state.toastheading}</strong>
                                <p>{this.state.toastMsg}</p>
                            </Toast.Body>
                            <button type="button"
                                onClick={this.closeToast}
                                className="close"
                                data-dismiss="toast"
                                aria-label="Close" />
                        </div>
                    </Toast>
                </div>


            </>
        );
    }

}
export default UsageAndPayment;


