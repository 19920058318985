import React from "react";
import Image from "../image";

class PaymentSuccess extends React.Component {

    render() {
        return (
            <div className="qd-payment-success">
                <div className="qd-payment-success__content">
                    <Image src="illustration_paymentSuccess"
                           alt="Payment Success"/>
                    <h2>Your purchase was a success!</h2>
                    <p>Your Qualdo™ account has been updated.
                        If you need an invoice, visit your payment history.</p>
                    <button type="button"
                            onClick={() => window.location.href = "/dashboard"}
                            className="btn btn-lg btn-primary btn-circle">
                        View Plan Details
                    </button>
                </div>
            </div>
        );
    }
}


export default PaymentSuccess;