import React from 'react';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Load from "../../components/loadAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTrash} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {deleteCardDetails, updatePaymentCard} from "../../utils/event_handling";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


class EditCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleCustomerName = this.handleCustomerName.bind(this);
        this.handleExpiryDate = this.handleExpiryDate.bind(this);
        this.setExpiryDateStatus = this.setExpiryDateStatus.bind(this);
        this.getComponentClass = this.getComponentClass.bind(this);
        this.submitEditDetails = this.submitEditDetails.bind(this);
        this.updatePaymentCard = updatePaymentCard.bind(this);
        this.deleteCardDetails = deleteCardDetails.bind(this);
        this.validateExpiryValues = this.validateExpiryValues.bind(this);
        this.removeCardDetails = this.removeCardDetails.bind(this);

        let expiryDate = undefined;
        let customerName = '';
        const exCardDetails = this.props.exCardDetails;
        if (exCardDetails !== undefined && exCardDetails !== null) {
            expiryDate = exCardDetails.expires;
            customerName = exCardDetails.customerName;
        }

        this.state = {
            errorOccurred: false,
            load: false,
            disableCardButton: false,
            validation: {
                customer_name: {
                    is_valid: false,
                    is_invalid: false
                },
                expiration_date: {
                    is_valid: false,
                    is_invalid: false
                }
            },
            customerName: customerName,
            expiryDate: expiryDate,
            expiryMonth: '',
            expiryYear: '',
            exCardDetails: exCardDetails,
        };
    }

    componentDidCatch(error, info) {
        this.setState({errorOccurred: true});
    }

    getComponentClass(component) {
        let reqClass = "form-control";
        let validation = this.state.validation;
        let componentInfo = validation[component];

        if (componentInfo === undefined || componentInfo === null) {
            return reqClass;
        }

        if (componentInfo.is_valid) {
            reqClass = `${reqClass} is-valid`;
        } else if (componentInfo.is_invalid) {
            reqClass = `${reqClass} is-invalid`;
        }

        return reqClass;
    }

    componentDidMount() {

    }

    static getDerivedStateFromProps(props, state) {
        if (props.exCardDetails !== state.exCardDetails) {
            return {exCardDetails: props.exCardDetails};
        } else {
            return null;
        }
    }

    handleCustomerName(event) {
        const customerName = event.target.value;
        this.setState({customerName: customerName});
        let validation = this.state.validation;

        const customerNameStatus = validation.customer_name;
        customerNameStatus.is_valid = false;
        customerNameStatus.is_invalid = false;

        if (!customerName.trim()) {
            customerNameStatus.is_invalid = true;
        } else {
            customerNameStatus.is_valid = true;
        }

        this.setState({validation: validation});
    }

    setExpiryDateStatus(isValid = false, message = "") {
        let validation = this.state.validation;
        const expirationDateStatus = validation.expiration_date;
        expirationDateStatus.is_valid = false;
        expirationDateStatus.is_invalid = false;

        if (isValid) {
            expirationDateStatus.is_valid = true;
        } else {
            expirationDateStatus.is_invalid = true;
        }
        this.setState({validation: validation});
    }

    validateExpiryValues(expiryDate){
        let hasError = !expiryDate.trim();
        if (hasError) {
            this.setExpiryDateStatus();
            return null;
        }

        let splits = String(expiryDate).split("/");
        hasError = splits.length !== 2;
        if (hasError) {
            this.setExpiryDateStatus();
            return null;
        }

        let monthPart = splits[0].trim();
        let yearPart = splits[1].trim();
        if (monthPart.length === 0 ||
            monthPart.length > 2 ||
            yearPart.length === 0 ||
            yearPart.length > 4) {
            this.setExpiryDateStatus();
            return null;
        }

        let monthIndex = parseInt(monthPart);
        let yearIndex = parseInt(yearPart);
        if (isNaN(monthIndex) || isNaN(yearIndex) || monthIndex < 1 || monthIndex > 12) {
            this.setExpiryDateStatus();
            return null;
        }

        let currentDate = moment()
        let currentYearStr;
        if (yearPart.length === 4) {
            currentYearStr = currentDate.format('YYYY').toString();
        } else {
            currentYearStr = currentDate.format('YY').toString();
        }

        let difference = yearIndex - parseInt(currentYearStr);
        if (difference < 0 || difference > 20) {
            this.setExpiryDateStatus();
            return null;
        }

        if (difference === 0 && monthIndex < parseInt(currentDate.format('MM').toString())) {
            this.setExpiryDateStatus();
            return null;
        }

        let expiryMonth = monthIndex;
        if (monthIndex < 10) {
            expiryMonth = '0' + monthIndex;
        }

        this.setState({
            expiryMonth: expiryMonth,
            expiryYear: yearIndex
        });

        this.setExpiryDateStatus(true);

        return {
            expiryMonth: expiryMonth,
            expiryYear: yearIndex
        };
    }

    handleExpiryDate(event) {
        const expiryDate = event.target.value;
        this.setState({expiryDate: expiryDate});
        this.validateExpiryValues(expiryDate);
    }

    removeCardDetails() {
        this.deleteCardDetails(this.props.exCardDetails);
    }

    submitEditDetails() {
        this.setState({disableCardButton: true});
        let expiryRes = this.validateExpiryValues(this.state.expiryDate);

        if (expiryRes === null){
            return;
        }

        let validation = this.state.validation;
        const customerNameStatus = validation.customer_name;
        if (customerNameStatus.is_invalid){
            this.setState({disableCardButton: false});
            return;
        }

        let editData = {
            "expiry_month": expiryRes.expiryMonth,
            "expiry_year": expiryRes.expiryYear,
            "customer_name": this.state.customerName,
            "id": this.props.exCardDetails.id,
            "brand": this.props.exCardDetails.brand,
            "lastFourDigits": this.props.exCardDetails.lastFourDigits,
        };

        this.updatePaymentCard(editData);
    }

    render() {
        return (
            <Form className="payment__card"
                  key={this.props.exCardDetails.id}
            >
                {this.state.load ? <Load/> : ''}

                {
                    this.state.load ?
                        '' :
                        <>
                            <h4>Payment method</h4>
                            <p className="text-muted">
                                <strong>
                                    Qualdo™ Pay {this.state.exCardDetails.brand} Credit Card
                                </strong>
                                {" ending in"} {this.state.exCardDetails.lastFourDigits}
                            </p>
                        </>
                }

                <Form.Row>
                    <Col xs={7} className="form-group"
                         style={{display: this.state.load ? "none" : "block"}}
                    >
                        <Form.Group controlId="formCardName">
                            <Form.Label>Name on card <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Name"
                                          className={this.getComponentClass("customer_name")}
                                          onChange={this.handleCustomerName}
                                          defaultValue={this.state.exCardDetails.customerName}
                            />
                            <div className="invalid-feedback">
                                Invalid cardholder name
                            </div>
                        </Form.Group>
                    </Col>

                    <Col sm={3} xs={5} className="form-group"
                         style={{display: this.state.load ? "none" : "block"}}
                    >
                        <Form.Group controlId="formCardExpiryDate">
                            <Form.Label>Expiration Date <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Expiration Date"
                                          className={this.getComponentClass("expiration_date")}
                                          onChange={this.handleExpiryDate}
                                          defaultValue={this.state.exCardDetails.expires}
                            />
                            <div className="invalid-feedback">
                                Please provide valid expiration date
                            </div>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Button id="btn-add-card"
                        className="btn-primary btn-circle mr-2"
                        onClick={this.submitEditDetails}
                        disabled={this.state.disableCardButton}
                        >
                    <i><FontAwesomeIcon icon={faCheck}/></i>
                    Save Changes
                </Button>

                <Button className="btn-outline btn-grey btn-circle mr-2"
                        onClick={this.props.hideAddCardSection}
                        type="submit">
                    Cancel
                </Button>

                {
                    this.props.showRemoveCard ?

                        <Button id="btn-remove-card"
                                onClick={this.removeCardDetails}
                                className="btn-outline btn-grey btn-circle mr-2"
                        >
                            <i><FontAwesomeIcon icon={faTrash}/></i>
                            Remove Card
                        </Button>

                        :

                        <OverlayTrigger placement="top"
                                        overlay={
                                            <Tooltip id={'tooltip-top'}>
                                                At least one payment method is
                                                <br/> required for an active Qualdo™
                                                <br/> account. Please add a new credit
                                                <br/> card to remove this card.
                                            </Tooltip>
                                        }>
                            <i>
                                <Button id="btn-removed-disabled"
                                        className="btn-outline btn-grey btn-circle mr-2"
                                >
                                    <i><FontAwesomeIcon icon={faTrash}/></i>
                                    Remove Card
                                </Button>
                            </i>
                        </OverlayTrigger>
                }

            </Form>
        );
    }
}

export default EditCardComponent;