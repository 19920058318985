import React from 'react';
import Image from './image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { handleClick, getAllDeployment } from "../utils/event_handling";
import HelpZendesk from "./help.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import { browserHistory } from 'react-router';
import {
    faPaperPlane,
    faUser, faReceipt, faAddressCard,
    faUserCog, faSignOutAlt,
    faHeadset, faBook,faQuestion
} from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import { Link } from 'react-router';
import OfflineComponent from './offlineComponent'
import { Offline } from "react-detect-offline";
import { get_document_link } from "../utils/common_utils";
import { SUBSCRIPTION_DOC } from "../utils/constant";

const userToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        href=""
        ref={ref}
        className="qd-header__topbar-icon dropdown-toggle"
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <FontAwesomeIcon color="#fff" icon={faUser} />
        {children}
    </span>
));

// const customLink = React.forwardRef(({link, tab, children, onClick}, ref) => (
//     <Link to={link} className={link.includes(tab) ? "dropdown-item active" : "dropdown-item"}>
//         {children}
//     </Link>
// ));




let activeItems = {
    'configure': 'nav-item',
    'profile': 'nav-item',
    'monitor': 'nav-item',
    'metrics': 'nav-item',
    'alerts': 'nav-item',
    'discover': 'nav-item'
};

class NavigationHeader extends React.Component {
    constructor(props) {
        super(props);
        this.getAllDeployment = getAllDeployment.bind(this);
        this.handleHelp = this.handleHelp.bind(this);
        this.newDeployment = this.newDeployment.bind(this)
        this.state = {
            zendeskShow: false,
            active: '',
            newDeploymentData: null,
            status: null,
            loader:true,
        }
        this.getAllDeployment(true);
    }


    componentDidUpdate(){
        if (window.location.pathname === '/deployment') {
            // Make deployment button active when it's inside the deployment screen
            $('.new-deployment').addClass('active');
            $('.dashboard').removeClass('active');
        }
        if (window.location.pathname === '/dashboard') {

            // Make Dashboard button active when it's inside the deployment screen
            $('.dashboard').addClass('active');
        }
    }

    componentDidMount() {
        $('body').addClass('theme-orange')
        let header = $(".qd-header");
        let body = $("body");
        let shrinkIt = header.height();
        $(window).scroll(function () {
            let scroll = $(window).scrollTop();
            if (scroll >= shrinkIt) {
                header.addClass("sticky-header");
                body.addClass("has-sticky-header");
            } else {
                header.removeClass("sticky-header");
                body.removeClass("has-sticky-header");
            }
        });
        $("#navBarToggler").click(function () {
            if ($("#navBarToggler").hasClass("collapsed")) {
                $("#navBarToggler").removeClass("collapsed");
                $("#navbarCollapse").addClass("show");
            } else {
                $("#navBarToggler").addClass("collapsed");
                $("#navbarCollapse").removeClass("show");
            }
        });



    }

    handleHelp() {
        //this.setState({zendeskShow:true});
        window.zE(function () {
            window.zE.show();
            window.zE.identify({
                name: localStorage.getItem('user_name'),
                email: localStorage.getItem('emailId'),
            });
        });
        window.zE.activate({ hideOnClose: false });
    }

    newDeployment() {
        // localStorage.removeItem('deployment_id');
        localStorage.removeItem('wizardStage');
        localStorage.removeItem('currentId');
        localStorage.removeItem('deploymentStatus');
        localStorage.setItem('currentStatus', 'step1')
        localStorage.setItem('validate', 'true')

        // NewDeployment will be true when user click create new deployment in dashboard screen

        if (this.props.secondDeployment === true && this.state.newDeploymentData != null && this.state.newDeploymentData.length < 1) {
            let currentStatus = 'step1'
            localStorage.setItem('validate', true)
            localStorage.setItem('deployment_name', "")
            localStorage.setItem('currentStatus', currentStatus);
            localStorage.setItem('selectedPlan', 'annually');
            browserHistory.push("/deployment");
        }
        else if (this.state.newDeploymentData != null && this.state.newDeploymentData.length > 0) {

            // newDeploymentData will be fetch using getAllDeployment()
            let currentStatus = this.state.newDeploymentData[0]['status']
            localStorage.setItem('deployment_id', this.state.newDeploymentData[0]['deployment_id']);
            localStorage.setItem('currentId', this.state.newDeploymentData[0]['deployment_id']);
            // Direct to wizard 2nd step
            if (this.state.newDeploymentData[0]['status'] === 'initiated') {
                localStorage.setItem("DeploymentDetails", JSON.stringify(this.state.newDeploymentData[0]));
                currentStatus = 'step2'
                localStorage.setItem('currentStatus', currentStatus);
                localStorage.setItem('deployment_name', this.state.newDeploymentData[0]['deployment_name']);
                setTimeout(() =>
                    browserHistory.push("/deployment"), 1000)
            }
            // Direct to wizard 3rd step
            else if (this.state.newDeploymentData[0]['status'] === "payment success") {
                currentStatus = 'step3'
                localStorage.setItem('currentStatus', currentStatus);
                browserHistory.push("/deployment");
            }
            // Direct to wizard 4th step
            else if (this.state.newDeploymentData[0]['status'] === "deployment initiated" || this.state.newDeploymentData[0]['status'] === "active" || this.props.data[0]['status'] === "failed") {
                currentStatus = 'step4'
                localStorage.setItem('currentStatus', currentStatus);
                localStorage.setItem('wizardStage', currentStatus);
                browserHistory.push("/deployment");
            }
            // No condition satisfied direct to 1st step
            else {
                setTimeout(() =>
                    browserHistory.push('/deployment'))
            }
        }
        else (
            setTimeout(() =>
                browserHistory.push('/deployment'))
        )


    }

    // Dashboard button call
    handleDashboard() {
        localStorage.setItem('deploymentStatus','deployed')
        browserHistory.push('/dashboard')
    }

    render() {
        for (let [key] of Object.entries(activeItems)) {
            activeItems[key] = "nav-item";
        }
        activeItems[this.props.page] = 'nav-item active'

        let status = localStorage.getItem('deploymentStatus')
        let href = '/deployment'
        if(window.location.pathname !== '/deployment' && status === 'deployed'){
            href = '/dashboard'
        }
        return (
            <>
                <dt className="title-text"><small >{"Subscription Management"}</small></dt>
                <header className="qd-header">

                    <div className="qd-header-container navbar navbar-expand-md">
                        <Navbar expand="lg">
                            <Navbar.Brand href={href}>
                                <div className="qd-header__brand">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src="logoLight"
                                    />

                                </div>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    {(window.location.pathname === '/deployment' 
                                        && localStorage.getItem('secondDeployment') === 'true')
                                        || window.location.pathname !== "/deployment" ?
                                        <>
                                            {/* user will get the dashboard button anyone of the dep;loyments is in dashboard, 
                                            During first deployment in profile page dashboard is not shown */}
                                            {(localStorage.getItem('secondDeployment') === 'true' && window.location.pathname === 'deployment') ||
                                            (window.location.pathname !== 'deployment' && localStorage.getItem('secondDeployment') === 'true') ||
                                            (localStorage.getItem('currentState') !== "deployed")
                                             ?
                                                <>
                                                        {(localStorage.getItem('secondDeployment') === 'true' || localStorage.getItem('secondDeployment') === true)?
                                                                <>
                                                                    <Nav.Item className="dashboard">
                                                                        <Nav.Link onClick={this.handleDashboard}>Dashboard</Nav.Link>
                                                                    </Nav.Item>
                                                                    {this.state.loader ?'':
                                                                    <>
                                                                        <Nav.Item className="new-deployment">
                                                                        {this.state.status === 'deployment initiated' ?
                                                                            <Nav.Link onClick={this.newDeployment} className="animated-striped-progress">Deployment In-Progress</Nav.Link>
                                                                                    : (this.state.status === 'failed') ?
                                                                                        <Nav.Link onClick={this.newDeployment} className="is-failed"><i className="nav-icon nav-icon__error"></i><span className='deployment-failed'>Deployment Failed</span></Nav.Link>
                                                                                        : (this.state.status === 'active') ?
                                                                                            <Nav.Link onClick={this.newDeployment}><i className="nav-icon nav-icon__success"></i>Deployment Ready</Nav.Link>
                                                                                            :
                                                                                            <Nav.Link onClick={this.newDeployment} id={this.state.active}>Create New Deployment</Nav.Link>
                                                                                }
                                                                    </Nav.Item>
                                                                    </>
                                                                    }
                                                                    
                                                                </>
                                                            :(this.state.loader)
                                                            ? 
                                                                ''
                                                            :
                                                                <Nav.Item className="new-deployment">
                                                                {this.state.status === 'deployment initiated' ?
                                                                    <Nav.Link onClick={this.newDeployment} className="animated-striped-progress">In-Progress Deployment</Nav.Link>
                                                                            : (this.state.status === 'failed') ?
                                                                                <Nav.Link onClick={this.newDeployment} className="is-failed"><i className="nav-icon nav-icon__error"></i>Deployment Failed</Nav.Link>
                                                                                : (this.state.status === 'active') ?
                                                                                    <Nav.Link onClick={this.newDeployment}><i className="nav-icon nav-icon__success"></i>Deployment Ready</Nav.Link>
                                                                                    :
                                                                                    <Nav.Link onClick={this.newDeployment} id={this.state.active}>Create New Deployment</Nav.Link>
                                                                }
                                                                </Nav.Item>
                                                        }
                                                </>
                                                : 
                                            ''}
                                        </>
                                        :
                                        ''
                                    }
                                </Nav>
                                <div className="qd-header__topbar-item dropdown d-md-none d-lg-inline-block">
                                    <div className="qd-header__topbar-wrapper">
                                        <div className="qd-header__topbar-icon"
                                            onClick={this.handleHelp}>
                                            <span className="nav-link">
                                                <FontAwesomeIcon onClick={this.handleHelp} color="#fff"
                                                    icon={faQuestion} />
                                            </span>
                                        </div>
                                    </div>
                                    {this.state.zendeskShow === true ? <HelpZendesk /> : ""}
                                </div>
                                <div className="qd-header__topbar-item qd-header__topbar-item--user">
                                    <div className="qd-header__topbar-wrapper dropdown">
                                        <span className="qd-header__topbar-welcome">Hi,</span>
                                        <span className="qd-header__topbar-username">
                                            {localStorage.getItem('user_name')}
                                        </span>
                                        <Dropdown alignRight>
                                            <Dropdown.Toggle as={userToggle}>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={this.onItemClick} to="/settings" as={Link} activeClassName="active"><i><FontAwesomeIcon
                                                    icon={faUserCog} /></i>Profile</Dropdown.Item>
                                                {((window.location.pathname !== '/deployment' && status === 'deployed') || localStorage.getItem('secondDeployment') === 'true') ?
                                                    <>
                                                        <Dropdown.Item to="/paymentprofile"
                                                            as={Link}
                                                            activeClassName="active" >
                                                            <i><FontAwesomeIcon icon={faAddressCard} /></i>
                                                            Payment Profile
                                                        </Dropdown.Item>

                                                        <Dropdown.Item to="/usagepayment"
                                                            as={Link}
                                                            activeClassName="active">
                                                            <i><FontAwesomeIcon icon={faPaperPlane} /></i>
                                                            Upcoming Payment
                                                        </Dropdown.Item>
                                                        <Dropdown.Item to="/billinghistory"
                                                            as={Link}
                                                            activeClassName="active">
                                                            <i><FontAwesomeIcon icon={faReceipt} /></i>
                                                            Billing History
                                                        </Dropdown.Item>
                                                    </> : ' '
                                                }
                                                <Dropdown.Item href="#!" onClick={this.handleHelp}>
                                                    <i><FontAwesomeIcon icon={faHeadset} /></i>
                                                    Help Desk & Support
                                                </Dropdown.Item>

                                                <Dropdown.Item href={get_document_link("index", SUBSCRIPTION_DOC)} target="_blank">
                                                    <i><FontAwesomeIcon icon={faBook} /></i>
                                                    Help Document
                                                </Dropdown.Item>
                                                <Dropdown.Item name='logout'
                                                    onClick={handleClick.bind(this)}>
                                                    <i><FontAwesomeIcon icon={faSignOutAlt} /></i>
                                                    Log Out
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Navbar.Collapse>
                        </Navbar>

                    </div>
                    <Offline polling={false}>
                        <OfflineComponent>
                        </OfflineComponent>
                    </Offline>
                </header>
            </>
        );
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(NavigationHeader);

export function SimpleHeader() {

    return (
        <header className="qd-header">
            <div className="qd-header-container navbar navbar-expand-md">
                <div className="qd-header__brand">
                    <a href="#!">
                        <Image
                            className="qd-header__brand-logo-default"
                            src="logoLight"
                        />
                    </a>
                </div>
                <button className="navbar-toggler collapsed" type="button"
                    data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="navbar-collapse collapse flex-row-reverse" id="navbarCollapse">
                    <div className="qd-header__topbar-item qd-header__topbar-item--user">
                        <div className="qd-header__topbar-wrapper dropdown">
                            <span className="qd-header__topbar-welcome">Hi,</span>
                            <span className="qd-header__topbar-username">
                                {localStorage.getItem('user_name')}
                            </span>
                            <Dropdown alignRight>
                                <Dropdown.Toggle as={userToggle}>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/settings">
                                        <i><FontAwesomeIcon icon={faUserCog} /></i>Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item href={get_document_link("index")} target="_blank">
                                        <i><FontAwesomeIcon icon={faBook} /></i>Help document
                                    </Dropdown.Item>
                                    <Dropdown.Item name='logout'
                                        onClick={handleClick.bind(this)}>
                                        <i><FontAwesomeIcon icon={faSignOutAlt} /></i>
                                        Log Out
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <Offline polling={false}>
                <OfflineComponent>
                </OfflineComponent>
            </Offline>
        </header>
    );

}
