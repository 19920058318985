import React from 'react';
import Image from '../components/image';
import Banner from '../components/banner';
import Login from './components/login';
import ErrorHandler from '../components/error_500';
import { postUIlogs } from "../utils/event_handling";



class Home extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.postUIlogs = postUIlogs.bind(this);
        this.state = {
            errorOccurred: false,
        };
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    render() {
        return (
            this.state.errorOccurred ?
                    <ErrorHandler></ErrorHandler>
            :
            <div className="user-prelogin">
                <div className="row gutter-reset">
                    <div className="col">
                        <div className="prelogin-container">
                            <Image
                                className="login-logo"
                                src="logo"
                            />
                            <Login router={this.props.router} isFirstTimeLogin={this.props.route.isFirstTimeLogin}/> 
                        </div>
                    </div>
                    <Banner/>
                </div>
            </div>
        );
    }
}

export default Home;