import React from "react";
import Image from "./image";

class OfflineComponent extends React.Component {

    constructor(props) {
            super(props);
            this.handleClick = this.handleClick.bind(this);
            this.state={
                show: true
            }
    }
    handleClick(){
        this.setState({show:false});
    }
    render() {
        if(this.state.show) {
            return (
            <>
                <div className="internet-issue">
                    <div className="card internet-issue-card">
                        <button type="button" onClick={this.handleClick} className="close close-sm"></button>
                        <Image
                            className="qd-header__brand-logo-default"
                            src="brokenInternet"
                        />
                        <div className="card-body">
                            <h5 className="card-title">Your connection was interrupted</h5>
                            <div className="card-text">
                                <p className="mb-0">It looks like we are having trouble connecting.</p>
                                <p className="mb-0">
                                    Please check your internet connection and try again.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
        }else {
            return('');
        }

    }
}


export default OfflineComponent;