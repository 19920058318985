import {iam_ip} from "./ip_details";
import CryptoJS from 'crypto-js';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {
    DateParts,
    DEFAULT_CURRENCY_SYMBOL,
    IS_NONE_CHECK,
    PROTOCOL,
    QualdoPlanCycle,
    IAM_SECRET_KEY,
    DRX_DOCUMENT_BASE_LINK, DOCUMENT_LINK_MAPPING,
    } from './constant';

const cachedLocalData = {};
export function getRedirectionLinkToChangePassword() {
    return "/password-reset"
}


export function addTime(inputDate, quantity, part = DateParts.YEAR) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const day = inputDate.getDate();

    let result = null;
    if (part === DateParts.YEAR) {
        result = new Date(year + quantity, month, day);
    } else if (part === DateParts.MONTH) {
        result = new Date(year, month + quantity, day);
    } else if (part === DateParts.DAY) {
        result = new Date(year, month, day + quantity);
    }

    return result;
}

export function getCurrencySymbol() {
    let currentPlan = getCurrentPlanDetailOfUser();
    if (currentPlan === null) {
        return DEFAULT_CURRENCY_SYMBOL;
    }

    return currentPlan.details.currency;
}

export function getQualdoPlanCycle() {
    let selectedPlanCycle = localStorage.getItem('qualdoPlanCycle');
    if (selectedPlanCycle === QualdoPlanCycle.TRIAL_14_DAYS) {
        selectedPlanCycle = QualdoPlanCycle.MONTHLY;
    }
    if (selectedPlanCycle === undefined || selectedPlanCycle === null ) {
        selectedPlanCycle = QualdoPlanCycle.YEARLY;
    }
    return selectedPlanCycle;
}

export function getCurrentPlanDetailOfUser() {
    let details_json = null;
    let planDetails = localStorage.getItem("plan_details");
    if (planDetails !== null && planDetails !== undefined
        && planDetails !== "null" && String(planDetails).trim().length > 0) {
        details_json = JSON.parse(planDetails);
    }

    return details_json;
}

export function removeQualdoPlanCycle() {
    localStorage.removeItem("qualdoPlanCycle");
    localStorage.removeItem("src_choose_plan")
}

export const toastObj = {
    "alert-success": {
        "className": "toast floating-top-toast toast-success",
        "icon": faCheckCircle,
        "heading": "Success!"
    },
    "alert-danger": {"className": "toast floating-top-toast toast-danger", "icon": faTimesCircle, "heading": "Error!"},
    "alert-info": {"className": "toast floating-top-toast toast-info", "icon": faTimesCircle, "heading": "Info!"},
    "alert-warning": {"className": "toast floating-top-toast toast-warning", "icon": faTimesCircle, "heading": "Warning!"}
};

export function decryptForLocalUsage(incomingString) {
    const decText = CryptoJS.AES.decrypt(incomingString, IAM_SECRET_KEY);
    const decryptedRes = decText.toString(CryptoJS.enc.Utf8);
    if (decryptedRes !== "") {
        return JSON.parse(decryptedRes)
    }
    return null
}

export function encryptForLocalUsage(incomingJSON) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(incomingJSON), IAM_SECRET_KEY);
    return ciphertext.toString();
}

export function get_document_link(page="index",doc_link){
    let token = localStorage.getItem("sessionToken");
    let document_link ="#"
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            removeLocalStorageKeys();
            window.location = '/';
        }
        token = item.value;
        if(page === undefined){
            page = "index"
        }
        let data = {"user_id":localStorage.getItem("user_id"),
                "team_id":localStorage.getItem("team_id"),
                "auth_token":token,
                "iam_ip":iam_ip
            }
        let ec = encryptForLocalUsage(data)
        data = ec.replace(/\+/g, 'qua111111d000');
    //   let authDetails = "?user_id="+localStorage.getItem("user_id")+
    //             "&team_id="+localStorage.getItem("team_id")+"&auth_token="+token+"&iam_ip="+iam_ip
        let authDetails = "?data="+data
        let url = DOCUMENT_LINK_MAPPING[page];
        if (url !== undefined){
            let sections = url.split("#")
            if(sections.length > 1){
                let section = sections[1];
                url= sections[0];
                authDetails = authDetails+"&section="+section;
            }
        }
        if(doc_link !== undefined){
            document_link = doc_link+ url+authDetails
        }
        else{
            document_link = DRX_DOCUMENT_BASE_LINK+ url+authDetails
        }
        

//      document_link = "https://docs.qualdo.ai/index.html?user_id="+localStorage.getItem("user_id")+
//                         "&team_id="+localStorage.getItem("team_id")+"&auth_token="+token+"&iam_ip="+iam_ip

   }
   return document_link
}

export function removeLocalStorageKeys() {

    // Clear localstorage
    localStorage.removeItem('DataSetupStatus');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('team_id');
    localStorage.removeItem('env_id');
    localStorage.removeItem("org_id");
    localStorage.removeItem('user_id');
    localStorage.removeItem('current_status');
    localStorage.removeItem('user_signup_type');
    localStorage.removeItem('isPlanExpired');
    localStorage.removeItem('qualdoPlan');
    localStorage.removeItem('qualdoPlanCycle');
    localStorage.removeItem('qualdoEdition');
    localStorage.removeItem('plan_details');
    localStorage.removeItem('permissions');
    localStorage.removeItem('user_name');
    localStorage.removeItem('qualdoPlanDetails');
    localStorage.removeItem('emailId');
    localStorage.removeItem('warning_message');
    localStorage.removeItem('selected_notification_id');
    localStorage.removeItem('featureAccess');

    // Clear localforage - local db memory and reset the values
}

export function getFeatureAccess() {
    let featureAccess = cachedLocalData.featureAccess;
    if (!IS_NONE_CHECK.includes(featureAccess)) {
        return featureAccess;
    }

    let fa = localStorage.getItem('featureAccess');
    if (IS_NONE_CHECK.includes(fa) || fa === '') {
        // Someone deleted feature access from local storage
        return null;
    }

    let featureAccessData = decryptForLocalUsage(fa);
    cachedLocalData.featureAccess = featureAccessData;
    return featureAccessData;
}

export function userValidation(data,type="username")
{
    if(data === "" || data ===null || data ===undefined){return false;}
    const urlRegex = RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    let result = (urlRegex.test(data));
    if(!result && type === "username")
    {
        const userNameRegex = RegExp(/^(?![_.~`!@#$%^&*()?<>;:"'{} ])([a-zA-Z0-9\u00C0-\u00ff .]*)$/);
        result = (userNameRegex.test(data));
        return result;
    }
    else if (!result && type === "input")
    {
        const userInput = RegExp(/^(?![_.~`!@#$%^&*()?<>;:"'{} ])([a-zA-Z0-9\u00C0-\u00ff -_ .]*)$/);
        result = (userInput.test(data));
        return result;
    }
}

export function customDateFormat(date_str){
    let d= new Date(date_str).toGMTString()
    d = d.split(' ');
    let result = d[1] +' '+ d[2]+ ", "+ d[3]
    return result
}

export function getProtocol(ipAddress) {
    if (ipAddress.includes("localhost")){
        return "http:";
    }

    return PROTOCOL;
}

export function getCompleteEndpoint(protocol, ip, end_point) {
    let _separator = "/";
    if (end_point.startsWith("/")) {
        _separator = "";
    }
    return protocol + '//' + ip + _separator + end_point;
}

export function isPlanChanged(selectedPlan, selectedCycle) {
    let currentUserPlan = getCurrentPlanDetailOfUser();
    if (currentUserPlan === null) {
        return true;
    }

    let currentPlanCycle = currentUserPlan.plan_cycle;
    let currentPlanName = currentUserPlan.plan_name;
    return (selectedPlan !== currentPlanName || selectedCycle !== currentPlanCycle);
}