import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { Formik ,ErrorMessage} from 'formik';
import * as yup from 'yup';
import PaymentSectionHeader from "./paymentSectionHeader";
import { ZIP_DATA, COUNTRY } from "../../../utils/billingAddressConstant";
import Select from 'react-select';
import {LEGAL_AGREEMENT_LINK} from "../../../utils/constant"
class BillingAddress extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            companyName: '', 
            addressLine1:'',
            addressLine2: '',
            country: '', 
            zipCode: '',
            state:'',
            city: '',
            sectionTitle: "Personal + Business Contact Details",
            optionscountry:COUNTRY,
            agreeCheck2:false,

        }
        
    }

    onSubmit(){
    }

    render() {
        let initialValues=this.state;
            let addressSchema =  yup.object({
                                     companyName: yup.string().min(3,"Company must be at least 3 characters").required("Company is a required field"),
                                     addressLine1: yup.string().min(3,"Address Line must be at least 3 characters").required("Address Line is a required field"),
                                     city: yup.string().required("City is a required field").matches(/^[A-Za-z ]+$/,"City should contains letters only").min(3,"City must be at least 3 characters"),
                                     state: yup.string().required("State is a required field").matches(/^[A-Za-z ]+$/,"State should contains letters only").min(3,"State must be at least 3 characters"),
                                     country: yup.string().required("Country is a required field"),
                                     zipCode: yup.number().required("Zip Code is a required field")
                                     .test(
                                         "Invalid Zipcode",
                                         function test(value){
                                            let country = this.parent.country
                                            let regex = ZIP_DATA[country]
                                            return regex.test(value)
                                         }  
                                     ),
                                 });
            return (
                <>
                    <PaymentSectionHeader sectionIndex={4}
                                          sectionTitle={this.state.sectionTitle}
                    />
                    <div className="qd-payment-body" ref={this.props.divRef}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={12}>
                                        <Formik
                                              validationSchema={addressSchema}
                                              onSubmit={this.onSubmit}
                                              initialValues={initialValues}
                                         >
                                         {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleBlur,
                                              handleSubmit,
                                              isSubmitting,
                                              isValid,
                                         }) => (
                                            <Form 
                                            name="customerAddressValidation"
                                            className="needs-validation" noValidate method="POST"
                                            onSubmit={handleSubmit}
                                        >
                                            <Form.Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="companyName">
                                                        <Form.Label>Company Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      name="companyName"
                                                                      onChange={value => {
                                                                        handleChange("companyName")(value);
                                                                        this.props.saveNewAddress("company_name", value.target.value, isValid);
                                                                      }}
                                                                      value={values.companyName}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={errors.companyName && touched.companyName}
                                                                      placeholder="Abc Company"/>
                                                    <Form.Control.Feedback type="invalid">
                                                       { touched.companyName && errors.companyName }
                                                    </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="addressLine1">
                                                        <Form.Label>Address line 1 <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      name="addressLine1"
                                                                      value={values.addressLine1}
                                                                      onChange={value => {
                                                                        handleChange("addressLine1")(value);
                                                                        this.props.saveNewAddress("address_line_1", value.target.value, isValid);
                                                                      }}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={errors.addressLine1 && touched.addressLine1}
                                                                      placeholder="123 Abc Street"/>
                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.addressLine1 && errors.addressLine1 }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="addressLine2">
                                                        <Form.Label>Address line 2 (optional)</Form.Label>
                                                        <Form.Control type="text"
                                                                      value={values.addressLine2}
                                                                      onChange={value => {
                                                                        handleChange("addressLine2")(value);
                                                                        this.props.saveNewAddress("address_line_2", value.target.value);
                                                                      }}
                                                                      name="addressLine2"
                                                                      placeholder="#1"/>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="city">
                                                        <Form.Label>City <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      value={values.city}
                                                                      onBlur={handleBlur}
                                                                      onChange={value => {
                                                                        handleChange("city")(value);
                                                                        this.props.saveNewAddress("city",value.target.value, isValid);
                                                                      }}
                                                                      isInvalid={errors.city && touched.city}
                                                                      name="city"
                                                                      placeholder="Columbus"/>
                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.city && errors.city }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="state">
                                                        <Form.Label>State/Province/Region <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      value={values.state}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={errors.state && touched.state}
                                                                      onChange={value => {
                                                                        handleChange("state")(value);
                                                                        this.props.saveNewAddress("state", value.target.value, isValid);
                                                                      }}
                                                                      name="state"
                                                                      placeholder="Kentucky"/>
                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.state && errors.state }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>

                                                <Col md={6}>
                                                    <Form.Group controlId="country">
                                                        <Form.Label>Country <span className="text-danger">*</span></Form.Label>                                                      
                                                            <Select         
                                                                    name="Country"    
                                                                    className="flex-grow-1"
                                                                    classNamePrefix='select-control'                                             
                                                                    options={COUNTRY}  
                                                                    onChange={selectedOption => {
                                                                        handleChange("country")(selectedOption.value);
                                                                        this.props.saveNewAddress("country", selectedOption.value, isValid);
                                                                        this.setState({ country: selectedOption.value });
                                                                    }}  
                                                                                                            
                                                                    onBlur={handleBlur}   
                                                                    isInvalid={errors.country && touched.country}                                                                         
                                                                    placeholder="Select a country"                                                                                                             
                                                                /> 
                                                            <ErrorMessage component="div" className="error-text" name="country" />
                                                            {/* <Form.Control.Feedback type="invalid">
                                                                {touched.country && errors.country}
                                                            </Form.Control.Feedback> */}
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="zipCode">
                                                        <Form.Label>ZIP code <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text"
                                                                      name="zipCode"
                                                                      value={values.zipCode}
                                                                      onChange={value => {
                                                                        handleChange("zipCode")(value);
                                                                        this.props.saveNewAddress("zip_code", value.target.value, isValid);
                                                                      }}
                                                                      onBlur={handleBlur}
                                                                      isInvalid={errors.zipCode && touched.zipCode}
                                                                      placeholder="12345"/>

                                                        <Form.Control.Feedback type="invalid">
                                                            { touched.zipCode && errors.zipCode }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                               
                                            </Form.Row>

                                            <Form.Group controlId="formBasicCheckbox"
                                                        className="mt-2">
                                                <Form.Label>Would you like to make this address as default address?</Form.Label>
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    name={"isPrimary"}
                                                    className="text-muted"
                                                    onChange={this.props.makeDefaultBillingAddress}
                                                    id={"custom-checkbox1"}
                                                    label={"Yes, please make this my default address."}
                                                />
                                            </Form.Group>
                                            {
                                                (this.props.billingAddresses.length > 0)
                                                    ?
                                                    <Button
                                                        className="btn-outline btn-grey btn-circle"
                                                        onClick={this.props.hideAddBillingSection}
                                                        type="submit">
                                                        Choose from existing billing addresses
                                                    </Button>
                                                    :
                                                    ''
                                            }
                                            <button type="submit"
                                            id = 'billing_address_id'
                                                          onClick={this.onSubmit}
                                                          className="btn btn-primary btn-circle show-success-toast d-none"
                                                          data-dismiss="modal">
                                                          <FontAwesomeIcon icon={faCheck} /> Update
                                                        </button>
                                        </Form>)}
                    </Formik>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Formik>
                        </Formik>
                        <Form.Group ref={this.props.agreementRef}
                            className="mt-3 mb-0">
                            <Form.Check
                                custom
                                type="checkbox"
                                className="text-muted"
                                id='agreeCheck2'
                                checked={this.state.agreeCheck2}
                                label={
                                    <span>I have read and understood the 
                                        <a 
                                            href={LEGAL_AGREEMENT_LINK}
                                            rel="noopener noreferrer" target="_blank"> End User Licence Agreement </a>
                                    </span>
                                }
                                onChange={e => {
                                    this.setState({agreeCheck2:!this.state.agreeCheck2})
                                    this.props.agreeCheck(!this.state.agreeCheck2)
                                }}
                            />
                             <div
                                name="agreement"
                                id = "agreeCheck_id"
                                className="agreement_class error-text d-none"
                                >
                                Please agree to the Licence Agreement
                            </div>
                        </Form.Group>
                    </div>
                </>
            );

    }
}

export default BillingAddress;
