import React from 'react';
import { browserHistory } from 'react-router';
import Image from '../components/image';
import { CopyRightsFooter } from '../components/copyrights';
import ReactWizard from "react-bootstrap-wizard";
import { Row, Col, Button, FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faStar, faLongArrowAltLeft, faArrowLeft, faCheckCircle, faTimesCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Form from 'react-bootstrap/Form';
import $ from 'jquery';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import * as yup from 'yup';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Formik, ErrorMessage } from 'formik';
import {
    toastObj
} from "../utils/common_utils";
import BillingAddress from "../deploy/components/paymentcomponents/billingAddress.js";

import {
    CONFIGURE_DATA_SOURCE_URL, PAYMENT_CARD_TYPES, PAYMENT_COMPONENT_STYLES,
    PAYMENT_FIELD_ID_MAP,
    QualdoPlanCycle, DEFAULT_SUBNET_RANGE, DEPLOYMENT_DATA, PRIVATE_CLOUD, 
    // QUALDO_CLOUD,
    DRX_DOCUMENT_BASE_LINK, GCP_REGIONS
} from "../utils/constant";
import Card from "react-bootstrap/Card";
import {
    getApi, planDetails, getBrainTreeAuthToken, getPaymentDetailsTableData,
    updateDeployment, triggerDeployment, submitUsersPayment, getDeployment,
    updateplancycle, validateCoupon, getDeploymentPlanDetails, call_post_api, call_update_api
} from "../utils/event_handling";
import { iam_ip } from "../utils/ip_details";
import Toast from "react-bootstrap/Toast";
import PaymentOptions from "../deploy/components/paymentcomponents/paymentOptions";
import PaymentSectionHeader from "../deploy/components/paymentcomponents/paymentSectionHeader";
import { ZIP_DATA } from "../utils/billingAddressConstant";
import Load from '../components/loadAction';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import CardHelpContent from './components/cardHelpContent'
import { setDeploymentData } from "../redux/actions";
import { connect } from "react-redux";
import TagsInput from "./TagsInput"
import Modal from 'react-bootstrap/Modal';
import {Mediakind} from "./../utils/mediaKind"

let braintree = require('braintree-web');

let step = 1
if (localStorage.getItem('deployStatus') === 'deployed') {
    localStorage.setItem('deployStatus', 'initiated')
}
let currentStep = ''

const renderTooltip = (props) => (
    <Tooltip {...props}>
        Need Help ?
    </Tooltip>
);

const closeTooltip = (props) => (
    <Tooltip {...props}>
        Remove to change plan
        {/* Promo Code ? */}
    </Tooltip>
);

class FirstStep extends React.Component {
    constructor(props) {
        super(props);
        this.deploymentNameRef = React.createRef();
        this.cloudRef = React.createRef();
        this.settingRef = React.createRef();
        this.authorizednwRef = React.createRef();
        this.isValidated = this.isValidated.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.getDeployment = getDeployment.bind(this);
        this.getDeploymentPlanDetails = getDeploymentPlanDetails.bind(this);
        this.planDetails = planDetails.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.promoCodeHandler = this.promoCodeHandler.bind(this);
        this.promoRepeatHandler = this.promoRepeatHandler.bind(this);
        this.promoSuccessHandler = this.promoSuccessHandler.bind(this);
        this.validateCoupon = validateCoupon.bind(this);
        this.handlevalidate = this.handlevalidate.bind(this);
        this.editSubnet = this.editSubnet.bind(this);
        this.updateDeployment = updateDeployment.bind(this)
        this.triggerDeployment = triggerDeployment.bind(this);
        // this.removeTags = this.removeTags.bind(this)
        // this.addTags = this.addTags.bind(this)
        // Iniitial value for Wizard Step-1.
        this.state = {
            deploymentDetails: null,
            deployment_name: '',
            compute_config: DEPLOYMENT_DATA['compute_config'],
            cloud_support: PRIVATE_CLOUD,
            requiredQualdoCloud:false,
            subscription: DEPLOYMENT_DATA['subscription'],
            cycle: DEPLOYMENT_DATA['cycle'],
            status: DEPLOYMENT_DATA['status'],
            subnet: DEFAULT_SUBNET_RANGE,
            account_id: '',
            service_account: '',
            region: '',
            // whitelisted_ip: '',  // Intially whitelisted_ip is set to false for wizard validation 
            // While sending data to backend it'll be set to null
            public_network: true,
            restricted_network: false,
            cloud: DEPLOYMENT_DATA['cloud'],
            edition: DEPLOYMENT_DATA['edition'],
            deployment_config: '',
            plan_price: null,
            toastStatus: false,
            toastMsg: null,
            toastClass: '',
            toastLink: null,
            toastIcon: null,
            toastHeading: null,
            errorOccurred: null,
            json_file: null,
            validCoupon: null,
            coupon: null,
            couponClass: 'null',
            discount: null,
            final_price: null,
            annualCoupon: null,
            montlyCoupon: null,
            showModal: false,
            helpSection: null,
            post: null,
            validate: null,
            enableEdit: false,
            loader: true,
            whitelisted_ip: [],
            errorClass: '',
            promo_form: 'Hidden',
            promo_text: 'Show',
            promo_success_text: 'Hidden',
            disableCycle: false,
            // elastic_search_key: "",
            // elasticSearch: false,
            placeHolder: "Enter IP Address",
            agreeCheck1: false,
            aggreeCheck2: false,
            checkBoxWarn: false,
        };


        // Restrict the user to move deployment screen without using Create new deployment button
        // create New Deployment ---> deployment screen.
        if (localStorage.getItem('currentStatus') === 'deployed') {
            browserHistory.push('/dashboard')
        }

        // Prefetch values using getDeployment when user toggle from wizard Step-1 -> Step-2
        this.planDetails();
        setTimeout(() =>
            this.getDeployment(), 100
        );
        setTimeout(() =>
            this.getDeploymentPlanDetails(), 200
        );
        this.getDeploymentPlanDetails();
        this.setState({ cycle: this.state.cycle })
    }

    // To handle the changes(Coupon code changes) done in wizard step-2 --> wizard Step-1 using getDerivedStateFromProps()
    static getDerivedStateFromProps(props, state) {
        if (props.wizardData !== undefined) {
            if (props.wizardData['Billing Details'] !== state.deploymentDetails) {
                if (props.wizardData['Billing Details'] !== undefined) {
                    if (props.wizardData['Billing Details']['deployment_config'] !== null) {
                        return {
                            discount: props.wizardData['Billing Details']['discount'],
                            deploymentDetails: props.wizardData['Billing Details'],
                            validCoupon: props.wizardData['Billing Details']['validCoupon'],
                            coupon: props.wizardData['Billing Details']['coupon'],
                            couponClass: props.wizardData['Billing Details']['couponClass'],
                            final_price: props.wizardData['Billing Details']['final_price'],
                            plan_price: props.wizardData['Billing Details']['plan_price'],
                            annualCoupon: props.wizardData['Billing Details']['annualCoupon'],
                            cycle: props.wizardData['Billing Details']['cycle'],
                            montlyCoupon: props.wizardData['Billing Details']['montlyCoupon'],
                            promo_form: props.wizardData['Billing Details']['promo_form'],
                            promo_text: props.wizardData['Billing Details']['promo_text'],
                            promo_success_text: props.wizardData['Billing Details']['promo_success_text'],
                            disableCycle: props.wizardData['Billing Details']['disableCycle'],
                        }
                    }
                }
            }
        }

        return null;
    }

    componentDidUpdate() {
        $('.nav-pills li a').each(function (i) {
            if ($(this).hasClass("checked") === true) {
                if ($(this).hasClass("active") === false) {
                    $(this).addClass('is-done');
                    $(this).removeClass('checked');
                }
            }
        });
        currentStep = localStorage.getItem('currentStatus')
        if (currentStep !== "step1") {
            $(".btn-next").trigger("click");
        }
        let next = "Next"
        let Retry = localStorage.getItem("Retry")
        if (Retry === 'true' ) {
            next = "Deploy Now"
        }
        $(".btn-previous").html(`<i class="icon-arrow-back"></i> Previous`);
        $(".btn-next").html(next + ` <i class="icon-arrow-right"></i>`);
        $(".btn-finish").html(`<i class="icon-check"></i> Finish`);
        // Will have loader until the page gets data from the
        if (this.state.loader === true) {
            $(".btn-next").addClass('d-none')
        }
        else {
            $(".btn-next").removeClass('d-none')
        }
    }

    isValidated() {
        if (this.state.validCoupon !== true) {
            this.setState({ validCoupon: null })
            this.setState({ couponClass: null })
            this.setState({ coupon: null })
            this.setState({ promo_form: 'Hidden' })
            this.setState({ promo_text: 'Show' })
        }

        // Re-deploy process
        if (this.state.status === 'failed') {
            let reDeployData = {}
            if ( this.state.cloud_support === PRIVATE_CLOUD && this.state['deployment_name'] && this.state['edition'] && this.state['region'] && this.state.status &&
                this.state['account_id'] && this.state['service_account'] && this.state.deployment_config !== null) {
                reDeployData['deployment_name'] = this.state.deployment_name
                reDeployData['region'] = this.state.region
                reDeployData['account_id'] = this.state.account_id
                reDeployData['service_account'] = this.state.service_account
                reDeployData['whitelisted_ip'] = this.state.whitelisted_ip
                reDeployData['deployment_config'] = this.state.deployment_config
                reDeployData['subnet'] = this.state.subnet
                // reDeployData['elastic_search_key'] = this.state.elastic_search_key
                reDeployData['status'] = 'deployment initiated'
                reDeployData['json_file'] = localStorage.getItem('json_file')
                this.updateDeployment(reDeployData)
                /**
                 * After updating the deployment
                 * Will call the trigger deployment function
                 */
                this.triggerDeployment()
                $('.deployment-failed').text('Deployment In-Progress')
                $('.nav-icon__error').addClass('d-none')
                $('.new-deployment').addClass('animated-striped-progress')
                localStorage.setItem('currentStatus', 'step4')
                localStorage.setItem('wizardStage', 'step4')
                localStorage.setItem('Retry', 'false')
                return true
            }
            // else if(this.state.cloud_support === QUALDO_CLOUD){
            //     reDeployData['deployment_name'] = this.state.deployment_name
            //     reDeployData['whitelisted_ip'] = this.state.whitelisted_ip
            //     // reDeployData['subnet'] = this.state.subnet
            //     // reDeployData['elastic_search_key'] = this.state.elastic_search_key
            //     reDeployData['status'] = 'deployment initiated'
            //     this.updateDeployment(reDeployData)
            //     $('.deployment-failed').text('Deployment In-Progress')
            //     $('.nav-icon__error').addClass('d-none')
            //     $('.new-deployment').addClass('animated-striped-progress')
            //     localStorage.setItem('currentStatus', 'step4')
            //     localStorage.setItem('wizardStage', 'step4')
            //     localStorage.setItem('Retry', 'false')
            // }
            else {
                return false
            }

        }
        if (this.state.restricted_network === true && this.state.whitelisted_ip.length < 1) {
            $(".whitelisted-ip").removeClass('d-none')
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            return false
        }

        // To handle scroll in Wizard-step2
        localStorage.setItem("Scroll", true)

        if (this.state.final_price !== null) {
            this.setState({ plan_price: this.state.final_price })

        }
        this.setState({ post: null })

        // Avoid validation when user get back to update deployment details 
        let validate = localStorage.getItem('validate');
        if (validate === 'true' || validate === true) {
            $("#deploy_validation").trigger("click");
        }
        this.setState({ json_file: localStorage.getItem('json_file') })
        step = 2
        // return true
        if (currentStep !== 'step1') {
            return true
        }
        let data = {}
        const teamId = localStorage.getItem('team_id');
        const admin_email = localStorage.getItem('emailId');
        data['admin_email'] = admin_email
        data['tenant_id'] = teamId
        data['status'] = this.state.status

        // Set whitelisted_ip to default value
        if (this.state.public_network === true) {
            data['whitelisted_ip'] = DEPLOYMENT_DATA['whitelisted_ip']
        }
        else {
            data['whitelisted_ip'] = this.state.whitelisted_ip
        }

        /**
         *  Scroll the user to unfilled field
         */
        if(this.state.deployment_name.length < 1){
            window.scrollTo(0, this.deploymentNameRef.current.offsetTop)
            return false

        }

        if(this.state.cloud_support === PRIVATE_CLOUD){
            if(this.state.account_id.length < 1 || this.state.service_account.length < 1){
                window.scrollTo(0, this.cloudRef.current.offsetTop)
                return false
    
            }
            else if(this.state.region.length < 1){
                window.scrollTo(0, this.settingRef.current.offsetTop)
                return false
            }
    
            if(this.state.agreeCheck1 === false){
                this.setState({checkBoxWarn:true})
                window.scrollTo(0, this.settingRef.current.offsetTop)
                return false
            }
        }

        if (this.state.cloud_support === PRIVATE_CLOUD 
            && this.state['deployment_name'] 
            && this.state['edition'] 
            && this.state['region'] 
            && this.state.status 
            && this.state['account_id'] 
            && this.state['service_account'] 
            && this.state.deployment_config !== null 
            && ((this.state.restricted_network === true && this.state['whitelisted_ip'].length > 0) 
                || (this.state.public_network === true)) && this.state.agreeCheck1 === true
            // &&
            //     ((this.state.elasticSearch === true && this.state.elastic_search_key.length > 0) 
            // || (this.state.elasticSearch === false))
                ) {
            data['deployment_name'] = this.state.deployment_name
            data['cloud_support'] = this.state.cloud_support
            data['cloud'] = this.state.cloud
            data['edition'] = this.state.edition
            data['compute_config'] = this.state.compute_config
            data['region'] = this.state.region
            data['subscription'] = this.state.subscription
            data['cycle'] = this.state.cycle
            data['account_id'] = this.state.account_id
            data['service_account'] = this.state.service_account
            data['deployment_config'] = this.state.deployment_config
            data['subnet'] = this.state.subnet
            // data['elastic_search_key'] = this.state.elastic_search_key
            data['json_file'] = localStorage.getItem('json_file')
            data['whitelisted_ip'] = this.state.whitelisted_ip
            if(data['whitelisted_ip'].length < 1){
                data['whitelisted_ip'] = DEPLOYMENT_DATA['whitelisted_ip']
            }
            this.setState({ json_file: data['json_file'] })
            if (localStorage.getItem('deployment_id') === null || localStorage.getItem('deployment_id') === undefined) {
                this.setState({ currentStep: 'step1' })
                let endPoint = "deployments/teams/" + teamId;
                localStorage.setItem("deployment_name", data['deployment_name']);
                let id = localStorage.getItem('deployment_id')
                if (id === null) {
                    call_post_api(iam_ip, endPoint, data).then((response) => {
                        if (response.code === 401) {
                            this.showToast("alert-danger", response.message, "")
                            this.setState({ errorOccurred: true })
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                            return false
                        } else if (response.code === 200) {
                            localStorage.setItem('currentStatus', 'step2')
                            this.setState({ 
                                    post: false,
                                    errorOccurred: false,
                                    currentStep: 'step2',
                                 })
                            localStorage.setItem('validate', false)
                            localStorage.setItem("deployment_id", response['deployment_id']);
                            localStorage.setItem("currentId", response['deployment_id']);
                            step = 2
                            return true
                        }
                    });
                }
            }
            else {
                const deploymentId = localStorage.getItem('deployment_id');
                let endPoint = "/deployments/update/" + deploymentId
                localStorage.setItem("deployment_name", data['deployment_name']);
                call_update_api(iam_ip, endPoint, data).then((response) => {
                    if (response.code !== 200) {
                        this.showToast("alert-danger", response.message, "")
                        this.setState({ errorOccurred: true,
                         });
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                        return false
                    } else if (response !== null && response.code === 200) {
                        localStorage.setItem('currentStatus', 'step2')
                        this.setState({ currentStep: 'step2',
                                        errorOccurred: false,
                        })
                        localStorage.setItem('validate', false)
                        localStorage.setItem("deployment_id", response['deployment_id']);
                        step = 2
                        return true
                    }
                });
            }
        }
        // else if(this.state.cloud_support === QUALDO_CLOUD && 
        //         this.state['deployment_name'].length > 0 &&
        //         ((this.state.restricted_network === true && this.state['whitelisted_ip'].length > 0) ||
        //         (this.state.public_network === true)))
        // {         
        //         data['deployment_name'] = this.state.deployment_name
        //         data['cloud_support'] = this.state.cloud_support
        //         data['cloud'] = this.state.cloud
        //         data['edition'] = this.state.edition
        //         data['compute_config'] = this.state.compute_config
        //         data['subscription'] = this.state.subscription
        //         data['cycle'] = this.state.cycle
        //         data['region'] = this.state.region
        //         data['account_id'] = this.state.account_id
        //         data['service_account'] = this.state.service_account
        //         data['subnet'] = DEFAULT_SUBNET_RANGE
        //         data['whitelisted_ip'] = this.state.whitelisted_ip
        //         if(data['whitelisted_ip'].length < 1){
        //             data['whitelisted_ip'] = DEPLOYMENT_DATA['whitelisted_ip']
        //         }
        //         if (localStorage.getItem('deployment_id') === null || localStorage.getItem('deployment_id') === undefined) {
        //             this.setState({ currentStep: 'step1' })
        //             let endPoint = "deployments/teams/" + teamId;
        //             localStorage.setItem("deployment_name", data['deployment_name']);
        //             let id = localStorage.getItem('deployment_id')
        //             if (id === null) {
        //                 call_post_api(iam_ip, endPoint, data).then((response) => {
        //                     if (response.code === 401) {
        //                         this.showToast("alert-danger", response.message, "")
        //                         this.setState({ errorOccurred: true })
        //                         window.scrollTo({
        //                             top: 0,
        //                             left: 0,
        //                             behavior: 'smooth'
        //                         });
        //                         return false
        //                     } else if (response.code === 200) {
        //                         localStorage.setItem('currentStatus', 'step2')
        //                         this.setState({ post: false,
        //                                         errorOccurred: false,
        //                                         currentStep: 'step2',
        //                         })
        //                         localStorage.setItem('validate', false)
        //                         localStorage.setItem("deployment_id", response['deployment_id']);
        //                         localStorage.setItem("currentId", response['deployment_id']);
        //                         step = 2
        //                         return true
        //                     }
        //                 });
        //             }
        //         }
        //         else {
        //             const deploymentId = localStorage.getItem('deployment_id');
        //             let endPoint = "/deployments/update/" + deploymentId
        //             localStorage.setItem("deployment_name", data['deployment_name']);
        //             call_update_api(iam_ip, endPoint, data).then((response) => {
        //                 if (response.code !== 200) {
        //                     this.showToast("alert-danger", response.message, "")
        //                     this.setState({ errorOccurred: true });
        //                     window.scrollTo({
        //                         top: 0,
        //                         left: 0,
        //                         behavior: 'smooth'
        //                     });
        //                     return false
        //                 } else if (response !== null && response.code === 200) {
        //                     localStorage.setItem('currentStatus', 'step2')
        //                     this.setState({ currentStep: 'step2',
        //                                     errorOccurred: false,

        //                      })
        //                     localStorage.setItem('validate', false)
        //                     localStorage.setItem("deployment_id", response['deployment_id']);
        //                     step = 2
        //                     return true
        //                 }
        //             });
        //         }
        //     }
        else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            return false
        }
    }

    onValueChange(event) {
        this.setState({
            cloud_support: event.target.value
        });
        // if(this.state.cloud_support === QUALDO_CLOUD){
        //     this.setState({
        //         restricted_network:false,
        //         public_network:true,
        //         // elasticSearch: false,
        //         account_id: '',
        //         service_account:'',
        //         deployment_config:'',
        //         region:'',
        //         deploymentDetails:null,
        //         // elastic_search_key:'',
        //     })
        // }
    }

    handleUpload = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            this.setState({ deployment_config: e.target.result });
        };
    };

    onClickHandler(e) {
        localStorage.setItem("selectedPlan", e);
        let selectedPlan = localStorage.getItem('selectedPlan');;
        this.setState({ selectedPlan: selectedPlan })
        let planprice = localStorage.getItem('annualplanprice');
        let plantitle = localStorage.getItem('annualplantitle');
        if (this.state.discount === null) {
            this.setState({ plan_price: planprice })
        }
        this.setState({ subscription: plantitle })

        if (e === 'annually') {
            this.setState({ promo_form: 'Hidden' })
            this.setState({ promo_text: 'Show' })
            this.setState({ couponClass: null })
            this.setState({ 'montlyCoupon': null })
            this.setState({ 'annualCoupon': null })
            this.setState({ 'validCoupon': null })
            this.setState({ 'coupon': null })
            this.setState({ 'final_price': null })
            this.promoCodeHandlerRemove()
            if (this.state.annualCoupon === false || this.state.annualCoupon === null) {
                this.setState({ plan_price: localStorage.getItem('annualplanprice') })
                localStorage.setItem("selectedplanprice", localStorage.getItem('annualplanprice'))
                localStorage.setItem('selectedPlan', e);
            }
            else {
                this.setState({ plan_price: localStorage.getItem('annualplanprice') })
                localStorage.setItem("selectedplanprice", this.state.plan_price)
                localStorage.setItem('selectedPlan', e);
            }

        }
        else {
            this.setState({ promo_form: 'Hidden' })
            this.setState({ promo_text: 'Show' })
            this.setState({ couponClass: null })
            this.setState({ 'annualCoupon': null })
            this.setState({ 'montlyCoupon': null })
            this.setState({ 'validCoupon': null })
            this.setState({ 'coupon': null })
            this.setState({ 'final_price': null })
            this.promoCodeHandlerRemove()
            if (this.state.montlyCoupon === false || this.state.montlyCoupon === null) {
                this.setState({ plan_price: localStorage.getItem('monthlyplanprice') })
                localStorage.setItem("selectedplanprice", localStorage.getItem('monthlyplanprice'))
                localStorage.setItem('selectedPlan', e);
            }
            else {
                this.setState({ plan_price: localStorage.getItem('monthlyplanprice') })
                localStorage.setItem("selectedplanprice", this.state.plan_price)
                localStorage.setItem('selectedPlan', e);
            }

        }
        this.setState({ cycle: e })

    }

    showToast(status, msg, link) {
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"]
        });

        if (link.trim().length > 0) {
            setTimeout(function () {
                window.location.href = link;
            }, 10000);
        }
    }

    closeToast() {
        this.setState({ toastStatus: false }, this.getData);
    }

    promoCodeHandler() {
        this.setState({ promo_form: 'Show' })
        this.setState({ promo_text: 'Hidden' })
    }
    promoCodeHandlerRemove() {
        this.setState({ promo_form: 'Hidden' })
        this.setState({ promo_text: 'Show' })
        this.setState({ disableCycle: false })
    }
    promoSuccessHandler() {
        // /coupon/validate
        let data = {
            'coupon_code': this.state.coupon,
            'user_id': localStorage.getItem('user_id'),
            'edition': this.state.edition,
            'cloud': this.state.cloud,
            'cycle': this.state.cycle
        }

        this.validateCoupon(data);
        setTimeout(() => {
            if (this.state.validCoupon === true) {
                this.setState({ disableCycle: true })
                this.setState({ promo_form: 'Hidden' })
                this.setState({ promo_success_text: 'Show' })

            }
        }, 1000)

    }

    promoRepeatHandler() {
        this.setState({ validCoupon: null })
        this.onClickHandler(this.state.cycle)
        this.setState({ promo_form: 'Hidden' })
        this.setState({ promo_success_text: 'Hidden' })

    }

    handlevalidate(event) {
        this.setState({ couponClass: '' })
        const { name, value } = event.target;
        $('.promo-code').addClass('d-none')
        this.setState({
            [name]: value
        });
    }


    handleClick(data) {
        this.setState({ showModal: true, helpSection: data });
    }

    hideModal = () => {
        this.setState({ showModal: false });
    };

    editSubnet() {
        this.setState({ enableEdit: true })
    }


    selectedTags = whitelisted_ip => {
        whitelisted_ip.map((ip) => {
            this.setState({ whitelisted_ip: ip })
            return null;
        })
        this.setState({ whitelisted_ip })
        $("#ip-address").removeClass("input-group")
        if (whitelisted_ip.length < 1) {
            $(".whitelisted-ip").removeClass('d-none')
        }
        else {
            $(".whitelisted-ip").addClass('d-none')
        }
    };

    render() {
        if (step === 1) {
            $(".card-footer").show();
        }
        // payment footer sticky
        window.onscroll = function (ev) {
            if ($(window).width() > 576) {
                if ((window.innerHeight + window.scrollY + 200) >= document.body.offsetHeight) {
                    $(".billing-payment-card").removeClass("sticky-payment-card")
                }
                else {
                    $(".billing-payment-card").addClass("sticky-payment-card")
                }
            } else {
                if ((window.innerHeight + window.scrollY + 260) >= document.body.offsetHeight) {
                    $(".billing-payment-card").removeClass("sticky-payment-card")
                }
                else {
                    $(".billing-payment-card").addClass("sticky-payment-card")
                }
            }

        };


        $('.custom-file-input').on('change', function () {
            var fileName = document.getElementById("customFileLangHTML").files[0].name;
            $(this).next('.custom-file-label').addClass("selected").html(fileName);
            localStorage.setItem("json_file", fileName)
        });

        $('.btn-next').on('click', function () {
            $('#payment-summary').toggleClass('d-none');
            // $('.col-lg-8').removeClass('offset-lg-2');
        });
        let initial_values = this.state;
        let validateschema = null;

        validateschema = yup.object({
            deployment_name: yup.string().required("Deployment Name is a required field"),
            region: yup.string().nullable(true).required("Cloud Region is a required field"),
            deployment_config: yup.string().required("Compute Profile is a required field"),
            account_id: yup.string().required("Project Id is a required field"),
            service_account: yup.string().required("Account Name is a required field"),
        });

        if(this.state.cloud_support === PRIVATE_CLOUD){
            // if (this.state.restricted_network === true && this.state.elasticSearch === true) {
            //     validateschema = yup.object({
            //         deployment_name: yup.string().required("Deployment Name is a required field"),
            //         coupon: yup.string().required("Required Coupon"),
            //         region: yup.string().required("Cloud Region is a required field"),
            //         edition: yup.string().required("Edition is a required field"),
            //         deployment_config: yup.string().required("Compute Profile is a required field"),
            //         account_id: yup.string().required("Project Id is a required field"),
            //         service_account: yup.string().required("Account Name is a required field"),
            //         whitelisted_ip: yup.array().required("IP Address is a required field"),
            //         elastic_search_key: yup.string().required("Elastics search key is a required field"),
            //     });
            // }
            if(this.state.restricted_network === true){
                validateschema = yup.object({
                    deployment_name: yup.string().required("Deployment Name is a required field"),
                    region: yup.string().required("Cloud Region is a required field"),
                    deployment_config: yup.string().required("Compute Profile is a required field"),
                    account_id: yup.string().required("Project Id is a required field"),
                    service_account: yup.string().required("Account Name is a required field"),
                    whitelisted_ip: yup.array().required("IP Address is a required field"),
                });
            }
            if(this.state.restricted_network !== true){
                validateschema = yup.object({
                    deployment_name: yup.string().required("Deployment Name is a required field"),
                    region: yup.string().required("Cloud Region is a required field"),
                    deployment_config: yup.string().required("Compute Profile is a required field"),
                    account_id: yup.string().required("Project Id is a required field"),
                    service_account: yup.string().required("Account Name is a required field"),
                });
            }
        }
        // else if(this.state.cloud_support === QUALDO_CLOUD){
        //      if(this.state.restricted_network === true){
        //         validateschema = yup.object({
        //             deployment_name: yup.string().required("Deployment Name is a required field"),
        //             whitelisted_ip: yup.array().required("IP Address is a required field"),
        //         });
        //     }
        //     else if(this.state.restricted_network === false){
        //         validateschema = yup.object({
        //             deployment_name: yup.string().required("Deployment Name is a required field"),
        //         });
        //     }
        // }


        // let validate = localStorage.getItem('validate');
        // if(validate === 'true'){
        //     validateschema = null
        // }
        // /**
        //  * While handling of submit if these variables are not null we're making validate to null
        //  * To avoid the error message during submit of coupon code
        //  * 
        //  */

        // let dataList = [this.state.deployment_name, this.state.account_id, this.state.service_account, this.state.deployment_config]
        // if(this.state.cloud_support === QUALDO_CLOUD){
        //     dataList = [this.state.deployment_name]
        // }
        // for (let value of dataList) {
        //     if (value.length > 0) {
        //         validateschema = null;
        //     }
        // }
        return (
            <>
                {this.state.loader ? <Load />
                    :
                    <>
                        <div>
                            <Toast className={this.state.toastClass}
                                onClose={() => this.closeToast()}
                                show={this.state.toastStatus} delay={5000} autohide>
                                <div className="d-flex align-items-center">
                                    {
                                        this.state.toastStatus ?
                                            <i className="toast-icon">
                                                <FontAwesomeIcon icon={this.state.toastIcon} />
                                            </i>
                                            :
                                            ''
                                    }

                                    <Toast.Body>
                                        <strong className="toast-title">{this.state.toastHeading}</strong>
                                        <p>{this.state.toastMsg}</p>
                                    </Toast.Body>
                                    <button type="button"
                                        onClick={this.closeToast}
                                        className="close"
                                        data-dismiss="toast"
                                        aria-label="Close" />
                                </div>
                            </Toast>
                        </div>
                        {
                            this.state.showModal ?
                                <CardHelpContent
                                    show={this.state.showModal}
                                    onHide={this.hideModal}
                                    name={this.state.helpSection}
                                /> : ""
                        }


                        <Formik
                            validationSchema={validateschema}
                            initialValues={initial_values}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                            }) => (

                                <Form name="deploymentDetails"
                                    className="needs-validation"
                                    noValidate
                                    onSubmit={handleSubmit}
                                    >
                                    <div className="qd-deployment_info">
                                        <span className="welcome-text">Let's get started with Qualdo™-DRX configuration details</span>
                                        <h3>Please provide cloud details for Qualdo deployment</h3>
                                        <p>Configuration details include enterprise cloud settings and subscription plan.</p>
                                    </div>
                                    <div className="qd-vertical-dot-step" ref={this.deploymentNameRef}>
                                        <div className="qd-vds__heading">
                                            <span className="dot"></span>
                                            <h4 className="qd-vds__title">Name This Deployment</h4>
                                        </div>
                                        <div className="qd-vds__content" required="True">
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    id="deployment_name"
                                                    placeholder="Enter Deployment Names"
                                                    value={this.state.deployment_name}
                                                    onChange={e => {
                                                        handleChange("deployment_name")(e.target.value);
                                                        this.setState({ deployment_name: e.target.value });
                                                    }}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.deployment_name && touched.deployment_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {touched.deployment_name && errors.deployment_name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>


                                        <div className="qd-vds__heading" ref={this.cloudRef}>
                                            <span className="dot"></span>
                                            <h4 className="qd-vds__title">Cloud Service
                                                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                                                    <small className="text-muted ml-2"
                                                        onClick={this.handleClick.bind(this, 'Cloud Service')}
                                                        data-toggle="modal" data-target="#model__help"
                                                    ><i>
                                                            <FontAwesomeIcon icon={faQuestionCircle} /></i></small>
                                                </OverlayTrigger>
                                            </h4>

                                        </div>
                                        <div className="qd-vds__content">
                                            <FormGroup>
                                                {/* {   this.state.requiredQualdoCloud 
                                                        &&
                                                    <Form.Check
                                                    custom
                                                    inline
                                                    type="radio"
                                                    value={QUALDO_CLOUD}
                                                    checked={this.state.cloud_support === QUALDO_CLOUD}
                                                    label={QUALDO_CLOUD}
                                                    onChange={this.onValueChange}
                                                    id="gcpradio1"
                                                    />
                                                } */}
                                                <Form.Check
                                                    custom
                                                    inline
                                                    type="radio"
                                                    value={PRIVATE_CLOUD}
                                                    checked={this.state.cloud_support === PRIVATE_CLOUD}
                                                    label={PRIVATE_CLOUD}
                                                    onChange={this.onValueChange}
                                                    id="gcpradio2"
                                                />

                                            </FormGroup>
                                            {this.state.cloud_support === PRIVATE_CLOUD?
                                                <>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="cloud">
                                                                Cloud Preference
                                                                    </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl
                                                            value="Google Cloud"
                                                            aria-describedby="cloud"
                                                        />
                                                    </InputGroup>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text >
                                                                Google Cloud Project ID
                                                                    </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control
                                                            type="text"
                                                            id="account_id"
                                                            placeholder="Enter Project ID"
                                                            value={this.state.account_id}
                                                            onChange={e => {
                                                                handleChange("account_id")(e.target.value);
                                                                this.setState({ account_id: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.account_id && touched.account_id}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {touched.account_id && errors.account_id}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>

                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text >
                                                                Service Account Name
                                                                    </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control
                                                            type="text"
                                                            id="service_account"
                                                            placeholder="Enter Service Account Name"
                                                            value={this.state.service_account}
                                                            onChange={e => {
                                                                handleChange("service_account")(e.target.value);
                                                                this.setState({ service_account: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.service_account && touched.service_account}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {touched.service_account && errors.service_account}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                    <div class="custom-file mb-3" onChange={this.handleUpload}>
                                                        <input type="file" class="custom-file-input" id="customFileLangHTML" />
                                                        <label class="custom-file-label" for="customFileLangHTML" data-browse="Service Account Json" >Choose Service Account Json</label>
                                                    </div>
                                                </>
                                            :
                                                ''
                                            }
                                        </div>
                                        {this.state.cloud_support === PRIVATE_CLOUD
                                        ?
                                            <>
                                                <div className="qd-vds__heading" ref={this.settingRef}>
                                                    <span className="dot"></span>
                                                    <h4 className="qd-vds__title">Settings
                                                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                                            <small className="text-muted ml-2"
                                                                onClick={this.handleClick.bind(this, 'Settings')}
                                                                data-toggle="modal" data-target="#model__help"
                                                            ><i>
                                                                    <FontAwesomeIcon icon={faQuestionCircle} /></i></small>
                                                        </OverlayTrigger>
                                                    </h4>
                                                </div>
                                                <div className="qd-vds__content">
                                                    <InputGroup className="mb-3 has-append-icon">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>
                                                                Subnet
                                                                    </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl
                                                            type="text"
                                                            id="subnet"
                                                            value={this.state.subnet}
                                                            disabled={!this.state.enableEdit}
                                                            onChange={e => {
                                                                handleChange("subnet")(e.target.value);
                                                                this.setState({ subnet: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.subnet && touched.subnet}
                                                            placeholder="Enter Subnet CIDR (10.101.0.0/16)"
                                                        />
                                                        <div className="input-group-append-icon">
                                                            <OverlayTrigger placement="top"
                                                                overlay={<Tooltip
                                                                >

                                                                    Edit Subnet Mask
                                                                        </Tooltip>}
                                                            >
                                                                <i onClick={this.editSubnet}>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </i>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <Form.Control.Feedback type="invalid">
                                                            {touched.subnet && errors.subnet}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text >
                                                                Region
                                                                    </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Select
                                                            id="region"
                                                            name="region"
                                                            className="flex-grow-1"
                                                            classNamePrefix='select-control'
                                                            options={GCP_REGIONS}
                                                            value={this.state.region.length > 1
                                                                ?
                                                                GCP_REGIONS.map((e) =>
                                                                (
                                                                    e['value'] === this.state.region
                                                                        ?
                                                                        e
                                                                        :
                                                                        ''
                                                                )
                                                                )
                                                                :
                                                                ''
                                                            }
                                                            onChange={selectedOption => {
                                                                handleChange("region")(selectedOption.value);
                                                                this.setState({ region: selectedOption.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            placeholder='Select Region'
                                                            isInvalid={errors.region && touched.region}
                                                        />
                                                        <ErrorMessage component="div" className="error-text" name="region" />
                                                        <Form.Control.Feedback type="invalid">
                                                            {touched.region && errors.region}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                    <Form.Group>
                                                        <Form.Check
                                                            custom
                                                            type="checkbox"
                                                            className="text-muted"
                                                            id='agreeCheck1'
                                                            checked={this.state.agreeCheck1}                                                            
                                                            label={
                                                                <span>I have read & understood the 
                                                                    <a 
                                                                        href={DRX_DOCUMENT_BASE_LINK+"gcloud_prerequisites.html"}
                                                                        rel="noopener noreferrer" target="_blank"> resource requirements </a> 
                                                                    for Qualdo™-DRX and I confirm the availability of the required resources exclusively for Qualdo™-DRX
                                                                </span>
                                                            }
                                                            onChange={e => {
                                                                handleChange("agreeCheck1")(e.target.value);
                                                                this.setState({ 
                                                                                agreeCheck1: !this.state.agreeCheck1 
                                                                                 
                                                                            });
                                                                let warn = this.state.agreeCheck1 === false?true:false
                                                                this.setState({checkBoxWarn:!warn})
                                                            }}
                                                            
                                                        />
                                                         {
                                                            this.state.checkBoxWarn === true ?
                                                                <div
                                                                    name="agreement"
                                                                    id = "agreeCheck1_id"
                                                                    className="agreement_class error-text"
                                                                    >
                                                                    Please agree to the resource requirements.
                                                                </div>
                                                            :
                                                                ''
                                                        }
                                                    </Form.Group>
                                                </div>    

                                                

                                                {/* Component for Elastic search field */}
                                                {/* <div className="qd-vds__heading">
                                                <span className="dot"></span>
                                                <h4 className="qd-vds__title">Elastic Search Api Key
                                                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                                                        <small className="text-muted ml-2"
                                                            onClick={this.handleClick.bind(this, 'Elastic Search')}
                                                            data-toggle="modal" data-target="#model__help"
                                                        ><i>
                                                                <FontAwesomeIcon icon={faQuestionCircle} /></i></small>
                                                    </OverlayTrigger>
                                                </h4>
                                                </div>

                                                <div className="qd-vds__content">
                                                <>
                                                    <FormGroup>
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            type="radio"
                                                            checked={this.state.elasticSearch}
                                                            label="Yes"
                                                            onChange={e => {
                                                                handleChange("elasticSearch")(e.target.value);
                                                                this.setState({ elasticSearch: true });
                                                            }
                                                            }
                                                            id="elastic-search-yes"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            type="radio"
                                                            checked={!this.state.elasticSearch}
                                                            label="No"
                                                            onChange={e => {
                                                                handleChange("elasticSearch")(e.target.value);
                                                                this.setState({ elasticSearch: false, elastic_search_key: null });
                                                            }
                                                            }
                                                            id="elastic-search-no"
                                                        />
                                                    </FormGroup>
                                                    {this.state.elasticSearch === true
                                                        ?
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text >
                                                                    API Key     
                                                                                // <OverlayTrigger placement="top" overlay={<Tooltip>If you have own elastic search account provide api key to create deployment else leave it blank</Tooltip>}>
                                                                                //     <i className="ml-1">
                                                                                //         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                                //             <g id="Group_3339" data-name="Group 3339" transform="translate(1167 -2254)">
                                                                                //                 <path id="info_FILL0_wght400_GRAD0_opsz48" d="M10.994,15.25h1.125v-4.5H10.994ZM11.5,9.362a.616.616,0,0,0,.44-.169.568.568,0,0,0,.179-.431.63.63,0,0,0-.179-.45.611.611,0,0,0-.881,0,.63.63,0,0,0-.179.45.568.568,0,0,0,.179.431.616.616,0,0,0,.441.169ZM11.5,19a7.257,7.257,0,0,1-2.906-.591A7.484,7.484,0,0,1,6.2,6.194a7.688,7.688,0,0,1,2.39-1.6,7.491,7.491,0,0,1,9.816,9.816,7.688,7.688,0,0,1-1.6,2.391,7.5,7.5,0,0,1-2.381,1.612A7.3,7.3,0,0,1,11.5,19Zm.019-1.125a6.113,6.113,0,0,0,4.5-1.865,6.182,6.182,0,0,0,1.856-4.529,6.128,6.128,0,0,0-1.856-4.5A6.15,6.15,0,0,0,11.5,5.125,6.159,6.159,0,0,0,6.991,6.981,6.134,6.134,0,0,0,5.125,11.5a6.143,6.143,0,0,0,1.866,4.51,6.165,6.165,0,0,0,4.528,1.865ZM11.5,11.5Z" transform="translate(-1169.5 2251.5)" />
                                                                                //                 <rect id="Rectangle_896" data-name="Rectangle 896" width="18" height="18" transform="translate(-1167 2254)" fill="none" />
                                                                                //             </g>
                                                                                //         </svg>
                                                                                //     </i>
                                                                                // </OverlayTrigger> 
                                                                            
                                                                            </InputGroup.Text>
                                                                        </InputGroup.Prepend>
                                                                        <Form.Control
                                                                                    type="text"
                                                                                    id="elastic_search_key"
                                                                                    className="form-group"
                                                                                    placeholder="Enter Elastic Search Api key"
                                                                                    value={this.state.elastic_search_key}
                                                                                    onChange={e => {
                                                                                        handleChange("elastic_search_key")(e.target.value);
                                                                                        this.setState({ elastic_search_key: e.target.value });
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    isInvalid={errors.elastic_search_key && touched.elastic_search_key}
                                                                                />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {touched.elastic_search_key && errors.elastic_search_key}
                                                                        </Form.Control.Feedback>
                                                                    </InputGroup>
                                                                :
                                                                    ''
                                                            }
                                                        </>
                                                    </div> 
                                                */}
                                            </>                                        
                                        :
                                            ''
                                        }



                                                <>
                                                <div className="qd-vds__heading">
                                                        <span className="dot"></span>
                                                        <h4 className="qd-vds__title">Authorized Network
                                                            <OverlayTrigger placement="top" overlay={renderTooltip}>
                                                        <small className="text-muted ml-2"
                                                            onClick={this.handleClick.bind(this, 'Authorized Network')}
                                                            data-toggle="modal" data-target="#model__help"
                                                        ><i>
                                                                <FontAwesomeIcon icon={faQuestionCircle} /></i></small>
                                                    </OverlayTrigger>
                                                </h4>
                                            </div>
                                            <div className="qd-vds__content">
                                                <>
                                                    <FormGroup>

                                                        <Form.Check
                                                            custom
                                                            inline
                                                            type="radio"
                                                            value={this.state.public_network}
                                                            checked={this.state.public_network}
                                                            label="Enable Public Access"
                                                            onChange={e => {
                                                                handleChange("public_network")(e.target.value);
                                                                this.setState({ public_network: !this.state.public_network });
                                                                this.setState({ restricted_network: !this.state.restricted_network });
                                                            }
                                                            }
                                                            id="public-network-access"
                                                        />

                                                        <Form.Check
                                                            custom
                                                            inline
                                                            type="radio"
                                                            value={this.state.restricted_network}
                                                            checked={this.state.restricted_network}
                                                            label="Enable Restricted Access"
                                                            onChange={e => {
                                                                handleChange("restricted_network")(e.target.value);
                                                                this.setState({ restricted_network: !this.state.restricted_network });
                                                                this.setState({ public_network: !this.state.public_network });
                                                            }
                                                            }
                                                            id="restricted-network-access"
                                                        />
                                                    </FormGroup>
                                                    {this.state.restricted_network === true
                                                        ?
                                                        <><Form >
                                                            <Form.Group controlId="whitelisted_ip">
                                                                <Form.Label>
                                                                    IP Address
                                                                            <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                192.168.1.1 or 191.168.1.1/24.
                                                                                Hint: hit "Enter" for each IP.

                                                                                    </Tooltip>}
                                                                    >
                                                                        <i className="icon-info-circle"></i>
                                                                    </OverlayTrigger>
                                                                </Form.Label>
                                                                <div
                                                                    id="ip-address"
                                                                    className="invalid-feedback">
                                                                    Please enter valid IP address
                                                                        </div>
                                                                <TagsInput
                                                                    selectedTags={this.selectedTags}
                                                                    tags={this.state.whitelisted_ip}
                                                                    placeholder={this.state.whitelisted_ip.lenght > 1 ? '' : this.state.placeHolder}
                                                                />
                                                                <div
                                                                    name="whitelisted_ip"
                                                                    className="whitelisted-ip error-text d-none"
                                                                >
                                                                    IP Address is a required field
                                                                        </div>
                                                            </Form.Group>
                                                        </Form>
                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </>


                                                {
                                                    (localStorage.getItem('Retry') === 'true')
                                                        ?
                                                        ''
                                                        :
                                                        <>
                                                            <div className='billing-price-estimation'>
                                                                <Card>
                                                                    <Card.Body>
                                                                        <span className='badge'>Price Estimation <sup><FontAwesomeIcon icon={faStar} /></sup></span>
                                                                        <div className="billing-period-selector">
                                                                            <div key={this.state.cycle.length} className="billing-period-selector__radio">
                                                                                <div className="custom-control custom-radio flex-column mb-sm-2">
                                                                                    <input
                                                                                        key={this.state.cycle}
                                                                                        type="radio"
                                                                                        id="customRadio11"
                                                                                        name="customRadio"
                                                                                        value="annually"
                                                                                        disabled={this.state.disableCycle}
                                                                                        className="custom-control-input"
                                                                                        defaultChecked={this.state.cycle === 'annually' ? true : ''}
                                                                                        onClick={(e) => this.onClickHandler(e.target.value)}

                                                                                    />
                                                                                    {this.state.cycle === 'annually' && this.state.annualCoupon === true ?
                                                                                        <label className="custom-control-label mr-1" htmlFor="customRadio11">Annual <small class="text-success">You’re saving ${this.state.discount}</small></label>
                                                                                        :
                                                                                        <label className="custom-control-label mr-1" htmlFor="customRadio11">Annual </label>

                                                                                    }


                                                                                </div>

                                                                                <div className="custom-control custom-radio">
                                                                                    <input type="radio"
                                                                                        id="customRadio22"
                                                                                        name="customRadio"
                                                                                        disabled={this.state.disableCycle}
                                                                                        value="monthly"
                                                                                        defaultChecked={this.state.cycle === QualdoPlanCycle.MONTHLY}
                                                                                        onClick={(e) => this.onClickHandler(e.target.value)}
                                                                                        className="custom-control-input" />

                                                                                    {this.state.cycle === 'monthly' && this.state.montlyCoupon === true ?
                                                                                        <label className="custom-control-label mr-1" htmlFor="customRadio22">Monthly <small class="text-success">You’re saving ${this.state.discount}</small></label>
                                                                                        :
                                                                                        <label className="custom-control-label mr-1" htmlFor="customRadio22">Monthly </label>

                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                            <div className="billing-period-selector__price">
                                                                                <h4>
                                                                                    <>
                                                                                        {(this.state.cycle === 'annually' && this.state.annualCoupon === true) ?
                                                                                            <h4>
                                                                                                <small><s class="text-danger">${localStorage.getItem('annualplanprice')}</s></small> ${this.state.plan_price}
                                                                                            </h4>
                                                                                            : (this.state.cycle === 'monthly' && this.state.montlyCoupon === true) ?
                                                                                                <h4>
                                                                                                    <small><s class="text-danger">${localStorage.getItem('monthlyplanprice')}</s></small> ${this.state.plan_price}
                                                                                                </h4>
                                                                                                :
                                                                                                <h4>
                                                                                                    ${this.state.plan_price}
                                                                                                </h4>

                                                                                        }

                                                                                    </>
                                                                                </h4>
                                                                                <small>
                                                                                    per {this.state.cycle === QualdoPlanCycle.YEARLY
                                                                                        ?
                                                                                        'annum'
                                                                                        :
                                                                                        'month'
                                                                                    }
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <div className="promocode-block mt-3">
                                                                                        {this.state.validCoupon === true ?
                                                                                            <span className="promocode-success-text d-block"><strong>{this.state.coupon}</strong> is applied!!
                                                                                                    <OverlayTrigger placement="top" overlay={closeTooltip}>
                                                                                                    <i className="ml-2 text-muted" onClick={this.promoRepeatHandler}><FontAwesomeIcon icon={faTimesCircle} /></i>
                                                                                                </OverlayTrigger>
                                                                                            </span>
                                                                                            :
                                                                                            ''
                                                                                        }

                                                                                        {this.state.promo_text === 'Show' ?
                                                                                            <span className="promocode-text" onClick={this.promoCodeHandler}>Have a Promo Code ?</span>

                                                                                            :
                                                                                            ''
                                                                                        }
                                                                                        {this.state.promo_form === 'Show' ?
                                                                                            <Formik>
                                                                                                <form>
                                                                                                    <div className="promocode-form show">
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            id='coupon'
                                                                                                            name='coupon'
                                                                                                            placeholder="Enter Promocode"
                                                                                                            value={this.state.coupon || ''}
                                                                                                            className={"form-control " + this.state.couponClass}
                                                                                                            onChange={this.handlevalidate} />
                                                                                                        <button className="btn btn-light ml-3" onClick={this.promoSuccessHandler}>Apply</button>
                                                                                                    </div>
                                                                                                </form>
                                                                                            </Formik>
                                                                                            :
                                                                                            ''
                                                                                        }
                                                                                        {this.state.validCoupon !== false ? '' :
                                                                                            <div
                                                                                                name="promo-code"
                                                                                                className="promo-code error-text d-none"
                                                                                            >
                                                                                                The promocode is invalid
                                                                                                </div>
                                                                                        }
                                                                                    </div>

                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <hr />

                                                                        <ol className="billing-user-guide">
                                                                            <li><FontAwesomeIcon icon={faStar} />This Subscription Fee has to be paid now: ${this.state.plan_price}</li>
                                                                            <li><FontAwesomeIcon icon={faStar} />Cloud usage cost will be billed monthly in your cloud account.</li>
                                                                            
                                                                            {/* {this.state.cloud_support === QUALDO_CLOUD
                                                                                ?   
                                                                                    ''
                                                                                :
                                                                                    <li><FontAwesomeIcon icon={faStar} />Cloud usage cost will be billed monthly in your cloud account.</li>
                                                                            } */}
                                                                        </ol>

                                                                    </Card.Body>
                                                                </Card>
                                                            </div>
                                                        </>
                                                }

                                            </div>
                                        </>

                                    </div>
                                    <button
                                        type="submit"
                                        id='deploy_validation'
                                        className="btn btn-primary btn-circle show-success-toast d-none"
                                        data-dismiss="modal"
                                    >
                                        <FontAwesomeIcon icon={faCheck} /> Update
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </>
                }
            </>
        );
    }
}



class SecondStep extends React.Component {
    constructor(props) {
        super(props);
        this.planDetails = planDetails.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.cardDetailsRef = React.createRef();
        this.billingDetailsRef = React.createRef()
        this.agreementRef = React.createRef()
        this.saveNewAddress = this.saveNewAddress.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.clientDidCreate = this.clientDidCreate.bind(this);
        this.hostedFieldsDidCreate = this.hostedFieldsDidCreate.bind(this);
        this.showPaymentPage = this.showPaymentPage.bind(this);
        this.cardTypeProcessor = this.cardTypeProcessor.bind(this);
        this.cardValidator = this.cardValidator.bind(this);
        this.getCardTypeImage = this.getCardTypeImage.bind(this);
        this.tokenizeFunction = this.tokenizeFunction.bind(this);
        this.handleCustomerName = this.handleCustomerName.bind(this);
        this.getComponentClass = this.getComponentClass.bind(this);
        this.makeCurrentAsDefaultPayment = this.makeCurrentAsDefaultPayment.bind(this);
        this.validateAddress = this.validateAddress.bind(this)
        this.addressSelectionHandler = this.addressSelectionHandler.bind(this);
        this.hasValidCardParts = this.hasValidCardParts.bind(this);
        this.createPaymentDataForUser = this.createPaymentDataForUser.bind(this);
        this.showAddBillingDetailsSection = this.showAddBillingDetailsSection.bind(this);
        this.hideAddBillingDetailsSection = this.hideAddBillingDetailsSection.bind(this);
        this.addressUpdateHandler = this.addressUpdateHandler.bind(this);
        this.makeDefaultBillingAddress = this.makeDefaultBillingAddress.bind(this);
        this.getSelectedPlan = this.getSelectedPlan.bind(this);
        this.getPaymentDetailsTableData = getPaymentDetailsTableData.bind(this);
        this.setAutoDebitStatus = this.setAutoDebitStatus.bind(this);
        this.getApi = getApi.bind(this);
        this.getBrainTreeAuthToken = getBrainTreeAuthToken.bind(this);
        this.submitUsersPayment = submitUsersPayment.bind(this);
        this.handlePay = this.handlePay.bind(this);
        this.handleReturn = this.handleReturn.bind(this);
        this.getDeployment = getDeployment.bind(this);
        this.getDeploymentPlanDetails = getDeploymentPlanDetails.bind(this);

        // Promo_code Functions
        this.promoCodeHandler = this.promoCodeHandler.bind(this);
        this.promoRepeatHandler = this.promoRepeatHandler.bind(this);
        this.promoSuccessHandler = this.promoSuccessHandler.bind(this);
        this.validateCoupon = validateCoupon.bind(this);
        this.handlevalidate = this.handlevalidate.bind(this);
        this.promoCodeSuccess = this.promoCodeSuccess.bind(this)
        this.agreeCheck = this.agreeCheck.bind(this)
        this.closeModal = this.closeModal.bind(this)
        var month = new Date(),
            nextBillingMonth = month.getDate() + '-' + (month.getMonth() + 1 + 1) + '-' + month.getFullYear();

        var year = new Date(),
            nextBillingYear = year.getDate() + '-' + (year.getMonth() + 1) + '-' + (year.getFullYear() + 1);
        this.state = {
            deploymentDetails: "",
            disabled: false,
            payment_details: '',
            plan_price: null,
            nextBillingMonth: nextBillingMonth,
            nextBillingYear: nextBillingYear,
            DeploymentData: '',
            customStep: 2,
            secondStep: "second step here",
            error_message: null,
            showNotAllowedPopup: false,
            reRenderNavComponent: false,
            planDetails: {},
            addNewCard: true,
            canShowAddBillingAddress: true,
            hostedFields: null,
            orderID: null,
            toastStatus: false,
            toastMsg: "",
            toastClass: "",
            toastLink: "",
            toastIcon: "",
            toastHeading: "",
            errorOccurred: true,
            unitCostForData: 52,
            addOnCostForData: 0,
            unitCostForModel: 9,
            addOnCostForModel: 0,
            nextBillingDate: null,
            cycleCost: 0,
            addOnCycles: [],
            paymentPageLoading: true,
            cardType: null,
            customerNameClass: '',
            enableSubmitButton: true,
            braintreeAuthorizationToken: null,
            customerName: "",
            coupon_code: null,
            make_default: true,
            isEdit: false,
            previousExpiryDate: "",
            previousCardNumber: "",
            previousCvv: "",
            availableCards: null,
            billingAddresses: [],
            selectedPayment: null,
            selectedAddress: null,
            disableSubmitBtn: false,
            newBillingAddress: { "isPrimary": false },
            paymentSuccess: false,
            braintreeLoading: false,
            autoDebit: false,
            selectedPlan: '',
            "sectionTitle": "Personal + Business Contact Details",
            validation: {
                cvv: {
                    is_valid: false,
                    is_invalid: false
                },
                expiration_date: {
                    is_valid: false,
                    is_invalid: false
                },
                card_number: {
                    is_valid: false,
                    is_invalid: false
                },
                cardHolderName: {
                    is_valid: false,
                    is_invalid: false
                },
            },
            validCoupon: null,
            coupon: null,
            couponClass: '',
            discount: null,
            final_price: null,
            annualCoupon: null,
            montlyCoupon: null,
            loader: false,
            deployment_name: '',
            compute_config: DEPLOYMENT_DATA['compute_config'],
            cloud_support: PRIVATE_CLOUD,
            subscription: DEPLOYMENT_DATA['subscription'],
            cycle: DEPLOYMENT_DATA['cycle'],
            status: DEPLOYMENT_DATA['status'],
            subnet: DEFAULT_SUBNET_RANGE,
            account_id: '',
            service_account: '',
            region: '',
            cloud: DEPLOYMENT_DATA['cloud'],
            promo_form: 'Hidden',
            promo_text: 'Show',
            promo_success_text: 'Hidden',
            disableCycle: false,
            showModal: false,
            failure_message: null,
            agreeCheck2:false
        }
        localStorage.setItem('firstPayment', true)
        this.planDetails()
        setTimeout(() => {
            this.getDeployment()
        }, 100);
        setTimeout(() =>
            this.getDeploymentPlanDetails(), 200
        );
        if (localStorage.getItem('coupon_code') !== null || localStorage.getItem('coupon_code') !== undefined) {
            this.setState({ 'annualCoupon': localStorage.getItem('annualCoupon') })
            this.setState({ 'montlyCoupon': localStorage.getItem('montlyCoupon') })
            this.setState({ 'final_price': localStorage.getItem('selectedplanprice') })
            this.setState({ 'discount': localStorage.getItem('discount') })
        }
        if (localStorage.getItem('validCoupon') === true) {
            this.promoCodeSuccess();
        }
    }


    componentDidMount() {
        $('#root').addClass('theme-orange')
    }

    componentDidUpdate(previousStats, currentState) {
        let scroll = 1
        if (currentState['braintreeAuthorizationToken'] === null && scroll === 1) {
            scroll += scroll
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
        if (this.state.validCoupon !== null) {
            if (this.state.validCoupon === false) {
                $('.promocode-form').addClass('show');
            }
        }

        if (localStorage.getItem('currentStatus') !== "step1") {
            $(".card-footer").hide();
        }

        if (this.state.coupon !== null) {
            $('.promocode-success-text').addClass('d-block');
        }

        if (step === 2) {
            step = 3
            // Render the Payment section 
            const bt_token = this.getBrainTreeAuthToken();
            this.setState({ braintreeAuthorizationToken: bt_token });
            this.showPaymentPage();
        }

        // When user click retry need to avoid intermediate steps
        if (step === 1) {
            setTimeout(function () {
                $(".btn-previous").trigger("click");
            }, 1);
        }
        if (this.state.status === 'deployment initiated') {
            setTimeout(function () {
                $(".btn-next").trigger("click");
            }, 1);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.wizardData['Cloud Details'] !== undefined) {
            if (props.wizardData['Cloud Details'] !== state.deploymentDetails) {
                //                if (props.wizardData['Cloud Details']['deployment_config'].length > 0 || "deployment_config" in props.wizardData['Cloud Details'] ) {
                if ("deployment_config" in props.wizardData['Cloud Details']) {
                    if (state.plan_price === null || state.plan_price === undefined) {
                        return {
                            state: props.wizardData["Cloud Details"],
                            discount: props.wizardData['Cloud Details']['discount'],
                            deploymentDetails: props.wizardData['Cloud Details'],
                            validCoupon: props.wizardData['Cloud Details']['validCoupon'],
                            coupon: props.wizardData['Cloud Details']['coupon'],
                            couponClass: props.wizardData['Cloud Details']['couponClass'],
                            final_price: props.wizardData['Cloud Details']['final_price'],
                            plan_price: props.wizardData['Cloud Details']['plan_price'],
                            annualCoupon: props.wizardData['Cloud Details']['annualCoupon'],
                            cycle: props.wizardData['Cloud Details']['cycle'],
                            montlyCoupon: props.wizardData['Cloud Details']['montlyCoupon'],
                            deployment_name: props.wizardData['Cloud Details']['deployment_name'],
                            account_id: props.wizardData['Cloud Details']['account_id'],
                            service_account: props.wizardData['Cloud Details']['service_account'],
                            region: props.wizardData['Cloud Details']['region'],
                            edition: props.wizardData['Cloud Details']['edition'],
                            promo_form: props.wizardData['Cloud Details']['promo_form'],
                            promo_text: props.wizardData['Cloud Details']['promo_text'],
                            promo_success_text: props.wizardData['Cloud Details']['promo_success_text'],
                            disableCycle: props.wizardData['Cloud Details']['disableCycle'],
                        }
                    }
                    else if (props.wizardData['Cloud Details']['plan_price'] != null) {
                        return {
                            state: props.wizardData["Cloud Details"],
                            discount: props.wizardData['Cloud Details']['discount'],
                            deploymentDetails: props.wizardData['Cloud Details'],
                            validCoupon: props.wizardData['Cloud Details']['validCoupon'],
                            coupon: props.wizardData['Cloud Details']['coupon'],
                            couponClass: props.wizardData['Cloud Details']['couponClass'],
                            final_price: props.wizardData['Cloud Details']['final_price'],
                            plan_price: props.wizardData['Cloud Details']['plan_price'],
                            annualCoupon: props.wizardData['Cloud Details']['annualCoupon'],
                            cycle: props.wizardData['Cloud Details']['cycle'],
                            montlyCoupon: props.wizardData['Cloud Details']['montlyCoupon'],
                            deployment_name: props.wizardData['Cloud Details']['deployment_name'],
                            account_id: props.wizardData['Cloud Details']['account_id'],
                            service_account: props.wizardData['Cloud Details']['service_account'],
                            region: props.wizardData['Cloud Details']['region'],
                            edition: props.wizardData['Cloud Details']['edition'],
                            promo_form: props.wizardData['Cloud Details']['promo_form'],
                            promo_text: props.wizardData['Cloud Details']['promo_text'],
                            promo_success_text: props.wizardData['Cloud Details']['promo_success_text'],
                            disableCycle: props.wizardData['Cloud Details']['disableCycle'],
                        }
                    }
                }
            }
        }
        return null;
    }
    setAutoDebitStatus() {
        this.setState({ autoDebit: !this.state.autoDebit })
    }

    showAddBillingDetailsSection() {
        this.setState({ canShowAddBillingAddress: true })
    }

    hideAddBillingDetailsSection() {
        this.setState({ canShowAddBillingAddress: false, newBillingAddress: { "isPrimary": false } })
    }

    hasValidCardParts() {
        const validation = this.state.validation;
        return (validation.card_number.is_valid &&
            validation.cvv.is_valid &&
            validation.expiration_date.is_valid &&
            validation.cardHolderName.is_valid
        );
    }

    scrollToCardDetails() {
        window.scrollTo(0, this.cardDetailsRef.current.offsetTop)
    }

    scrollToBillingDetails() {
        window.scrollTo(10, this.billingDetailsRef.current.offsetTop)
    }

    scrollToAgreement(){
        window.scrollTo(10, this.agreementRef.current.offsetTop)

    }

    addressSelectionHandler(status) {
        this.setState({ selectedAddress: status });
    }

    createPaymentDataForUser(details) {
        let newCard = this.state.addNewCard;

        let finalData = {
            "billing_cycle": localStorage.getItem('selectedPlan'),
            "order_id": this.state.orderID,
            "auto_debit": this.state.autoDebit,
            "plan_type": this.getSelectedPlan(),

        }
        if (this.state.coupon !== null) {
            finalData['coupon_code'] = this.state.coupon
        }

        if (newCard) {
            finalData["nonce"] = details["nonce"];
            finalData["customer_name"] = details["customer_name"];
            finalData["make_default"] = details["make_default"];
        } else {
            let paymentDetail = this.state.availableCards.filter(x => (
                String(x.id) === String(this.state.selectedPayment)))
            let paymentDetailElement = paymentDetail[0];
            finalData["payment_id"] = parseInt(String(paymentDetailElement.id));
            finalData["customer_name"] = paymentDetailElement.customerName;
        }

        let hasNewAddress = this.state.canShowAddBillingAddress;
        if (hasNewAddress) {
            let newAddress = this.state.newBillingAddress;
            newAddress["customer_name"] = finalData.customer_name;
            finalData["billing_address"] = newAddress
        } else {
            finalData["billing_address_id"] = parseInt(String(this.state.selectedAddress));
        }

        return finalData;
    }

    submitHandler(event) {
        // If user is adding new card detail, validate that all
        // corresponding values are filled correctly
        $("#billing_address_id").trigger("click");
        if (this.state.addNewCard) {
            let formIsInvalid = false;
            let retrievedState = this.state.hostedFields.getState();
            // Loop through the Hosted Fields and check
            // for validity, apply the is-invalid class
            // to the field container if invalid

            let validation = this.state.validation;
            for (let field of Object.keys(retrievedState.fields)) {
                if (!retrievedState.fields[field].isValid) {
                    // Mark the error component
                    let mappedID = PAYMENT_FIELD_ID_MAP[field];
                    if (validation === undefined) {
                        continue;
                    }

                    let currentCompValidation = validation[mappedID];
                    if (currentCompValidation === undefined || currentCompValidation == null) {
                        continue;
                    }

                    currentCompValidation.is_invalid = true;
                    formIsInvalid = true;
                }
            }

            if (this.state.customerName.trim().length === 0) {
                this.setState({ customerNameClass: 'is-invalid' });
                validation.cardHolderName.is_invalid = true;
                formIsInvalid = true;
            }

            this.setState({ validation: validation });

            if (formIsInvalid) {
                $("#icon-arrow").removeClass("d-none");
                $(".spinner-border").removeClass("d-inline-block")
                // skip tokenization request if any fields are invalid
                this.scrollToCardDetails();
                return;
            }
        }

        // If user is adding new billing address, validate that all
        // corresponding values are filled correctly
        if (this.state.canShowAddBillingAddress) {
            let isValidAddress = this.validateAddress();
            if (!isValidAddress) {
                $("#icon-arrow").removeClass("d-none");
                $(".spinner-border").removeClass("d-inline-block")
                this.setState({ disabled: false })
                this.scrollToBillingDetails();
                return;
            }
        }
        if(this.state.agreeCheck2 === false){
            $("#agreeCheck_id").removeClass("d-none");
            this.scrollToAgreement();
            $("#icon-arrow").removeClass("d-none");
            $(".spinner-border").removeClass("d-inline-block")
            this.setState({ disabled: false })
            return        
        }
        else{
            $("#agreeCheck_id").addClass("d-none");
        }
        // This case will happen, when user is adding new card detail
        // We will tokenize the user's card detail and then we will
        // use the tokenized card value along with the
        // newly added billing address or the chosen billing address which was already added
        this.state.hostedFields.tokenize(this.tokenizeFunction);
    }

    showPaymentPage() {
        // This function which will render the braintree's payment components such as
        // Card number, CVV, expiration date fields will be rendered by braintree components
        let braintreeAuthorizationToken = this.getBrainTreeAuthToken();
        braintree.client.create({
            authorization: braintreeAuthorizationToken
        }, this.clientDidCreate);
    }

    clientDidCreate(err, client) {
        braintree.hostedFields.create({
            client: client,
            styles: PAYMENT_COMPONENT_STYLES,
            fields: {
                number: {
                    selector: '#card_number',
                    type: "text",
                    placeholder: 'Enter your card number',
                    prefill: this.state.previousCardNumber

                },
                cvv: {
                    selector: '#cvv',
                    type: "password",
                    placeholder: 'cvv',
                    prefill: this.state.previousCvv

                },
                expirationDate: {
                    selector: '#expiration_date',
                    placeholder: 'MM/YY',
                    type: "text",
                    prefill: this.state.previousExpiryDate
                }
            }
        },
            this.hostedFieldsDidCreate);

    }

    cardTypeProcessor(event) {
        if (event.cards.length === 1) {
            const cardType = event.cards[0].type;
            this.setState({ cardType: cardType });
        } else {
            this.setState({ cardType: null });
        }
    }

    cardValidator(event) {
        const fieldName = event.emittedBy;
        let field = event.fields[fieldName];
        const currentFieldId = field.container.id;
        // Remove any previously applied error or warning classes
        let validation = this.state.validation;
        let isValidPreviously = validation[currentFieldId].is_valid;
        validation[currentFieldId].is_valid = false;
        validation[currentFieldId].is_invalid = false;

        if (field.isValid) {
            validation[currentFieldId].is_valid = true;
        } else if (field.isPotentiallyValid) {
            // skip adding classes if the field is
            // not valid, but is potentially valid
            if (isValidPreviously) {
                validation[currentFieldId].is_invalid = true;
            }
        } else {
            validation[currentFieldId].is_invalid = true;
        }

        this.setState({ validation: validation });
    }

    hostedFieldsDidCreate(err, hostedFields) {
        if (err === null) {
            this.setState({ hostedFields: hostedFields });
        }
        if (hostedFields !== undefined) {
            $("#icon-arrow").removeClass("d-none");
            $(".spinner-border").removeClass("d-inline-block")
            this.setState({ disabled: false })
            hostedFields.on('cardTypeChange', this.cardTypeProcessor);
            hostedFields.on('validityChange', this.cardValidator);
        }
        this.setState({ braintreeLoading: false });
    }

    tokenizeFunction(err, payload) {
        if (err) {
            this.props.enableSubmitButton(true);
        } else {
            let details = {
                "nonce": payload.nonce,
                "customer_name": this.state.customerName,
                "make_default": this.state.make_default
            };
            let finalData = this.createPaymentDataForUser(details);
            this.setState({ disableSubmitBtn: true });
            finalData['billing_cycle'] = this.state.cycle
            finalData['coupon_code'] = this.state.coupon
            this.setState({ payment_details: finalData });
            let cycleData = {}
            if (finalData['billing_cycle'] === 'monthly') {
                cycleData['billing_cycle'] = finalData['billing_cycle']
            }
            this.submitUsersPayment(this.state.payment_details);
            setTimeout(() =>
                updateplancycle(cycleData), 4000
            );
        }
    }

    addressUpdateHandler(event) {
        let address = this.state.newBillingAddress;
        address[event.target.name] = event.target.value;
        this.setState({ newBillingAddress: address });
    }

    makeDefaultBillingAddress(event) {
        let address = this.state.newBillingAddress;
        address[event.target.name] = !address.isPrimary;
        this.setState({ newBillingAddress: address });
    }

    getCardTypeImage() {
        return (<Image src={PAYMENT_CARD_TYPES[this.state.cardType]} />);
    }

    getComponentClass(component) {
        let reqClass = "form-control";
        let validation = this.state.validation;
        let componentInfo = validation[component];

        if (componentInfo === undefined || componentInfo === null) {
            return reqClass;
        }

        if (componentInfo.is_valid) {
            reqClass = `${reqClass} is-valid`;
        } else if (componentInfo.is_invalid) {
            reqClass = `${reqClass} is-invalid`;
        }

        return reqClass;
    }

    handleCustomerName(event) {
        const customerName = event.target.value;
        this.setState({ customerName: customerName });
        let validation = this.state.validation;
        let cardHolderDetail = validation["cardHolderName"];

        cardHolderDetail.is_valid = false;
        cardHolderDetail.is_invalid = false;

        if (!customerName.trim()) {
            $("#icon-arrow").removeClass("d-none");
            $(".spinner-border").removeClass("d-inline-block")
            this.setState({ disabled: false })
            cardHolderDetail.is_invalid = true;
        } else {
            cardHolderDetail.is_valid = true;
        }

        this.setState({ validation: validation });
    }

    makeCurrentAsDefaultPayment(event) {
        this.setState({ make_default: !this.state.make_default });
    }

    validateAddress() {
        let newAddress = this.state.newBillingAddress;
        let keys = ["company_name", "address_line_1", "city", "state", "zip_code", "country"];

        for (let i = 0; i <= (keys.length - 1); i++) {
            const currentKey = keys[i];
            const currentValue = newAddress[currentKey];
            if (currentValue === undefined || currentValue.length < 3) {
                $("#icon-arrow").removeClass("d-none");
                $(".spinner-border").removeClass("d-inline-block")
                this.setState({ disabled: false })
                return false
            }
            let country = newAddress['country'];
            let zip = newAddress['zip_code']
            if (currentKey === "country") {
                if (country !== undefined) {
                    let regex = ZIP_DATA[country]
                    return regex.test(zip)
                }
            }

        }
        return true
    }

    saveNewAddress(name, value, isValid) {
        let newBillingAddress = this.state.newBillingAddress;
        newBillingAddress[name] = value
        this.setState({ newBillingAddress: newBillingAddress });
    }

    agreeCheck(status){
        this.setState({agreeCheck2:status})      
    }


    getSelectedPlan() {
        let planType = 'professional'
        return planType;
    }

    isValidated() {
        let currentStatus = localStorage.getItem('currentStatus')
        if (currentStatus !== 'step2') {
            return true;
        }
    }

    handlePay() {
        $("#icon-arrow").addClass("d-none");
        $(".spinner-border").addClass("d-inline-block")
        this.setState({ disabled: true })
        this.submitHandler()

        setInterval(() => {
            if (this.state.errorOccurred === false) {
                $(".btn-next").trigger("click")
                step = step + 1
                localStorage.setItem("currentStatus", 'step3')
            }
        }, 10);

        let validation = this.state.validation;
        if (validation['cardHolderName'].is_valid === false || validation['card_number'].is_valid === false ||
            validation['cvv'].is_valid === false || validation['expiration_date'].is_valid === false) {
            $("#icon-arrow").removeClass("d-none");
            $(".spinner-border").removeClass("d-inline-block")
            this.setState({ disabled: false })
        }
    }

    handleReturn() {

        // Handle invalid and null coupon while handling go-back functionality
        // If the coupon is valid then it will refrect in step-1,
        // Else if the coupon is invalid will set state variable with initial values
        if (this.state.validCoupon !== true) {
            this.setState({ validCoupon: null })
            this.setState({ couponClass: null })
            this.setState({ coupon: null })
            this.setState({ promo_form: 'Hidden' })
            this.setState({ promo_text: 'Show' })
        }

        this.setState({ braintreeAuthorizationToken: null })
        $('.nav-pills li a').each(function (i) {
            if ($(this).hasClass("checked") === true) {
                if ($(this).hasClass("active") === false) {
                    $(this).remove('is-done');
                }
            }
        });

        // Avoid validation when user get back to update deployment details 
        localStorage.setItem('validate', false)

        localStorage.setItem("currentStatus", 'step1')
        this.getDeployment()
        let validation = this.state.validation;
        validation['expiration_date'].is_valid = false;
        validation['cvv'].is_valid = false;
        validation['card_number'].is_valid = false;
        validation['cardHolderName'].is_valid = false;

        // this.showPaymentPage();
        step = step - 2
        let braintree = this.state.braintreeLoading
        this.setState({ braintreeLoading: braintree })
        braintree = this.state.braintreeLoading

        setTimeout(() => {
            $(".btn-previous").trigger("click");
        }, 500)

        setTimeout(function () {
            $('.nav-pills li a').each(function () {
                $(this).removeClass('is-done');
            });
        }, 100);

    }

    onClickHandler(e) {
        localStorage.setItem("selectedPlan", e);
        let selectedPlan = localStorage.getItem('selectedPlan');;
        this.setState({ selectedPlan: selectedPlan })
        let planprice = localStorage.getItem('annualplanprice');
        let plantitle = localStorage.getItem('annualplantitle');
        if (this.state.discount === null) {
            this.setState({ plan_price: planprice })
        }
        this.setState({ subscription: plantitle })

        if (e === 'annually') {
            this.setState({ promo_form: 'Hidden' })
            this.setState({ promo_text: 'Show' })
            this.setState({ couponClass: null })
            this.setState({ 'montlyCoupon': null })
            this.setState({ 'annualCoupon': null })
            this.setState({ 'validCoupon': null })
            this.setState({ 'coupon': null })
            this.promoCodeHandlerRemove()
            localStorage.setItem('validCoupon', null)
            if (this.state.annualCoupon === false || this.state.annualCoupon === null) {
                this.setState({ plan_price: localStorage.getItem('annualplanprice') })
                localStorage.setItem("selectedplanprice", localStorage.getItem('annualplanprice'))
                localStorage.setItem('selectedPlan', e);
            }
            else {
                this.setState({ plan_price: localStorage.getItem('annualplanprice') })
                localStorage.setItem("selectedplanprice", this.state.plan_price)
                localStorage.setItem('selectedPlan', e);
            }

        }
        else {
            this.setState({ promo_form: 'Hidden' })
            this.setState({ promo_text: 'Show' })
            this.setState({ couponClass: null })
            localStorage.setItem('validCoupon', null)
            this.setState({ 'montlyCoupon': null })
            this.setState({ 'annualCoupon': null })
            this.setState({ 'validCoupon': null })
            this.setState({ 'coupon': null })
            this.promoCodeHandlerRemove()
            if (this.state.montlyCoupon === false || this.state.montlyCoupon === null) {
                this.setState({ plan_price: localStorage.getItem('monthlyplanprice') })
                localStorage.setItem("selectedplanprice", localStorage.getItem('monthlyplanprice'))
                localStorage.setItem('selectedPlan', e);
            }
            else {
                this.setState({ plan_price: localStorage.getItem('monthlyplanprice') })
                localStorage.setItem("selectedplanprice", this.state.plan_price)
                localStorage.setItem('selectedPlan', e);
            }

        }
        this.setState({ cycle: e })
    }

    promoCodeHandler() {
        this.setState({ promo_form: 'Show' })
        this.setState({ promo_text: 'Hidden' })
    }
    promoCodeHandlerRemove() {
        this.setState({ promo_form: 'Hidden' })
        this.setState({ promo_text: 'Show' })
        this.setState({ disableCycle: false })
    }
    promoSuccessHandler() {
        // /coupon/validate
        let data = {
            'coupon_code': this.state.coupon,
            'user_id': localStorage.getItem('user_id'),
            'edition': this.state.edition,
            'cloud': this.state.cloud,
            'cycle': this.state.cycle
        }
        this.validateCoupon(data);
        setTimeout(() => {
            if (this.state.validCoupon === true) {
                this.setState({ disableCycle: true })
                this.setState({ promo_form: 'Hidden' })
                this.setState({ promo_success_text: 'Show' })

            }
        }, 1000)

    }

    promoRepeatHandler() {
        this.setState({ validCoupon: null })
        this.onClickHandler(this.state.cycle)
        this.setState({ promo_form: 'Hidden' })
        this.setState({ promo_success_text: 'Hidden' })

    }

    handlevalidate(event) {
        this.setState({ couponClass: '' })
        const { name, value } = event.target;
        $('.promo-code').addClass('d-none')
        this.setState({
            [name]: value
        });
    }

    promoCodeSuccess() {
        this.setState({ promo_form: 'Hidden' })
        this.setState({ promo_success_text: 'Show' })
    }

    closeModal() {
        this.setState({ showModal: false })
    }

    render() {
        if (step >= 2) {
            if (localStorage.getItem('currentStatus') !== 'step1') {
                $(".card-footer").hide();
            }
        }
        return (
            <>
                <Modal show={this.state.showModal} size="md" centered>
                    <Modal.Body>
                        <button type="button" onClick={this.closeModal} class="icon-cross"><span class="sr-only">Close</span></button>
                        <div className="modal__alert p-sm-3">
                            <div className="modal_alert-icon">
                                <Image src="icon_paycard_error" />
                            </div>
                            <div className="modal_alert-text">
                                <h4 className="mb-3"><strong>Your transaction got failed !!</strong></h4>
                                <p><strong>{this.state.failure_message}</strong></p>
                            </div>
                            <div className="modal_alert-btn-group">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-circle"
                                    onClick={this.closeModal} >
                                    Dismiss
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {this.state.loader ?
                    <Load />
                    :
                    <>
                        {(this.state.deploymentDetails !== undefined && step >= 2) ?
                            <div>

                                <div className="qd-payment-block border-0 mr-0">
                                    <div className="heading-section">
                                        <h3 className="heading-section-title">Review & Pay to Get Started.</h3>
                                        <p>Please carefully review the details and pay now to begin deployment</p>
                                    </div>
                                    <div className="qd-payment-block">
                                        <div className="qd-payment-header">
                                            <h4 className="qd-payment-title">
                                                <span className="circle-number circle-number-gradient">
                                                    1
                                                </span>
                                                Cloud Configuration Details
                                            </h4>
                                            <span className="dotted-link text-info"
                                                disabled={this.state.disabled}
                                                onClick={this.handleReturn}>
                                                <i className="mr-1 mr-sm-2">
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </i>Change Details</span>
                                        </div>
                                        <div className="qd-payment-body">
                                            <Card className="fancy-card">
                                                <Card.Body>
                                                    <Row className="row-sm">
                                                        <Col md={6}>
                                                            <h6 className="card-subtitle text-muted mb-2">Cloud Settings</h6>
                                                            <p className="card-text mb-3">{this.state.deployment_name}</p>
                                                        </Col>
                                                        <Col md={6}>
                                                            <h6 className="card-subtitle text-muted mb-2">Edition</h6>
                                                            <ul className="pl-0">
                                                                <li key={"ds"}><strong>{this.state.edition}</strong>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col md={6}>
                                                            <h6 className="card-subtitle text-muted mb-2">Cloud</h6>
                                                            <div className="cloud-deployment-card mb-3">
                                                                <div className="card-icon">
                                                                    <Image src="gcp_brandMark" />
                                                                </div>
                                                                <div className="card-stacked">
                                                                    <h5 className="card-title">{this.state.cloud}</h5>
                                                                    <p className="card-text">{this.state.cloud_support}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <h6 className="card-subtitle mb-2 text-muted mb-2">Benefits</h6>
                                                            <ul className="pl-0 mb-2">
                                                                <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Data Reliablity Monitoring</li>
                                                                <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Alerts</li>
                                                                <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Notifications</li>
                                                                <li><FontAwesomeIcon icon={faCheck} className="text-success mr-2" />Data Quality Metrics</li>
                                                            </ul>
                                                        </Col>
                                                        <hr className="w-100 my-4" />
                                                        <Col md={12}>
                                                            <div className="deployment-summary">
                                                                <h6 className="card-subtitle text-muted mt-1 mb-3">Cloud Details</h6>
                                                                <div className="form-row mb-2">
                                                                    <label class="col-md-4 mb-1">Cloud Service</label>
                                                                    <Col md={7}>
                                                                        <span className="text-dark">{this.state.cloud_support}</span>
                                                                    </Col>
                                                                </div>
                                                                {/* {this.state.cloud_support === QUALDO_CLOUD?''
                                                                : */}
                                                                <>
                                                                    <div className="form-row mb-2">
                                                                        <label class="col-md-4 mb-1">Google Cloud Project ID</label>
                                                                        <Col md={7}>
                                                                            <span className="text-dark">{this.state.account_id}</span>
                                                                        </Col>
                                                                    </div>
                                                                    <div className="form-row mb-2">
                                                                        <label class="col-md-4 mb-1">Service Account Name</label>
                                                                        <Col md={7}>
                                                                            <span className="text-dark">{this.state.service_account}</span>
                                                                        </Col>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <label class="col-md-4 mb-1">Cloud/Region</label>
                                                                        <Col md={7}>
                                                                            <span className="text-dark">{this.state.cloud} - {this.state.region}</span>
                                                                        </Col>
                                                                    </div>
                                                                </>
                                                                {/* } */}
                                                                
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="qd-payment-block">
                                        <PaymentSectionHeader sectionIndex={2}
                                            sectionTitle={"Choose your billing cycle"}
                                        />

                                        <div className="qd-payment-body">
                                            <Card>
                                                <Card.Body>
                                                    <div className="billing-period-selector">
                                                        <div key={this.state.cycle.length} className="billing-period-selector__radio">
                                                            <div className="custom-control custom-radio flex-column mb-sm-2">

                                                                <input type="radio"
                                                                    id="customRadio1133"
                                                                    name="customRadio"
                                                                    value="annually"
                                                                    disabled={this.state.disableCycle}
                                                                    className="custom-control-input"
                                                                    defaultChecked={this.state.cycle}
                                                                    onClick={(e) => this.onClickHandler(e.target.value)}

                                                                />
                                                                {this.state.cycle === 'annually' && this.state.annualCoupon === true ?
                                                                    <label className="custom-control-label mr-1" htmlFor="customRadio1133">Annual <small class="text-success">You’re saving ${this.state.discount}</small></label>
                                                                    :
                                                                    <label className="custom-control-label mr-1" htmlFor="customRadio1133">Annual </label>

                                                                }

                                                            </div>

                                                            <div className="custom-control custom-radio">
                                                                <input type="radio"
                                                                    id="customRadio2244"
                                                                    name="customRadio"
                                                                    disabled={this.state.disableCycle}
                                                                    value="monthly"
                                                                    defaultChecked={this.state.cycle === QualdoPlanCycle.MONTHLY}
                                                                    onClick={(e) => this.onClickHandler(e.target.value)}
                                                                    className="custom-control-input" />

                                                                {this.state.cycle === 'monthly' && this.state.montlyCoupon === true ?
                                                                    <label className="custom-control-label mr-1" htmlFor="customRadio2244">Monthly <small class="text-success">You’re saving ${this.state.discount}</small></label>
                                                                    :
                                                                    <label className="custom-control-label mr-1" htmlFor="customRadio2244">Monthly </label>

                                                                }

                                                            </div>

                                                        </div>
                                                        <div className="billing-period-selector__price">
                                                            <h4>
                                                                <>
                                                                    {(this.state.cycle === 'annually' && this.state.annualCoupon === true) ?
                                                                        <h4>
                                                                            <small><s class="text-danger">${localStorage.getItem('annualplanprice')}</s></small> ${this.state.plan_price}
                                                                        </h4>
                                                                        : (this.state.cycle === 'monthly' && this.state.montlyCoupon === true) ?
                                                                            <h4>
                                                                                <small><s class="text-danger">${localStorage.getItem('monthlyplanprice')}</s></small> ${this.state.plan_price}
                                                                            </h4>
                                                                            :
                                                                            <h4>
                                                                                ${this.state.plan_price}
                                                                            </h4>
                                                                    }
                                                                </>
                                                            </h4>
                                                            <small>
                                                                per {this.state.cycle === QualdoPlanCycle.YEARLY
                                                                    ?
                                                                    'annum'
                                                                    :
                                                                    'month'
                                                                }
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="promocode-block mt-3">
                                                                {this.state.validCoupon === true ?
                                                                    <span className="promocode-success-text d-block"><strong>{this.state.coupon}</strong> is applied!!
                                                                        <OverlayTrigger placement="top" overlay={closeTooltip}>
                                                                            <i className="ml-2 text-muted" onClick={this.promoRepeatHandler}><FontAwesomeIcon icon={faTimesCircle} /></i>
                                                                        </OverlayTrigger>
                                                                    </span>
                                                                    :
                                                                    ''
                                                                }
                                                                {this.state.promo_text === 'Show' ?
                                                                    <span className="promocode-text" onClick={this.promoCodeHandler}>Have a Promo Code ?</span>
                                                                    :
                                                                    ''
                                                                }
                                                                {this.state.promo_form === 'Show' ?
                                                                    <div className="promocode-form show">
                                                                        <input
                                                                            type="text"
                                                                            id='coupon'
                                                                            name='coupon'
                                                                            placeholder="Enter Promocode"
                                                                            value={this.state.coupon || ''}
                                                                            className={"form-control " + this.state.couponClass}
                                                                            onChange={this.handlevalidate}
                                                                        />

                                                                        <button className="btn btn-light ml-3" onClick={this.promoSuccessHandler}>Apply</button>
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }

                                                                {this.state.validCoupon !== null && this.state.validCoupon === false ?
                                                                    <div className='invalid-feedback'>The promocode is invalid</div>
                                                                    :
                                                                    ''}

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <div className="renew-subscription-info mw-100 mb-2">
                                                        <h6>
                                                            Automatically renew my subscription
                                                        </h6>
                                                        <div className="custom-control custom-switch custom-switch-sm orange-switch ml-2">
                                                            <input id="paymentPageAutoDebit"
                                                                className="custom-control-input"
                                                                onChange={this.setAutoDebitStatus}
                                                                type="checkbox" />
                                                            <label className="custom-control-label"
                                                                htmlFor={"paymentPageAutoDebit"} />
                                                        </div>
                                                    </div>
                                                    <div class="mb-2">
                                                        <ol className="billing-user-guide">
                                                            <li><FontAwesomeIcon icon={faStar} />This Subscription Fee has to be paid now: ${this.state.plan_price}</li>
                                                            <li><FontAwesomeIcon icon={faStar} />Cloud usage cost will be billed monthly in your cloud account.</li>
                                                            {/* {this.state.cloud_support === QUALDO_CLOUD
                                                                ?   
                                                                    ''
                                                                :
                                                                    <li><FontAwesomeIcon icon={faStar} />Cloud usage cost will be billed monthly in your cloud account.</li>
                                                            } */}
                                                        </ol>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>

                                    </div>
                                    <div className="qd-payment-block">
                                        <PaymentSectionHeader sectionIndex={3}
                                            sectionTitle={"Enter Payment Details"}

                                        />
                                        <div className="qd-payment-body" ref={this.cardDetailsRef}>
                                            <Card>
                                                <Card.Body>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form className={"payment__card"}>
                                                                <PaymentOptions />
                                                                {
                                                                    this.state.braintreeLoading ?
                                                                        <Load /> : ''
                                                                }
                                                                <Form.Row style={{ display: this.state.braintreeLoading ? "none" : "block" }}>
                                                                    <Col xs={7}>
                                                                        <Form.Group
                                                                            controlId="formCardName">
                                                                            <Form.Label>Name
                                                                            on
                                                                                card <span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="Name"
                                                                                className={this.getComponentClass("cardHolderName")}
                                                                                onChange={this.handleCustomerName}
                                                                                defaultValue={this.state.customerName}
                                                                            />
                                                                            <div
                                                                                className="invalid-feedback">
                                                                                Invalid
                                                                                cardholder
                                                                                name
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row >
                                                                    <Col xs={7}
                                                                        style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                        className="form-group position-relative">
                                                                        <Form.Label
                                                                            className="hosted-fields--label">Credit
                                                                            card
                                                                            number <span className="text-danger">*</span></Form.Label>
                                                                        <div
                                                                            className={this.getComponentClass("card_number")}
                                                                            id="card_number" />
                                                                        <div
                                                                            className="invalid-feedback">
                                                                            Credit
                                                                            card
                                                                            number
                                                                            is
                                                                            required
                                                                        </div>
                                                                        {this.state.cardType === null
                                                                            ?
                                                                            '' :
                                                                            <div
                                                                                className="credit-card-icon">
                                                                                {this.getCardTypeImage()}
                                                                            </div>
                                                                        }
                                                                    </Col>

                                                                    <Col
                                                                        style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                        sm={3}
                                                                        xs={5} >
                                                                        <label
                                                                            htmlFor="expiration_date">Expiry <span className="text-danger">*</span></label>
                                                                        <div
                                                                            className={this.getComponentClass("expiration_date")}
                                                                            id="expiration_date" />
                                                                        <div
                                                                            className="invalid-feedback">
                                                                            Please
                                                                            provide
                                                                            valid
                                                                            expiration
                                                                            date
                                                                        </div>
                                                                    </Col>

                                                                    <Col
                                                                        style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                        xs={2}
                                                                        className="form-group">
                                                                        <Form.Label
                                                                            className="hosted-fields--cvv">CVV <span className="text-danger">*</span></Form.Label>
                                                                        <div
                                                                            className={this.getComponentClass("cvv")}
                                                                            id="cvv" />
                                                                        <div
                                                                            className="invalid-feedback">
                                                                            Security
                                                                            code
                                                                            required
                                                                        </div>
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Group
                                                                    style={{ display: this.state.braintreeLoading ? "none" : "block" }}
                                                                    controlId="formBasicCheckbox"
                                                                    bscustomprefix="custom-control"
                                                                    className="mt-2">
                                                                    <Form.Label>Would
                                                                    you like
                                                                    to
                                                                    make this
                                                                    card your
                                                                    primary
                                                                    payment
                                                                        method?</Form.Label>
                                                                    <Form.Check
                                                                        custom
                                                                        type="checkbox"
                                                                        name={"isPrimary"}
                                                                        className="text-muted"
                                                                        checked={this.state.make_default}
                                                                        onChange={this.makeCurrentAsDefaultPayment}
                                                                        defaultValue={this.state.make_default}
                                                                        id={"custom-checkbox2"}
                                                                        label={"Yes, please make the this my primary payment method"}
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        </Col>
                                                    </Row>

                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="qd-payment-block">
                                        <BillingAddress
                                            divRef={this.billingDetailsRef}
                                            selectedAddress={this.state.selectedAddress}
                                            makeDefaultBillingAddress={this.makeDefaultBillingAddress}
                                            addressUpdateHandler={this.addressUpdateHandler}
                                            canShowAddBillingAddress={this.state.canShowAddBillingAddress}
                                            showAddBillingSection={this.showAddBillingDetailsSection}
                                            hideAddBillingSection={this.hideAddBillingDetailsSection}
                                            addressSelectionHandler={this.addressSelectionHandler}
                                            billingAddresses={this.state.billingAddresses}
                                            onSubmit={this.handlePay}
                                            saveNewAddress={this.saveNewAddress}
                                            agreeCheck={this.agreeCheck}
                                            agreementRef={this.agreementRef}
                                        />
                                    </div>
                                    <div className="billing-payment-card sticky-payment-card">
                                        <div className="card-stacked">
                                            <div className="card-icon">
                                                <Image src="gcp_brandMark" />
                                            </div>
                                            <div className="card-content">
                                                <h5 className='card-title'>{this.state.deploymentDetails['cloud']}</h5>
                                                <h6 className='card-subtitle'>{this.state.deploymentDetails['cloud_support']} <strong>| {this.state.deploymentDetails['edition']}</strong></h6>
                                                <hr className="card-divider" />
                                                {this.state.cycle === 'annually' ?
                                                    <p className='card-text'>Billing cycle <strong>Annual</strong></p>

                                                    :
                                                    <p className='card-text'>Billing cycle <strong>Month</strong></p>

                                                }
                                                {this.state.cycle === 'annually' ?
                                                    <p className='card-text'>Next billing date <strong>{this.state.nextBillingYear}</strong></p>

                                                    :
                                                    <p className='card-text'>Next billing date <strong>{this.state.nextBillingMonth}</strong></p>
                                                }
                                            </div>
                                        </div>
                                        <div className="card-action">
                                            <div className="total-billing-price">
                                                {
                                                    this.state.cycle === 'annually' ?
                                                        <>
                                                            <h4>
                                                                ${this.state.plan_price}
                                                            </h4>
                                                            <p>Total Annual Bill</p>
                                                        </>
                                                        :
                                                        <>
                                                            <h4 >
                                                                ${this.state.plan_price}

                                                            </h4>
                                                            <p>Total Monthly Bill</p>
                                                        </>
                                                }
                                            </div>
                                            <div className="payment-btn-action">
                                                <button className="btn btn-outline btn-grey btn-circle mr-2"
                                                    disabled={this.state.disabled}
                                                    onClick={this.handleReturn}><span className="d-none d-md-block">Go Back</span><FontAwesomeIcon icon={faArrowLeft} className="d-block d-md-none mr-0" />
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-circle"
                                                    disabled={this.state.disabled}
                                                    onClick={this.handlePay}>Pay Now to Deploy <FontAwesomeIcon id="icon-arrow" icon={faArrowRight} className="ml-1 mr-0"/>
                                                    <span class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                                                    <FontAwesomeIcon id="check-circle" icon={faCheckCircle} className="ml-1 mr-0 d-none" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </>
                }
            </>
        );
    }
}
class ThirdStep extends React.Component {
    constructor(props) {
        super(props);
        this.isValidated = this.isValidated.bind(this);
        this.triggerDeployment = triggerDeployment.bind(this);
        this.getDeployment = getDeployment.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            customStep: 3,
            thirdStep: "third step here",
            disabled: false,
            currentStep: '',
            loader: true,
            status: ''
        };
        setTimeout(() => {
            this.setState({ loader: false })
        }, 500)
    }
    componentDidMount() {
        this.getDeployment();
    }
    componentDidUpdate() {
        if (localStorage.getItem('currentStatus') !== 'step1') {
            $(".card-footer").hide();
        }
        // When user click retry need to avoid intermediate steps(step2 and step3)
        if (step === 1) {
            setTimeout(function () {
                $(".btn-previous").trigger("click");
            }, 1);
        }

        if (this.state.status === 'deployment initiated') {
            setTimeout(function () {
                $(".btn-next").trigger("click");
            }, 1);
        }
    }

    isValidated() {

        if (localStorage.getItem("wizardStage") === 'step4') {
            return true
        }
        if (localStorage.getItem('deploymentStatus') === 'deployed') {
            return true;
        }
        else {
            return false
        }
    }

    handleSubmit() {
        this.setState({ disabled: true })
        this.triggerDeployment()
        localStorage.setItem('currentStatus', 'step4')
        localStorage.setItem('wizardStage', 'step4')
        $(".btn-next").trigger("click");
    }

    render() {
        return (
            <>
                {this.state.loader
                    ?
                    <Load />
                    :
                    <>
                        {(step === '3' || localStorage.getItem("currentStatus") === "step3") ?
                            <div className="qd-payment-success">
                                <div className="qd-payment-success__content">
                                    <Image src="illustration_paymentSuccess"
                                        alt="Payment Success" />
                                    <h2>Your Payment was Successful</h2>
                                    <p>Click below to get started on Qualdo™ deployment.</p>
                                    <Button className="btn-circle" onClick={this.handleSubmit} disabled={this.state.disabled}>Deploy Now <FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-0" /></Button>
                                </div>
                            </div>

                            :
                            this.isValidated()
                        }
                    </>}
            </>
        )

    }
}

class ForthStep extends React.Component {
    constructor(props) {
        super(props);
        this.isValidated = this.isValidated.bind(this);
        this.getDeployment = getDeployment.bind(this);
        this.deploymentRetry = this.deploymentRetry.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.triggerDeployment = triggerDeployment.bind(this);
        this.redeployDeployment = this.redeployDeployment.bind(this);
        this.handleErrorCollapse = this.handleErrorCollapse.bind(this);
        this.state = {
            customStep: 4,
            thirdStep: "third step here",
            progressStatus: 'deployment initiated',
            time: 5,
            statusText: null,
            currentStep: '',
            errorOccurred: '',
            loader: true,
            showModal: false,
            status: '',
            prerequesties: null

        };
    }
    componentDidMount() {
        setTimeout(() =>
            this.getDeployment()
            , 500)
    }
    componentDidUpdate(previousState, currentState) {
        if (this.state.progressStatus === 'initiated' || this.state.progressStatus === null || this.state.progressStatus === 'payment success' ||
            (this.state.progressStatus === 'deployment initiated' && localStorage.getItem('wizardStage') !== 'step4') ||
            (localStorage.getItem('wizardStage') === 'step1' && this.state.progressStatus === 'failed')) {
            $('#help_video').addClass('d-none')
        }
        if ((this.state.progressStatus === 'deployment initiated' && localStorage.getItem('wizardStage') === 'step4') || this.state.progressStatus === 'active' ||
            (localStorage.getItem('wizardStage') === 'step4' && this.state.progressStatus === 'failed') ||
            (localStorage.getItem('wizardStage') === 'step4')) {
            $('#help_video').removeClass('d-none')
        }
        if (localStorage.getItem('currentStatus') !== 'step1') {
            $(".card-footer").hide();
        }
        let timeOut = 1
        let timer;
        let currentStatus = localStorage.getItem('currentStatus')
        if (this.state.progressStatus !== 'active' && timeOut < 360000 && currentStatus === 'step4') {
            timer = setInterval(() => {
                this.getDeployment()
            }, 120000);
            timeOut = 120000 + timeOut
        }
        else if (timeOut > 360000 && (this.state.progressStatus !== 'active' || this.state.progressStatus !== 'failed')) {
            timer = setInterval(() => {
                this.getDeployment()
            }, 240000);
        }
        else {
            clearInterval(timer)
        }
    }

    isValidated() {
        return true
    }

    handleSubmit() {
        localStorage.setItem("currentStatus", 'step4');
        localStorage.setItem("deploymentStatus", 'deployed');
        let currentStep = localStorage.getItem('currentStatus')
        let status = { 'status': 'deployed' }
        let deployemntStatus = localStorage.getItem('currentState')
        if (deployemntStatus !== 'deleted' && currentStep === 'step4') {
            updateDeployment(status)
            setTimeout(() => browserHistory.push('/dashboard'), 1000);
        }
        else if(deployemntStatus === 'deleted'){
            setTimeout(() => browserHistory.push('/dashboard'), 1000);
        }
    }

    deploymentRetry() {
        localStorage.setItem("currentStatus", 'step1')
        localStorage.setItem("wizardStage", 'step1')
        localStorage.setItem("Retry", 'true')
        step = step - 2
        this.setState({ progressStatus: 'initiated' })
        setTimeout(function () {
            $(".btn-previous").trigger("click");
        }, 1);
        $("#deployment-nav-header").addClass("status")
    }

    redeployDeployment() {
        $('.deployment-failed').text('Deployment In-Progress')
        $('.nav-icon__error').addClass('d-none')
        $('.new-deployment').addClass('animated-striped-progress')
        this.setState({ progressStatus: 'deployment initiated' })
        this.setState({ time: 5 })
        this.triggerDeployment()

    }
    handleClick(data) {
        this.setState({ showModal: true, helpSection: data });
    }

    hideModal = () => {
        this.setState({ showModal: false });
    };

    handleErrorCollapse() {
        $(".collapse-content").addClass("show");
        $("#see-more").addClass("d-none");
    }

    render() {
        return (
            <>
                {
                    this.state.showModal ?
                        <CardHelpContent
                            show={this.state.showModal}
                            onHide={this.hideModal}
                            name={this.state.helpSection}
                        /> : ""
                }
                {this.state.loader
                    ?
                    <Load />
                    :
                    <>
                        {(localStorage.getItem('wizardStage') === 'step4' && this.state.progressStatus !== 'failed' &&
                            this.state.progressStatus !== 'active') || this.state.progressStatus === 'deployment initiated' ?
                            <>
                                <div className="deployment-progress-block">
                                    <div className="deployment-progress-wrap">
                                        <div className="deployment-progress-content">
                                            <div className="deployment-progress-status">
                                                <span className="progress-title-tag">Creating your deployment</span>
                                                <h3 className="progress-title">Your Deployment is in progress</h3>

                                                {this.state.time < 100 ?
                                                    <ProgressBar animated now={(this.state.time < 5 ? 5 : this.state.time)} label={`${(this.state.time < 5 ? 5 : this.state.time)}%`} />
                                                    // variant="danger"
                                                    :

                                                    <ProgressBar animated now={90} label={`${90}%`} />
                                                }
                                                <span className="progress-info">
                                                    Qualdo infrastructure will be brought up from scratch (takes about 30 mins).
                                                <p>Kindly wait or come back later.</p>
                                                </span>
                                            </div>
                                            <div className="text-center"><Button className="btn-circle mt-3 mt-sm-4" disabled><i className="icon-arrow-rotate"></i>Please wait...</Button></div>
                                        </div>

                                    </div>
                                </div>
                            </>
                            :
                            (this.state.progressStatus === 'failed') ?
                                <>
                                    <div className="deployment-progress-block">
                                        <div className="deployment-progress-wrap">
                                            <div className="deployment-progress-content">
                                                <div className="deployment-progress-status">
                                                    <span className="progress-title-tag d-none">Creating your deployment</span>
                                                    <h3 className="progress-title d-none">Your Deployment is in progress</h3>
                                                    <h3 className="progress-title text-danger"><i className="icon-sync-error"></i>Deployment Failed!</h3>
                                                    {this.state.time < 100 ?
                                                        <ProgressBar variant="danger" now={(this.state.time)} />

                                                        :

                                                        <ProgressBar now={90} />
                                                    }

                                                </div>
                                                <div className="text-center d-none">
                                                    <Button
                                                        className="btn-circle mt-3 mt-sm-4"
                                                        disabled>
                                                        <i className="icon-arrow-rotate">
                                                        </i>
                                                        Please wait...
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="deployment-error-msg">
                                                <div className="alert moniker-alert">
                                                    <h5 className="alert-heading mb-3">Deployment failed details:</h5>

                                                    {this.state.statusText !== null ?
                                                        <ul className="deployment-error-list">
                                                            {(this.state.statusText.map(row =>
                                                                <li>{row}</li>
                                                            ))}
                                                        </ul>
                                                        :
                                                        ''
                                                    }

                                                    <div className="collapse-content">
                                                        <figure className="highlight">
                                                            {this.state.prerequesties !== null
                                                                ?
                                                                <>
                                                                    {(this.state.prerequesties.map(row =>
                                                                        <pre>
                                                                            <code>
                                                                                <span>{row}</span>
                                                                            </code>
                                                                        </pre>
                                                                    ))}
                                                                </>
                                                                :
                                                                ''
                                                            }
                                                        </figure>
                                                    </div>
                                                    {this.state.prerequesties !== null ?
                                                        <Button id="see-more" className="btn-link" variant="" onClick={this.handleErrorCollapse.bind(this)}>
                                                            See More
                                                        </Button>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                <div className="text-center">
                                                    <Button
                                                        className="btn-link px-2 mr-3"
                                                        variant=""
                                                        onClick={this.deploymentRetry}
                                                    >
                                                        <i className="mr-2">
                                                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                        </i>
                                                        Edit Cloud Details
                                                    </Button>
                                                    <Button
                                                        onClick={this.redeployDeployment}
                                                        className="btn-circle btn-danger">
                                                        Try Again
                                                        <i className="mr-2">
                                                            <FontAwesomeIcon icon={faArrowRight} />
                                                        </i>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : (this.state.progressStatus === 'active') ?
                                    <>
                                        <div className="p-md-5">
                                            <Row>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <div className="deployment-success-msg">
                                                        <Image className="success-msg__icon" src="icon_green_check" />
                                                        <h4 className="success-msg__title">Your deployment is ready!</h4>
                                                    </div>
                                                    <div className="text-center"><Button className="btn-circle mt-4" onClick={this.handleSubmit}>Continue <FontAwesomeIcon icon={faArrowRight} /></Button></div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                    :(this.state.progressStatus === 'deleted')?
                                    <>
                                     <div className="p-md-5">
                                            <Row>
                                                <Col md={{ span: 10, offset: 1 }}>
                                                    <div className="deployment-success-msg">
                                                        <Image className="success-msg__icon" src="icon_green_check" />
                                                        <h4 className="success-msg__title">Your deployment is Deleted!</h4>
                                                    </div>
                                                    <div className="text-center"><Button className="btn-circle mt-4" onClick={this.handleSubmit}>Back to Dashboard <FontAwesomeIcon icon={faArrowRight} /></Button></div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                    :''
                        }
                    </>
                }
            </>
        )

    }
}

var steps = [
    // this step hasn't got a isValidated() function, so it will be considered to be true
    { stepName: "Cloud Details", component: FirstStep, stepIcon: "qd-wizard_circle" },
    // this step will be validated to false
    { stepName: "Billing Details", component: SecondStep, stepIcon: "qd-wizard_circle" },
    // this step will never be reachable because of the seconds isValidated() steps function that will always return false
    { stepName: "Payment Confirmation", component: ThirdStep, stepIcon: "qd-wizard_circle" },
    { stepName: "Deployment", component: ForthStep, stepIcon: "qd-wizard_circle" }

]

class Deployment extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.finishButtonClick = this.finishButtonClick.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.planDetails = planDetails.bind(this)
        this.getDeployment = getDeployment.bind(this)

        // if(localStorage.getItem('deploymentStatus') === null || localStorage.getItem('deploymentStatus') === undefined){
        //     window.location.href = "/";
        // }
        let sessionToken = localStorage.getItem("sessionToken");
        if (sessionToken === "undefined" || sessionToken === "null" || sessionToken === null || sessionToken === undefined) {
            window.location = '/';
            return;
        }
        this.state = {
            isLoginPage: true,
            errorOccurred: false,
            firstStep: "first step here",
            password: "",
            data: "",
            status: "initiated",
        };
        this.getDeployment()
    }

    finishButtonClick = (allStates) => {
    };

    componentDidMount() {
        $('#root').addClass('theme-orange')
        this.planDetails()

    }
    componentDidUpdate() {
    }
    submitHandler() {
    }

    render() {
        return (
            <>
                <div>

                    <NavigationHeader
                        status={this.state.status}
                        newDeployment={true}
                    >
                    </NavigationHeader>
                    <BreadCrumb />
                </div>
                <div className="qd-container">
                    <div className="qd-body no-tabs qd-grid shadow-none bg-transparent">
                        <div className="qd-section qd-grid-item">
                            <div className='sticky__billing-summary-card'>
                                <Row className="m-0">
                                    <Col xl={{ span: 8, offset: 2 }}>
                                        <div className="portlet mb-3">
                                            <div className="portlet-body p-0">
                                                <div className="qd-wizard">
                                                    <ReactWizard
                                                        steps={steps}
                                                        navSteps={this.navstep}
                                                        description=""
                                                        headerTextCenter
                                                        validate
                                                        color="primary"
                                                        stateVal
                                                        finishButtonClick={this.finishButtonClick}
                                                        progressbar={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row id="help_video" className="m-0 d-none">
                                    <Col lg={{ span: 8, offset: 2 }}>
                                        <div className="portlet mb-3">
                                            <div className="portlet-body p-3 p-md-5">

                                                {this.state.status !== 'active'
                                                    ?
                                                    <p className="text-muted mb-3">While you're waiting</p>
                                                    : ''
                                                }
                                                <h4 className="text-dark mb-3">Discover what you can do with Qualdo™</h4>
                                                <Mediakind
                                                    url= {CONFIGURE_DATA_SOURCE_URL}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyRightsFooter />

            </>
        );

    }
}
const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, { setDeploymentData })(Deployment);