import React from 'react';
import logo from '../assets/img/logo_qualdo.svg';
import oopsHuman from '../assets/img/opps_img_human.svg';
import oopsGlobe from '../assets/img/oops_img_globe.svg';
import googleLogo from '../assets/img/icon_google.svg';
import dataEngLogo from '../assets/img/icons/icon_data-engineers.svg';
import dataStewLogo from '../assets/img/icons/icon_data-stewards.svg';
import dataSciLogo from '../assets/img/icons/icon_data-scientists.svg';
import dataDevLogo from '../assets/img/icons/icon_data-devops.svg';
import logoLight from '../assets/img/logo_qualdo-light.svg';
import dataQualityLogo from '../assets/img/icons/icon_data-quality.svg';
import urlLink from '../assets/img/icons/icon_url-link.svg';
import notificationLogo from '../assets/img/icons/icon_notification.svg';
import addLogo from '../assets/img/icons/icon_add-data.svg';
import blob from '../assets/img/brand-logo_azure-blob-storage.png';
import pipelineTrigger from '../assets/img/icons/icon_pipeline-tigger.svg';
import success from '../assets/img/icons/icon_success.svg';
import incremental from '../assets/img/incremental.svg';
import timeSeries from '../assets/img/line-time-series-default.png';
import alert from '../assets/img/icons/icon_data-alert.svg';
import email from '../assets/img/icons/icon_thankyou-email.svg';
import linegraph from '../assets/img/icon-graph.png';
import areachartinfo from '../assets/img/d3-area-chart_info.png';
import areachartsuccess from '../assets/img/d3-area-chart_success.png';
import areachartwarning from '../assets/img/d3-area-chart_warning.png';
import areachartdanger from '../assets/img/d3-area-chart_danger.png';
import areachartPurple from '../assets/img/d3-area-chart_purple.png';
import areachartGray from '../assets/img/d3-area-chart_gray.png';
import areachartOrange from '../assets/img/d3-area-chart_orange.png';
import areachartBrown from '../assets/img/d3-area-chart_brown.png';
import brokenInternet from '../assets/img/icons/icon_internet-broken.svg';
import icon_privacyPolicy from '../assets/img/secure_data.svg';

import creditCard_visa from '../assets/img/credit-card_visa.svg';
import creditCard_master from '../assets/img/credit-card_master.svg';
import creditCard_amex from '../assets/img/credit-card_amex.svg';
import creditCard_discover from '../assets/img/credit-card_discover.svg';
import creditCard_jcb from '../assets/img/credit-card_jcb.svg';
import creditCard_paypal from '../assets/img/credit-card_paypal.svg';

import pricingIcon_free from '../assets/img/pricing-icon_free.svg';
import pricingIcon_starter from '../assets/img/pricing-icon_starter.svg';
import pricingIcon_professional from '../assets/img/pricing-icon_professional.svg';

import plan_starter from '../assets/img/plan-icon_starter.svg';
import plan_professional from '../assets/img/pricing-icon_professional.svg';
import illustration_paymentSuccess from '../assets/img/illustration_payment-success.svg';
import loader from "../assets/img/loader.gif"
import spinner from "../assets/img/spinner.gif"

import upgradePlan from '../assets/img/illustration_upgrade-rocket.svg';
import exceedModelLimit from '../assets/img/illustration_exceed-model-limit.svg';

import icon_monitor from '../assets/img/icon_monitor.svg';
import icon_configure from '../assets/img/icon_configure.svg';

import userProfile from  '../assets/img/user_profile.png';
import accountProfile from  '../assets/img/account_profile.png';
import icon_emptyData from '../assets/img/icons/icon_empty-data.svg';
import icon_uniqueData from '../assets/img/icons/icon_unique-data.svg';
import icon_duplicateData from '../assets/img/icons/icon_duplicate-data.svg';
import icon_errorData from '../assets/img/icons/icon_error-data.svg';
import icon_downRed from '../assets/img/icon_arrow-down_red.svg';
import icon_upGreen from '../assets/img/icon_arrow-up_green.svg';
import plan_navigation_arrow from '../assets/img/icons/icon_arrow-right.svg';
import icon_green_check from '../assets/img/icons/icon_check-green.svg';
import icon_model_cubes from '../assets/img/icons/icon_model-cubes.svg';

import icon_filter from '../assets/img/icons/icon_filter.svg';
import icon_cross_light from '../assets/img/icons/icon_cross-light.svg';
import icon_discover from '../assets/img/icons/icon_search-placeholder.svg';
import icon_play from '../assets/img/icons/icon_play.svg';
import qualdo_brandMark from '../assets/img/icons/qualdo_brandmark.svg';
import azure_brandMark from '../assets/img/icons/azure_brandmark.svg';
import gcp_brandMark from '../assets/img/icons/gcp_brandmark.svg';
import aws_brandMark from '../assets/img/icons/aws_brandmark.svg';
import icon_dollar from '../assets/img/icons/icon_dollar.svg';
import icon_renew from '../assets/img/icons/icon_renew.svg';
import icon_renew_disable from '../assets/img/icons/icon_renew-disable.svg';
import icon_trash_failed from '../assets/img/icons/icon_trash-failed.svg';
import icon_trash_success from '../assets/img/icons/icon_trash-success.svg';

//Discover page icons
import icon_attribute from '../assets/img/icons/icon_attribute.svg';
import icon_datasource from '../assets/img/icons/icon_datasource.svg';
import icon_dataset from '../assets/img/icons/icon_dataset.svg';
import icon_environment from '../assets/img/icons/icon_environment.svg';
import icon_exclamation_gray from "../assets/img/icons/icon_exclamation-gray.svg"
import icon_paycard_error from "../assets/img/icon_paycard-error.svg"
class Image extends React.Component {
    constructor() {
        super();
        this.images = {
            'oopsHuman': oopsHuman,
            'oopsGlobe': oopsGlobe,
            'logo': logo,
            'googleLogo': googleLogo,
            'dataEngLogo': dataEngLogo,
            'dataStewLogo': dataStewLogo,
            'dataSciLogo': dataSciLogo,
            'dataDevLogo': dataDevLogo,
            'logoLight': logoLight,
            'dataQuality': dataQualityLogo,
            'urlLink': urlLink,
            'notification': notificationLogo,
            'addLogo': addLogo,
            'blob': blob,
            'pipeline trigger':pipelineTrigger,
            'success':success,
            'incremental': incremental,
            'timeSeries': timeSeries,
            'alert':alert,
            'email':email,
            'linegraph': linegraph,
            'areachartinfo': areachartinfo,
            'areachartsuccess': areachartsuccess,
            'icon_down_red': icon_downRed,
            'icon_up_green': icon_upGreen,
            'plan_navigation_arrow': plan_navigation_arrow,
            'icon_green_check': icon_green_check,
            'areachartwarning': areachartwarning,
            'areachartdanger': areachartdanger,
            'areachartPurple': areachartPurple,
            'areachartGray': areachartGray,
            'areachartOrange': areachartOrange,
            'areachartBrown': areachartBrown,
            'brokenInternet': brokenInternet,
            'icon_privacyPolicy': icon_privacyPolicy,
            'creditCard_visa':creditCard_visa,
            'creditCard_master':creditCard_master,
            'creditCard_amex':creditCard_amex,
            'creditCard_discover':creditCard_discover,
            'creditCard_jcb':creditCard_jcb,
            'creditCard_paypal':creditCard_paypal,
            'pricingIcon_professional':pricingIcon_professional,
            'pricingIcon_free':pricingIcon_free,
            'pricingIcon_starter':pricingIcon_starter,
            'upgradePlanIcon': upgradePlan,
            'exceedModelLimit': exceedModelLimit,
            'plan_starter': plan_starter,
            'plan_professional': plan_professional,
            'illustration_paymentSuccess': illustration_paymentSuccess,
            'loader': loader,
            'spinner': spinner,
            'icon_monitor': icon_monitor,
            'icon_configure': icon_configure,
            'userProfile': userProfile,
            'icon_emptyData': icon_emptyData,
            'icon_uniqueData': icon_uniqueData,
            'icon_duplicateData': icon_duplicateData,
            'icon_errorData': icon_errorData,
            'icon_filter': icon_filter,
            'icon_model_cubes': icon_model_cubes,
            'icon_cross_light': icon_cross_light,
            'icon_discover': icon_discover,
            'icon_attribute':icon_attribute,
            'icon_datasource':icon_datasource,
            'icon_dataset':icon_dataset,
            'icon_environment':icon_environment,
            'icon_exclamation_gray': icon_exclamation_gray,
            'icon_paycard_error': icon_paycard_error,
            'icon_play': icon_play,
            'qualdo_brandMark': qualdo_brandMark,
            'azure_brandMark': azure_brandMark,
            'gcp_brandMark': gcp_brandMark,
            'aws_brandMark': aws_brandMark,
            'icon_dollar': icon_dollar,
            'accountProfile': accountProfile,
            'icon_renew': icon_renew,
            'icon_renew_disable': icon_renew_disable,
            'icon_trash_failed':icon_trash_failed,
            'icon_trash_success':icon_trash_success,
        };
    }

    render() {
        return (
            <img
                width={this.props.width}
                height={this.props.height}
                className={this.props.className}
                src={this.images[this.props.src]}
                style={this.props.styles} alt=""
            />
        );
    }
}

export default Image;