export const ZIP_DATA = {
    "undefined":/^\d{4}$/,
    "Afghanistan":/^\d{4}$/,
    "Åland Islands":/^\d{5}$/,
    "Albania":/^\d{4}$/,
    "Algeria":/^\d{5}$/,
    "American Samoa":/^\d{5}(-{1}\d{4,6})$/,
    "Andorra":/^[Aa][Dd]\d{3}$/,
    "Angola":'None',
    "Anguilla":/^[Aa][I][-][2][6][4][0]$/,
    "Antigua and Barbuda":'None',
    "Argentina":/^\d{4}|[A-Za-z]\d{4}[a-zA-Z]{3}$/,
    "Armenia":/^\d{4}$/,
    "Aruba":'None',
    "Ascension island":/^[Aa][Ss][Cc][Nn]\s{0,1}[1][Zz][Zz]$/,
    "Australia":/^\d{4}$/,
    "Austria":/^\d{4}$/,
    "Azerbaijan":/^[Aa][Zz]\d{4}$/,
    "Bahamas":'None',
    "Bahrain":/^\d{3,4}$/,
    "Bangladesh":/^\d{4}$/,
    "Barbados":/^[Aa][Zz]\d{5}$/,
    "Belarus":/^\d{6}$/,
    "Belgium":/^\\d{4}$/,
    "Belize":'None',
    "Benin":'None',
    "Bermuda":/^[A-Za-z]{2}\s([A-Za-z]{2}|\d{2})$/,
    "Bhutan":/^\d{5}$/,
    "Bolivia":/^\d{4}$/,
    "Bonaire, Sint Eustatius and Saba":'None',
    "Bosnia and Herzegovina":/^\d{5}$/,
    "Botswana":'None',
    "Brazil":/^\d{5}-\d{3}$/,
    "British Antarctic Territory":/^[Bb][Ii][Qq]{2}\s{0,1}[1][Zz]{2}$/,
    "British Indian Ocean Territory":/^[Bb]{2}[Nn][Dd]\s{0,1}[1][Zz]{2}$/,
    "British Virgin Islands":/^[Vv][Gg]\d{4}$/,
    "Brunei":/^[A-Za-z]{2}\d{4}$/,
    "Bulgaria":/^\d{4}$/,
    "Burkina Faso":'None',
    "Burundi":'None',
    "Cambodia":/^\d{5}$/,
    "Cameroon":'None',
    "Canada":/^(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\d(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\s{0,1}\d(?=[^DdFfIiOoQqUu\d\s])[A-Za-z]\d$/,
    "Cape Verde":/^\d{4}$/,
    "Cayman Islands":/^[Kk][Yy]\d[-\s]{0,1}\d{4}$/,
    "Central African Republic":'None',
    "Chad":/^\d{5}$/,
    "Chile":/^\d{7}\s(\d{3}-\d{4})$/,
    "China":/^\d{6}$/,
    "Christmas Island":/^\d{4}$/,
    "Cocos (Keeling) Island":/^\d{4}$/,
    "Colombia":/^\d{6}$/,
    "Comoros":'None',
    "Congo (Brazzaville)":'None',
    "Congo, Democratic Republic":/^[Cc][Dd]$/,
    "Cook Islands":'None',
    "Costa Rica":/^\d{4,5}$/,
    "Côte d'Ivoire (Ivory Coast)":'None',
    "Croatia":/^\d{5}$/,
    "Cuba":/^\d{5}$/,
    "Curaçao":'None',
    "Cyprus":/^\d{4}$/,
    "Czech Republic":/^\d{5}\s(\d{3}\s\d{2})$/,
    "Denmark":/^\d{4}$/,
    "Djibouti":'None',
    "Dominica":'None',
    "Dominican Republic":/^\d{5}$/,
    "East Timor":'None',
    "Ecuador":/^\d{6}$/,
    "El Salvador":/^1101$/,
    "Egypt":/^\d{5}$/,
    "Equatorial Guinea":'None',
    "Eritrea":'None',
    "Estonia":/^\d{5}$/,
    "Ethiopia":/^\d{4}$/,
    "Falkland Islands":/^[Ff][Ii][Qq]{2}\s{0,1}[1][Zz]{2}$/,
    "Faroe Islands":/^\d{3}$/,
    "Fiji":'None',
    "Finland":/^\d{5}$/,
    "France":/^\d{5}$/,
    "French Guiana":/^973\d{2}$/,
    "French Polynesia":/^987\d{2}$/,
    "French Southern and Antarctic Territories":'None',
    "Gabon":/^\\d{2}\\s[a-zA-Z-_ ]\s\d{2}$/,
    "Gambia":'None',
    "Georgia":/^\d{4}$/,
    "Germany":/^\d{5}$/,
    "Ghana":'None',
    "Gibraltar":/^[Gg][Xx][1]{2}\s{0,1}[1][Aa]{2}$/,
    "Greece":/^\d{3}\s{0,1}\d{2}$/,
    "Greenland":/^\d{4}$/,
    "Grenada": 'None',
    "Guadeloupe":/^971\d{2}$/,
    "Guam":/^\d{5}$/,
    "Guatemala":/^\d{5}$/,
    "Guernsey":/^[A-Za-z]{2}\d\s{0,1}\d[A-Za-z]{2}$/,
    "Guinea":"None",
    "Guinea Bissau":/^\d{4}$/,
    "Guyana":'None',
    "Haiti":/^\d{4}$/,
    "Heard and McDonald Islands":/^\d{4}$/,
    "Honduras":/^\d{5}$/,
    "Hong Kong":'None',
    "Hungary":/^\d{4}$/,
    "Iceland":/^\d{3}$/,
    "India":/^\d{6}$/,
    "Indonesia":/^\d{5}$/,
    "Iran":/^\d{5}-\d{5}$/,
    "Iraq":/^\d{5}$/,
    "Ireland":'None',
    "Isle of Man":/^[Ii[Mm]\d{1,2}\s\d\[A-Z]{2}$/,
    "Israel":/^\b\d{5}(\d{2})?$/,
    "Italy":/^\d{5}$/,
    "Jamaica":/^\d{2}$/,
    "Japan":/^\d{7}\s(\d{3}-\d{4})$/,
    "Jersey":/^[Jj][Ee]\d\s{0,1}\d[A-Za-z]{2}$/,
    "Jordan":/^\d{5}$/,
    "Kazakhstan":/^\d{6}$/,
    "Kenya":/^\d{5}$/,
    "Kiribati":'None',
    "Korea, North":'None',
    "Korea, South":/^\d{6}\s(\d{3}-\d{3})$/,
    "Kosovo":/^\d{5}$/,
    "Kuwait":/^\d{5}$/,
    "Kyrgyzstan":/^\d{6}$/,
    "Latvia":/^[Ll][Vv][- ]{0,1}\d{4}$/,
    "Laos":/^\d{5}$/,
    "Lebanon":/^\d{4}\s{0,1}\d{4}$/,
    "Lesotho":/^\d{3}$/,
    "Liberia":/^\d{4}$/,
    "Libya":/^\d{5}$/,
    "Liechtenstein":/^\d{4}$/,
    "Lithuania":/^[Ll][Tt][- ]{0,1}\d{5}$/,
    "Luxembourg":/^\d{4}$/,
    "Macau":'None',
    "Macedonia":/^\d{4}$/,
    "Madagascar":/^\d{3}$/,
    "Malawi":'None',
    "Maldives":/^\d{4,5}$/,
    "Malaysia":/^\\d{5}$/,
    "Mali":'None',
    "Malta":/^[A-Za-z]{3}\s{0,1}\d{4}$/,
    "Marshall Islands":/^\d{5}$/,
    "Mauritania":'None',
    "Mauritius":'None',
    "Martinique":/^972\d{2}$/,
    "Mayotte":/^976\d{2}$/,
    "Micronesia":/^\\d{5}$/,
    "Mexico":/^\d{5}$/,
    "Moldova":/^[Mm][Dd][- ]{0,1}\d{4}$/,
    "Monaco":/^980\d{2}$/,
    "Mongolia":/^\d{5}$/,
    "Montenegro":/^\d{5}$/,
    "Montserrat":/^[Mm][Ss][Rr]\s{0,1}\d{4}$/,
    "Morocco":/^\d{5}$/,
    "Mozambique":/^\d{4}$/,
    "Myanmar":/^\d{5}$/,
    "Namibia":/^\d{5}$/,
    "Nauru":'None',
    "Nepal":/^\d{5}$/,
    "Netherlands":/^\d{4}\s{0,1}[A-Za-z]{2}$/,
    "New Caledonia":/^988\d{2}$/,
    "New Zealand":/^\d{4}$/,
    "Nicaragua":/^\d{5}$/,
    "Niger":/^\d{4}$/,
    "Nigeria":/^\d{6}$/,
    "Niue":'None',
    "Norfolk Island":/^\d{4}$/,
    "Northern Mariana Islands":/^\d{5}$/,
    "Norway":/^\d{4}$/,
    "Oman":/^\d{3}$/,
    "Pakistan":/^\d{5}$/,
    "Palau":/^\d{5}$/,
    "Panama":/^\d{6}$/,
    "Papua New Guinea":/^\d{3}$/,
    "Paraguay":/^\d{4}$/,
    "Peru":/^\d{5}$/,
    "Philippines":/^\d{4}$/,
    "Pitcairn Islands":/^[Pp][Cc][Rr][Nn]\s{0,1}[1][Zz]{2}$/,
    "Poland":/^\d{2}[- ]{0,1}\d{3}$/,
    "Portugal":/^\d{4}[- ]{0,1}\d{3}$/,
    "Puerto Rico":/^\d{5}$/,
    "Qatar":'None',
    "Réunion":/^974\d{2}$/,
    "Romania":/^\d{6}$/,
    "Russia":/^\d{6}$/,
    "Saint Barthélemy":/^97133$/,
    "Saint Helena":/^[Ss][Tt][Hh][Ll]\s{0,1}[1][Zz]{2}$/,
    "Saint Kitts and Nevis":'None',
    "Saint Lucia":'None',
    "Saint Martin":/^97150$/,
    "Saint Pierre and Miquelon":/^97500$/,
    "Saint Vincent and the Grenadines":/^[Vv][Cc]\d{4}$/,
    "San Marino":/^4789\d$/,
    "Sao Tome and Principe":'None',
    "Saudi Arabia":/^\d{5}(-{1}\d{4})?$/,
    "Senegal":/^\d{5}$/,
    "Serbia":/^\d{5}$/,
    "Seychelles":'None',
    "Sint Maarten":'None',
    "Sierra Leone":'None',
    "Singapore":/^\d{6}$/,
    "Slovakia":/^\d{5}\s(\d{3}\s\d{2})$/,
    "Slovenia":/^([Ss][Ii][- ]{0,1}){0,1}\d{4}$/,
    "Solomon Islands":'None',
    "Somalia":'None',
    "South Africa":/^\d{4}$/,
    "South Georgia and the South Sandwich Islands":/^[Ss][Ii][Qq]{2}\s{0,1}[1][Zz]{2}$/,
    "South Korea":/^\d{6}\s(\d{3}-\d{3})$/,
    "Spain":/^\d{5}$/,
    "Sri Lanka":/^\d{5}$/,
    "Sudan":/^\d{5}$/,
    "Suriname":'None',
    "Swaziland":/^[A-Za-z]\d{3}$/,
    "Sweden":/^\d{3}\s*\d{2}$/,
    "Switzerland":/^\d{4}$/,
    "Svalbard and Jan Mayen":/^\d{4}$/,
    "Syria":'None',
    "Taiwan":/^\d{5}$/,
    "Tajikistan":/^\d{6}$/,
    "Tanzania":'None',
    "Thailand":/^\d{5}$/,
    "Togo":'None',
    "Tokelau":'None',
    "Tonga":'None',
    "Trinidad and Tobago":/^\d{6}$/,
    "Tristan da Cunha":/^[Tt][Dd][Cc][Uu]\s{0,1}[1][Zz]{2}$/,
    "Tunisia":/^\d{4}$/,
    "Turkey":/^\d{5}$/,
    "Turkmenistan":/^\d{6}$/,
    "Turks and Caicos Islands":/^[Tt][Kk][Cc][Aa]\s{0,1}[1][Zz]{2}$/,
    "Tuvalu":'None',
    "Uganda":'None',
    "Ukraine":/^\d{5}$/,
    "United Arab Emirates":'None',
    "United Kingdom":/^[A-Z]{1,2}[0-9R][0-9A-Z]?\s*[0-9][A-Z-[CIKMOV]]{2}/,
    "United States":/^\b\d{5}\b(?:[- ]{1}\d{4})?$/,
    "Uruguay":/^\d{5}$/,
    "U.S. Virgin Islands":/^\d{5}$/,
    "Uzbekistan":/^\d{3} \d{3}$/,
    "Vanuatu":'None',
    "Vatican":/^120$/,
    "Venezuela":/^\d{4}(\s[a-zA-Z]{1})?$/,
    "Vietnam":/^\d{6}$/,
    "Wallis and Futuna":/^986\d{2}$/,
    "Yemen":'None',
    "Zambia":/^\d{5}$/,
    "Zimbabwe":'None'
 }

 export const COUNTRY = [
 { label:'Afghanistan',value:'Afghanistan' },
 { label:'Åland Islands',value:'Åland Islands' },
 { label:'Albania',value:'Albania' },
 { label:'Algeria',value:'Algeria' },
 { label:'American Samoa',value:'American Samoa' },
 { label:'Andorra',value:'Andorra' },
 { label:'Angola',value:'Angola' },
 { label:'Anguilla',value:'Anguilla' },
 { label:'Antigua and Barbuda',value:'Antigua and Barbuda' },
 { label:'Argentina',value:'Argentina' },
 { label:'Armenia',value:'Armenia' },
 { label:'Aruba',value:'Aruba' },
 { label:'Ascension island',value:'Ascension island' },
 { label:'Australia',value:'Australia' },
 { label:'Austria',value:'Austria' },
 { label:'Azerbaijan',value:'Azerbaijan' },
 { label:'Bahamas',value:'Bahamas' },
 { label:'Bahrain',value:'Bahrain' },
 { label:'Bangladesh',value:'Bangladesh' },
 { label:'Barbados',value:'Barbados' },
 { label:'Belarus',value:'Belarus' },
 { label:'Belgium',value:'Belgium' },
 { label:'Belize',value:'Belize' },
 { label:'Benin',value:'Benin' },
 { label:'Bermuda',value:'Bermuda' },
 { label:'Bhutan',value:'Bhutan' },
 { label:'Bolivia',value:'Bolivia' },
 { label:'Bonaire, Sint Eustatius and Saba',value:'Bonaire, Sint Eustatius and Saba' },
 { label:'Bosnia and Herzegovina',value:'Bosnia and Herzegovina' },
 { label:'Botswana',value:'Botswana' },
 { label:'Brazil',value:'Brazil' },
 { label:'British Antarctic Territory',value:'British Antarctic Territory' },
 { label:'British Indian Ocean Territory',value:'British Indian Ocean Territory' },
 { label:'British Virgin Islands',value:'British Virgin Islands' },
 { label:'Brunei',value:'Brunei' },
 { label:'Bulgaria',value:'Bulgaria' },
 { label:'Burkina Faso',value:'Burkina Faso' },
 { label:'Burundi',value:'Burundi' },
 { label:'Cambodia',value:'Cambodia' },
 { label:'Cameroon',value:'Cameroon' },
 { label:'Canada',value:'Canada' },
 { label:'Cape Verde',value:'Cape Verde' },
 { label:'Cayman Islands',value:'Cayman Islands' },
 { label:'Central African Republic',value:'Central African Republic' },
 { label:'Chad',value:'Chad' },
 { label:'Chile',value:'Chile' },
 { label:'China',value:'China' },
 { label:'Christmas Island',value:'Christmas Island' },
 { label:'Cocos (Keeling) Island',value:'Cocos (Keeling) Island' },
 { label:'Colombia',value:'Colombia' },
 { label:'Comoros',value:'Comoros' },
 { label:'Congo (Brazzaville)',value:'Congo (Brazzaville)' },
 { label:'Congo, Democratic Republic',value:'Congo, Democratic Republic' },
 { label:'Cook Islands',value:'Cook Islands' },
 { label:'Costa Rica',value:'Costa Rica' },
 { label:'Croatia',value:'Croatia' },
 { label:'Cuba',value:'Cuba' },
 { label:'Curaçao',value:'Curaçao' },
 { label:'Cyprus',value:'Cyprus' },
 { label:'Czech Republic',value:'Czech Republic' },
 { label:'Denmark',value:'Denmark' },
 { label:'Djibouti',value:'Djibouti' },
 { label:'Dominica',value:'Dominica' },
 { label:'Dominican Republic',value:'Dominican Republic' },
 { label:'East Timor',value:'East Timor' },
 { label:'Ecuador',value:'Ecuador' },
 { label:'El Salvador',value:'El Salvador' },
 { label:'Egypt',value:'Egypt' },
 { label:'Equatorial Guinea',value:'Equatorial Guinea' },
 { label:'Eritrea',value:'Eritrea' },
 { label:'Estonia',value:'Estonia' },
 { label:'Ethiopia',value:'Ethiopia' },
 { label:'Falkland Islands',value:'Falkland Islands' },
 { label:'Faroe Islands',value:'Faroe Islands' },
 { label:'Fiji',value:'Fiji' },
 { label:'Finland',value:'Finland' },
 { label:'France',value:'France' },
 { label:'French Guiana',value:'French Guiana' },
 { label:'French Polynesia',value:'French Polynesia' },
 { label:'French Southern and Antarctic Territories',value:'French Southern and Antarctic Territories' },
 { label:'Gabon',value:'Gabon' },
 { label:'Gambia',value:'Gambia' },
 { label:'Georgia',value:'Georgia' },
 { label:'Germany',value:'Germany' },
 { label:'Germany',value:'Germany' },
 { label:'Germany',value:'Germany' },
 { label:'Ghana',value:'Ghana' },
 { label:'Gibraltar',value:'Gibraltar' },
 { label:'Greece',value:'Greece' },
 { label:'Greenland',value:'Greenland' },
 { label:'Grenada',value:'Grenada' },
 { label:'Guadeloupe',value:'Guadeloupe' },
 { label:'Guam',value:'Guam' },
 { label:'Guatemala',value:'Guatemala' },
 { label:'Guernsey',value:'Guernsey' },
 { label:'Guinea',value:'Guinea' },
 { label:'Guinea Bissau',value:'Guinea Bissau' },
 { label:'Guyana',value:'Guyana' },
 { label:'Haiti',value:'Haiti' },
 { label:'Heard and McDonald Islands',value:'Heard and McDonald Islands' },
 { label:'Honduras',value:'Honduras' },
 { label:'Hong Kong',value:'Hong Kong' },
 { label:'Hungary',value:'Hungary' },
 { label:'Iceland',value:'Iceland' },
 { label:'India',value:'India' },
 { label:'Indonesia',value:'Indonesia' },
 { label:'Iran',value:'Iran' },
 { label:'Iraq',value:'Iraq' },
 { label:'Ireland',value:'Ireland' },
 { label:'Isle of Man',value:'Isle of Man' },
 { label:'Israel',value:'Israel' },
 { label:'Italy',value:'Italy' },
 { label:'Jamaica',value:'Jamaica' },
 { label:'Japan',value:'Japan' },
 { label:'Jersey',value:'Jersey' },
 { label:'Jordan',value:'Jordan' },
 { label:'Kazakhstan',value:'Kazakhstan' },
 { label:'Kenya',value:'Kenya' },
 { label:'Kiribati',value:'Kiribati' },
 { label:'Korea, North',value:'Korea, North' },
 { label:'Korea, South',value:'Korea, South' },
 { label:'Kosovo',value:'Kosovo' },
 { label:'Kuwait',value:'Kuwait' },
 { label:'Kyrgyzstan',value:'Kyrgyzstan' },
 { label:'Latvia',value:'Latvia' },
 { label:'Laos',value:'Laos' },
 { label:'Lebanon',value:'Lebanon' },
 { label:'Lesotho',value:'Lesotho' },
 { label:'Liberia',value:'Liberia' },
 { label:'Libya',value:'Libya' },
 { label:'Liechtenstein',value:'Liechtenstein' },
 { label:'Lithuania',value:'Lithuania' },
 { label:'Luxembourg',value:'Luxembourg' },
 { label:'Macau',value:'Macau' },
 { label:'Macedonia',value:'Macedonia' },
 { label:'Madagascar',value:'Madagascar' },
 { label:'Malawi',value:'Malawi' },
 { label:'Maldives',value:'Maldives' },
 { label:'Malaysia',value:'Malaysia' },
 { label:'Mali',value:'Mali' },
 { label:'Malta',value:'Malta' },
 { label:'Marshall Islands',value:'Marshall Islands' },
 { label:'Mauritania',value:'Mauritania' },
 { label:'Mauritius',value:'Mauritius' },
 { label:'Martinique',value:'Martinique' },
 { label:'Mayotte',value:'Mayotte' },
 { label:'Micronesia',value:'Micronesia' },
 { label:'Mexico',value:'Mexico' },
 { label:'Micronesia',value:'Micronesia' },
 { label:'Moldova',value:'Moldova' },
 { label:'Monaco',value:'Monaco' },
 { label:'Mongolia',value:'Mongolia' },
 { label:'Montenegro',value:'Montenegro' },
 { label:'Montserrat',value:'Montserrat' },
 { label:'Morocco',value:'Morocco' },
 { label:'Mozambique',value:'Mozambique' },
 { label:'Myanmar',value:'Myanmar' },
 { label:'Namibia',value:'Namibia' },
 { label:'Nauru',value:'Nauru' },
 { label:'Nepal',value:'Nepal' },
 { label:'Netherlands',value:'Netherlands' },
 { label:'New Caledonia',value:'New Caledonia' },
 { label:'New Zealand',value:'New Zealand' },
 { label:'Nicaragua',value:'Nicaragua' },
 { label:'Niger',value:'Niger' },
 { label:'Nigeria',value:'Nigeria' },
 { label:'Niue',value:'Niue' },
 { label:'Norfolk Island',value:'Norfolk Island' },
 { label:'Northern Mariana Islands',value:'Northern Mariana Islands' },
 { label:'Norway',value:'Norway' },
 { label:'Oman',value:'Oman' },
 { label:'Pakistan',value:'Pakistan' },
 { label:'Palau',value:'Palau' },
 { label:'Panama',value:'Panama' },
 { label:'Papua New Guinea',value:'Papua New Guinea' },
 { label:'Paraguay',value:'Paraguay' },
 { label:'Peru',value:'Peru' },
 { label:'Philippines',value:'Philippines' },
 { label:'Pitcairn Islands',value:'Pitcairn Islands' },
 { label:'Poland',value:'Poland' },
 { label:'Portugal',value:'Portugal' },
 { label:'Portugal',value:'Portugal' },
 { label:'Puerto Rico',value:'Puerto Rico' },
 { label:'Qatar',value:'Qatar' },
 { label:'Réunion',value:'Réunion' },
 { label:'Romania',value:'Romania' },
 { label:'Russia',value:'Russia' },
 { label:'Saint Barthélemy',value:'Saint Barthélemy' },
 { label:'Saint Helena',value:'Saint Helena' },
 { label:'Saint Kitts and Nevis',value:'Saint Kitts and Nevis' },
 { label:'Saint Lucia',value:'Saint Lucia' },
 { label:'Saint Martin',value:'Saint Martin' },
 { label:'Saint Pierre and Miquelon',value:'Saint Pierre and Miquelon' },
 { label:'Saint Vincent and the Grenadines',value:'Saint Vincent and the Grenadines' },
 { label:'San Marino',value:'San Marino' },
 { label:'Sao Tome and Principe',value:'Sao Tome and Principe' },
 { label:'Saudi Arabia',value:'Saudi Arabia' },
 { label:'Senegal',value:'Senegal' },
 { label:'Serbia',value:'Serbia' },
 { label:'Serbia',value:'Serbia' },
 { label:'Seychelles',value:'Seychelles' },
 { label:'Sint Maarten',value:'Sint Maarten' },
 { label:'Sierra Leone',value:'Sierra Leone' },
 { label:'Singapore',value:'Singapore' },
 { label:'Singapore',value:'Singapore' },
 { label:'Singapore',value:'Singapore' },
 { label:'Slovakia',value:'Slovakia' },
 { label:'Slovenia',value:'Slovenia' },
 { label:'Solomon Islands',value:'Solomon Islands' },
 { label:'Somalia',value:'Somalia' },
 { label:'South Africa',value:'South Africa' },
 { label:'South Georgia and the South Sandwich Islands',value:'South Georgia and the South Sandwich Islands' },
 { label:'South Korea',value:'South Korea' },
 { label:'Spain',value:'Spain' },
 { label:'Sri Lanka',value:'Sri Lanka' },
 { label:'Sudan',value:'Sudan' },
 { label:'Suriname',value:'Suriname' },
 { label:'Swaziland',value:'Swaziland' },
 { label:'Sweden',value:'Sweden' },
 { label:'Switzerland',value:'Switzerland' },
 { label:'Svalbard and Jan Mayen',value:'Svalbard and Jan Mayen' },
 { label:'Syria',value:'Syria' },
 { label:'Taiwan',value:'Taiwan' },
 { label:'Tajikistan',value:'Tajikistan' },
 { label:'Tanzania',value:'Tanzania' },
 { label:'Thailand',value:'Thailand' },
 { label:'Togo',value:'Togo' },
 { label:'Tokelau',value:'Tokelau' },
 { label:'Tonga',value:'Tonga' },
 { label:'Trinidad and Tobago',value:'Trinidad and Tobago' },
 { label:'Tristan da Cunha',value:'Tristan da Cunha' },
 { label:'Tunisia',value:'Tunisia' },
 { label:'Turkey',value:'Turkey' },
 { label:'Turkmenistan',value:'Turkmenistan' },
 { label:'Turks and Caicos Islands',value:'Turks and Caicos Islands' },
 { label:'Tuvalu',value:'Tuvalu' },
 { label:'Uganda',value:'Uganda' },
 { label:'Ukraine',value:'Ukraine' },
 { label:'United Arab Emirates',value:'United Arab Emirates' },
 { label:'United Kingdom',value:'United Kingdom' },
 { label:'United States',value:'United States' },
 { label:'Uruguay',value:'Uruguay' },
 { label:'U.S. Virgin Islands',value:'U.S. Virgin Islands' },
 { label:'Uzbekistan',value:'Uzbekistan' },
 { label:'Vanuatu',value:'Vanuatu' },
 { label:'Vatican',value:'Vatican' },
 { label:'Venezuela',value:'Venezuela' },
 { label:'Vietnam',value:'Vietnam' },
 { label:'Wallis and Futuna',value:'Wallis and Futuna' },
 { label:'Yemen',value:'Yemen' },
 { label:'Zambia',value:'Zambia' },
 { label:'Zimbabwe',value:'Zimbabwe' }];
