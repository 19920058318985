import React from 'react';
import Image from '../components/image';
import Banner from './components/banner';
import SignupForm from './components/signup';
import EmailNotification from './components/email';
import ErrorHandler from '../components/error_500';
import { postUIlogs } from "../utils/event_handling";


const pageTypes = {
     SIGNUP: 0,
     TEAM: 1,
     GOOGLE: 2,
     EMAIL: 3,
     INVALID_REQUEST: 4
}

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.setSignupPage = this.setSignupPage.bind(this);
        this.setTeamPage = this.setTeamPage.bind(this);
        this.setGooglePage = this.setGooglePage.bind(this);
        this.setEmailPage = this.setEmailPage.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        let pageType = 0;
        if (this.props.route !== undefined && this.props.route.pageType !== undefined && this.props.route.pageType !== null) {
            pageType = this.props.route.pageType;
        } else if( this.props.pageType !== undefined && this.props.pageType !== null) {
            pageType = this.props.pageType;
        }
        this.state = {
            errorOccurred: false,
            pageType: pageType,
            userDetails: null,
            responseText: "",
            signupType: "signup",
            email: ""
        };
    }
    static getDerivedStateFromProps(props, state) {
        let windowPathName = window.location.pathname;
        if (windowPathName === "/invalid-request" && props.route.pageType !== undefined && props.route.pageType !== state.pageType){
            return {
                pageType: props.route.pageType
            }
        }
        return null;
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    setSignupPage() {
        this.setState ({pageType: pageTypes.SIGNUP});
    }

    setTeamPage(data) {
        this.setState ({pageType: pageTypes.TEAM, userDetails: data["response"]});
    }

    setGooglePage() {
        this.setState ({pageType: pageTypes.GOOGLE});
    }

    setEmailPage(responseText) {
        this.setState ({pageType: pageTypes.EMAIL, responseText: responseText});
    }
    setEmail(value,type) {
        this.setState({email:value,signupType:type});
    }
    renderPage(pageType) {
        switch(pageType) {  
            case pageTypes.SIGNUP:
                return <SignupForm setEmail={this.setEmail} setGooglePage={this.setGooglePage} setTeamPage={this.setTeamPage} setEmailPage={this.setEmailPage}/>;
            case pageTypes.EMAIL:
                return <EmailNotification signupType={this.state.signupType} setPage={this.EmailNotification} responseText={this.state.responseText} email={this.state.email}/>;
            case pageTypes.INVALID_REQUEST:
                return <SignupForm showInvalidRequestModal={true}/>;
            default:
                return <SignupForm setPage={this.setSignupPage}/>;
        }
    }

    render() {
        return (
            this.state.errorOccurred ?
                <ErrorHandler></ErrorHandler>
            :
            <div className="user-prelogin">
                <div className="row gutter-reset">
                    <div className="col">
                        <div className="prelogin-container">
                            <Image
                                className="login-logo"
                                src="logo"
                            />
                            {this.renderPage(this.state.pageType)}
                        </div>
                    </div>
                    <Banner/>
                </div>
            </div>
        );
    }

}

export default Signup;