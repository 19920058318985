import * as MKPlayerSDK from "@mediakind/mkplayer"
import React from 'react'
import "./mkplayer-ui.css"
import {decryptForLocalUsage} from "./common_utils";

export function Mediakind(props) {

    let key = decryptForLocalUsage(localStorage.getItem('v_ky'))
    let token = decryptForLocalUsage(localStorage.getItem('v_tk'))

    let vContainerRef = React.useRef(null);
    let player = null;
    const playerConfig = {
        key: key,
        ui: true,
        playback: {
            muted: true,
            autoplay: false,
            preferredTech: [
                {
                    player: 'html5',
                    streaming: 'hls'
                },
                {
                    player: 'native',
                    streaming: 'hls'
                }
            ]
        },
    };

    const sourceConfig = {
        hls: props.url,
        drm: {
            clearkey: {
                LA_URL: "HLS_AES",
                 "headers": {
                    "Authorization": "Bearer=" + token,
                    "Content-Type": "application/json"
            }
            }
        }
    };

    React.useEffect(() => {
        load();
    })


    const load = async () => {
        if (MKPlayerSDK) {
            if (player) {
                await player?.unload?.();
                player = null;
            }

            player = new MKPlayerSDK.MKPlayer(
                vContainerRef.current,
                playerConfig
            );
            player.load(sourceConfig).then(() => {
            if (!playerConfig.playback.autoplay) {
                player.play(); // to start playback when autoplay is disabled
                // you can also start playback from sourceloaded event handler!
            }
            });
        } else {
            console.log("::: MKPlayer SDK not initiated yet :::");
        }
    }

    return (
        <div className="container">
            <div
            ref={vContainerRef}
            className="video-container"
            id="video-container"
            ></div>
        </div>
    );
}
