import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import {get_document_link} from '../../utils/common_utils'
import {SUBSCRIPTION_DOC} from '../../utils/constant'
class CardHelpContent extends Component {

      constructor(props) {
            super(props);
            this.state = {
            }

      }

      componentDidUpdate() {
            $('body').addClass('theme-orange')
      }

      renderContent(name) {
            // 
            switch (name) {
                  case "Cloud Service":
                        return (
                              <>

                                    <h4 className="text-info">Steps to create service account json: </h4>
                                    <ol className="model__help-list">
                                          <li>Go to console.cloud.google.com/apis/credentials</li>
                                          <li>On the top left there is a blue "create credentials" button. click it and select "service account key.</li>
                                          <li>Choose the service account you want, and select "JSON" as the key type.</li>
                                          <li>It should allow you to download  the json file.</li>
                                          <li>Refer to the<a rel="noopener noreferrer" target="_blank" href={get_document_link("deployment",SUBSCRIPTION_DOC)}> Qualdo™ Subscription Manager Documentation </a> for more details.</li>
                                    </ol>
                                    {/* <h4 className="text-info" syt>{name}</h4>
                               <p>GCP is a public cloud vendor that offers a suite of computing services to do everything from data management to delivering web and video over the web to AI and machine learning tools.</p> */}
                                    <h4 className="text-info">Prerequisites: </h4>
                                    <h5 className="text-info">An existing GCP Project with billing and the following APIs enabled: </h5>
                                    <ol className="model__help-list">
                                          <li>Compute Engine API</li>
                                          <li>Cloud Dataproc API</li>
                                          <li>Cloud Logging API</li>
                                          <li>Secret Manager API</li>
                                          <li>Kubernetes Engine API</li>
                                          <li>Cloud Dataproc API</li>
                                          <li>Cloud SQL Admin API</li>
                                          <li>Service Networking API</li>
                                          <li>Cloud Resource Manager API</li>
                                    </ol>
                                    <h5 className="text-info"> An existing Service Account with the following Roles & Permissions enabled:</h5>
                                    <ol className="model__help-list">
                                          <li>Cloud SQL Admin</li>
                                          <li>Cloud SQL Client</li>
                                          <li>Dataproc Editor</li>
                                          <li>GKE Hub Service Agent</li>
                                          <li>Kubernetes Engine Service Agent</li>
                                          <li>Secret Manager Admin</li>
                                          <li>Service Networking Admin</li>
                                          <li>Storage Admin</li>
                                    </ol>
                              </>
                        );
                  case "Settings":
                        return (
                              <>
                                    {/* <h4 className="text-info">{name}</h4> */}
                                    <h4 className="text-info">Prerequisites: </h4>
                                    <h5 className="text-info">
                                          Make sure there is a minimal resource quota available in the <strong>Region</strong> in which you want to deploy <strong>Qualdo</strong>. The resource quotas required exclusively for Qualdo are:
                                    </h5>
                                    <ol className="model__help-list">
                                          <li>CPUS - 132</li>
                                          <li>DISKS_TOTAL_GB - 4096</li>
                                          <li>STATIC_ADDRESSES - 5</li>
                                          <li>IN_USE_ADDRESSES - 18</li>
                                          <li>SSD_TOTAL_GB - 3000</li>
                                          <li>PERSISTENT_DISK_SSD_GB- 5000</li>
                                    </ol>

                                    <p>Refer to the<a rel="noopener noreferrer" target="_blank" href={get_document_link("deployment",SUBSCRIPTION_DOC)}> Qualdo™ Subscription Manager Documentation </a> for more details.</p>
                              </>
                        );
                  case "Elastic Search":
                        return(
                              <>
                                    {/* <h4 className="text-info">{name}</h4> */}
                                    <h4 className="text-info">Elastic Search Api Key: </h4>
                                    <ol className="model__help-list">
                                          <li>Click on "Yes" if you have own elastic search account</li>
                                          <li>Provide api key inside the input field  to create deployment</li>
                                          <li>If you don't have api key leave it as default "No".</li>
                                    </ol>
                                    <p>Refer to the<a rel="noopener noreferrer" target="_blank" href={get_document_link("deployment",SUBSCRIPTION_DOC)}> Qualdo™ Subscription Manager Documentation </a> for more details.</p>
                              </>
                        );
                  case "Authorized Network":
                        return(
                              <>
                                    {/* <h4 className="text-info">{name}</h4> */}
                                    <h4 className="text-info">
                                          Authorized Network:
                                    </h4>
                                    <ol className="model__help-list">
                                          <li>You can specify CIDR ranges to allow IP addresses in those range to access your instance</li>
                                          <li>Provide an address range using CIDR notation (e.g. 192.168.99.0/24), or an IP address(e.g. 192.168.99.0).</li>
                                          <li>You can also provide a list of IP addresses or address range using either IPv4 or IPv6.</li>
                                    </ol>
                                    <p>Refer to the<a rel="noopener noreferrer" target="_blank" href={get_document_link("deployment",SUBSCRIPTION_DOC)}> Qualdo™ Subscription Manager Documentation </a> for more details.</p>
                              </>
                        );
                  default:
                        return ''

            }

      }

      render() {

            return (
                  <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName="modal-vertical shadow" className="fixed-right model__help" size="md">
                        <Modal.Header className="modal-header py-3 align-items-center bg-dark text-white" closeButton>
                              <Modal.Title id="contained-modal-title-vcenter">
                                    <h4 className="mb-0"><i><FontAwesomeIcon icon={faQuestionCircle} /></i>Help</h4>
                              </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <div className="qd-search-bar d-none">
                                    <div className="input-group border">
                                          <div className="input-group-prepend">
                                                <button id="button-addon4" type="button" className="btn"><FontAwesomeIcon icon={faSearch} /></button>
                                          </div>
                                          <input type="search" placeholder="Search" className="form-control" />
                                    </div>
                              </div>
                              <div className="model__help-content">
                                    {this.props.page === "model" ? this.renderModelContent(this.props.name) : this.renderContent(this.props.name)}
                              </div>
                        </Modal.Body>
                  </Modal>
            );
      }
}

export default CardHelpContent;
