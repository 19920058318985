import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
// import { getApi } from "../../utils/User_handling";
import Load from "../../components/loadAction";
import { toastObj } from "../../utils/common_utils";
import { Row, Col } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard, faBuilding, faFlag, faIdCard } from '@fortawesome/fontawesome-free-regular';
import Alert from 'react-bootstrap/Alert';
import BasicPortlet from '../../components/basicPortlet';
import AddCardComponent from "./addCardComponent";
import PaymentCardsList from "./paymentCardsList";
import EditCardComponent from "./editCardComponent";

import {
  getApi, getBrainTreeAuthToken, getBillingHistoryTableData,
  getPaymentDetailsTableData, getBillingAddressDetails,
  submitPrimaryCardRequest, updateAutoDebitStatus, updateCustomerAddress
} from "../../utils/event_handling";
import { ZIP_DATA, COUNTRY } from "../../utils/billingAddressConstant";
import CustomSelect from '../../components/customSelect';
import Form from "react-bootstrap/Form";
import { Formik } from 'formik';
import * as yup from 'yup';

class PaymentProfile extends React.Component {
  constructor(props) {
    super(props);
    this.getApi = getApi.bind(this);
    this.showToast = this.showToast.bind(this);
    this.closeToast = this.closeToast.bind(this);


    this.showAddCardSection = this.showAddCardSection.bind(this);
    this.hideAddCardSection = this.hideAddCardSection.bind(this);
    this.updateAlertSection = this.updateAlertSection.bind(this);
    this.showEditSection = this.showEditSection.bind(this);
    this.showAlertSection = this.showAlertSection.bind(this);
    this.makeCardAsPrimary = this.makeCardAsPrimary.bind(this);
    this.getEmptyCardSection = this.getEmptyCardSection.bind(this);
    this.addNewCardDetailToList = this.addNewCardDetailToList.bind(this);
    this.updatePrimaryStatusOfCards = this.updatePrimaryStatusOfCards.bind(this);
    this.updateCardDetailToList = this.updateCardDetailToList.bind(this);
    this.removeCardDetailFromList = this.removeCardDetailFromList.bind(this);
    this.setAutoDebit = this.setAutoDebit.bind(this);

    this.getApi = getApi.bind(this);
    this.updateAutoDebitStatus = updateAutoDebitStatus.bind(this);
    this.getPaymentDetailsTableData = getPaymentDetailsTableData.bind(this);
    this.getBillingHistoryTableData = getBillingHistoryTableData.bind(this);
    this.getBillingAddressDetails = getBillingAddressDetails.bind(this);
    this.submitPrimaryCardRequest = submitPrimaryCardRequest.bind(this);
    this.getBrainTreeAuthToken = getBrainTreeAuthToken.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.updateCustomerAddress = updateCustomerAddress.bind(this);

    this.state = {
      toastStatus: false,
      emailsubscription: [],
      chechkall: false,
      btnchkalltext: "Unsubscribe All",
      emailsubscriptioninitalloader: true,
      changeAddress: false,
      showAddCard: false,
      isEditMode: false,
      showAlert: false,
      exCardDetails: null,
      cardGroupLoading: true,
      alertText: "",
      alertStatus: "Success! ",
      alertClass: "success",
      availableCards: null,
      billingHistory: null,
      braintreeAuthorizationToken: '',
      renderCards: false,
      AutoDebitToggle: true,
      autoDebit: null,
      billinginitalloader: true,
      billing_address: null,
      companyName: '',
      addressLine1: '',
      addressLine2: '',
      country: '',
      zipCode: '',
      state: '',
      city: '',
      sectionTitle: "Personal + Business Contact Details",
      optionscountry: COUNTRY,
      showChangeAddress:true,
      updatedAddress : false,
    };
    this.getData();
  }

  componentDidMount() {

    this.getData();
    const bt_token = this.getBrainTreeAuthToken();
    this.setState({
      cardGroupLoading: false,
      braintreeAuthorizationToken: bt_token
    });


  }

  getData() {
    this.getApi("get_user_payments_and_billing_history")
  }
  showAddCardSection(event) {
    this.setState({ showAddCard: true })
  }


  setAutoDebit() {

    //  the toggle button, till the API update is complete to prevent continuous click
    this.setState({ AutoDebitToggle: true });

    // Call the API to update auto debit status in server
    const newAutoDebitStatus = !this.state.autoDebit;
    this.updateAutoDebitStatus(newAutoDebitStatus);

    // Enable the toggle button, since API call is over
    this.setState({ AutoDebitToggle: false });
  }

  hideAddCardSection(event) {
    this.setState({
      showAddCard: false,
      isEditMode: false,
      exCardDetails: null
    })
  }

  updateAlertSection(status) {
    this.setState({ showAlert: status })
  }

  showAlertSection(status, alertText, showAddCard, alertClass) {
    this.setState({
      alertStatus: status,
      alertText: alertText,
      showAlert: true,
      showAddCard: showAddCard,
      alertClass: alertClass
    }, () => {
      window.setTimeout(() => {
        this.setState({ showAlert: false })
      }, 8000)
    });
  }


  addNewCardDetailToList(cardDetail) {
    let availableCards = this.state.availableCards;

    if (availableCards === null) {
      availableCards = [];
    }

    const currentCardIsDefault = cardDetail.isPrimary;
    let currentCardDetail = {
      "brand": cardDetail.card_type,
      "customerName": cardDetail.cardholder_name,
      "expires": `${cardDetail.expiration_month}/${cardDetail.expiration_year}`,
      "lastFourDigits": cardDetail.last_4,
      "isPrimary": currentCardIsDefault,
      "id": cardDetail.id
    };

    // Make other cards as non primary
    if (currentCardIsDefault) {
      for (let card of availableCards) {
        card["isPrimary"] = false;
      }
    }

    availableCards.push(currentCardDetail);
    this.setState({ availableCards: availableCards })
  }

  removeCardDetailFromList(cardDetailID) {
    let availableCards = this.state.availableCards;

    if (availableCards === null) {
      availableCards = [];
    }

    let updatedAvailableCards = [];
    for (let card of availableCards) {
      if (card.id === cardDetailID) {
        continue;
      }

      updatedAvailableCards.push(card);
    }

    this.setState({ availableCards: updatedAvailableCards });
    this.setState({ renderCards: !this.state.renderCards });
    this.hideAddCardSection();
  }

  updateCardDetailToList(cardDetail) {
    let availableCards = this.state.availableCards;

    if (availableCards === null) {
      availableCards = [];
    }

    for (let card of availableCards) {
      if (card.id !== cardDetail.id) {
        continue;
      }

      card.customerName = cardDetail.customer_name;
      card.expires = `${cardDetail.expiry_month}/${cardDetail.expiry_year}`;
    }

    this.setState({ availableCards: availableCards });
    this.setState({ renderCards: !this.state.renderCards });
    this.hideAddCardSection();
  }

  showEditSection(exCardDetails) {
    this.setState({ exCardDetails: exCardDetails })
    this.setState({ showAddCard: true })
    this.setState({ isEditMode: true })

  }

  makeCardAsPrimary(cardDetail) {
    this.setState({ cardGroupLoading: true });
    this.submitPrimaryCardRequest(cardDetail);
    this.setState({ cardGroupLoading: false });
  }

  getEmptyCardSection() {
    if (this.state.availableCards === null) {
      return (<Load />);
    }

    if (this.state.availableCards.length === 0 &&
      !this.state.isEditMode &&
      !this.state.showAddCard) {

      return (<BasicPortlet className="pb-0" title="Payment Method"
        bodyClassName=""
        content={
          <div>
            <p>Hm, looks like you don’t have a credit card on
              file.</p>
            <button type="button"
              onClick={this.showAddCardSection}
              className="btn btn-primary btn-circle">
              <i>
                <FontAwesomeIcon icon={faCreditCard} />
              </i> Add payment method
            </button>
          </div>
        }
      />)
    }
  }

  updatePrimaryStatusOfCards(cardId) {
    if (this.state.availableCards === null) {
      return;
    }

    for (let card of this.state.availableCards) {
      card["isPrimary"] = String(card.id) === String(cardId);
    }
    this.setState({ renderCards: !this.state.renderCards });
  }



  selectAll() {
    let checkallarr;
    if (this.state.chechkall) {

      checkallarr = this.state.emailsubscription;
      checkallarr.map(function (emailsubscriptiondata, i) {
        checkallarr[i].email_user_status = 1;
        // emailsubscriptiondata.email_user_status
        return checkallarr;
      });

      this.setState({
        btnchkalltext: "Unsubscribe All",
        chechkall: false,
        emailsubscription: checkallarr,
      });

    } else {
      checkallarr = this.state.emailsubscription;
      checkallarr.map(function (emailsubscriptiondata, i) {

        checkallarr[i].email_user_status = 0;
        return checkallarr;
      });

      this.setState({
        btnchkalltext: "Subscribe All",
        chechkall: true,
        emailsubscription: checkallarr,
      });
    }
    this.getApi("save_email_subscription");
  }

  closeToast() {
    this.setState({ toastStatus: false });
  }
  showToast(status, msg, link) {
    this.setState({
      toastStatus: true,
      toastMsg: msg,
      toastClass: toastObj[status]["className"],
      toastIcon: toastObj[status]["icon"],
      toastheading: toastObj[status]["heading"]
    });
  }



  toggleEmailGroup = (i) => (event) => {
    let checkedlist = this.state.emailsubscription;
    checkedlist[i].email_user_status = (event.target.checked === true ? 1 : 0);
    // let textflag = 0;
    // checkedlist.map(function (emailsubscriptiondata, i) {
    //   if(checkallarr[i].email_user_status = 1){
    //     textflag = 
    //   }
    // });
    this.setState({ emailsubscription: checkedlist })
    this.getApi("save_email_subscription");
  }


  updateAddress() {
    this.setState({country:'Select Country'})
    this.setState({ changeAddress: !this.state.changeAddress })
    this.setState({ showChangeAddress: !this.state.showChangeAddress })

  }

  submitUpdate(){
    let details = this.state
    let data = {"isPrimary": false, "company_name": "", "address_line_1": "", 
    "address_line_2": "", "city": "", "state": "", "zip_code": "", "country": "", 
    "customer_name": ""}
    // data['billing_address_id'] = this.state.billing_address.billing_address_id
    // data['is_default'] = this.state.billing_address.is_default
    if(this.state.billing_address.details.isPrimary === false){
      data['isPrimary'] = true
    }
    else{
      data['isPrimary'] = this.state.billing_address.details.isPrimary
    }
    data['company_name'] = details.companyName
    data['address_line_1'] = details.addressLine1
    data['address_line_2'] = details.addressLine2
    data['city'] = details.city
    data['state'] = details.state
    data['country'] = details.country
    data['zip_code'] = details.zipCode
    // data['customer_name'] = this.state.billing_address.details.customer_name
    // this.setState({billing_address:data})
    let addDetails = {'billing_address_id':this.state.billing_address.billing_address_id,'details':data}
    this.updateCustomerAddress(addDetails)

    setTimeout(() => { if (this.state.updatedAddress === true){
      this.setState({changeAddress:false})
      this.setState({ showChangeAddress: true })
      this.getApi("get_user_payments_and_billing_history")
    } }, 1000);
  }
  onSubmit() {
    
    
    // window.location.reload(false);
    // browserHistory.push('/paymentprofile');

  }

  render() {
    let availableCards = this.state.availableCards;
    if (availableCards === null) {
      availableCards = [];
    }

    let showRemoveCard = availableCards.length > 1;

    let initialValues = this.state;
    let addressSchema = yup.object({
      companyName: yup.string().min(3, "Company must be at least 3 characters").required("Company is a required field"),
      addressLine1: yup.string().min(3, "Address Line must be at least 3 characters").required("Address Line is a required field"),
      city: yup.string().required("City is a required field").matches(/^[A-Za-z ]+$/, "City should contains letters only").min(3, "City must be at least 3 characters"),
      state: yup.string().required("State is a required field").matches(/^[A-Za-z ]+$/, "State should contains letters only").min(3, "State must be at least 3 characters"),
      country: yup.string().required("Country is a required field"),
      zipCode: yup.number().required("Zip Code is a required field")
        .test(
          "Invalid Zipcode",
          function test(value) {
            let country = this.parent.country
            if (country === undefined || country === 'Select Country') {
              const message = "Select a country";
              return this.createError({ message: message });
            }
            let regex = ZIP_DATA[country]
            return regex.test(value)
          }
        ),
    });


    // if (this.state.billing_address !== null){

    //   }

    return (
      <>

        <div className="portlet-title">

          <div className="qd-section_content shadow-sm settings-preferences">
            <div className="qd-section-header">
              <h4 className="qd-section-header-title">
                <FontAwesomeIcon icon={faAddressCard} /> Payment profile
              </h4>
            </div>
            <div className="qd-section-body">

              <div style={{
                display: this.state.billinginitalloader ? "block" : "none",
              }}>
                {<Load />}
              </div>
              <div style={{
                visibility: this.state.billinginitalloader ? "hidden" : "visible",
              }}>
                <Row>
                  <Col md={12}>
                    {
                      this.state.showAlert
                        ?
                        <Alert variant={this.state.alertClass} onClose={() => this.updateAlertSection(false)}
                          isopen={String(this.state.showAlert)}
                          dismissible>
                          <p className="mb-0">
                            <strong className="mr-2 font-weight-bold">
                              {this.state.alertStatus}
                            </strong>
                            {this.state.alertText}
                          </p>
                        </Alert>
                        :
                        ''
                    }

                    {
                      this.state.showAddCard
                        ?
                        <div className="portlet">
                          <div className="portlet-title">
                            <div className="caption float-none pb-1">
                              <span className="text-info">
                                {
                                  this.state.isEditMode
                                    ?
                                    'Edit credit or debit card' :
                                    "Add New Credit Card Details"
                                }

                              </span>
                              <p className="text-muted mb-0 bold">
                                {
                                  this.state.isEditMode
                                    ?
                                    'You can edit your card details' :
                                    'Please add your credit card details here to subscribe'
                                    // "You can add your credit card for the feature purchase and " +
                                    // "automatically renew"
                                }
                                .
                              </p>
                            </div>
                          </div>
                          <div className="portlet-body">
                            <Row>
                              {
                                this.state.isEditMode ?
                                  <Col md={12}>
                                    <EditCardComponent
                                      key={
                                        (this.state.exCardDetails === undefined ||
                                          this.state.exCardDetails === null)
                                          ?
                                          0 :
                                          this.state.exCardDetails.id
                                      }
                                      showAlertSection={this.showAlertSection}
                                      showRemoveCard={showRemoveCard}
                                      exCardDetails={this.state.exCardDetails}
                                      updateCardDetailToList={this.updateCardDetailToList}
                                      removeCardDetailFromList={this.removeCardDetailFromList}
                                      hideAddCardSection={this.hideAddCardSection} />
                                  </Col> :
                                  <Col md={12}>
                                    <AddCardComponent
                                      addNewCardDetailToList={this.addNewCardDetailToList}
                                      authorizationToken={this.state.braintreeAuthorizationToken}
                                      showAlertSection={this.showAlertSection}
                                      hideAddCardSection={this.hideAddCardSection} />
                                  </Col>
                              }
                            </Row>

                          </div>
                        </div>
                        :
                        ''
                    }

                    {
                      this.getEmptyCardSection()
                    }

                    {this.state.cardGroupLoading
                      ?
                      <Load />
                      :
                      (this.state.isEditMode || this.state.showAddCard) ?
                        ''
                        :
                        <PaymentCardsList
                          cards={this.state.availableCards}
                          key={this.state.renderCards}
                          showEditSection={this.showEditSection}
                          makeCardAsPrimary={this.makeCardAsPrimary}
                          showAddCardSection={this.showAddCardSection}>
                        </PaymentCardsList>
                    }


                    <div className="portlet">
                      <div className="portlet-title">
                        <div className="caption float-none pb-1">
                          <span className="text-info">Payment Profile</span>
                          <p>
                          Please add your profile details to subscribe
                          </p>

                          {/* <p>You can add your credit card for the feature purchase and
                            automatically renew.</p> */}
                        </div>
                      </div>
                      {this.state.billing_address !== null?
                       
                          <p>
                            <div className="portlet-body px-0">
                              <div className="row row-sm">
                                <div className="col-md-6">
                                  {/* <div className="payment-profile-card">
                                    <i className="card-icon">
                                      <FontAwesomeIcon icon={faUserCircle} />
                                    </i>
                                    <div className="card-content">
                                      <h5 className="card-title">Payment profile id</h5>
                                      <h6 className="card-value">7233-1232-8750</h6>
                                    </div>
                                  </div> */}
                                                                   <div className="payment-profile-card">
                                    <i className="card-icon">
                                      <FontAwesomeIcon icon={faIdCard} />
                                    </i>
                                    <div className="card-content">
                                      <h5 className="card-title">Account Type</h5>
                                      <h6 className="card-value">Business</h6>
                                    </div>
                                  </div>
                                  <div className="payment-profile-card">
                                    <i className="card-icon">
                                      <FontAwesomeIcon icon={faFlag} />
                                    </i>
                                    <div className="card-content">
                                      <h5 className="card-title">Country or Region</h5>
                                      <h6 className="card-value">{this.state.billing_address.details.country}</h6>
                                    </div>
                                  </div>
 
                                  <div className="payment-profile-card">
                                    <i className="card-icon">
                                      <FontAwesomeIcon icon={faBuilding} />
                                    </i>
                                    <div className="card-content">
                                      <h5 className="card-title">Business name & Address</h5>
                                      {this.state.changeAddress === true ?

                                        <div className="qd-payment-body">
                                          <Row>
                                                <Col md={12}>
                                                  <Formik
                                                    validationSchema={addressSchema}
                                                    onSubmit={this.submitUpdate}
                                                    initialValues={initialValues}
                                                  >
                                                    {({
                                                      values,
                                                      errors,
                                                      touched,
                                                      handleChange,
                                                      handleBlur,
                                                      handleSubmit,
                                                      isSubmitting,
                                                      isValid,
                                                    }) => (
                                                      <Form
                                                        className="needs-validation" noValidate method="POST"
                                                        onSubmit={handleSubmit}
                                                      >
                                                        <Form.Row>
                                                          <Col md={6}>
                                                            <Form.Group controlId="companyName">
                                                              <Form.Label>Company Name <span className="text-danger">*</span></Form.Label>
                                                              <Form.Control type="text"
                                                                name="companyName"
                                                                value={values.companyName}
                                                                onChange={value => {
                                                                  handleChange("companyName")(value);
                                                                  this.setState({companyName:value.target.value});
                                                                  // this.props.saveNewAddress("company_name", value.target.value, isValid);
                                                                }}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.companyName && touched.companyName}
                                                                placeholder="Abc Company" />
                                                              <Form.Control.Feedback type="invalid">
                                                                {touched.companyName && errors.companyName}
                                                              </Form.Control.Feedback>
                                                            </Form.Group>
                                                          </Col>
                                                        </Form.Row>

                                                        <Form.Row>
                                                          <Col md={6}>
                                                            <Form.Group controlId="addressLine1">
                                                              <Form.Label>Address line 1 <span className="text-danger">*</span></Form.Label>
                                                              <Form.Control type="text"
                                                                name="addressLine1"
                                                                value={values.addressLine1}
                                                                onChange={value => {
                                                                  handleChange("addressLine1")(value);
                                                                  this.setState({addressLine1:value.target.value});

                                                                  // this.props.saveNewAddress("address_line_1", value.target.value, isValid);
                                                                }}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.addressLine1 && touched.addressLine1}
                                                                placeholder="123 Abc Street" />
                                                              <Form.Control.Feedback type="invalid">
                                                                {touched.addressLine1 && errors.addressLine1}
                                                              </Form.Control.Feedback>
                                                            </Form.Group>
                                                          </Col>
                                                          <Col md={6}>
                                                            <Form.Group controlId="addressLine2">
                                                              <Form.Label>Address line 2 (optional)</Form.Label>
                                                              <Form.Control type="text"
                                                                value={values.addressLine2}
                                                                onChange={value => {
                                                                  handleChange("addressLine2")(value);
                                                                  this.setState({addressLine2:value.target.value});

                                                                  // this.props.saveNewAddress("address_line_2", value.target.value);
                                                                }}
                                                                name="addressLine2"
                                                                placeholder="#1" />
                                                            </Form.Group>
                                                          </Col>
                                                        </Form.Row>

                                                        <Form.Row>
                                                          <Col md={6}>
                                                            <Form.Group controlId="city">
                                                              <Form.Label>City <span className="text-danger">*</span></Form.Label>
                                                              <Form.Control type="text"
                                                                value={values.city}
                                                                onBlur={handleBlur}
                                                                onChange={value => {
                                                                  handleChange("city")(value);
                                                                  this.setState({city:value.target.value});

                                                                  // this.props.saveNewAddress("city", value.target.value, isValid);
                                                                }}
                                                                isInvalid={errors.city && touched.city}
                                                                name="city"
                                                                placeholder="Columbus" />
                                                              <Form.Control.Feedback type="invalid">
                                                                {touched.city && errors.city}
                                                              </Form.Control.Feedback>
                                                            </Form.Group>
                                                          </Col>
                                                          <Col md={6}>
                                                            <Form.Group controlId="state">
                                                              <Form.Label>State/Province/Region <span className="text-danger">*</span></Form.Label>
                                                              <Form.Control type="text"
                                                                value={values.state}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.state && touched.state}
                                                                onChange={value => {
                                                                  handleChange("state")(value);
                                                                  this.setState({state:value.target.value});

                                                                  // this.props.saveNewAddress("state", value.target.value, isValid);
                                                                }}
                                                                name="state"
                                                                placeholder="Kentucky" />
                                                              <Form.Control.Feedback type="invalid">
                                                                {touched.state && errors.state}
                                                              </Form.Control.Feedback>
                                                            </Form.Group>
                                                          </Col>
                                                        </Form.Row>

                                                        <Form.Row>

                                                          <Col md={6}>
                                                            <Form.Group controlId="country">
                                                              <Form.Label>Country <span className="text-danger">*</span></Form.Label>
                                                              <CustomSelect
                                                                name="Country"
                                                                // value={this.state.country}
                                                                options={this.state.optionscountry}
                                                                onChange={selectedOption => {
                                                                  handleChange("country")(selectedOption.value);
                                                                  this.setState({country:selectedOption.value});

                                                                  // this.props.saveNewAddress("country", selectedOption.value, isValid);
                                                                }}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.country && touched.country}
                                                                placeholder="Select a country"
                                                              />
                                                              <Form.Control.Feedback type="invalid">
                                                                {touched.country && errors.country}
                                                              </Form.Control.Feedback>
                                                            </Form.Group>
                                                          </Col>

                                                          <Col md={6}>
                                                            <Form.Group controlId="zipCode">
                                                              <Form.Label>ZIP code <span className="text-danger">*</span></Form.Label>
                                                              <Form.Control type="text"
                                                                name="zipCode"
                                                                value={values.zipCode}
                                                                onChange={value => {
                                                                  handleChange("zipCode")(value);
                                                                  this.setState({zipCode:value.target.value});
                                                                  // this.props.saveNewAddress("zip_code", value.target.value, isValid);
                                                                }}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.zipCode && touched.zipCode}
                                                                placeholder="12345" />

                                                              <Form.Control.Feedback type="invalid">
                                                                {touched.zipCode && errors.zipCode}
                                                              </Form.Control.Feedback>
                                                            </Form.Group>
                                                          </Col>

                                                        </Form.Row>

                                                        {/* <Form.Group controlId="formBasicCheckbox"
                                                        className="mt-2">
                                                        <Form.Label>Would you like to make this address as default address?</Form.Label>
                                                        <Form.Check
                                                          custom
                                                          type="checkbox"
                                                          name={"isPrimary"}
                                                          className="text-muted"
                                                          onChange={this.props.makeDefaultBillingAddress}
                                                          id={"custom-checkbox1"}
                                                          label={"Yes, please make this my default address."}
                                                        />
                                                      </Form.Group>
                                                      {
                                                        (this.props.billingAddresses.length > 0)
                                                          ?
                                                          <Button
                                                            className="btn-outline btn-grey btn-circle"
                                                            onClick={this.props.hideAddBillingSection}
                                                            type="submit">
                                                            Choose from existing billing addresses
                                                          </Button>
                                                          :
                                                          ''
                                                      } */}
                                                        <button
                                                          onClick={this.updateAddress}
                                                          className="btn btn-outline btn-grey btn-circle mr-2"
                                                          data-dismiss="modal">
                                                          {/* <FontAwesomeIcon icon={faCheck} />  */}
                                                          Cancel
                                                        </button>
                                                        <button type="submit"
                                                          onClick={this.onSubmit}
                                                          className="btn btn-primary btn-circle show-success-toast"
                                                          data-dismiss="modal">
                                                          <FontAwesomeIcon icon={faCheck} /> Update
                                                        </button>

                                                      </Form>)}
                                                  </Formik>
                                                </Col>
                                              </Row>
                                        </div>
                                        :
                                        <h6 className="card-value">{this.state.billing_address.details.company_name}
                                          <br />{this.state.billing_address.details.address_line_1}
                                          {/* <br />{this.state.billing_address.details.address_line_2} */}
                                          <br />{this.state.billing_address.details.city}
                                          <br />{this.state.billing_address.details.state} - {this.state.billing_address.details.zip_code}
                                          <br />{this.state.billing_address.details.country}
                                        </h6>

                                      }
                                    </div>
                                  </div>
                                  {this.state.showChangeAddress?
                                  <div>
                                    <button type="submit" className="btn btn-outline-primary btn-circle ml-5 mb-2" onClick={this.updateAddress}><FontAwesomeIcon icon={faPencilAlt} />Change Address</button>
                                  </div>
                                  
                                  :
                                  
                                  ''}
                                  
                                </div>
                              </div>
                            </div>
                          </p>
                      
                      :
                      
                      ''}


                    </div>

                  </Col>
                </Row>
              </div>
            </div>
          </div>

        </div>


        <div id="toastMsg">
          <Toast className={this.state.toastClass}
            onClose={() => this.closeToast()}
            show={this.state.toastStatus}
            delay={3000} autohide>
            <div className="d-flex align-items-center">

              {this.state.toastStatus ?
                <i className="toast-icon">
                  <FontAwesomeIcon icon={this.state.toastIcon} />
                </i>
                :
                ''
              }

              <Toast.Body>
                <strong className="toast-title">{this.state.toastheading}</strong>
                <p>{this.state.toastMsg}</p>
              </Toast.Body>
              <button type="button"
                onClick={this.closeToast}
                className="close"
                data-dismiss="toast"
                aria-label="Close" />
            </div>
          </Toast>
        </div>

      </>
    );
  }

}
export default PaymentProfile;