import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {CONFIGURE_DATA_SOURCE_URL,
        PROFILE_DATA_URL} from "../../utils/constant";
import {Mediakind} from "./../../utils/mediaKind"


class VideoContent extends Component {
  constructor(props) {
        super(props);
        this.getVideo = this.getVideo.bind(this);
        this.getVideoUrl = this.getVideoUrl.bind(this);
        this.state = {
        }
  }

  renderContent(name) {
      if(this.props.modelName !== undefined) {
        switch(this.props.modelName) {
            case "Model Errors":
                  return (<><h4 className="text-info">{this.props.modelName}</h4>
                            <p>This page is the primary source to look for any issues around your model. All the supported metrics on the model side are configured with a threshold by default but it can be changed at any point in time from the Metrics page. Any threshold breach on drift detected is highlighted as the error in red.</p>
                            <h4 className="text-info">Drifts in the configured model</h4>
                            <p>This section is a view to understand different errors/drifts contributing to the model decay. At this point of time, any threshold violation of the performance metrics will appear under this section.</p>
                            <h4 className="text-info">Drifts in the serving data of the model</h4>
                            <p>This section is a view to represent the overall drift in the serving data. Many features might be contributing to it and the same can be understood by clicking on contribution in the options.</p>
                            <h4 className="text-info">Consistency Errors in the Serving data in comparison with the Training data</h4>
                            <p>This section highlights any consistency error between serving and training data. Consistency problems can easily lead to model failure if unnoticed.</p>
                            <h4 className="text-info">Feature Drifts on Serving data over time</h4>
                            <p>This section captures the drifts in features of the serving data over time which can cause a potential decay of the model. </p>
                            <h4 className="text-info">Feature Drifts on Serving data in comparison with training data</h4>
                            <p>This section captures the drifts in features of the serving data in comparison with the training data. Variations in serving data can cause the poor performance of a model compared to the time it was trained.</p>
                            <h4 className="text-info">Drifts in Predictions of model</h4>
                            <p>This section captures how the predictions of the model themselves are drifting over time. It needn’t always point to a performance issue in the model but will be a good starting point to understand any imbalance of serving data.</p>
                            <h4 className="text-info">Drifts in response of model</h4>
                            <p>This section captures how the business response to the predictions made are drifting over time.</p>
                          </>);
            default:
                  return ""
        }
      }
      switch(name) {
         case "Discover":
              return (<><h4 className="text-info">{name}</h4></>);
         default:
                  return ""
      }
  }

  getVideo() {
        switch(this.props.name) {
            case "ml_model":
                  return "https://www.youtube.com/embed/YiuGaP_UhwA";
            default:
                  return ""
        }
  }

  getVideoUrl(){
     let video_url = CONFIGURE_DATA_SOURCE_URL;
     switch(this.props.video_url) {
        case "profile_data":
              return PROFILE_DATA_URL;
        default:
              return video_url
     }
  }

  render() {
    let video_url = this.getVideoUrl();
     return (
              <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName="modal-vertical shadow" className="fixed-right model__help" size="md">
                  <Modal.Header className="modal-header py-3 align-items-center bg-dark text-white" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                              <h4 className="mb-0"><i><FontAwesomeIcon icon={faQuestionCircle}/></i>Help</h4>
                        </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="qd-search-bar d-none">
                        <div className="input-group border">
                          <div className="input-group-prepend">
                            <button id="button-addon4" type="button" className="btn"><FontAwesomeIcon icon={faSearch}/></button>
                          </div>
                          <input type="search" placeholder="Search" className="form-control"/>
                        </div>
                    </div>
                    <div className="model__help-content">
                        {this.renderContent(this.props.name)}
                     </div>
                    <div className="card my-3">
                        <div className="card-body p-0">
                          <Mediakind
                            url= {video_url}
                            />
                        </div>
                    </div>
                  </Modal.Body>
                  {/* <Modal.Footer>
                   </Modal.Footer> */}
              </Modal>
    );
  }
}

export default VideoContent;
